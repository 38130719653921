import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Autocomplete,
  Avatar,
  Typography,
  Container,
  Card,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add, RemoveCircle } from '@mui/icons-material';
import * as Yup from 'yup';
import moment from 'moment';
import avatar from '../assets/avatar.png';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';

const AddMaterialsToProject = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = React.useState([]);
  const [open3, setOpen3] = React.useState(false);
  const [supervisors, setSupervisors] = React.useState([]);
  const [materials, setMaterials] = React.useState([{}]);

  const validationSchema = Yup.object({
    materials: Yup.array().required('Materials are required'),
    projectId: Yup.string().required('Project is required'),
    date: Yup.date().required('Date is required'),
    description: Yup.string(),
  });

  const getProjects = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
        params: {
          statuses: ['APPROVED', 'ONGOING'],
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSupervisors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'SUPERVISOR',
        },
      });
      console.log(res.data.data);
      setSupervisors(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getMaterials = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/material/active`);
      console.log(res);
      if (res.data.isError === false) {
        setMaterials(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProjects();
    getSupervisors();
    getMaterials();
  }, []);

  return (
    <Page title="Add Journal">
      <Container maxWidth="xl">
        <PageHeader title="Add Materials to Project" />
        <Card>
          <Box m={2}>
            <Formik
              initialValues={{
                materials: [
                  {
                    materialId: '',
                    quantity: '',
                    unit: '',
                  },
                ],
                projectId: '',
                date: moment().format('YYYY-MM-DD'),
                description: '',
                supervisor: '',
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/materialFlowLog/addMultipleToProject`,
                    values
                  );
                  actions.setSubmitting(false);
                  toast.success(response.data.message);
                  navigate('/dashboard/warehouse/material');
                } catch (error) {
                  console.log(error);
                  actions.setSubmitting(false);
                  toast.error(error.response.data.message);
                }
              }}
            >
              {(formik) => (
                <Form>
                  <Stack spacing={2} mt={2}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                      <Autocomplete
                        id="asynchronous-demo"
                        fullWidth
                        open={open3}
                        onOpen={() => {
                          setOpen3(true);
                        }}
                        onClose={() => {
                          setOpen3(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={projects}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('projectId', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            error={Boolean(formik.touched.projectId && formik.errors.projectId)}
                            helperText={formik.touched.projectId && formik.errors.projectId}
                            label="Project"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                      <TextField
                        fullWidth
                        id="date"
                        name="date"
                        label="Date"
                        sx={{ width: '220px' }}
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        {...formik.getFieldProps('date')}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                      />
                    </Stack>

                    <Autocomplete
                      fullWidth
                      id="asynchronous-demo"
                      onChange={(_, value) => {
                        console.log('Selected Value:', value);
                        formik.setFieldValue('supervisor', value ? value._id : null);
                      }}
                      isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                      getOptionLabel={(option) => option.fullname}
                      getOptionSelected={(option, value) => option.fullname === value.fullname}
                      options={supervisors}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supervisor"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar
                              sx={{ width: 40, height: 40, cursor: 'pointer' }}
                              src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                            />
                            <Stack direction={'column'}>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                {option.fullname}
                              </Typography>
                              <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                {option.role?.name?.replaceAll('_', ' ')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </li>
                      )}
                    />

                    <TextField
                      fullWidth
                      multiline
                      id="description"
                      name="description"
                      label="Description"
                      {...formik.getFieldProps('description')}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                    {formik.values.materials.map((material, index) => (
                      <Stack key={index} direction="row" spacing={2} alignItems="center">
                        <Typography sx={{ fontWeight: 'bold' }}>{index + 1}.</Typography>
                        <Autocomplete
                          fullWidth
                          id="asynchronous-demo"
                          onChange={(_, value) => {
                            formik.setFieldValue(`materials[${index}].materialId`, value._id);
                            formik.setFieldValue(`materials[${index}].unit`, value.unit);
                          }}
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => `${option.name} (${option.stock} ${option.unit} available)`}
                          options={materials}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              error={Boolean(formik.touched.materials && formik.errors.materials)}
                              helperText={formik.touched.materials && formik.errors.materials}
                              label="Material"
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                  src={option?.image && process.env.REACT_APP_API_URL + option.image}
                                >
                                  {option?.image ? '' : option.name.charAt(0).toUpperCase()}
                                </Avatar>

                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.name}
                                  </Typography>
                                  <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      Unit:
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      {option.unit}
                                    </Typography>
                                  </Stack>
                                  <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      Stock:
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      {option.stock}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                        <TextField
                          id="quantity"
                          name="quantity"
                          sx={{
                            width: '150px',
                          }}
                          inputProps={{ min: 1, max: material.stock }}
                          label="Quantity"
                          type="number"
                          {...formik.getFieldProps(`materials[${index}].quantity`)}
                          error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                          helperText={formik.touched.quantity && formik.errors.quantity}
                        />
                        <RemoveCircle
                          sx={{
                            cursor: 'pointer',
                          }}
                          color="error"
                          onClick={() => {
                            formik.setFieldValue(
                              'materials',
                              formik.values.materials.filter((_, i) => i !== index)
                            );
                          }}
                        />
                      </Stack>
                    ))}
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="end">
                      <Button
                        variant="outlined"
                        onClick={() => {
                          formik.setFieldValue('materials', [
                            ...formik.values.materials,
                            {
                              materialId: '',
                              quantity: '',
                            },
                          ]);
                        }}
                        startIcon={<Add />}
                      >
                        Material
                      </Button>
                    </Stack>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={formik.isSubmitting}
                        sx={{
                          width: '100px',
                        }}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AddMaterialsToProject;
