import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
} from '@mui/material';
import { ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';

// components
import Scrollbar from './Scrollbar';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function WorkerWorkSummary() {
  const [searchParams, setSearchParams] = useSearchParams();
  const workerId = searchParams.get('workerId');
  const [workerSummaryData, setWorkerSummaryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [workerSummaryCount, setWorkerSummaryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [worker, setWorker] = useState({});

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getWorkerSumamry(temp);
  };

  const getWorkerSumamry = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/workEntry/summary`, {
        params: {
          ...options,
          workerId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setWorkerSummaryCount(res.data.data.maxRecords);
          setWorkerSummaryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWorkerSumamryWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/workEntry/summary`, {
        params: {
          ...options,
          workerId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setWorkerSummaryCount(res.data.data.maxRecords);
          setWorkerSummaryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWorker = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member`, { params: { id: workerId, role: 'WORKER' } })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setWorker(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(workerSummaryCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getWorkerSumamry(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, companyName: e.target.value };
    setOptions(temp);
    setPages(0);
    getWorkerSumamryWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter._id.length) {
      temp._id = filter._id;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.contactPerson) {
      temp.contactPerson = filter.contactPerson;
    }
    if (filter.companyName) {
      temp.companyName = filter.companyName;
    }
    if (filter.email) {
      temp.email = filter.email;
    }
    if (filter.phone1) {
      temp.phone1 = filter.phone1;
    }
    if (filter.phone2) {
      temp.phone2 = filter.phone2;
    }
    if (filter.companyAddress) {
      temp.companyAddress = filter.companyAddress;
    }

    setOptions(temp);
    setPages(0);
    getWorkerSumamry(temp);
  };
  useEffect(() => {
    getWorkerSumamry(options);
    getWorker();
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getWorkerSumamry(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                {/* <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search ..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack> */}
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={2} my={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Avatar
                    src={
                      (worker?.profilePic && process.env.REACT_APP_API_URL + worker.profilePic) ||
                      'https://www.w3schools.com/howto/img_avatar.png'
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                  <Stack>
                    <Typography sx={{ fontWeight: 'bold' }}>{worker?.fullname}</Typography>
                    <Typography sx={{ fontSize: '14px' }}>{worker?.email}</Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Project</TableCellCustom>
                        <TableCellCustom> Work Hours</TableCellCustom>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workerSummaryData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{item?.projectData?.name}</TableCellCustom>
                          <TableCellCustom>{item?.totalWorkHrs}</TableCellCustom>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'} 
                count={workerSummaryCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </Container>
        </>
      )}
    </>
  );
}
