// material
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import ProjectsOfWorkerTable from '../components/ProjectsOfWorkerTable';

export default function ProjectsOfWorker() {
  const [searchParams, setSearchParams] = useSearchParams();
  const workerId = searchParams.get('workerId');

  return (
    <Page title="Worker Projects">
      <Container maxWidth="xl">
        <PageHeader title="Worker Projects" />
        <ProjectsOfWorkerTable workerId={workerId}/>
      </Container>
    </Page>
  );
}
