import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
} from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBack, Delete } from '@mui/icons-material';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import Filter from '../components/filters/Leads';
import ManagerFilter from '../components/filters/LeadFilterForManager';
import TelecallerFilter from '../components/filters/LeadFilterForTeleCaller';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/LeadStatusChange';
import Popup1 from '../components/popups/LeadDelete';
import ExcelUpload from '../components/popups/ExcelUpload';
import Export from '../components/filters/LeadExport';
import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import { StyledTableRow } from '../theme/styledComponents';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: 0,
  margin: 0,
}));

export default function Leads() {
  const { accType } = useSelector((state) => state.user.userInfo);
  console.log('accType', accType);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const isLeadManager = accType && accType === 'SALES_MANAGER';
  const isTelecaller = accType && accType === 'SALES_STAFF';

  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState({});

  const [leadData, setLeadData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [leadCount, setLeadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const telecallerRef = useRef(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');

  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState({});

  const leadCreateAccess = hasAccessOf('crm.lead.c');
  const leadReadAccess = hasAccessOf('crm.lead.r');
  const leadWriteAccess = hasAccessOf('crm.lead.w');
  const leadDeleteAccess = hasAccessOf('crm.lead.d');

  // for export
  const [openExportModal, setOpenExportModal] = useState(false);

  // const leadSchema = Yup.object().shape({
  //   fullname: Yup.string().required('Name is required'),
  //   source: Yup.string().required('Source is required'),
  //   category: Yup.string().required('Category is required'),
  // });

  // if (admin) {
  //   leadSchema.shape({
  //     leadManager: Yup.string().required('Sales Manager is required'),
  //     teleCaller: Yup.string().required('Telecaller is required'),
  //   });
  // }

  // if (isLeadManager) {
  //   leadSchema.shape({
  //     teleCaller: Yup.string().required('Telecaller is required'),
  //   });
  // }

  const getStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/all/active`);
      console.log('status list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setStatus(res.data.data.status);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.statusData.name}` === event.target.value) {
      return;
    }
    const temp = status.filter((statusItem) => statusItem.name === event.target.value)[0];
    console.log(temp);
    setSelectedStatus(temp);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getLeads(options);
    }
    if (message) {
      toast(message);
    }
  };

  const openExcelUploadModal = () => {
    setOpenExportModal(true);
  };

  const closeExportModal = () => {
    setOpenExportModal(false);
    getLeads(options);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLeads(temp);
  };

  const getLeads = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/all`, {
        params: options,
      })
      .then((res) => {
        console.log('Leads', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLeadCount(res.data.data.maxRecords);
          setLeadData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLeadsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setLeadCount(res.data.data.maxRecords);
          setLeadData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getStatusColor1 = (statusName) => {
    const statusMapping = {
      Pending: '#eaea7e',
      Ongoing: '#FDDA0D',
      'Decision Pending': '#d1bc00',
      Interested: '#0b9703',
      'Not Interested': '#CC5500',
      Regret: '#EE4B2B',
    };
    return statusMapping[statusName] || '';
  };

  const exportLead = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/lead/export`, options);
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };

    setOptions(temp);
    setPages(0);
    getLeadsWithoutLoading(temp);
  };

  const handleStatusFilterChange = (event) => {
    if (event.target.value !== 'all') {
      const temp = { ...options, status: event.target.value };

      setOptions(temp);
      setIsLoading(true);
      getLeads(temp);
      setStatusFilter(event.target.value);
    } else {
      const temp = { ...options, status: '' };

      setOptions(temp);
      setIsLoading(true);
      getLeads(temp);
      setStatusFilter('all');
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { ...options };
    if (filter.fullname) {
      temp.fullname = filter.fullname;
    }
    if (filter.email) {
      temp.email = filter.email.trim();
    }
    if (filter.phone) {
      temp.phone = filter.phone;
    }
    if (filter.leadManager) {
      temp.leadManager = filter.leadManager;
    }
    if (filter.teleCaller) {
      temp.teleCaller = filter.teleCaller;
    }
    if (filter.status) {
      temp.status = filter.status;
    }
    if (filter.category) {
      temp.category = filter.category;
    }
    if (filter.source) {
      temp.source = filter.source;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.customer) {
      temp.customer = filter.customer;
    }

    setOptions(temp);
    setPages(0);
    getLeads(temp);
    setSelectedFilter(filter);
  };

  useEffect(() => {
    if (leadReadAccess) {
      getLeads(options);
    }
  }, []);

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <>
      <Page title="RFQs">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {leadCreateAccess && (
              <ExcelUpload openExportModal={openExportModal} closeExportModal={closeExportModal} getLeads={getLeads} />
            )}

            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
                <PageHeader title="RFQs" />

                <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" alignItems={'center'} spacing={5}>
                  {leadReadAccess && (
                    <Stack
                      direction="row"
                      sx={{ width: '100%' }}
                      justifyContent="end"
                      alignItems={'center'}
                      spacing={1}
                    >
                      <Export />
                      <LoadingButton
                        endIcon={<Iconify icon="ph:export-bold" />}
                        variant="outlined"
                        color="primary"
                        onClick={() => exportLead(options)}
                        loading={isLoading1}
                        size="small"
                      >
                        Export
                      </LoadingButton>
                    </Stack>
                  )}
                </Stack>
              </Stack>

              {admin && leadReadAccess && (
                <Filter applyFilters={applyFilters} selectedFilter={selectedFilter} filterValues={options} />
              )}
              {!!isLeadManager && leadReadAccess && (
                <ManagerFilter applyFilters={applyFilters} filterValues={options} />
              )}
              {!!isTelecaller && leadReadAccess && (
                <TelecallerFilter applyFilters={applyFilters} filterValues={options} />
              )}
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search lead..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                    <TextField
                      select
                      value={statusFilter}
                      label="Status"
                      onChange={(e) => handleStatusFilterChange(e)}
                      sx={{ width: '150px', height: '100%' }}
                    >
                      <MenuItem value={'all'}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                          <Box
                            sx={{
                              width: 14,
                              height: 14,
                              backgroundColor: '#fff',
                              borderRadius: '50%',
                            }}
                          />
                          <Box>All</Box>
                        </Stack>
                      </MenuItem>
                      {status.map((item, index) => (
                        <MenuItem value={item._id} key={index}>
                          <Stack direction="row" key={index} spacing={0.5} alignItems="center">
                            <Box
                              sx={{
                                width: 14,
                                height: 14,
                                backgroundColor: getStatusColor1(item.name),
                                borderRadius: '50%',
                              }}
                            />
                            <Box>{item.name}</Box>
                          </Stack>
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    {leadCreateAccess && (
                      <Button startIcon={<AddIcon />} variant="contained" onClick={openExcelUploadModal}>
                        Excel upload
                      </Button>
                    )}
                    {leadCreateAccess && (
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => navigate('/dashboard/crm/lead/add')}
                      >
                        Add RFQ
                      </Button>
                    )}
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>Sl No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>RFQ No.</TableCell>
                          <TableCell>Fullname</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Phone</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Customer</TableCell>
                          <TableCell>Source</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Sales Staff</TableCell>
                          <TableCell>Manager</TableCell>
                          <TableCell>Action</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {leadData?.map((item, id) => (
                          <StyledTableRow key={id}>
                            <TableCellCustom>
                              <Box
                                sx={{
                                  width: 14,
                                  height: 34,
                                  backgroundColor: getStatusColor1(item?.statusData?.name),
                                  padding: 0,
                                  margin: 0,
                                }}
                              />
                            </TableCellCustom>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>{Moment(item.createdAt).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{item.categoryData.name}</TableCell>
                            <TableCell>{item.leadNumber}</TableCell>
                            <TableCell sx={{ minWidth: '150px' }}>{item.fullname}</TableCell>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{item.phone}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.customer ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{item?.sourceData?.name}</TableCell>
                            <TableCell>
                              {leadWriteAccess ? (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={item?.statusData?.name}
                                    onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    {status &&
                                      status?.map((item) => (
                                        <MenuItem key={item._id} value={item.name}>
                                          <Stack direction="row" spacing={0.5} alignItems="center">
                                            <Box
                                              sx={{
                                                width: 14,
                                                height: 14,
                                                backgroundColor: getStatusColor1(item.name),
                                                borderRadius: '50%',
                                              }}
                                            />
                                            <Box>{item.name}</Box>
                                          </Stack>
                                        </MenuItem>
                                      ))}
                                  </Select>
                                  {selectedIndex === id && (
                                    <Popup item={item} handleClose={handleClose} status={selectedStatus} />
                                  )}
                                </FormControl>
                              ) : (
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                  <Box
                                    sx={{
                                      width: 14,
                                      height: 14,
                                      backgroundColor: getStatusColor1(item.name),
                                      borderRadius: '50%',
                                    }}
                                  />
                                  <Box>{item.name}</Box>
                                </Stack>
                              )}
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ cursor: admin ? 'pointer' : 'default' }}
                                onClick={() => {
                                  if (admin) {
                                    navigate(`/dashboard/general/employees/sales-staff/${item?.telecallerData?._id}`);
                                  }
                                }}
                              >
                                {item?.telecallerData?.fullname}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{ cursor: admin ? 'pointer' : 'default' }}
                                onClick={() => {
                                  if (admin) {
                                    navigate(`/dashboard/general/employees/lead-manager/${item?.managerData?._id}`);
                                  }
                                }}
                              >
                                {item?.managerData?.fullname}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" spacing={1}>
                                <Link to={`/dashboard/crm/lead/view/${item._id}`}>
                                  <Button
                                    variant="contained"
                                    startIcon={<RemoveRedEyeIcon />}
                                    size="small"
                                    color="viewButton"
                                  >
                                    View
                                  </Button>
                                </Link>
                              </Stack>
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                    const temp = { ...options, size: e.target.value, page: 0 };
                    setPages(0);
                    setOptions(temp);
                    getLeads(temp);
                  }}
                  component={'div'}
                  count={leadCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
