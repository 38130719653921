import { Container, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import React from 'react';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import LeadNotes from '../components/LeadNotes';
import LeadAttachments from '../components/LeadAttachments';

function RfqNotes() {
  //  i want to get state stored in react-router-dom
  const location = useLocation();
  const project = location.state;
  console.log('project', project);

  return (
    <Page title="RFQ Notes">
      <Container maxWidth="xl">
        <PageHeader title={'RFQ Notes'} />
        <Stack spacing={3}>
          <Typography variant="h6" gutterBottom> 
            Project Name: {project.name}
          </Typography>
          <LeadNotes hideAddNote={'true'} />
          <LeadAttachments />
        </Stack>
      </Container>
    </Page>
  );
}

export default RfqNotes;
