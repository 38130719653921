import { Box, Card, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

const PermissionCheckboxes = ({ subCategory, accessList, setAccessList }) => {
  const handlePermissionChange = (perm) => {
    const newAccessList = { ...accessList };
    const newSubCategory = { ...newAccessList[subCategory] };
    newSubCategory[perm] = !newSubCategory[perm];
    newAccessList[subCategory] = newSubCategory;
    setAccessList(newAccessList);
  };

  return (
    <Stack direction="row" spacing={0.7}>
      {Object.keys(accessList[subCategory]).map((perm) => (
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
            },
          }}
          key={perm}
          control={
            <Checkbox
              size="small"
              checked={accessList[subCategory][perm]}
              onChange={() => handlePermissionChange(perm)}
            />
          }
          label={perm.charAt(0).toUpperCase() + perm.slice(1)}
        />
      ))}
    </Stack>
  );
};

function CheckBoxGroup({ accessList, setAccessList, name }) {
  console.log(accessList);
  return (
    <Grid item xs={12} md={6}>
      {/* <Typography variant="h6">{name}</Typography> */}
      <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
        <FormControlLabel
          sx={{ '& .MuiFormControlLabel-label': { fontWeight: 600, fontSize: '18px' } }}
          control={
            <Checkbox
              color="primary"
              size="large"
              checked={Object.keys(accessList).every((subCategory) =>
                Object.values(accessList[subCategory]).every((perm) => perm)
              )}
              indeterminate={
                Object.keys(accessList).some((subCategory) =>
                  Object.values(accessList[subCategory]).some((perm) => perm)
                ) &&
                !Object.keys(accessList).every((subCategory) =>
                  Object.values(accessList[subCategory]).every((perm) => perm)
                )
              }
              onChange={(e) => {
                const updateAccessList = (isChecked) => {
                  const newAccessList = { ...accessList };
                  Object.keys(newAccessList).forEach((subCategory) => {
                    const newSubCategory = { ...newAccessList[subCategory] };
                    Object.keys(newSubCategory).forEach((perm) => {
                      newSubCategory[perm] = isChecked;
                    });
                    newAccessList[subCategory] = newSubCategory;
                  });
                  setAccessList(newAccessList);
                };

                if (e.target.checked) {
                  updateAccessList(true);
                } else {
                  updateAccessList(false);
                }
              }}
            />
          }
          label={name}
        />
        <Typography varaint="caption">
          {/* <>C</> = Create &nbsp; | &nbsp; <>R</> = Read &nbsp; | &nbsp; <>W</> = Write &nbsp; | &nbsp; <>D</> = Delete */}
        </Typography>
      </Stack>
      {/* <Stack gap={3} direction={'row'} justifyContent="flex-start" paddingBottom="20px" flexWrap={'wrap'}>
        {Object.keys(accessList).map((subCategory) => (
          <Card sx={{ padding: '10px' }} key={subCategory}>
            <Stack key={subCategory}>
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontWeight: 600,
                  },
                }}
                control={
                  <Checkbox
                    color="secondary"
                    size="medium"
                    checked={Object.values(accessList[subCategory]).every((perm) => perm)}
                    indeterminate={
                      Object.values(accessList[subCategory]).some((perm) => perm) &&
                      !Object.values(accessList[subCategory]).every((perm) => perm)
                    }
                    onChange={() => {
                      const newAccessList = { ...accessList };
                      const newSubCategory = { ...newAccessList[subCategory] };
                      const allPerms = Object.keys(newSubCategory);
                      const allPermsTrue = allPerms.every((perm) => newSubCategory[perm]);
                      allPerms.forEach((perm) => {
                        newSubCategory[perm] = !allPermsTrue;
                      });
                      newAccessList[subCategory] = newSubCategory;
                      setAccessList(newAccessList);
                    }}
                  />
                }
                label={subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
              />
              <PermissionCheckboxes
                category="accessList"
                subCategory={subCategory}
                accessList={accessList}
                setAccessList={setAccessList}
              />
            </Stack>
          </Card>
        ))}
      </Stack> */}
    </Grid>
  );
}

export default CheckBoxGroup;
