import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  Dialog,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  OutlinedInput,
  useMediaQuery,
  Chip,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/LedgerCategories';

// components
import Scrollbar from './Scrollbar';
import AddLedgerSubCategory from './popups/AddLedgerSubCategory';
import { StyledTableRow } from '../theme/styledComponents';

export default function LedgerCategories() {
  const matches = useMediaQuery('(max-width:600px)');
  const [ledgerCategoryData, setLedgerCategoryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [ledgerCategoryCount, setLedgerCategoryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const getLedgerCategorys = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/ledgerCategory/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLedgerCategoryData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getLedgerCategorys(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getLedgerCategorys(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Sub Categories</TableCell>
                        <TableCell sx={{ width: '80px' }}>Action</TableCell>
                        {/* <TableCell>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ledgerCategoryData?.map((item, id) => (
                       <StyledTableRow key={id}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>
                            <Chip
                              size="small"
                              label={item.type}
                              color={item.type === 'DEBIT' ? 'orangee1' : 'greenish'}
                            />
                          </TableCell>
                          <TableCell>
                            <Stack spacing={1} my={2}>
                              {item.children.map((child, index) => (
                                <Stack direction="row" spacing={1} key={index}>
                                  <Typography sx={{ minWidth: '20px' }}>{index + 1}</Typography>
                                  <Typography>{child}</Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <AddLedgerSubCategory ledgercategory={item} callApi={getLedgerCategorys} />
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </>
          </Container>
        </>
      )}
    </>
  );
}
