import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { Add, DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { FilePond, File, registerPlugin } from 'react-filepond';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';
import { convertToWords, formatNumberLpoReference } from '../utils/commonFunctions';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: '#000',
  backgroundColor: '#fde18e',
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const AddPurchase = () => {
  const navigate = useNavigate();
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [company, setCompany] = useState({});
  const [vendor, setVendor] = useState({});
  const [files, setFiles] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [paymentTerm, setPaymentTerm] = useState('');
  const [notes, setNotes] = useState('');
  const [purchaseOrders, setPurchaseOrders] = useState([{}]);

  const convertToParseFloat = (value) => parseFloat(value).toFixed(5);

  const purchaseSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
    vendorId: Yup.string().required('Vendor is required'),
    billNumber: Yup.string().required('Bill Number is required'),
  });

  const formik = useFormik({
    initialValues: {
      invoiceDate: moment().format('YYYY-MM-DD'),
      vendorId: '',
      subTotal: '',
      totalTax: '',
      grandTotal: '',
      items: [],
      billNumber: '',
      termsAndConditions: '',
      attachments: [],
      paymentTerms: [],
      notes: [],
      purchaseOrderId: '',
      grandTotalInWords: '',
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      const data = new FormData();
      files.forEach((item) => {
        data.append('attachments', item.file);
      });
      data.append('invoiceDate', values.invoiceDate);
      data.append('vendorId', values.vendorId);
      data.append('subTotal', convertToParseFloat(values.subTotal));
      data.append('totalTax', convertToParseFloat(values.totalTax));
      data.append('grandTotal', convertToParseFloat(values.grandTotal));
      data.append('items', JSON.stringify(values.items));
      data.append('billNumber', values.billNumber);
      data.append('termsAndConditions', values.termsAndConditions);
      data.append(
        'paymentTerms',
        values?.paymentTerms?.length > 0 ? JSON.stringify(values.paymentTerms) : JSON.stringify([paymentTerm])
      );
      data.append('notes', values?.notes?.length > 0 ? JSON.stringify(values.notes) : JSON.stringify([notes]));
      data.append('purchaseOrderId', values.purchaseOrderId);
      data.append('grandTotalInWords', values.grandTotalInWords);

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/purchase`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getVendors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/active`);
      console.log(res.data.data, 'vendors');
      setVendors(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaxes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tax/active/all`);
      console.log(res.data.data);
      setTaxes(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const totalCalculator = (quantity, price, tax) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    total += (total * Number(tax)) / 100;
    return total.toFixed(5);
  };

  const taxCalculator = (price, tax) => {
    let totalTax = 0;
    totalTax = (price * Number(tax)) / 100;
    return totalTax.toFixed(2);
  };

  const getCompany = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`);
      console.log(res.data.data, 'company');
      setCompany(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendor = async (_id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, { params: { _id } });
      console.log(res.data, 'vendor');
      if (!res.data.isError) {
        setVendor(res.data.data.vendor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPurchaseOrderOrders = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseOrder/pending/list`);
      console.log(res.data.data);
      setPurchaseOrders(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const setFormValues = (data) => {
    formik.setFieldValue('items', data.items);
  };

  const getPurchaseOrder = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseOrder/one`, {
        params: {
          _id: id,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setFormValues(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/accountSettings`);
      console.log(res.data.data, 'accountSettings');
      setAccountSettings(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendors();
    getTaxes();
    getCompany();
    getAccountSettings();
    getPurchaseOrderOrders();
  }, []);

  useEffect(() => {
    if (values.vendorId) {
      getVendor(values.vendorId);
    }
  }, [values.vendorId]);

  useEffect(() => {
    if (values.purchaseOrderId) {
      const purchaseOrder = purchaseOrders.find((item) => item._id === values.purchaseOrderId);
      formik.setFieldValue('vendorId', purchaseOrder.vendorId);
      getPurchaseOrder(purchaseOrder._id);
    }
  }, [values?.purchaseOrderId]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    let totalTax = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.unitPrice) * Number(item.quantity);
      totalTax += Number(item.unitPrice) * Number(item.quantity) * (Number(item.tax) / 100);
      grandTotal += Number(item.total);
    });
    const grandTotalInWords = convertToWords(grandTotal);
    formik.setFieldValue('grandTotalInWords', grandTotalInWords);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
    formik.setFieldValue('totalTax', totalTax);
  }, [values.items]);

  useEffect(() => {
    const attachments = [];
    files.forEach((item) => {
      attachments.push(item.file);
    });
    console.log(attachments);
    formik.setFieldValue('attachments', attachments);
  }, [files]);
  return (
    <Page title="Add Purchase">
      <Container maxWidth="xl">
        <PageHeader title="Add Purchase" />
        <Card>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                  direction={'row'}
                  px={5}
                  py={3}
                  sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                >
                  {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2}>
                      <StyledTextField
                        label="Invoice Date"
                        size="small"
                        type="date"
                        {...getFieldProps('invoiceDate')}
                        error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                        helperText={touched.invoiceDate && errors.invoiceDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <StyledTextField
                        label="Bill Number"
                        size="small"
                        type="text"
                        {...getFieldProps('billNumber')}
                        error={Boolean(touched.billNumber && errors.billNumber)}
                        helperText={touched.billNumber && errors.billNumber}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Grid container>
                  <Grid item xs={12}>
                    <Stack px={5} pt={5} direction={'row'} spacing={2} alignItems={'center'}>
                      <Typography sx={{ width: '90px' }}>Purchase Order:</Typography>
                      <Autocomplete
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        getOptionLabel={(option) => formatNumberLpoReference(option.poReference)}
                        getOptionSelected={(option, value) => option._id === value._id}
                        options={purchaseOrders}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('purchaseOrderId', value._id);
                          } else {
                            formik.setFieldValue('vendorId', '');
                            formik.setFieldValue('purchaseOrderId', '');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{
                              minWidth: '250px',
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.purchaseOrderId && errors.purchaseOrderId)}
                            helperText={touched.purchaseOrderId && errors.purchaseOrderId}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  {!values?.purchaseOrderId && (
                    <Grid item xs={12}>
                      <Stack px={5} pt={2} direction={'row'} spacing={2} alignItems={'center'}>
                        <Typography sx={{ width: '90px' }}>Supplier:</Typography>
                        <Autocomplete
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.companyName === value.companyName}
                          getOptionLabel={(option) => option.companyName}
                          getOptionSelected={(option, value) => option.companyName === value.companyName}
                          options={vendors}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('vendorId', value._id);
                            } else {
                              formik.setFieldValue('vendorId', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                minWidth: '250px',
                              }}
                              InputProps={{
                                ...params.InputProps,
                              }}
                              error={Boolean(touched.vendorId && errors.vendorId)}
                              helperText={touched.vendorId && errors.vendorId}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 35, height: 35, cursor: 'pointer' }}
                                  src={
                                    (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) ||
                                    'https://www.w3schools.com/howto/img_avatar2.png'
                                  }
                                />
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.companyName}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                    {option.contactPerson}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                    {option.phone1}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        From
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{vendor.companyName}</Typography>
                        <Typography>{vendor.contactPerson}</Typography>
                        <Typography>{vendor.companyAddress}</Typography>
                        <Typography>{vendor.email}</Typography>
                        <Typography>{vendor.phone1}</Typography>
                        {vendor?.trn && <Typography>{`TRN: ${vendor?.trn}`}</Typography>}
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        To
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{company.companyName}</Typography>
                        <Typography>{company.address}</Typography>
                        <Typography>{company.pin}</Typography>
                        <Typography>{company.phone}</Typography>
                        <Typography>{company.email}</Typography>
                        <Typography>{`TRN: ${accountSettings?.trn}`}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack p={5}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sl No.</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Unit Price</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Total</TableCell>
                              <TableCell>Tax</TableCell>
                              <TableCell>Tax Amount</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell sx={{ width: '50px' }}>{index + 1}</TableCell>
                                <TableCell
                                  sx={{
                                    minWidth: '250px',
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    value={item?.name || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = { ...newItems[index], name: e.target.value };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: '120px',
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    type="number"
                                    value={item.unitPrice || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        unitPrice: Number(e.target.value),
                                        total: totalCalculator(item.quantity, Number(e.target.value), item.tax),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell sx={{ width: '120px' }}>
                                  <TextField
                                    fullWidth
                                    type="number"
                                    value={item.quantity || ''}
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        return;
                                      }
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        quantity: Number(e.target.value),
                                        total: totalCalculator(Number(e.target.value), item.unitPrice, item.tax || 0),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Typography>{item.quantity * item.unitPrice}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Stack direction="row" spacing={5} alignItems="center">
                                    <Autocomplete
                                      sx={{
                                        minWidth: '150px',
                                        maxWidth: '200px',
                                      }}
                                      fullWidth
                                      id="asynchronous-demo"
                                      isOptionEqualToValue={(option, value) => option.name === value.name}
                                      getOptionLabel={(option) => `${option.name} `}
                                      getOptionSelected={(option, value) => option.name === value.name}
                                      options={taxes}
                                      onChange={(_, value) => {
                                        if (value) {
                                          const newItems = [...values.items];
                                          newItems[index] = {
                                            ...newItems[index],
                                            tax: value.percentage,
                                            taxId: value._id,
                                            total: totalCalculator(item.quantity, item.unitPrice, value.percentage),
                                          };
                                          formik.setFieldValue('items', newItems);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Tax"
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <li {...props}>
                                          <Stack direction={'column'}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                              {option.name}
                                            </Typography>
                                            <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                              {option.percentage} %
                                            </Typography>
                                          </Stack>
                                        </li>
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: '120px',
                                  }}
                                >
                                  <Typography>
                                    {item.unitPrice &&
                                      item.tax &&
                                      item.quantity &&
                                      taxCalculator(Number(item.unitPrice) * Number(item.quantity), item.tax)}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      const newItems = [...values.items];
                                      newItems.splice(index, 1);
                                      formik.setFieldValue('items', newItems);
                                    }}
                                    variant="outlined"
                                    color="error"
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newItems = [...values.items];
                            newItems.push({
                              name: '',
                              unitPrice: 0,
                              tax: 0,
                              quantity: 0,
                              total: 0,
                            });
                            formik.setFieldValue('items', newItems);
                          }}
                        >
                          Add Item
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
                  {/* <Box sx={{ flex: '1' }}></Box> */}
                  <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Sub Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.subTotal && parseFloat(values.subTotal).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Total VAT(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.totalTax && parseFloat(values.totalTax).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.grandTotal && parseFloat(values.grandTotal).toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack px={5} pt={5} direction={'row'} spacing={2} alignItems={'center'} justifyContent={'end'}>
                  <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{values?.grandTotalInWords}</Typography>
                </Stack>
                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Payment Terms
                  </Typography>
                  {values?.paymentTerms?.map((term, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${term}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newPaymentTerms = [...values.paymentTerms];
                          newPaymentTerms.splice(index, 1);
                          formik.setFieldValue('paymentTerms', newPaymentTerms);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField
                      label="Payment Term"
                      fullWidth
                      value={paymentTerm}
                      onChange={(e) => setPaymentTerm(e.target.value)}
                    />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('paymentTerms', [...values.paymentTerms, paymentTerm]);
                        setPaymentTerm('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Notes
                  </Typography>
                  {values?.notes?.map((note, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${note}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newNotes = [...values.notes];
                          newNotes.splice(index, 1);
                          formik.setFieldValue('notes', newNotes);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField label="Note" fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('notes', [...values.notes, notes]);
                        setNotes('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={10} mt={2}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={10}
                      credits={false}
                      // server="/api"
                      name="files"
                      labelIdle="Drag & Drop images"
                    />
                  </Grid>
                </Grid>
                <Box p={5} sx={{ textAlign: 'right' }}>
                  <Button type="button" variant="contained" onClick={() => resetForm()}>
                    Reset
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting} sx={{ marginLeft: 2 }}>
                    Submit
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AddPurchase;
