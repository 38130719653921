import { Edit, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckBoxGroup from './smallComponents/CheckBoxGroup';

export default function EditAdmin({ profileData, setEditMode, getAdmin, setImage }) {
  const { accType } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const [dashboard, setDashboard] = useState(profileData?.accessList.dashboard);

  const [general, setGeneral] = useState(profileData?.accessList.general);

  const [profile, setProfile] = useState(profileData?.accessList.profile);

  const [crm, setCrm] = useState(profileData?.accessList.crm);

  const [warehouse, setWarehouse] = useState(profileData?.accessList.warehouse);

  const [purchaseOrder, setPurchaseOrder] = useState(
    profileData?.accessList.purchaseOrder || {
      purchaseOrder: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
    }
  );

  const [project, setProject] = useState(profileData?.accessList.project);

  const [account, setAccount] = useState(profileData?.accessList.account);

  const [showAccessList, setShowAccessList] = useState(false);

  const AdminSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must be at most 20 characters'),
  });

  const formik = useFormik({
    initialValues: {
      id: profileData?._id || '',
      fullname: profileData?.fullname || '',
      email: profileData?.email || '',
      password: '',
      profilePic: '',
      description: profileData?.description || '',
      designation: profileData?.designation || '',
    },
    validationSchema: AdminSchema,

    onSubmit: async () => {
      try {
        console.log({ accessList: { dashboard, general, profile, crm, warehouse, project, account } });
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/admin/subAdmin`,
          {
            ...values,
            accessList: {
              dashboard,
              general,
              profile,
              crm,
              warehouse,
              project,
              account,
              purchaseOrder,
              tasks: {
                tasks: {
                  c: true,
                  r: true,
                  u: true,
                  d: true,
                },
              },
            },
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        console.log(res);
        toast(res.data.message);
        setEditMode(false);
        resetForm();
        getAdmin();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (values.profilePic) {
      setImage(URL.createObjectURL(values.profilePic));
    }
  }, [values.profilePic]);

  return (
    <Grid item xs={12} sm={6} md={7} lg={8} padding={2}>
      <Stack direction="column" alignItems="center" justifyContent="start" sx={{ height: '100%' }} marginLeft={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" margin={1} sx={{ width: '100%' }}>
          <Typography variant="h4" gutterBottom>
            EDIT ACCOUNT DETAILS
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {admin && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="text"
                    size="small"
                    label="Full Name"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('fullname')}
                    error={Boolean(touched.fullname && errors.fullname)}
                    helperText={touched.fullname && errors.fullname}
                  />
                </Grid>
              )}
              {admin && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="email"
                    size="small"
                    label="Email"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
              )}
              {admin && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="text"
                    size="small"
                    label="Designation"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('designation')}
                    error={Boolean(touched.designation && errors.designation)}
                    helperText={touched.designation && errors.designation}
                  />
                </Grid>
              )}
              {admin && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="text"
                    size="small"
                    label="Description"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              )}
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  select
                  size="small"
                  label="Account Type"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('accType')}
                  error={Boolean(touched.accType && errors.accType)}
                  helperText={touched.accType && errors.accType}
                >
                  <MenuItem value="SUB_ADMIN">SUB ADMIN</MenuItem>
                  <MenuItem value="SUPER_ADMIN">SUPER ADMIN</MenuItem>
                </TextField>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  type="password"
                  size="small"
                  label="Password"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('password')}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="file"
                  size="small"
                  label="Profile Picture"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/jpeg, image/jpg, image/png' }}
                  name="profilePic"
                  onChange={(event) => formik.setFieldValue('profilePic', event.target.files[0])}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={'bold'}>Access List</Typography>
                  </Grid>
                  {/* <CheckBoxGroup name="Profile" accessList={profile} setAccessList={setProfile} /> */}
                  {/* <CheckBoxGroup name="General" accessList={general} setAccessList={setGeneral} /> */}
                  <CheckBoxGroup name="CRM" accessList={crm} setAccessList={setCrm} />
                  <CheckBoxGroup name="Project" accessList={project} setAccessList={setProject} />
                  <CheckBoxGroup name="Purchase Order" accessList={purchaseOrder} setAccessList={setPurchaseOrder} />
                  <CheckBoxGroup name="Warehouse" accessList={warehouse} setAccessList={setWarehouse} />
                  {/* <CheckBoxGroup name="Dashboard" accessList={dashboard} setAccessList={setDashboard} /> */}
                  <CheckBoxGroup name="Account" accessList={account} setAccessList={setAccount} />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} spacing={2} justifyContent="end">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditMode(false);
                      setImage(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
    </Grid>
  );
}
