// import * as React from 'react';
// import axios from 'axios';
// import Menu from '@mui/material/Menu';
// import { Formik, Form } from 'formik';
// import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem, Autocomplete } from '@mui/material';
// import Iconify from '../Iconify';

// const Filter = ({ type, applyFilters }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [projects, setProjects] = React.useState([]);
//   const [tools, setTools] = React.useState([]);
//   const [open3, setOpen3] = React.useState(false);
//   const [open4, setOpen4] = React.useState(false);

//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const getProjects = async () => {
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
//         params: {
//           statuses: ['APPROVED', 'ONGOING', 'PAUSED', 'COMPLETED'],
//         },
//       });
//       console.log(res);
//       if (res.data.isError === false) {
//         setProjects(res.data.data);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const getTools = async () => {
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_API_URL}/tool/active`);
//       console.log(res);
//       if (res.data.isError === false) {
//         setTools(res.data.data);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   React.useEffect(() => {
//     getProjects();
//     getTools();
//   }, []);

//   return (
//     <>
//       <div>
//         <Box style={{ display: 'flex', justifyContent: 'end' }}>
//           <IconButton onClick={handleClick}>
//             <Iconify icon="ic:round-filter-list" />
//             <Typography variant="h6"> Filter</Typography>
//           </IconButton>

//           <Menu
//             id="basic-menu"
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//             MenuListProps={{
//               'aria-labelledby': 'basic-button',
//             }}
//           >
//             <Formik
//               initialValues={{
//                 projectId: '',
//                 toolId: '',
//                 startDate: '',
//                 endDate: '',
//               }}
//               onSubmit={async (values) => {
//                 applyFilters(values);
//               }}
//             >
//               {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
//                 <Form style={{ padding: '10px' }}>
//                   <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
//                     <Stack direction="row" spacing={1}>
//                       <Autocomplete
//                         id="asynchronous-demo"
//                         fullWidth
//                         open={open3}
//                         onOpen={() => {
//                           setOpen3(true);
//                         }}
//                         onClose={() => {
//                           setOpen3(false);
//                         }}
//                         isOptionEqualToValue={(option, value) => option.name === value.name}
//                         getOptionLabel={(option) => option.name}
//                         options={projects}
//                         onChange={(_, value) => {
//                           if (value) {
//                             values.projectId = value._id;
//                           }
//                         }}
//                         renderInput={(params) => (
//                           <TextField
//                             {...params}
//                             label="Project"
//                             InputProps={{
//                               ...params.InputProps,
//                             }}
//                           />
//                         )}
//                       />
//                     </Stack>
//                     {type === 'all' && (
//                       <Stack direction="row" spacing={1}>
//                         <Autocomplete
//                           id="asynchronous-demo"
//                           fullWidth
//                           open={open4}
//                           onOpen={() => {
//                             setOpen4(true);
//                           }}
//                           onClose={() => {
//                             setOpen4(false);
//                           }}
//                           isOptionEqualToValue={(option, value) => option.name === value.name}
//                           getOptionLabel={(option) => option.name}
//                           options={tools}
//                           onChange={(_, value) => {
//                             if (value) {
//                               values.toolId = value._id;
//                             }
//                           }}
//                           renderInput={(params) => (
//                             <TextField
//                               {...params}
//                               label="Tool"
//                               InputProps={{
//                                 ...params.InputProps,
//                               }}
//                             />
//                           )}
//                         />
//                       </Stack>
//                     )}
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         type="date"
//                         id="startDate"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         name="startDate"
//                         fullWidth
//                         label="Start Date"
//                         value={values.startDate}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         type="date"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         id="endDate"
//                         name="endDate"
//                         fullWidth
//                         label="Start Date"
//                         value={values.endDate}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
//                       Filter
//                     </Button>
//                   </Stack>
//                 </Form>
//               )}
//             </Formik>
//           </Menu>
//         </Box>
//       </div>
//     </>
//   );
// };

// export default Filter;

import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Grid,
  Card,
  Collapse,
  IconButton,
  Typography,
  Box,
  Autocomplete,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters, type }) => {
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [tools, setTools] = React.useState([]);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      projectId: filterValues.projectId,
      toolId: filterValues.toolId,
      startDate: filterValues?.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
      endDate: filterValues?.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
      type: filterValues.type,
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getProjects = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
        params: {
          statuses: ['APPROVED', 'ONGOING', 'PAUSED', 'COMPLETED'],
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTools = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tool/active`);
      console.log(res);
      if (res.data.isError === false) {
        setTools(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProjects();
    getTools();
  }, []);

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open3}
                    onOpen={() => {
                      setOpen3(true);
                    }}
                    onClose={() => {
                      setOpen3(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={projects}
                    onChange={(_, value) => {
                      if (value) {
                        values.projectId = value._id;
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                  />
                </Grid>
                {type === 'all' && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Autocomplete
                      id="asynchronous-demo"
                      fullWidth
                      open={open4}
                      onOpen={() => {
                        setOpen4(true);
                      }}
                      onClose={() => {
                        setOpen4(false);
                      }}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      getOptionLabel={(option) => option.name}
                      options={tools}
                      onChange={(_, value) => {
                        if (value) {
                          values.toolId = value._id;
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tool"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="startDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('startDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="endDate"
                    name="endDate"
                    fullWidth
                    label="End Date"
                    {...getFieldProps('endDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField select fullWidth id="type" name="type" label="Type" {...getFieldProps('type')}>
                    <MenuItem value="WAREHOUSE_IN">Warehouse In</MenuItem>
                    <MenuItem value="WAREHOUSE_OUT">Warehouse Out</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          projectId: '',
                          toolId: '',
                          startDate: '',
                          endDate: '',
                          type: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
