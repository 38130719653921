import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
// material
import TabCard from '../smallComponents/TabCard';
import hasAccessOf from '../../store/hasAccessOf';

// components

export default function CustomerDetailPageTabs({ customer }) {
  const [projectCount, setProjectCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);

  const projectReadAccess = hasAccessOf('project.project.r');
  const qtoReadAccess = hasAccessOf('project.qto.r');

  const getProjectCount = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/count/member`, {
        params: {
          id: customer._id,
          role: 'CUSTOMER',
        },
      });
      console.log('projectCount', res.data);
      setProjectCount(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getNotifiactions = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation/deadline/notifications`, {
        params: { ...options, customerId: customer?._id },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setNotificationCount(res.data.data.maxRecords);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjectCount();
    getNotifiactions({ size: 10, page: 0 });
  }, [customer]);

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              title="RFQs"
              color="greenish"
              icon={'mdi:leads-outline'}
              navigate={`/dashboard/general/customers/leads?customerId=${customer?._id}`}
            />
          </Grid>
          {projectReadAccess && (
            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                count={projectCount}
                title="Projects"
                color="purplish"
                icon={'icons8:parallel-tasks'}
                navigate={`/dashboard/general/customers/projects?customerId=${customer._id}`}
              />
            </Grid>
          )}
          {qtoReadAccess && (
            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                count={notificationCount}
                title="Notifications"
                color="greenish"
                icon={'ic:baseline-notifications-none'}
                navigate={`/dashboard/project/notifications?customerId=${customer._id}`}
              />
            </Grid>
          )} 
          <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              title="Accounts"
              color="balanced"
              total={12}
              icon={'carbon:account'}
              navigate={`/dashboard/general/customers/accounts?customerId=${customer._id}`}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
}
