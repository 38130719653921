import { useState, useEffect } from 'react';
import Menu from '@mui/material/Menu';
import axios from 'axios';
import { Formik, Form, FormikProvider, useFormik } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem, Collapse, Grid } from '@mui/material';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters }) => {
  const [open, setOpen] = useState(false);

  const [telecallers, setTelecallers] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [status, setStatus] = useState([]);
  const [telecallersLoading, setTelecallersLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const getTelecallers = async (managerId) => {
    try {
      setTelecallersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/teleCaller/for-filter`, {
        params: {
          managerId,
        },
      });
      console.log('telecallers list for filter', res.data.data);
      if (!res.data.isError) {
        if (res.data.data.records) {
          setTelecallers(res.data.data.records);
        }
      }
      setTelecallersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      setSourcesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/all/active`);
      console.log('sources list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setSources(res.data.data.status);
        }
      }
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/all/for-filter`);
      console.log('categories list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setCategories(res.data.data.status);
        }
      }
      setCategoriesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      setStatusLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadStatus/all/active`);
      console.log('status list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setStatus(res.data.data.status);
        }
      }
      setStatusLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSources();
    getCategories();
    getStatus();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: filterValues.fullname,
      email: filterValues.email,
      phone: filterValues.phone,
      leadManager: filterValues.leadManager,
      teleCaller: filterValues.teleCaller,
      source: filterValues.source,
      category: filterValues.category,
      status: filterValues.status,
      startDate: filterValues.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
      endDate: filterValues.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
      customer: filterValues.customer,
    },
    onSubmit: async () => {
      const updateValues = {
        ...values,
        startDate: values.startDate ? new Date(moment(values.startDate).startOf('day')) : null,
        endDate: values.endDate ? new Date(moment(values.endDate).endOf('day')) : null,
      };
      applyFilters(updateValues);
    },
  });

  const { errors, touched, values, isSubmitting, setFieldValue, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField id="fullname" name="fullname" fullWidth label="Fullname" {...getFieldProps('fullname')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField id="email" name="email" fullWidth label="Email" {...getFieldProps('email')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField id="phone" name="phone" fullWidth label="Phone" {...getFieldProps('phone')} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    fullWidth
                    id="category"
                    value={values.category ? categories.find((c) => c._id === values.category) : null}
                    onChange={(event, newValue) => {
                      setFieldValue('category', newValue ? newValue._id : '');
                    }}
                    options={categories}
                    getOptionLabel={(option) => option.name}
                    loading={categoriesLoading}
                    renderInput={(params) => <TextField {...params} label="Category" fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    id="status"
                    fullWidth
                    value={values.status ? status.find((s) => s._id === values.status) : null}
                    onChange={(event, newValue) => {
                      setFieldValue('status', newValue ? newValue._id : '');
                    }}
                    options={status}
                    getOptionLabel={(option) => option.name}
                    loading={statusLoading}
                    renderInput={(params) => <TextField {...params} label="Status" fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Autocomplete
                    id="source"
                    fullWidth
                    value={values.source ? sources.find((s) => s._id === values.source) : null}
                    onChange={(event, newValue) => {
                      setFieldValue('source', newValue ? newValue._id : '');
                    }}
                    options={sources}
                    getOptionLabel={(option) => option.name}
                    loading={sourcesLoading}
                    renderInput={(params) => <TextField {...params} label="Source" fullWidth />}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="startDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('startDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="endDate"
                    name="endDate"
                    fullWidth
                    label="End Date"
                    {...getFieldProps('endDate')}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          fullname: '',
                          email: '',
                          phone: '',
                          source: '',
                          category: '',
                          status: '',
                          startDate: '',
                          endDate: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
