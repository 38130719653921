import { Avatar, Box, Card, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BorderColor, Close } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import VendorTables from '../components/VendorTables ';

import PageHeader from '../components/smallComponents/PageHeader';
import VendorTabCards from '../components/cardTabs/VendorTabCards';
import LoadingIcon from '../components/smallComponents/LoadingIcon';
import VendorEditForm from '../components/VendorEditForm';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewVendor() {
  const { id } = useParams();

  const [vendor, setVendor] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const getVendor = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, { params: { _id: id } });
      console.log(res);
      if (res.data.isError === false) {
        setVendor(res.data.data.vendor);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/purchase/all`, {
        params: { vendorId: id },
      });
      setPaymentData(res.data.data.records);
      console.log('res', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendor();
    getPaymentData();
  }, [id]);

  return (
    <Page title="View Supplier">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Container maxWidth="xl">
          <PageHeader title={'Supplier'} />
          <Stack direction={'column'} spacing={2}>
            <Card>
              <Box sx={{ padding: 2 }}>
                {!editMode ? (
                  <Box padding={'10px'}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} spacing={3}>
                      <Typography variant="h5">Supplier Details</Typography>
                      <IconButton onClick={() => setEditMode(!editMode)}>
                        {!editMode ? <BorderColor /> : <Close />}
                      </IconButton>
                    </Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem
                          title="Supplier ID"
                          data={vendor?._id}
                          icon={'mdi:account'}
                          profilePic={process.env.REACT_APP_API_URL + vendor.profilePic}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem title="Company Name" data={vendor?.companyName} icon={'mdi:account'} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem
                          title="Supplier Types"
                          data={vendor?.vendorTypes?.map((type) => type.name?.replaceAll('_', ' ')).join(', ')}
                          icon={'mdi:category'}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem title="Contact Person" data={vendor?.contactPerson} icon={'mdi:account'} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem title=" Email" data={vendor?.email} icon={'mdi:email'} />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem title=" Phone" data={`${vendor?.phone1}, ${vendor?.phone2}`} icon={'mdi:phone'} />
                      </Grid>
                      {/* TRN  */}
                      <Grid item xs={12} md={6} lg={4}>
                        <DetailItem title="TRN" data={vendor?.trn} icon="mdi:account-cash" />
                      </Grid>
                      <Grid item xs={12}>
                        <DetailItem title=" Address" data={vendor?.companyAddress} icon={'mdi:map-marker'} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <DetailItem
                          title="Grand Total"
                          icon="majesticons:money-line"
                          data={paymentData?.grandTotal || 0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <DetailItem
                          title="Paid Amount"
                          icon="majesticons:money-line"
                          data={paymentData?.paidAmount || 0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <DetailItem
                          title="Balance Amount"
                          icon="majesticons:money-line"
                          data={paymentData?.balanceAmount || 0}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <VendorEditForm vendor={vendor} setEditMode={setEditMode} editMode={editMode} getVendor={getVendor} />
                )}
              </Box>
            </Card>
            <VendorTabCards vendor={vendor} />
            <VendorTables />
          </Stack>
        </Container>
      )}
    </Page>
  );
}

export default ViewVendor;
