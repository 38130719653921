import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { Add, DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import { convertToWords } from '../utils/commonFunctions';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

const AddBill = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customerData = location.state;
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [customers, setCustomers] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [company, setCompany] = useState({});
  const [customer, setCustomer] = useState({});
  const [projects, setProjects] = useState([]);
  const [quotaions, setQuotations] = useState([]);
  const [items, setItems] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [paymentTerm, setPaymentTerm] = useState('');
  const [notes, setNotes] = useState('');

  const convertToParseFloat = (value) => parseFloat(value).toFixed(2);

  const billSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
  });

  const formik = useFormik({
    initialValues: {
      invoiceDate: moment().format('YYYY-MM-DD'),
      customerId: customerData?._id || '',
      subTotal: '',
      grandTotal: '',
      totalTax: '',
      items: [],
      dueDate: '',
      projectId: '',
      quotationId: '', // _id of quotation, not quotationId generated by system
      poDate: '',
      poNumber: '',
      paymentTerms: [],
      notes: [],
      grandTotalInWords: '',
    },
    validationSchema: billSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/billing`, {
          ...values,
          subTotal: convertToParseFloat(values.subTotal),
          grandTotal: convertToParseFloat(values.grandTotal),
          items: values?.items?.map((item) => ({
            ...item,
            unitPrice: item.progressiveInvoiceUnitPrice,
            originalUnitPrice: item.unitPrice,
            progressiveInvoiceValue: item.progressiveInvoiceValue,
          })),
          paymentTerms: values.paymentTerms.length ? values.paymentTerms : [paymentTerm],
          notes: values.notes.length ? values.notes : [notes],
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getCustomers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/active`);
      console.log(res.data.data);
      setCustomers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaxes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tax/active/all`);
      console.log(res.data.data);
      setTaxes(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompany = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`);
      console.log(res.data.data, 'company');
      setCompany(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/accountSettings`);
      console.log(res.data.data, 'accountSettings');
      setAccountSettings(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomer = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/one`, { params: { id } });
      console.log(res.data.data);
      setCustomer(res.data.data.customer);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async (customerId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
        params: {
          customerId,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
        if (projectId) {
          const project = res.data.data.find((project) => project._id === projectId);
          if (project) {
            formik.setFieldValue('projectId', project._id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotations = async (customerId, projectId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/list`, {
        params: {
          customerId,
          projectId,
          quotationStatus: 'APPROVED',
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setQuotations(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotationItems = async (quotationId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/items/list`, {
        params: {
          id: quotationId,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setItems(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomers();
    getTaxes();
    getCompany();
    getAccountSettings();
  }, []);

  useEffect(() => {
    if (values.customerId) {
      getCustomer(values.customerId);
      getProjects(values.customerId);
    }
  }, [values.customerId]);

  useEffect(() => {
    if (values.customerId && values.projectId) {
      getQuotations(values.customerId, values.projectId);
    }
  }, [values.customerId, values.projectId]);

  useEffect(() => {
    if (values.quotationId) {
      getQuotationItems(values.quotationId);
    }
  }, [values.quotationId]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    let totalTax = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.quantity) * Number(item.progressiveInvoiceUnitPrice);
      grandTotal += Number(item.total);
      totalTax += Number(item.quantity) * Number(item.progressiveInvoiceUnitPrice) * (Number(item.tax) / 100);
    });
    const grandTotalInWords = convertToWords(grandTotal);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
    formik.setFieldValue('totalTax', totalTax);
    formik.setFieldValue('grandTotalInWords', grandTotalInWords);
    console.log(items);
  }, [values.items]);

  const totalCalculator = (quantity, price, tax) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    total += (total * Number(tax)) / 100;
    return total.toFixed(2);
  };

  const taxCalculator = (price, tax) => {
    let totalTax = 0;
    totalTax = (price * Number(tax)) / 100;
    return totalTax.toFixed(2);
  };

  return (
    <Page title="Add Invoice">
      <Container maxWidth="xl">
        <PageHeader title="Add Invoice" />
        <Card>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                  direction={'row'}
                  px={5}
                  py={3}
                  sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                >
                  {/* <Box sx={{ flex: '1' }}>Logo</Box> */}

                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2}>
                      <StyledTextField
                        label="Invoice Date"
                        size="small"
                        type="date"
                        {...getFieldProps('invoiceDate')}
                        error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                        helperText={touched.invoiceDate && errors.invoiceDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <StyledTextField
                        label="Due Date"
                        size="small"
                        type="date"
                        {...getFieldProps('dueDate')}
                        error={Boolean(touched.dueDate && errors.dueDate)}
                        helperText={touched.dueDate && errors.dueDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Grid container rowGap={2} mt={5}>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                        Customer:
                      </Typography>
                      {!customerData ? (
                        <Autocomplete
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                          getOptionLabel={(option) => option.fullname}
                          getOptionSelected={(option, value) => option.fullname === value.fullname}
                          options={customers}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('customerId', value._id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                minWidth: '250px',
                              }}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 35, height: 35, cursor: 'pointer' }}
                                  src={
                                    (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) ||
                                    'https://www.w3schools.com/howto/img_avatar2.png'
                                  }
                                />
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.fullname}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                    {option.phone}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      ) : (
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 35, height: 35, cursor: 'pointer' }}
                            src={
                              (customerData?.profilePic && process.env.REACT_APP_API_URL + customerData.profilePic) ||
                              'https://www.w3schools.com/howto/img_avatar2.png'
                            }
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                              {customerData?.fullname}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1', color: 'white' }}>
                              {customerData?.phone}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                        Project:
                      </Typography>
                      {values?.customerId && !projectId ? (
                        <Autocomplete
                          id="asynchronous-demo"
                          fullWidth
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.name === value.name}
                          options={projects}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('projectId', value._id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                minWidth: '250px',
                              }}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction={'column'}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}
                                >
                                  {option.name}
                                </Typography>
                              </Stack>
                            </li>
                          )}
                        />
                      ) : (
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                          {projects.find((project) => project._id === values.projectId)?.name}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                        Quotation :
                      </Typography>
                      {values?.projectId && (
                        <Autocomplete
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.quotationId === value.quotationId}
                          getOptionLabel={(option) => option.quotationId}
                          getOptionSelected={(option, value) => option.quotationId === value.quotationId}
                          options={quotaions}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('quotationId', value._id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: '180px',
                              }}
                              label="Quotation"
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.quotationId}
                                </Typography>
                              </Stack>
                            </li>
                          )}
                        />
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        From
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{company.companyName}</Typography>
                        <Typography>{company.address}</Typography>
                        <Typography>{company.pin}</Typography>
                        <Typography>{company.phone}</Typography>
                        <Typography>{company.email}</Typography>
                        <Typography>{`TRN: ${accountSettings?.trn}`}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        To
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{customer.fullname}</Typography>
                        <Typography>{customer.address}</Typography>
                        <Typography>{customer.phone}</Typography>
                        <Typography>{customer.email}</Typography>
                        {customer && customer.fullname && <Typography>{`TRN: ${customer.trn || ''}`}</Typography>}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Bank Details
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{`Bank name: ${accountSettings?.bankName}`}</Typography>
                        <Typography>{`IBAN: ${accountSettings?.iban}`}</Typography>
                        <Typography>{`Bank Account number: ${accountSettings?.bankAccountNumber}`}</Typography>
                        <Typography>{`Beneficiary Name: ${accountSettings?.beneficiaryName}`}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack pt={5} px={5}>
                      <Stack paddingLeft={4} spacing={2}>
                        <TextField
                          type="date"
                          label="PO Date"
                          size="small"
                          {...getFieldProps('poDate')}
                          error={Boolean(touched.poDate && errors.poDate)}
                          helperText={touched.poDate && errors.poDate}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        <TextField
                          label="PO Number"
                          size="small"
                          {...getFieldProps('poNumber')}
                          error={Boolean(touched.poNumber && errors.poNumber)}
                          helperText={touched.poNumber && errors.poNumber}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack p={5}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sl No.</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Unit Price</TableCell>
                              <TableCell>P.I. Unit Price</TableCell>
                              <TableCell>Total</TableCell>
                              <TableCell>VAT</TableCell>
                              <TableCell>VAT Amount</TableCell>
                              <TableCell>Progressive Invoice Value(%)</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  <Stack justifyContent={'start'} sx={{ height: '100%' }}>
                                    <Autocomplete
                                      sx={{
                                        minWidth: '150px',
                                      }}
                                      id="asynchronous-demo"
                                      freeSolo // allow free input
                                      onInputChange={(e, value) => {
                                        const newItems = [...values.items];
                                        newItems[index] = { ...newItems[index], name: value, qtoItemId: '' };
                                        formik.setFieldValue('items', newItems);
                                      }}
                                      isOptionEqualToValue={(option, value) => option.name === value.name}
                                      getOptionLabel={(option) => option.name}
                                      getOptionSelected={(option, value) => option.name === value.name}
                                      options={items}
                                      onChange={(_, value) => {
                                        if (value) {
                                          const newItems = [...values.items];
                                          newItems[index] = {
                                            ...newItems[index],
                                            name: value.name,
                                            unitPrice: value.unitPrice || 0,
                                            progressiveInvoiceUnitPrice: value.unitPrice || 0,
                                            progressiveInvoiceValue: 100,
                                            quantity: value.quantity || 0,
                                            tax: newItems[index].tax || 0,
                                            taxId: newItems[index].taxId || '',
                                            qtoItemId: value._id || '',
                                            total: totalCalculator(
                                              value.quantity || 0,
                                              value.unitPrice || 0,
                                              newItems[index].tax || 0
                                            ),
                                          };
                                          formik.setFieldValue('items', newItems);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <li {...props}>
                                          <Stack direction={'column'}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                              {option.name}
                                            </Typography>
                                            <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                              {option.unitPrice} AED
                                            </Typography>
                                          </Stack>
                                        </li>
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell sx={{ width: '100px' }}>
                                  <TextField
                                    disabled={!item.name}
                                    fullWidth
                                    type="number"
                                    value={item.quantity || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        quantity: Number(e.target.value),
                                        total: totalCalculator(
                                          Number(e.target.value),
                                          item.progressiveInvoiceUnitPrice || 0,
                                          item.tax
                                        ),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: '120px',
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    type="number"
                                    value={item.unitPrice || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        unitPrice: Number(e.target.value),
                                        progressiveInvoiceUnitPrice:
                                          (Number(e.target.value) * item.progressiveInvoiceValue) / 100,
                                        total: totalCalculator(
                                          item.quantity,
                                          (Number(e.target.value) * item.progressiveInvoiceValue) / 100,
                                          item.tax
                                        ),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Typography>{item.progressiveInvoiceUnitPrice}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{item.quantity * item.progressiveInvoiceUnitPrice}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Stack direction="row" spacing={5} alignItems="center">
                                    <Autocomplete
                                      sx={{
                                        minWidth: '110px',
                                        maxWidth: '200px',
                                      }}
                                      fullWidth
                                      id="asynchronous-demo"
                                      isOptionEqualToValue={(option, value) => option.name === value.name}
                                      getOptionLabel={(option) => `${option.name}`}
                                      getOptionSelected={(option, value) => option.name === value.name}
                                      options={taxes}
                                      onChange={(_, value) => {
                                        if (value) {
                                          const newItems = [...values.items];
                                          newItems[index] = {
                                            ...newItems[index],
                                            tax: value.percentage,
                                            taxId: value._id,
                                            total: totalCalculator(
                                              item.quantity,
                                              item.progressiveInvoiceUnitPrice,
                                              value.percentage
                                            ),
                                          };
                                          formik.setFieldValue('items', newItems);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Tax"
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <li {...props}>
                                          <Stack direction={'column'}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                              {option.name}
                                            </Typography>
                                          </Stack>
                                        </li>
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {item.progressiveInvoiceUnitPrice &&
                                      item.unitPrice &&
                                      item.tax &&
                                      item.quantity &&
                                      taxCalculator(
                                        Number(item.progressiveInvoiceUnitPrice) * Number(item.quantity),
                                        item.tax
                                      )}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    sx={{
                                      width: '100px',
                                    }}
                                    type="number"
                                    value={item.progressiveInvoiceValue || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        progressiveInvoiceValue: Number(e.target.value),
                                        progressiveInvoiceUnitPrice: (Number(e.target.value) * item.unitPrice) / 100,
                                        total: totalCalculator(
                                          item.quantity,
                                          (Number(e.target.value) * item.unitPrice) / 100,
                                          item.tax
                                        ),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      const newItems = [...values.items];
                                      newItems.splice(index, 1);
                                      formik.setFieldValue('items', newItems);
                                    }}
                                    variant="outlined"
                                    color="error"
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newItems = [...values.items];
                            newItems.push({
                              name: '',
                              qtoItemId: '',
                              unitPrice: 0,
                              progressiveInvoiceValue: 100,
                              progressiveInvoiceUnitPrice: 0,
                              tax: 0,
                              taxId: '',
                              quantity: 0,
                              total: 0,
                            });
                            formik.setFieldValue('items', newItems);
                          }}
                        >
                          Add Item
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
                  {/* <Box sx={{ flex: '1' }}></Box> */}
                  <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Sub Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.subTotal && parseFloat(values.subTotal).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Total VAT(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.totalTax && parseFloat(values.totalTax).toFixed(2)}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.grandTotal && parseFloat(values.grandTotal).toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack px={5} pt={5} direction={'row'} spacing={2} justifyContent={'end'}>
                  <Typography sx={{ fontWeight: 600 }}>{values.grandTotalInWords}</Typography>
                </Stack>
                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Payment Terms
                  </Typography>
                  {values?.paymentTerms?.map((term, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${term}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newPaymentTerms = [...values.paymentTerms];
                          newPaymentTerms.splice(index, 1);
                          formik.setFieldValue('paymentTerms', newPaymentTerms);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField
                      label="Payment Term"
                      fullWidth
                      value={paymentTerm}
                      onChange={(e) => setPaymentTerm(e.target.value)}
                    />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('paymentTerms', [...values.paymentTerms, paymentTerm]);
                        setPaymentTerm('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Notes
                  </Typography>
                  {values?.notes?.map((note, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${note}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newNotes = [...values.notes];
                          newNotes.splice(index, 1);
                          formik.setFieldValue('notes', newNotes);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField label="Note" fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('notes', [...values.notes, notes]);
                        setNotes('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack p={5} sx={{ textAlign: 'right' }} justifyContent={'end'} spacing={5} direction={'row'}>
                  <Button type="button" variant="contained" onClick={() => resetForm()}>
                    Reset
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AddBill;
