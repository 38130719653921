import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { Download } from '@mui/icons-material';

export default function ExcelUpload({ closeExportModal, getLeads, openExportModal }) {
  const { accType } = useSelector((state) => state.user.userInfo);

  const matches = useMediaQuery('(max-width:600px)');
  const [leadManagers, setLeadManagers] = useState([]);
  const [telecallers, setTelecallers] = useState([]);
  const [sources, setSources] = useState([]);
  const [categories, setCategories] = useState([]);
  const [leadManagersLoading, setLeadManagersLoading] = useState(true);
  const [telecallersLoading, setTelecallersLoading] = useState(false);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});

  const leadSchema = Yup.object().shape({
    leadManager: Yup.string().required('Sales Manager is required'),
    source: Yup.string().required('Lead Source is required'),
    category: Yup.string().required('Category is required'),
  });

  const formik = useFormik({
    initialValues: {
      source: '',
      leadManager: '',
      teleCaller: '',
      category: '',
    },
    validationSchema: leadSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/lead/excel`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          closeExportModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const getTelecallers = async (managerId) => {
    try {
      setTelecallersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/leadManager/telecallers/all`, {
        params: {
          managerId,
        },
      });
      console.log('telecallers list', res.data.data);
      if (!res.data.isError) {
        if (res.data.data.telecallers) {
          setTelecallers(res.data.data.telecallers);
        }
      }
      setTelecallersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadManagers = async () => {
    try {
      setLeadManagersLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/leadManager/list`);
      console.log('lead managers list', res.data.data.records);
      if (!res.data.isError) {
        if (res.data.data.records) {
          setLeadManagers(res.data.data.records);
        }
      }
      setLeadManagersLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSources = async () => {
    try {
      setSourcesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadSource/all/active`);
      console.log('sources list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setSources(res.data.data.status);
        }
      }
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategories = async () => {
    try {
      setCategoriesLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadCategory/all/active`);
      console.log('categories list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setCategories(res.data.data.status);
        }
      }
      setCategoriesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadDoc = async (e, id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead/sample-file-download`);
      window.open(res.data.data.url, '_blank');
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN') {
      getSources();
      getCategories();
      getLeadManagers();
    }
  }, [accType]);

  useEffect(() => {
    if (values.leadManager) {
      getTelecallers(values.leadManager);
    }
  }, [values.leadManager]);

  return (
    <Dialog open={openExportModal} onClose={closeExportModal} fullScreen={matches}>
      <DialogTitle>Add RFQ</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={6}>
                  <Button endIcon={<Download />} onClick={downloadDoc} variant="contained" sx={{ width: '100%' }}>
                    Download Sample File
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    type="file"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    label="Upload File"
                    onChange={(e) => {
                      setFieldValue('lead', e.target.files[0]);
                      console.log(e.target.files[0]);
                    }}
                    error={Boolean(touched.lead && errors.lead)}
                    helperText={touched.lead && errors.lead}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open3}
                    onOpen={() => {
                      setOpen3(true);
                    }}
                    onClose={() => {
                      setOpen3(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={sources}
                    loading={sourcesLoading}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('source', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={Boolean(touched.source && errors.source)}
                        helperText={touched.source && errors.source}
                        label="Source"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {sourcesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open4}
                    onOpen={() => {
                      setOpen4(true);
                    }}
                    onClose={() => {
                      setOpen4(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={categories}
                    loading={categoriesLoading}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('category', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={Boolean(touched.category && errors.category)}
                        helperText={touched.category && errors.category}
                        label="Category"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {categoriesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open1}
                    onOpen={() => {
                      setOpen1(true);
                    }}
                    onClose={() => {
                      setOpen1(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    options={leadManagers}
                    loading={leadManagersLoading}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('leadManager', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        error={Boolean(touched.leadManager && errors.leadManager)}
                        helperText={touched.leadManager && errors.leadManager}
                        label="Sales Manager"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {leadManagersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open2}
                    onOpen={() => {
                      setOpen2(true);
                    }}
                    onClose={() => {
                      setOpen2(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    options={telecallers}
                    loading={telecallersLoading}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('teleCaller', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sales Staff" 
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {telecallersLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={closeExportModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Add RFQ
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
