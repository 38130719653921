import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem, Avatar, Autocomplete } from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [employees, setEmployees] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [members, setMembers] = React.useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`);
      console.log(res.data.data);
      setEmployees(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdmins = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/all/active/list`);
      console.log(res.data.data);
      setAdmins(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getMembers();
    getAdmins();
  }, []);

  React.useEffect(() => {
    setMembers([...employees, ...admins]);
  }, [employees, admins]);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                assignedBy: '',
                assignedTo: '',
                assignedDate: '',
                dueDate: '',
                status: '',
                startDate: '',
                endDate: '',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
                    <Autocomplete
                      fullWidth
                      id="assignedBy"
                      name="assignedBy"
                      value={members.find((option) => option._id === values.assignedBy) || null}
                      onChange={(event, newValue) => {
                        values.assignedBy = newValue?._id;
                      }}
                      options={members}
                      getOptionLabel={(option) => option.fullname}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Assigned By"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar
                              sx={{ width: 40, height: 40, cursor: 'pointer' }}
                              src={option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic}
                            >
                              {option?.profilePic ? '' : option.fullname.charAt(0)}
                            </Avatar>
                            <Stack direction={'column'}>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                {option.fullname} 
                              </Typography>
                              <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                {option.email}
                              </Typography>
                              <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                {option.role?.replaceAll('_', ' ')}
                              </Typography>
                              <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                {option.accType?.replaceAll('_', ' ')}
                              </Typography>
                            </Stack>
                          </Stack>
                        </li>
                      )}
                    />
                    <Stack direction="row" spacing={1}>
                      <Autocomplete
                        fullWidth
                        id="assignedTo"
                        name="assignedTo"
                        value={members.find((option) => option._id === values.assignedTo) || null}
                        onChange={(event, newValue) => {
                          values.assignedTo = newValue?._id;
                        }}
                        options={members}
                        getOptionLabel={(option) => option.fullname}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assigned To"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                src={option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic}
                              >
                                {option?.profilePic ? '' : option.fullname.charAt(0)}
                              </Avatar>
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.email}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.role?.replaceAll('_', ' ')}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.accType?.replaceAll('_', ' ')}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="assignedDate"
                        name="assignedDate"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="Assigned Date"
                        value={values.assignedDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="dueDate"
                        name="dueDate"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="Due Date"
                        value={values.dueDate}
                        onChange={handleChange}
                      />
                      <TextField
                        select
                        fullWidth
                        id="status"
                        name="status"
                        label="Status"
                        value={values.status}
                        onChange={handleChange}
                      >
                        <MenuItem value="NOT_STARTED">Not Started</MenuItem>
                        <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                        <MenuItem value="FINAL_STAGE">Final Stage</MenuItem>
                        <MenuItem value="COMPLETED">Completed</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        type="date"
                        id="startDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="startDate"
                        fullWidth
                        label="Start Date"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <TextField
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        id="endDate"
                        name="endDate"
                        fullWidth
                        label="Start Date"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
