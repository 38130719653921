import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

const PermissionCheckboxes = ({ subCategory, accessList }) => (
  <Stack direction="row" spacing={1} pl={1}>
    {Object.keys(accessList[subCategory]).map((perm) => (
      <FormControlLabel
        sx={{
          '& .MuiFormControlLabel-label': {
            fontSize: '14px',
          },
        }}
        key={perm}
        control={
          <Checkbox
            size="small"
            checked={accessList[subCategory][perm]}
          />
        }
        label={perm.charAt(0).toUpperCase() + perm.slice(1)}
      />
    ))}
  </Stack>
);

function CheckBoxGroupView({ accessList, name }) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <FormControlLabel
        sx={{ '& .MuiFormControlLabel-label': { fontWeight: 600, fontSize: '18px' } }}
        control={
          <Checkbox
            color="primary"
            size="large"
            checked={Object.keys(accessList).every((subCategory) =>
              Object.values(accessList[subCategory]).every((perm) => perm)
            )}
            indeterminate={
              Object.keys(accessList).some((subCategory) =>
                Object.values(accessList[subCategory]).some((perm) => perm)
              ) &&
              !Object.keys(accessList).every((subCategory) =>
                Object.values(accessList[subCategory]).every((perm) => perm)
              )
            }
          />
        }
        label={name}
      />
      <Stack gap={2} pl={2}>
        {Object.keys(accessList).map((subCategory) => (
          <Stack key={subCategory}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: 600,
                },
              }}
              control={
                <Checkbox
                  size="medium"
                  checked={Object.values(accessList[subCategory]).every((perm) => perm)}
                  indeterminate={
                    Object.values(accessList[subCategory]).some((perm) => perm) &&
                    !Object.values(accessList[subCategory]).every((perm) => perm)
                  }
                />
              }
              label={subCategory.charAt(0).toUpperCase() + subCategory.slice(1)}
            />
            <PermissionCheckboxes subCategory={subCategory} accessList={accessList} />
          </Stack>
        ))}
      </Stack>
    </Grid>
  );
}

export default CheckBoxGroupView;
