import { Box, Card, CircularProgress, Container, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Iconify from '../components/Iconify';
import InvoiceTablePurchase from '../components/InvoiceTablePurchase';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
     sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
      <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewPendingPurchasePayment() {
  const { vendorId } = useParams();
  const location = useLocation();
  const invoice = location.state && location.state.invoice;
  const [pendingPurchase, setPendingPurchase] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const getPendingPurchase = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/purchase/pending`, {
        params: { vendorId, _id: invoice ? invoice._id : '' },
      });
      console.log(res.data.data, 'pending purchase');
      if (!res.data.isError) {
        setPendingPurchase(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPendingPurchase();
  }, []);

  return (
    <Container maxWidth="xl">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Stack spacing={2}>
          <h2> Pending Purchase</h2>
          <Card>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:account" title="Supplier Name" data={pendingPurchase.companyName} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:email" title="Email" data={pendingPurchase.email} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="mdi:phone" title="Phone" data={pendingPurchase.phone} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="majesticons:money-line" title="Grand Total" data={pendingPurchase.grandTotal} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="majesticons:money-line" title="Paid Amount" data={pendingPurchase.paidAmount} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem icon="majesticons:money-line" title="Balance Amount" data={pendingPurchase.balanceAmount} />
              </Grid>
            </Grid>
          </Card>
          <InvoiceTablePurchase purchase={pendingPurchase} />
        </Stack>
      )}
    </Container>
  );
}

export default ViewPendingPurchasePayment;
