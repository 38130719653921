import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import {
  AddCircle,
  AlternateEmail,
  CheckCircle,
  FactCheck,
  FmdGood,
  Phone,
  Print,
  RemoveCircle,
  RestartAlt,
  Save,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import ReactToPrint from 'react-to-print';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import ViewQuoteBasicDetails from '../components/ViewQuoteBasicDetails';
import Logo from '../components/Logo';
import '../app.css';

import PageHeader from '../components/smallComponents/PageHeader';
import FinaliseQuoteModal from '../components/popups/FinaliseQuoteModal';
import Iconify from '../components/Iconify';
import ReviseQuoteModal from '../components/popups/ReviseQuoteModal';
import ClientApprovalQuoteModal from '../components/popups/ClientApprovalQuoteModal';
import AttachMentsContainer from '../components/attachments/AttachMentsContainer';
import SharedWithClientModal from '../components/popups/SharedWithClientModal';
import QuotationNotes from '../components/QuotationNotes';

const StyledTableCell = styled(TableCell)(
  ({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor, textAlign, color }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: bgcolor || theme.palette.primary.main,
      color: theme.palette.text.light,
      fontWeight: 'bold',
      textAlign: textAlign || 'left',
      border: '0.5px solid #d49292',
      borderLeft: cellBorderLeft || '0.5px solid #d49292',
      borderRight: cellBorderRight || '0.5px solid #d49292',
      fontSize: 14,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: textAlign || 'left',
      border: '0.5px solid #d49292',
      borderLeft: cellBorderLeft || '0.5px solid #d49292',
      borderRight: cellBorderRight || '0.5px solid #d49292',
      fontWeight: fontWeight || 'normal',
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: bgcolor ? (bgcolor === 'theme' ? theme.palette.primary.main : bgcolor) : '#fff',
      color: color || theme.palette.text.primary,
    },
  })
);

const StyledTableCell1 = styled(TableCell)(
  ({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor, textAlign, color }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: bgcolor || theme.palette.primary.main,
      color: theme.palette.text.light,
      fontWeight: 'bold',
      textAlign: textAlign || 'left',
      fontSize: 14,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: textAlign || 'left',
      border: '0.5px solid #e5e0e0',
      borderLeft: cellBorderLeft || '0.5px solid #e5e0e0',
      borderRight: cellBorderRight || '0.5px solid #e5e0e0',
      fontWeight: fontWeight || 'normal',
      // eslint-disable-next-line no-nested-ternary
      backgroundColor: bgcolor ? (bgcolor === 'theme' ? theme.palette.primary.main : bgcolor) : '#fff',
      color: color || theme.palette.text.primary,
    },
  })
);

function ViewQuote() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [quote, setQuote] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [companyAddress, setCompanyAddress] = useState({});
  const [openFinalise, setOpenFinalise] = useState(false);
  const [openRevise, setOpenRevise] = useState(false);
  const [openSharedWithClient, setOpenSharedWithClient] = useState(false);
  const [openClientApprove, setOpenClientApprove] = useState(false);
  const [openClientReject, setOpenClientReject] = useState(false);
  const [quotationSettings, setQuotationSettings] = useState({});
  const componentRef = React.useRef();
  const [totalPriceInWordsPlaceholder, setTotalPriceInWordsPlaceholder] = useState('Enter Total Price in Words');
  const [totalAmcChargeInWordsPlaceholder, setTotalAmcChargeInWordsPlaceholder] = useState(
    'Enter Total AMC Charge in Words'
  );
  const [clientNotes, setClientNotes] = useState([]);
  const [currentTotalAmcChargeInWords, setCurrentTotalAmcChargeInWords] = useState('');
  const [currentTotalPriceInWords, setCurrentTotalPriceInWords] = useState('');

  const formik = useFormik({
    initialValues: {
      id,
      amcCharges: [],
      termsAndConditions: [],
      exclusions: [],
      remarks: [],
      approvedByName: '',
      approvedByDesignation: '',
      preparedByName: '',
      preparedByDesignation: '',
      quotationDate: '',
      quotationValidTill: '',
      save: false,
      totalPriceInWords: '',
      totalAmcChargeInWords: '',
      quotationDiscount: '',
      clientNotes: [],
    },
    onSubmit: async () => {
      try {
        if (currentTotalAmcChargeInWords === values.totalAmcChargeInWords) {
          //  send only if the value is changed, if changed it will up updated. else auto generated in backend
          delete values.totalAmcChargeInWords;
        }
        if (currentTotalPriceInWords === values.totalPriceInWords) {
          delete values.totalPriceInWords;
        }
        const formatedValues = {
          ...values,
          quotationPreparedByInWords: {
            fullname: values.preparedByName,
            designation: values.preparedByDesignation,
          },
          quotaionApprovedByInWords: {
            fullname: values.approvedByName,
            designation: values.approvedByDesignation,
          },
        };
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation/quote`, formatedValues);
        toast.success(res.data.message);
        getQuote();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const setValues = (data) => {
    formik.setFieldValue('amcCharges', data.amcCharges);
    formik.setFieldValue('termsAndConditions', data.termsAndConditions);
    formik.setFieldValue('exclusions', data.exclusions);
    formik.setFieldValue('remarks', data.remarks);
    formik.setFieldValue('clientNotes', data.clientNotes);
    formik.setFieldValue('totalPriceInWords', data.totalPriceInWords);
    formik.setFieldValue('totalAmcChargeInWords', data.totalAmcChargeInWords);
    formik.setFieldValue('approvedByName', data.quotaionApprovedByInWords?.fullname);
    formik.setFieldValue('approvedByDesignation', data.quotaionApprovedByInWords?.designation);
    formik.setFieldValue('preparedByName', data.quotationPreparedByInWords?.fullname);
    formik.setFieldValue('preparedByDesignation', data.quotationPreparedByInWords?.designation);
    formik.setFieldValue('quotationDate', data.quotationDate ? moment(data.quotationDate).format('YYYY-MM-DD') : '');
    formik.setFieldValue(
      'quotationValidTill',
      data.quotationValidTill ? moment(data.quotationValidTill).format('YYYY-MM-DD') : ''
    );
    formik.setFieldValue('quotationDiscount', data.quotationDiscount);
    formik.setFieldValue('roundedTotalPrice', data.roundedTotalPrice);
    formik.setFieldValue('totalDiscount', data.totalDiscount);
    setCurrentTotalAmcChargeInWords(data.totalAmcChargeInWords);
    setCurrentTotalPriceInWords(data.totalPriceInWords);
  };

  const getQuote = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/quote`, { params: { id } });
      console.log('quote', res.data);
      setQuote(res.data.data);
      setValues(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyAddress = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`)
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setCompanyAddress(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuotationSettings = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/projectSettings`)
      .then((res) => {
        setQuotationSettings(res.data.data);
        console.log('res', res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getQuote();
    getCompanyAddress();
    getQuotationSettings();
  }, []);

  const prefill = (item) => {
    if (quote && quotationSettings?.remarks && item === 'remarks') {
      formik.setFieldValue('remarks', quotationSettings?.remarks);
    }
    if (quote && quotationSettings?.termsAndConditions && item === 'termsAndConditions') {
      formik.setFieldValue('termsAndConditions', quotationSettings?.termsAndConditions);
    }
    if (quote && quotationSettings?.exclusions && item === 'exclusions') {
      formik.setFieldValue('exclusions', quotationSettings?.exclusions);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Box paddingBottom={10}>
            <ViewQuoteBasicDetails data={quote} getQuote={getQuote} />

            <ReactToPrint
              pageStyle={
                '@page { size: A4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }'
              }
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Print />}
                  sx={{ float: 'right', marginBottom: '10px' }}
                >
                  Print
                </Button>
              )}
              content={() => componentRef.current}
              copyStyles
            />
            <Box>
              <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'} spacing={3} mb={4}>
                <Logo />
                <Typography variant="h4" fontWeight="bold">
                  COMMERCIAL PROPOSAL
                </Typography>
              </Stack>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                    <Table
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '2px solid #490018',
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                            {companyAddress?.companyName}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                            <FmdGood />
                          </StyledTableCell>
                          <StyledTableCell>
                            {companyAddress?.address}, <br />
                            {companyAddress?.pin}
                          </StyledTableCell>
                        </TableRow>
                        <TableRow sx={{ height: '50px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                            <Phone />
                          </StyledTableCell>
                          <StyledTableCell>{companyAddress?.phone}</StyledTableCell>
                        </TableRow>
                        <TableRow sx={{ height: '50px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                            <AlternateEmail />
                          </StyledTableCell>
                          <StyledTableCell>{companyAddress?.email}</StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                    <Table
                      size="small"
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '2px solid #490018',
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                            MEP | Technical Services | Energy Saver & Management | IoT | Conditional Monitoring | IT |
                            Wireless & Networking
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ height: '160px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center" colSpan={2}>
                            <Grid container spacing={2} my={0.5}>
                              <Grid item xs={4}>
                                <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                  <Box
                                    component={'img'}
                                    src={`/static/Picture1.png`}
                                    sx={{ width: '50px', height: '50px' }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                  <Box
                                    component={'img'}
                                    src={`/static/Picture2.png`}
                                    sx={{ width: '50px', height: '50px' }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={4}>
                                <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                  <Box
                                    component={'img'}
                                    src={`/static/Picture3.png`}
                                    sx={{ width: '50px', height: '50px' }}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2} my={0.5}>
                              <Grid item xs={6}>
                                <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                  <Box
                                    component={'img'}
                                    src={`/static/Picture4.png`}
                                    sx={{ width: '50px', height: '50px' }}
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={6}>
                                <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                  <Box
                                    component={'img'}
                                    src={`/static/Picture5.png`}
                                    sx={{ width: 'auto', height: '50px' }}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                    <Table
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '2px solid #490018',
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                            Customer Details
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                            <FmdGood />
                          </StyledTableCell>
                          <StyledTableCell>{quote?.customerId?.fullname}</StyledTableCell>
                        </TableRow>
                        <TableRow sx={{ height: '40px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                            <Phone />
                          </StyledTableCell>
                          <StyledTableCell>{quote?.customerId?.phone}</StyledTableCell>
                        </TableRow>
                        <TableRow sx={{ height: '40px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold" textAlign="center">
                            <AlternateEmail />
                          </StyledTableCell>
                          <StyledTableCell>{quote?.customerId?.email}</StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '10px', height: '100%' }}>
                    <Table
                      sx={{
                        '& .MuiTableCell-root': {
                          padding: '3px',
                        },
                        border: '2px solid #490018',
                      }}
                    >
                      <TableHead>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell colSpan={2} fontWeight="bold" textAlign="center">
                            Quotation
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ height: '60px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold">
                            No
                          </StyledTableCell>
                          <StyledTableCell>{quote?.referenceNumber}</StyledTableCell>
                        </TableRow>
                        <TableRow sx={{ height: '40px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold">
                            Date
                          </StyledTableCell>
                          <StyledTableCell>
                            {/* {quote?.quotationDate ? moment(quote?.quotationDate).format('DD-MM-YYYY') : 'N/A'} */}
                            <TextField
                              className="print-hide"
                              id="quotationDate"
                              size="small"
                              //  make border hidden
                              InputProps={{
                                disableUnderline: true,
                              }}
                              variant="standard"
                              type="date"
                              {...getFieldProps('quotationDate')}
                              error={Boolean(touched.quotationDate && errors.quotationDate)}
                              helperText={touched.quotationDate && errors.quotationDate}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography className="print-show">
                              {quote?.quotationDate ? moment(quote?.quotationDate).format('DD-MM-YYYY') : 'N/A'}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow sx={{ height: '40px' }}>
                          <StyledTableCell sx={{ width: '80px' }} fontWeight="bold">
                            Valid Till
                          </StyledTableCell>
                          <StyledTableCell>
                            {/* {quote?.quotationValidTill ? moment(quote?.quotationValidTill).format('DD-MM-YYYY') : 'N/A'} */}
                            <TextField
                              className="print-hide"
                              id="quotationValidTill"
                              type="date"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              variant="standard"
                              size="small"
                              {...getFieldProps('quotationValidTill')}
                              error={Boolean(touched.quotationValidTill && errors.quotationValidTill)}
                              helperText={touched.quotationValidTill && errors.quotationValidTill}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography className="print-show">
                              {quote?.quotationValidTill
                                ? moment(quote?.quotationValidTill).format('DD-MM-YYYY')
                                : 'N/A'}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>

              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Box sx={{ marginTop: '5px' }}>
                    <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '20px' }}>
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          border: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell sx={{ width: '80px' }}>Sl. No.</StyledTableCell>
                            <StyledTableCell colSpan={5}>Description</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Unit</StyledTableCell>
                            <StyledTableCell sx={{ width: '70px' }}>Qty</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Unit Price</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Total Price</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Discount</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quote?.items?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell>{index + 1}</StyledTableCell>
                              <StyledTableCell colSpan={5}>{item.name}</StyledTableCell>
                              <StyledTableCell>{item.unit}</StyledTableCell>
                              <StyledTableCell>{item.quantity}</StyledTableCell>
                              <StyledTableCell>{item.roundedUnitPriceAfterOverHead}</StyledTableCell>
                              <StyledTableCell>{item.roundedTotalPriceAfterOverhead}</StyledTableCell>
                              <StyledTableCell>{item.discount}</StyledTableCell>
                            </TableRow>
                          ))}

                          <TableRow sx={{ height: '50px' }}>
                            <StyledTableCell textAlign="center" colSpan={7} fontWeight="bold">
                              {''}
                            </StyledTableCell>
                            <StyledTableCell colSpan={2} fontWeight="bold">
                              Sub Total
                            </StyledTableCell>
                            <StyledTableCell fontWeight="bold">{quote?.roundedTotalPrice}</StyledTableCell>
                            <StyledTableCell fontWeight="bold">{quote?.totalDiscount}</StyledTableCell>
                          </TableRow>

                          <TableRow sx={{ height: '50px' }}>
                            <StyledTableCell textAlign="center" colSpan={7} fontWeight="bold">
                              {''}
                            </StyledTableCell>
                            <StyledTableCell colSpan={2} fontWeight="bold">
                              Additional Discount
                            </StyledTableCell>
                            <StyledTableCell
                              fontWeight="bold"
                              contentEditable
                              onBlur={(e) => {
                                formik.setFieldValue('quotationDiscount', e.target.innerText);
                              }}
                            >
                              {quote?.quotationDiscount}
                            </StyledTableCell>
                            <StyledTableCell fontWeight="bold">{''}</StyledTableCell>
                          </TableRow>

                          <TableRow sx={{ height: '50px' }}>
                            <StyledTableCell
                              textAlign="center"
                              colSpan={7}
                              color={
                                (values.totalPriceInWords && values.totalPriceInWords.trim()) ||
                                !setTotalPriceInWordsPlaceholder
                                  ? '#000'
                                  : '#ccc'
                              }
                              fontWeight="bold"
                              contentEditable
                              onClick={() => setTotalPriceInWordsPlaceholder('')}
                              onBlur={(e) => {
                                formik.setFieldValue('totalPriceInWords', e.target.innerText);
                                setTotalPriceInWordsPlaceholder('Enter Total Price in Words');
                              }}
                            >
                              {values.totalPriceInWords && values.totalPriceInWords.trim()
                                ? values.totalPriceInWords
                                : totalPriceInWordsPlaceholder}
                            </StyledTableCell>
                            <StyledTableCell colSpan={2} bgcolor="theme" fontWeight="bold" color="#fff">
                              Total
                            </StyledTableCell>
                            <StyledTableCell fontWeight="bold" colSpan={2}>
                              {parseFloat(
                                Number(values?.roundedTotalPrice) -
                                  Number(values?.quotationDiscount) -
                                  Number(values?.totalDiscount)
                              ).toFixed(2)}
                            </StyledTableCell>
                          </TableRow>

                          <TableRow>
                            <StyledTableCell colSpan={11} textAlign="center">
                              Additional 5% VAT will be applicable as per UAE Federal rule
                            </StyledTableCell>
                          </TableRow>

                          {/*  add an empty row */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer
                      // className="page-break"
                      className={values.amcCharges.length ? 'page-break' : 'print-hide'}
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderTop: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell textAlign="center" sx={{ width: '80px' }}>
                              AMC Charges
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell sx={{ width: '80px' }}>Sl. No.</StyledTableCell>
                            <StyledTableCell colSpan={5}>Description</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Unit</StyledTableCell>
                            <StyledTableCell sx={{ width: '70px' }}>Qty</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Unit Price</StyledTableCell>
                            <StyledTableCell sx={{ width: '90px' }}>Total Price</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {values.amcCharges.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const amcCharges = [...values.amcCharges];
                                      amcCharges.splice(index, 1);
                                      formik.setFieldValue('amcCharges', amcCharges);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={5}
                                contentEditable
                                onBlur={(e) => {
                                  const amcCharges = [...values.amcCharges];
                                  amcCharges[index].name = e.target.innerText;
                                  formik.setFieldValue('amcCharges', amcCharges);
                                }}
                              >
                                {item.name}
                              </StyledTableCell>
                              <StyledTableCell
                                contentEditable
                                onBlur={(e) => {
                                  const amcCharges = [...values.amcCharges];
                                  amcCharges[index].unit = e.target.innerText;
                                  formik.setFieldValue('amcCharges', amcCharges);
                                }}
                              >
                                {item.unit}
                              </StyledTableCell>
                              <StyledTableCell
                                contentEditable
                                onBlur={(e) => {
                                  const amcCharges = [...values.amcCharges];
                                  amcCharges[index].quantity = Number(e.target.innerText);
                                  amcCharges[index].totalPrice = parseFloat(
                                    Number(item.unitPrice) * Number(e.target.innerText)
                                  ).toFixed(2);
                                  formik.setFieldValue('amcCharges', amcCharges);
                                }}
                              >
                                {item.quantity}
                              </StyledTableCell>
                              <StyledTableCell
                                contentEditable
                                onBlur={(e) => {
                                  const amcCharges = [...values.amcCharges];
                                  amcCharges[index].unitPrice = Number(e.target.innerText);
                                  amcCharges[index].totalPrice = parseFloat(
                                    Number(item.quantity) * Number(e.target.innerText)
                                  ).toFixed(2);
                                  formik.setFieldValue('amcCharges', amcCharges);
                                }}
                              >
                                {item.unitPrice}
                              </StyledTableCell>
                              <StyledTableCell>
                                {item.totalPrice ? item.totalPrice : item.unitPrice * item.quantity}
                              </StyledTableCell>
                            </TableRow>
                          ))}

                          <TableRow sx={{ height: '50px' }}>
                            <StyledTableCell
                              textAlign="center"
                              colSpan={7}
                              fontWeight="bold"
                              color={
                                (values.totalAmcChargeInWords && values.totalAmcChargeInWords.trim()) ||
                                !setTotalAmcChargeInWordsPlaceholder.length
                                  ? '#000'
                                  : '#ccc'
                              }
                              contentEditable
                              onClick={() => setTotalAmcChargeInWordsPlaceholder('')}
                              onBlur={(e) => {
                                formik.setFieldValue('totalAmcChargeInWords', e.target.innerText);
                                setTotalAmcChargeInWordsPlaceholder('Enter Total AMC Charge in Words');
                              }}
                            >
                              {values.totalAmcChargeInWords && values.totalAmcChargeInWords.trim()
                                ? values.totalAmcChargeInWords
                                : totalAmcChargeInWordsPlaceholder}
                            </StyledTableCell>
                            <StyledTableCell colSpan={2} bgcolor="theme" fontWeight="bold" color="#fff">
                              Sub Total
                            </StyledTableCell>
                            <StyledTableCell colSpan={2} fontWeight="bold">
                              {values.amcCharges.reduce(
                                (acc, item) => acc + Number(item.totalPrice || item.unitPrice * item.quantity),
                                0
                              )}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Button
                                size="small"
                                startIcon={<AddCircle />}
                                variant="contained"
                                color="successDarker"
                                sx={{ float: 'right' }}
                                onClick={() => {
                                  const amcCharges = [...values.amcCharges];
                                  amcCharges.push({
                                    name: '',
                                    unit: '',
                                    quantity: '',
                                    unitPrice: '',
                                    totalPrice: '',
                                  });
                                  formik.setFieldValue('amcCharges', amcCharges);
                                }}
                              >
                                Row
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.termsAndConditions?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            TERMS & CONDITIONS
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.termsAndConditions?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const termsAndConditions = [...values.termsAndConditions];
                                      termsAndConditions.splice(index, 1);
                                      formik.setFieldValue('termsAndConditions', termsAndConditions);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const termsAndConditions = [...values.termsAndConditions];
                                  termsAndConditions[index] = e.target.innerText;
                                  formik.setFieldValue('termsAndConditions', termsAndConditions);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                {!values?.termsAndConditions?.length && (
                                  <Button
                                    size="small"
                                    startIcon={<RestartAlt />}
                                    variant="contained"
                                    color="warning"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      prefill('termsAndConditions');
                                    }}
                                  >
                                    Prefill
                                  </Button>
                                )}
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const termsAndConditions = [...values.termsAndConditions];
                                    termsAndConditions.push('');
                                    formik.setFieldValue('termsAndConditions', termsAndConditions);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.exclusions?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            EXCLUSIONS
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.exclusions?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const exclusions = [...values.exclusions];
                                      exclusions.splice(index, 1);
                                      formik.setFieldValue('exclusions', exclusions);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const exclusions = [...values.exclusions];
                                  exclusions[index] = e.target.innerText;
                                  formik.setFieldValue('exclusions', exclusions);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                {!values?.exclusions?.length && (
                                  <Button
                                    size="small"
                                    startIcon={<RestartAlt />}
                                    variant="contained"
                                    color="warning"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      prefill('exclusions');
                                    }}
                                  >
                                    Prefill
                                  </Button>
                                )}
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const exclusions = [...values.exclusions];
                                    exclusions.push('');
                                    formik.setFieldValue('exclusions', exclusions);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/*  remarks */}
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.remarks?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            REMARKS
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.remarks?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const remarks = [...values.remarks];
                                      remarks.splice(index, 1);
                                      formik.setFieldValue('remarks', remarks);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const remarks = [...values.remarks];
                                  remarks[index] = e.target.innerText;
                                  formik.setFieldValue('remarks', remarks);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                {!values?.remarks?.length && (
                                  <Button
                                    size="small"
                                    startIcon={<RestartAlt />}
                                    variant="contained"
                                    color="warning"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      prefill('remarks');
                                    }}
                                  >
                                    Prefill
                                  </Button>
                                )}
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const remarks = [...values.remarks];
                                    remarks.push('');
                                    formik.setFieldValue('remarks', remarks);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* client notes */}
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.clientNotes?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            NOTES
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.clientNotes?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const clientNotes = [...values.clientNotes];
                                      clientNotes.splice(index, 1);
                                      formik.setFieldValue('clientNotes', clientNotes);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const clientNotes = [...values.clientNotes];
                                  clientNotes[index] = e.target.innerText;
                                  formik.setFieldValue('clientNotes', clientNotes);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const clientNotes = [...values.clientNotes];
                                    clientNotes.push('');
                                    formik.setFieldValue('clientNotes', clientNotes);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '20px' }}>
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderBottom: '2px solid #490018',
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                        }}
                      >
                        <TableBody>
                          <TableRow>
                            <StyledTableCell
                              size="small"
                              rowSpan={2}
                              textAlign="center"
                              fontWeight="bold"
                              sx={{ width: '120px' }}
                            >
                              Prepared By
                            </StyledTableCell>
                            <StyledTableCell
                              size="small"
                              textAlign="center"
                              contentEditable
                              sx={{ width: '320px' }}
                              onBlur={(e) => {
                                formik.setFieldValue('preparedByName', e.target.innerText);
                              }}
                            >
                              {values.preparedByName}
                            </StyledTableCell>
                            <StyledTableCell size="small" rowSpan={2}>
                              {''}
                            </StyledTableCell>
                            <StyledTableCell
                              size="small"
                              rowSpan={2}
                              textAlign="center"
                              fontWeight="bold"
                              sx={{ width: '120px' }}
                            >
                              Approved By
                            </StyledTableCell>
                            <StyledTableCell
                              size="small"
                              textAlign="center"
                              contentEditable
                              sx={{ width: '320px' }}
                              onBlur={(e) => {
                                formik.setFieldValue('approvedByName', e.target.innerText);
                              }}
                            >
                              {values.approvedByName}
                            </StyledTableCell>
                          </TableRow>
                          <TableRow>
                            <StyledTableCell
                              size="small"
                              textAlign="center"
                              contentEditable
                              sx={{ width: '320px' }}
                              onBlur={(e) => {
                                formik.setFieldValue('preparedByDesignation', e.target.innerText);
                              }}
                            >
                              {values.preparedByDesignation}
                            </StyledTableCell>
                            <StyledTableCell
                              size="small"
                              textAlign="center"
                              contentEditable
                              sx={{ width: '320px' }}
                              onBlur={(e) => {
                                formik.setFieldValue('approvedByDesignation', e.target.innerText);
                              }}
                            >
                              {values.approvedByDesignation}
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ padding: '10px' }}
                    className="print-hide"
                  >
                    {quote.quotationStatus === 'PENDING' && (
                      <>
                        <Button
                          variant="contained"
                          color="successDarker"
                          sx={{ marginRight: '10px' }}
                          onClick={() => {
                            setOpenFinalise(true);
                          }}
                          startIcon={<FactCheck />}
                        >
                          Finalise
                        </Button>
                        <FinaliseQuoteModal
                          setOpen={setOpenFinalise}
                          open={openFinalise}
                          getQuote={getQuote}
                          quoteId={quote?._id}
                        />
                      </>
                    )}

                    {['NA', 'PENDING'].includes(quote.quotationStatus) && (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        onClick={() => {
                          formik.setFieldValue('save', true);
                          formik.handleSubmit();
                        }}
                        startIcon={<Save />}
                      >
                        Save
                      </LoadingButton>
                    )}

                    {['NA', 'PENDING'].includes(quote.quotationStatus) && (
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        startIcon={<CheckCircle />}
                        onClick={() => {
                          formik.setFieldValue('save', false);
                          formik.handleSubmit();
                        }}
                      >
                        Submit
                      </LoadingButton>
                    )}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                    sx={{ padding: '10px' }}
                    className="print-hide"
                  >
                    {(quote.quotationStatus === 'FINALISED' || quote.quotationStatus === 'REJECTED') && (
                      <>
                        <Button
                          variant="contained"
                          color="errorDarker"
                          sx={{ marginRight: '10px' }}
                          onClick={() => {
                            setOpenRevise(true);
                          }}
                          startIcon={<Iconify icon="fluent-mdl2:repeat-header-rows" />}
                        >
                          Revise
                        </Button>
                        <ReviseQuoteModal
                          setOpen={setOpenRevise}
                          open={openRevise}
                          getQuote={getQuote}
                          quoteId={quote?._id}
                        />
                      </>
                    )}
                    {quote.quotationStatus === 'FINALISED' && (
                      <>
                        <Button
                          variant="contained"
                          color="errorDarker"
                          sx={{ marginRight: '10px' }}
                          onClick={() => {
                            setOpenSharedWithClient(true);
                          }}
                          startIcon={<Iconify icon="bi:send-fill" />}
                        >
                          Shared with Client
                        </Button>
                        <SharedWithClientModal
                          setOpen={setOpenSharedWithClient}
                          open={openSharedWithClient}
                          getQuote={getQuote}
                          quoteId={quote?._id}
                        />
                      </>
                    )}

                    {quote.quotationStatus === 'SHARED_WITH_CLIENT' && (
                      <>
                        <Button
                          variant="contained"
                          color="successDarker"
                          onClick={() => {
                            setOpenClientApprove(true);
                          }}
                          startIcon={<Iconify icon="mdi:thumb-up" />}
                        >
                          Client Approved
                        </Button>
                        <ClientApprovalQuoteModal
                          setOpen={setOpenClientApprove}
                          open={openClientApprove}
                          getQuote={getQuote}
                          quoteId={quote?._id}
                          type="APPROVED"
                        />
                      </>
                    )}

                    {quote.quotationStatus === 'SHARED_WITH_CLIENT' && (
                      <>
                        <Button
                          variant="contained"
                          color="successDarker"
                          onClick={() => {
                            setOpenClientReject(true);
                          }}
                          startIcon={<Iconify icon="mdi:thumb-down" />}
                        >
                          Client Rejected
                        </Button>
                        <ClientApprovalQuoteModal
                          getQuote={getQuote}
                          quoteId={quote?._id}
                          type="REJECTED"
                          setOpen={setOpenClientReject}
                          open={openClientReject}
                        />
                      </>
                    )}
                  </Stack>
                </Form>
              </FormikProvider>
            </Box>
          </Box>
          <Box sx={{ paddingY: '10px', marginBottom: '50px' }}>
            <QuotationNotes notes={quote?.notes} />
          </Box>
          <AttachMentsContainer type="quotation" />
        </Container>
      )}

      {/* printable quote */}
      <>
        <Stack
          direction={'column'}
          sx={{
            width: '1100px',
            height: '100%',
            padding: '20px',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
          spacing={5}
          ref={componentRef}
          className="print-only"
        >
          {/*  head */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }} pb={5}>
            <Stack direction="column" alignItems="flex-start">
              <Logo
                sx={{
                  width: '180px',
                }}
              />
              <Typography
                variant="h6"
                fontWeight={'bold'}
                sx={{
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                {companyAddress?.companyName}
              </Typography>
              <Typography sx={{ fontSize: '14px' }}>{companyAddress?.address}</Typography>
              <Typography sx={{ fontSize: '14px' }}>{companyAddress?.pin}</Typography>
              <Typography sx={{ fontSize: '14px' }}>{companyAddress?.phone}</Typography>
              <Typography sx={{ fontSize: '14px' }}>{companyAddress?.email}</Typography>
            </Stack>

            <Stack
              direction={'column'}
              alignItems={'flex-end'}
              sx={{
                width: '400px',
              }}
            >
              <Typography
                variant="h3"
                fontWeight={'bold'}
                sx={{
                  color: (theme) => theme.palette.macinsIndigo.main,
                  lineHeight: '1',
                }}
              >
                COMMERCIAL PROPOSAL
              </Typography>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }}>
                {`No. : ${quote?.referenceNumber}`}
              </Typography>
              {/* 
              MEP | Technical Services | Energy Saver & Management | IoT | Conditional Monitoring | IT |
                            Wireless & Networking    section with pictures */}
              <Stack sx={{ width: '100%' }} py={2}>
                <Grid container spacing={2}>
                  <Grid item xs={2.4}>
                    <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                      <Box component={'img'} src={`/static/Picture1.png`} sx={{ height: '35px' }} />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                      <Box component={'img'} src={`/static/Picture2.png`} sx={{ height: '35px' }} />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                      <Box component={'img'} src={`/static/Picture3.png`} sx={{ height: '35px' }} />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                      <Box component={'img'} src={`/static/Picture4.png`} sx={{ height: '35px' }} />
                    </Stack>
                  </Grid>
                  <Grid item xs={2.4}>
                    <Stack sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                      <Box component={'img'} src={`/static/Picture5.png`} sx={{ height: '40px' }} />
                    </Stack>
                  </Grid>
                </Grid>
                <Typography
                  sx={{ fontSize: '12px', color: (theme) => theme.palette.primary.main }}
                  variant="caption"
                  textAlign={'end'}
                >
                  MEP | Technical Services | Energy Saver & Management | IoT |
                  <br />
                  Conditional Monitoring | IT | Wireless & Networking
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }}>
            <Stack direction={'column'} spacing={1} alignItems={'flex-start'} sx={{ maxWidth: '300px' }}>
              <Typography
                fontWeight={'bold'}
                gutterBottom
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}
              >
                Customer
              </Typography>
              <Stack spacing={0.25} alignItems={'flex-start'}>
                <Typography sx={{ fontSize: '14px' }}>{quote?.customerId?.fullname}</Typography>
                <Typography sx={{ fontSize: '14px' }}>{quote?.customerId?.address}</Typography>
                <Typography sx={{ fontSize: '14px' }}>{quote?.customerId?.phone}</Typography>
                <Typography sx={{ fontSize: '14px' }}>{quote?.customerId?.email}</Typography>
              </Stack>
            </Stack>

            <Stack direction={'column'} spacing={1} alignItems={'flex-end'} sx={{ maxWidth: '300px' }}>
              <Typography
                fontWeight={'bold'}
                gutterBottom
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}
              >
                Quotation
              </Typography>
              <Stack spacing={0.25} alignItems={'flex-end'}>
                <Typography sx={{ fontSize: '14px' }}>
                  {`Date : ${quote?.quotationDate ? moment(quote?.quotationDate).format('DD-MM-YYYY') : 'N/A'}`}
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  {`Valid Till : ${
                    quote?.quotationValidTill ? moment(quote?.quotationValidTill).format('DD-MM-YYYY') : 'N/A'
                  }`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  items */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }}>
            <TableContainer component={Paper} sx={{ padding: '3px', marginBottom: '20px' }}>
              <Table
                sx={{
                  '& .MuiTableCell-root': {
                    padding: '4px',
                  },
                  border: '1px solid #eceff3',
                  overflow: 'hidden',
                }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell1 sx={{ width: '80px' }}>Sl. No.</StyledTableCell1>
                    <StyledTableCell1 colSpan={5}>Description</StyledTableCell1>
                    <StyledTableCell1 sx={{ width: '90px' }}>Unit</StyledTableCell1>
                    <StyledTableCell1 sx={{ width: '70px' }}>Qty</StyledTableCell1>
                    <StyledTableCell1 sx={{ width: '100px' }}>Unit Price</StyledTableCell1>
                    <StyledTableCell1 sx={{ width: '100px' }} colSpan={quote?.totalDiscount > 0 ? 1 : 2}>
                      Total Price
                    </StyledTableCell1>
                    {quote?.totalDiscount > 0 && <StyledTableCell1 sx={{ width: '100px' }}>Discount</StyledTableCell1>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quote?.items?.map((item, index) => (
                    <TableRow key={index}>
                      <StyledTableCell1>{index + 1}</StyledTableCell1>
                      <StyledTableCell1 colSpan={5}>{item.name}</StyledTableCell1>
                      <StyledTableCell1>{item.unit}</StyledTableCell1>
                      <StyledTableCell1>{item.quantity}</StyledTableCell1>
                      <StyledTableCell1 textAlign="right">
                        {parseFloat(item.roundedUnitPriceAfterOverHead).toFixed(2)}
                      </StyledTableCell1>
                      <StyledTableCell1 textAlign="right">
                        {parseFloat(item.roundedTotalPriceAfterOverhead).toFixed(2)}
                      </StyledTableCell1>
                      {quote?.totalDiscount > 0 && (
                        <StyledTableCell1 textAlign="right">
                          {Number(item.discount) > 0 ? parseFloat(item.discount).toFixed(2) : ''}
                        </StyledTableCell1>
                      )}
                    </TableRow>
                  ))}

                  <TableRow sx={{ height: '50px' }}>
                    <StyledTableCell1 textAlign="center" colSpan={7} fontWeight="bold">
                      {''}
                    </StyledTableCell1>
                    <StyledTableCell1 colSpan={2} fontWeight="bold">
                      Sub Total
                    </StyledTableCell1>
                    <StyledTableCell1 fontWeight="bold" textAlign="right">
                      {parseFloat(quote?.roundedTotalPrice).toFixed(2)}
                    </StyledTableCell1>
                    {quote?.totalDiscount > 0 && (
                      <StyledTableCell1 fontWeight="bold" textAlign="right">
                        {Number(quote?.totalDiscount) > 0 ? parseFloat(quote?.totalDiscount).toFixed(2) : ''}
                      </StyledTableCell1>
                    )}
                  </TableRow>

                  {quote?.quotationDiscount > 0 && (
                    <TableRow sx={{ height: '50px' }}>
                      <StyledTableCell1 textAlign="center" colSpan={7} fontWeight="bold">
                        {''}
                      </StyledTableCell1>
                      <StyledTableCell1 colSpan={2} fontWeight="bold">
                        Additional Discount
                      </StyledTableCell1>
                      <StyledTableCell1 fontWeight="bold" textAlign="right">
                        {parseFloat(quote?.quotationDiscount).toFixed(2)}
                      </StyledTableCell1>
                      <StyledTableCell1 fontWeight="bold">{''}</StyledTableCell1>
                    </TableRow>
                  )}

                  <TableRow sx={{ height: '50px' }}>
                    <StyledTableCell1 textAlign="center" colSpan={7} fontWeight="bold">
                      {quote?.totalPriceInWords && quote?.totalPriceInWords.trim() ? quote?.totalPriceInWords : ''}
                    </StyledTableCell1>
                    <StyledTableCell1 colSpan={2} bgcolor="theme" fontWeight="bold" color="#fff">
                      Total
                    </StyledTableCell1>
                    <StyledTableCell1 fontWeight="bold" colSpan={2} textAlign="center">
                      AED{' '}
                      {parseFloat(
                        Number(quote?.roundedTotalPrice) -
                          Number(quote?.quotationDiscount) -
                          Number(quote?.totalDiscount)
                      ).toFixed(2)}
                    </StyledTableCell1>
                  </TableRow>

                  <TableRow>
                    <StyledTableCell1 colSpan={11} textAlign="center">
                      Additional 5% VAT will be applicable as per UAE Federal rule
                    </StyledTableCell1>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>

          {quote?.amcCharges?.length > 0 && (
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }}>
              <TableContainer
                className={quote?.amcCharges.length ? 'page-break' : 'print-hide'}
                component={Paper}
                sx={{ padding: '3px', marginBottom: '20px' }}
              >
                <Table
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '3px',
                    },
                    borderLeft: '2px solid #490018',
                    borderRight: '2px solid #490018',
                    borderTop: '2px solid #490018',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell textAlign="center" sx={{ width: '80px' }}>
                        AMC Charges
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <Table
                  sx={{
                    '& .MuiTableCell-root': {
                      padding: '3px',
                    },
                    borderLeft: '2px solid #490018',
                    borderRight: '2px solid #490018',
                    borderBottom: '2px solid #490018',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ width: '80px' }}>Sl. No.</StyledTableCell>
                      <StyledTableCell colSpan={5}>Description</StyledTableCell>
                      <StyledTableCell sx={{ width: '90px' }}>Unit</StyledTableCell>
                      <StyledTableCell sx={{ width: '70px' }}>Qty</StyledTableCell>
                      <StyledTableCell sx={{ width: '90px' }}>Unit Price</StyledTableCell>
                      <StyledTableCell sx={{ width: '90px' }}>Total Price</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quote?.amcCharges.map((item, index) => (
                      <TableRow key={index}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{item.name}</StyledTableCell>
                        <StyledTableCell>{item.unit}</StyledTableCell>
                        <StyledTableCell>{item.quantity}</StyledTableCell>
                        <StyledTableCell>{item.unitPrice}</StyledTableCell>
                        <StyledTableCell>
                          {item.totalPrice ? item.totalPrice : item.unitPrice * item.quantity}
                        </StyledTableCell>
                      </TableRow>
                    ))}

                    <TableRow sx={{ height: '50px' }}>
                      <StyledTableCell textAlign="center" colSpan={7} fontWeight="bold">
                        {quote?.totalAmcChargeInWords && quote?.totalAmcChargeInWords.trim()
                          ? quote?.totalAmcChargeInWords
                          : ''}
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} bgcolor="theme" fontWeight="bold" color="#fff">
                        Sub Total
                      </StyledTableCell>
                      <StyledTableCell colSpan={2} fontWeight="bold">
                        {quote?.amcCharges.reduce(
                          (acc, item) => acc + Number(item.totalPrice || item.unitPrice * item.quantity),
                          0
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          )}

          {/*  terms and conditions */}
          {quote?.termsAndConditions?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '13px' }}>
                Terms & Conditions
              </Typography>
              <Stack spacing={1}>
                {quote?.termsAndConditions?.map((term, index) => (
                  <Typography key={index} sx={{ fontSize: '13px', color: (theme) => theme.palette.text.secondary }}>
                    {term}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/*  exclusions */}
          {quote?.exclusions?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '13px' }}>
                Exclusions
              </Typography>
              <Stack spacing={1}>
                {quote?.exclusions?.map((exclusion, index) => (
                  <Typography key={index} sx={{ fontSize: '13px', color: (theme) => theme.palette.text.secondary }}>
                    {exclusion}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/*  remarks */}
          {quote?.remarks?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '13px' }}>
                Remarks
              </Typography>
              <Stack spacing={1}>
                {quote?.remarks?.map((remark, index) => (
                  <Typography key={index} sx={{ fontSize: '13px', color: (theme) => theme.palette.text.secondary }}>
                    {remark}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/*  client notes */}
          {quote?.clientNotes?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '13px' }}>
                Notes
              </Typography>
              <Stack spacing={1}>
                {quote?.clientNotes?.map((note, index) => (
                  <Typography key={index} sx={{ fontSize: '13px', color: (theme) => theme.palette.text.secondary }}>
                    {note}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/*  Prepared By and  */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ width: '100%', marginTop: '80px' }}
          >
            <Stack direction={'column'} alignItems={'flex-start'}>
              <Typography
                fontWeight={'bold'}
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '13px' }}
                gutterBottom
              >
                Prepared By
              </Typography>
              <Typography sx={{ fontSize: '13px' }}>{quote?.quotationPreparedByInWords?.fullname}</Typography>
              <Typography sx={{ fontSize: '13px' }}>{quote?.quotationPreparedByInWords?.designation}</Typography>
            </Stack>
            <Stack direction={'column'} alignItems={'flex-end'}>
              <Typography
                fontWeight={'bold'}
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '13px' }}
                gutterBottom
              >
                Approved By
              </Typography>
              <Typography sx={{ fontSize: '13px' }}>{quote?.quotaionApprovedByInWords?.fullname}</Typography>
              <Typography sx={{ fontSize: '13px' }}>{quote?.quotaionApprovedByInWords?.designation}</Typography>
            </Stack>
          </Stack>

          {/* This document is computer generated and does not require the signature or the Company's stamp in order to be considered valid */}
          <Stack
            direction={'row'}
            justifyContent={'center'}
            className="disclaimer"
            alignItems={'center'}
            paddingTop={5}
            sx={{
              width: '100%',
              padding: '20px',
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'lighter',
                color: (theme) => theme.palette.text.secondary,
                fontStyle: 'italic',
              }}
              textAlign={'center'}
            >
              This document is computer generated and does not require the signature or the Company's stamp in order to
              be considered valid.
            </Typography>
          </Stack>
        </Stack>
      </>
    </>
  );
}

export default ViewQuote;
