import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Avatar,
  chip,
  Chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/TaxCategories';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/TaxCategoryStatusChange';
import { StyledTableRow } from '../theme/styledComponents';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '10px 15px',
}));

export default function TaxCategories() {
  const matches = useMediaQuery('(max-width:600px)');
  const [taxCategoryData, setTaxCategoryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [taxCategoryCount, setTaxCategoryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);

  const taxCategorySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      percentage: 0,
      isBlocked: 'false',
    },
    validationSchema: taxCategorySchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/tax`, values);
        if (!res.data.isError) {
          closeAddTaxCategoryModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getTaxCategorys(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getTaxCategorys(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddTaxCategoryModal = () => {
    setOpen(true);
  };
  const closeAddTaxCategoryModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTaxCategorys(temp);
  };

  const getTaxCategorys = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/tax/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTaxCategoryCount(res.data.data.maxRecords);
          setTaxCategoryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTaxCategorysWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tax/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setTaxCategoryCount(res.data.data.maxRecords);
          setTaxCategoryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(taxCategoryCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getTaxCategorys(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getTaxCategorysWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    // if (filter.name.length) {
    //   temp.name = filter.name;
    // }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.isPopular.length) {
      temp.isPopular = filter.isPopular;
    }

    setOptions(temp);
    setPages(0);
    getTaxCategorys(temp);
  };
  useEffect(() => {
    getTaxCategorys(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTaxCategorys(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Add taxCategory Dialog */}
          <Dialog open={open} onClose={closeAddTaxCategoryModal} fullScreen={matches}>
            <DialogTitle>Add TaxCategory</DialogTitle>
            <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Name"
                          select
                          {...getFieldProps('name')}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        >
                          <MenuItem value="GST">GST</MenuItem>
                          <MenuItem value="IGST">IGST</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <TextField
                          fullWidth
                          label="Percentage"
                          {...getFieldProps('percentage')}
                          error={Boolean(touched.percentage && errors.percentage)}
                          helperText={touched.percentage && errors.percentage}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField select fullWidth label="Status" {...getFieldProps('isBlocked')}>
                          <MenuItem value={'false'}>Active</MenuItem>
                          <MenuItem value={'true'}>Blocked</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeAddTaxCategoryModal}>Cancel</Button>
                      <LoadingButton type="submit" loading={isSubmitting}>
                        Add TaxCategory
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Box>
          </Dialog>
          {/* Add taxCategory dialog end */}
          <Container maxWidth="xl">
            <>
              {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search Tax name..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button startIcon={<AddIcon />} variant="contained" onClick={openAddTaxCategoryModal}>
                    Add Tax Category
                  </Button>

                  <Filter applyFilters={applyFilters} />
                </Stack>
              </Stack> */}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Name</TableCellCustom>
                        <TableCellCustom>Percentage</TableCellCustom>
                        <TableCellCustom>Status</TableCellCustom>
                        {/* <TableCell>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {taxCategoryData?.map((item, id) => (
                        <StyledTableRow key={id}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{item.name}</TableCellCustom>
                          <TableCellCustom>{item.percentage}</TableCellCustom>
                          <TableCellCustom>
                            {/* <FormControl sx={{ minWidth: 100 }}>
                              <Select
                                size="small"
                                value={item.isBlocked}
                                onChange={(e) => handleStatusChange(e, item, id)}
                              >
                                <MenuItem value={'false'}>🟢 Active</MenuItem>
                                <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                              </Select>
                              {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                            </FormControl> */}
                            <Chip
                              label={item.isBlocked ? 'Blocked' : 'Active'}
                              variant="outlined"
                              color={item.isBlocked ? 'error' : 'success'}
                              size="small"
                            />
                          </TableCellCustom>
                          {/* <TableCellCustom>
                            <Box>
                              <Link to={`/dashboard/taxCategory/view/${item._id}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Box>
                          </TableCellCustom> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={taxCategoryCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </Container>
        </>
      )}
    </>
  );
}
