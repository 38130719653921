import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  Chip,
  Box,
  Avatar,
} from '@mui/material';
import { Add, AddCircle, ArrowBack, Close, ContentCopy, Delete, Edit, RemoveCircle } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Filter from './filters/MaterialLogs';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import hasAccessOf from '../store/hasAccessOf';
import { StyledTableRow } from '../theme/styledComponents';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function MaterialTransaferLogs({ material, getMaterial, type }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [logData, setLogData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [logCount, setLogCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, materialId: type === 'material' ? id : null });

  const materialReadAccess = hasAccessOf('warehouse.material.r');
  const materialWriteAccess = hasAccessOf('warehouse.material.w');

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLogs(temp);
  };

  const getLogs = (options) => {
    if (!materialReadAccess) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/${type}`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLogsWithoutLoading = (options) => {
    if (!materialReadAccess) {
      setIsLoading(false);
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/${type}`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleSuccess = () => {
    setOpenPopup(false);
    getMaterial();
    getLogs(options);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, materialName: e.target.value };
    setOptions(temp);
    setPages(0);
    getLogsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.projectId) {
      temp.projectId = filter.projectId;
    }
    if (filter.materialId) {
      temp.materialId = filter.materialId;
      setName('');
      temp.materialName = '';
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.type) {
      temp.type = filter.type;
    }

    setOptions(temp);
    setPages(0);
    getLogs(temp);
  };

  useEffect(() => {
    getLogs(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Filter applyFilters={applyFilters} type={type} filterValues={options} />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search Material Name..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                endAdornment={
                  name.length > 0 && (
                    <Icon
                      onClick={() => {
                        setName('');
                        setOptions({ ...options, materialName: '' });
                        getLogsWithoutLoading({ ...options, materialName: '' });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Close />
                    </Icon>
                  )
                }
              />
            </Stack>
            {materialWriteAccess && (
              <Button
                startIcon={<Add />}
                variant="contained"
                onClick={() => navigate('/dashboard/warehouse/material/addToProject')}
              >
                Add
              </Button>
            )}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    {type === 'all' && <TableCell>Material Name</TableCell>}
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Projet Name</TableCell>
                    <TableCell>Supervisor</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logData?.map((item, id) => (
                    <StyledTableRow key={id}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      {type === 'all' && (
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={item?.image && process.env.REACT_APP_API_URL + item.image}
                              sx={{ width: 40, height: 40 }}
                            >
                              {item?.materialName?.charAt(0).toUpperCase()}
                            </Avatar>
                            <Box sx={{ marginLeft: 1 }}>
                              <Typography variant="subtitle2">{item?.materialName}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                      )}
                      <TableCell>{Moment(item.date).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          icon={item?.type === 'WAREHOUSE_IN' ? <AddCircle /> : <RemoveCircle />}
                          label={item?.type === 'WAREHOUSE_IN' ? 'IN' : 'OUT'}
                          color={item?.type === 'WAREHOUSE_IN' ? 'successDarker' : 'errorDarker'}
                        />
                      </TableCell>
                      <TableCell>{item?.projectName}</TableCell>
                      <TableCell>
                        {item?.supervisor && (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={
                                (item?.supervisor?.profilePic &&
                                  process.env.REACT_APP_API_URL + item.supervisor?.profilePic) ||
                                'https://www.w3schools.com/howto/img_avatar.png'
                              }
                              sx={{ width: 40, height: 40 }}
                            />
                            <Box sx={{ marginLeft: 1 }}>
                              <Typography variant="subtitle2">{item?.supervisor?.fullname}</Typography>
                              <Typography variant="caption">{item?.supervisor?.email}</Typography>
                            </Box>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>{item?.description}</TableCell>
                      <TableCell>{item?.unit}</TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getLogs(temp);
            }}
            component={'div'}
            count={logCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
