import { BorderColor, Edit, PhotoCamera } from '@mui/icons-material';
import { Box, Button, CircularProgress, Container, Grid, IconButton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import EditAdmin from './EditAdmin';

export default function AdminBasicInfo({ admin, getAdmin }) {
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (admin && admin?.fullname) setIsLoading(true);
  }, [admin]);
  return (
    <>
      {!isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl" sx={{ marginTop: '50px' }}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Grid
              container
              sx={{
                width: {
                  xs: '100%',
                  md: '85%',
                  lg: '85%',
                  xl: '80%',
                },
                borderRadius: '10px',
                boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              }}
            >
              <Grid item xs={12} sm={6} md={5} lg={4} padding={2}>
                <Box
                  component="img"
                  height="250px"
                  width="250px"
                  sx={{
                    objectFit: 'contain',
                  }}
                  src={
                    image ||
                    (admin?.profilePic && process.env.REACT_APP_API_URL + admin.profilePic) ||
                    'https://www.w3schools.com/howto/img_avatar.png'
                  }
                  alt="profile pic"
                />
              </Grid>
              {!editMode && (
                <Grid item xs={12} sm={6} md={7} lg={8} padding={2}>
                  <Stack
                    direction="column"
                    alignItems="center"
                    justifyContent="start"
                    sx={{ width: '100%', height: '100%' }}
                    marginLeft={2}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      margin={1}
                      sx={{ width: '100%' }}
                    >
                      <Typography variant="h4" gutterBottom>
                        ACCOUNT DETAILS
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" margin={1} sx={{ width: '100%' }}>
                      <Stack flex={1} justifyContent={'start'} alignItems="start">
                        <Typography>Name:</Typography>
                      </Stack>
                      <Stack flex={3} justifyContent={'start'} alignItems="start">
                        <Typography sx={{ fontWeight: 'bold' }}>{admin?.fullname}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" margin={1} sx={{ width: '100%' }}>
                      <Stack flex={1} justifyContent={'start'} alignItems="start">
                        <Typography>Designation:</Typography>
                      </Stack>
                      <Stack flex={3} justifyContent={'start'} alignItems="start">
                        <Typography sx={{ fontWeight: 'bold' }}>{admin?.designation}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" margin={1} sx={{ width: '100%' }}>
                      <Stack flex={1} justifyContent={'start'} alignItems="start">
                        <Typography>Description:</Typography>
                      </Stack>
                      <Stack flex={3} justifyContent={'start'} alignItems="start">
                        <Typography sx={{ fontWeight: 'bold' }}>{admin?.description}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" margin={1} sx={{ width: '100%' }}>
                      <Stack flex={1} justifyContent={'start'} alignItems="start">
                        <Typography>Email:</Typography> 
                      </Stack>
                      <Stack flex={3} justifyContent={'start'} alignItems="start">
                        <Typography sx={{ fontWeight: 'bold' }}>{admin?.email}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" margin={1} sx={{ width: '100%' }}>
                      <Stack flex={1} justifyContent={'start'} alignItems="start">
                        <Typography>Account Type:</Typography>
                      </Stack>
                      <Stack flex={3} justifyContent={'start'} alignItems="start">
                        <Typography sx={{ fontWeight: 'bold' }}>{admin?.accType?.replaceAll('_', ' ')}</Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" margin={1} sx={{ width: '100%' }}>
                      <Stack flex={1} justifyContent={'start'} alignItems="start">
                        <Typography>Member Since:</Typography>
                      </Stack>
                      <Stack flex={3} justifyContent={'start'} alignItems="start">
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {moment(admin.createdAt).format('MMM DD, YYYY')}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" justifyContent={'flex-end'} sx={{ width: '100%' }}>
                      <Button sx={{ marginX: '20px' }} onClick={() => setEditMode(true)}>
                        Edit
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              )}

              {editMode && (
                <EditAdmin profileData={admin} setEditMode={setEditMode} getAdmin={getAdmin} setImage={setImage} />
              )}
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
}
