import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { TextField, Button, Stack, MenuItem, Grid, Card, Collapse, IconButton, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters }) => {
  const [open, setOpen] = React.useState(false);
  const [memberRoles, setMemberRoles] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      vendorId: filterValues.vendorId,
      poReference: filterValues.poReference,
      referenceNumber: filterValues.referenceNumber,
      startDate: filterValues?.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
      endDate: filterValues?.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const getMemberRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/list`);
      if (!response.data.isError) {
        console.log(response.data.data);
        setMemberRoles(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getMemberRoles();
  }, []);

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="vendorId"
                    name="vendorId"
                    label="Supplier ID"
                    {...getFieldProps('vendorId')}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="poReference"
                    name="poReference"
                    label="PO Reference"
                    {...getFieldProps('poReference')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="referenceNumber"
                    name="referenceNumber"
                    label="Reference Number"
                    {...getFieldProps('referenceNumber')}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="startDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('startDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="endDate"
                    name="endDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('endDate')}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          id: filterValues.id,
                          fullname: filterValues.fullname,
                          isBlocked: filterValues.isBlocked,
                          email: filterValues.email,
                          phone: filterValues.phone,
                          startDate: filterValues?.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
                          endDate: filterValues?.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
