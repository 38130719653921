import axios from 'axios';
import { BorderColor, Close, Delete, Edit, RemoveRedEye } from '@mui/icons-material';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  CircularProgress,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ProjectEditForm from './ProjectEditForm';
import Iconify from './Iconify';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '40px', width: '40px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function ProjectBasicInfo({ project, getProject, projectWriteAccess }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  // const getStatusColor = (statusName) => {
  //   const statusMapping = {
  //     Pending: '#fff100',
  //     Ongoing: '#fff100',
  //     'Decision Pending': '#fff100',
  //     Interested: '#16c60c',
  //     'Not Interested': '#e81224',
  //   };
  //   return statusMapping[statusName] || 'white';
  // };

  useEffect(() => {
    if (project && project?.name) setIsLoading(false);
  }, [project]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box
            sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
            paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
            paddingTop={1}
            paddingBottom={4}
          >
            {!editMode && (
              <>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={project?.lead?.fullname ? 'space-between' : 'end'}
                  spacing={2}
                >
                  {project?.lead?.fullname && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => navigate(`/dashboard/project/rfq/${project?.lead?._id}`, { state: project })}
                    >
                      View RFQ Notes
                    </Button>
                  )}
                  {projectWriteAccess && (
                    <IconButton onClick={() => setEditMode(!editMode)}>
                      <BorderColor />{' '}
                    </IconButton>
                  )}
                </Stack>

                <Grid container spacing={2} mt={0.5}>
                  <Grid item xs={12}>
                    <DetailItem
                      icon={'icons8:parallel-tasks'}
                      title={'Project Name'}
                      data={project?.name}
                      background={'#fff100'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DetailItem
                      icon={'mdi:map-marker'}
                      title={'Location'}
                      data={project?.location}
                      background={'#fff100'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DetailItem
                      icon={'zondicons:question'}
                      title={'Status'}
                      data={project?.status?.replaceAll('_', ' ')}
                      background={'#fff100'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DetailItem
                      icon={'emojione-monotone:id-button'}
                      title={'Project ID'}
                      data={project?.projectId}
                      background={'#fff100'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DetailItem
                      icon={'mynaui:list-number'}
                      title={'Work Order Number'}
                      data={project?.workOrderNumber}
                      background={'#fff100'}
                    />
                  </Grid>
                  {project?.customerId?.fullname && (
                    <Grid item xs={12} lg={6}>
                      <DetailItem
                        icon={'clarity:users-solid'}
                        title={'Customer Name'}
                        data={project?.customerId?.fullname}
                        background={'#fff100'}
                      />
                    </Grid>
                  )}
                  {project?.customerId?.email && (
                    <Grid item xs={12} lg={6}>
                      <DetailItem
                        icon={'ic:round-email'}
                        title={'Email'}
                        data={project?.customerId?.email}
                        background={'#fff100'}
                      />
                    </Grid>
                  )}
                  {project?.customerId?.phone && (
                    <Grid item xs={12} lg={6}>
                      <DetailItem
                        icon={'ic:round-phone'}
                        title={'Phone'}
                        data={project?.customerId?.phone}
                        background={'#fff100'}
                      />
                    </Grid>
                  )}
                  {project?.customerId?.businessType && (
                    <Grid item xs={12} lg={6}>
                      <DetailItem
                        icon={'material-symbols-light:business-center-sharp'}
                        title={'Business Type'}
                        data={project?.customerId?.businessType}
                        background={'#fff100'}
                      />
                    </Grid>
                  )}
                  {/* {project?.lead?.fullname && (
                    <Grid item xs={12} lg={6}>
                      <DetailItem
                        icon={'mdi:leads-outline'}
                        title={'RFQ'}
                        data={project?.lead?.fullname}
                        background={'#fff100'}
                      />
                    </Grid>
                  )} */}
                </Grid>
              </>
            )}
            {editMode && (
              <ProjectEditForm
                project={project}
                getProject={getProject}
                setEditMode={setEditMode}
                editMode={editMode}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
}
