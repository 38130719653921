import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
  Chip,
  Box,
  Avatar,
} from '@mui/material';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/SalesStaffPerformance';

// components
import Scrollbar from './Scrollbar';
import hasAccessOf from '../store/hasAccessOf';
import { rfqStatusOrder } from '../utils/commonFunctions';
import { StyledTableRow } from '../theme/styledComponents';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function SalesStaffPerformance() {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const isAdmin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const isSalesStaff = accType === 'SALES_STAFF';
  const isSalesManager = accType === 'SALES_MANAGER';

  const { id } = useParams();
  const [logData, setLogData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [logCount, setLogCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openPopup, setOpenPopup] = useState(false);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const leadReadAccess = hasAccessOf('crm.lead.r');

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLogs(temp);
  };

  const getLogs = (options) => {
    if (!leadReadAccess) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/performance-report`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getLogsWithoutLoading = (options) => {
    if (!leadReadAccess) {
      setIsLoading(false);
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/lead/performance-report`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, materialName: e.target.value };
    setOptions(temp);
    setPages(0);
    getLogsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.teleCaller) {
      temp.teleCaller = filter.teleCaller;
    }
    if (filter.leadManager) {
      temp.leadManager = filter.leadManager;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    setPages(0);
    getLogs(temp);
  };

  useEffect(() => {
    getLogs(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent="end" alignItems="center">
            {/* <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search Material Name..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                endAdornment={
                  name.length > 0 && (
                    <Icon
                      onClick={() => {
                        setName('');
                        setOptions({ ...options, materialName: '' });
                        getLogsWithoutLoading({ ...options, materialName: '' });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Close />
                    </Icon>
                  )
                }
              />
            </Stack> */}
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              <Filter
                applyFilters={applyFilters}
                isAdmin={isAdmin}
                isSalesManager={isSalesManager}
                isSalesStaff={isSalesStaff}
              />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Sales Staff</TableCell>
                    <TableCell>Sales Manager</TableCell>
                    {rfqStatusOrder.map((item, id) => (
                      <TableCell key={id}>{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logData?.map((item, id) => (
                    <StyledTableRow key={id}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>
                        {' '}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={
                              (item?.teleCallerPic && process.env.REACT_APP_API_URL + item.teleCallerPic) ||
                              'https://www.w3schools.com/howto/img_avatar.png'
                            }
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.teleCaller}</Typography>
                            <Typography variant="caption">{item.teleCallerEmail}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {' '}
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={
                              (item?.managerData?.profilePic &&
                                process.env.REACT_APP_API_URL + item.managerData?.profilePic) ||
                              'https://www.w3schools.com/howto/img_avatar.png'
                            }
                            sx={{ width: 40, height: 40 }}
                          />
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.managerData?.fullname}</Typography>
                            <Typography variant="caption">{item.managerData?.email}</Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      {rfqStatusOrder.map((status, id) => {
                        const temp = item?.leadCountByStatus?.find((item) => item.name === status);
                        if (temp) {
                          return <TableCell key={id}>{temp.count}</TableCell>;
                        }
                        return <TableCell key={id}>0</TableCell>;
                      })}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getLogs(temp);
            }}
            component={'div'}
            count={logCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
