import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Chip,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Employee';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/EmployeeStatusChange';

import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import { setChipColor, setChipIcon } from '../utils/commonFunctions';
import EmployeeProfileAvatar from '../components/smallComponents/EmployeeProfileAvatar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function Employees() {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);

  const getEmployeesAccess = hasAccessOf('general.employee.r');
  const writeEmployeesAccess = hasAccessOf('general.employee.w');
  const createEmployeesAccess = hasAccessOf('general.employee.c');

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getEmployees(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getEmployees(temp);
  };

  const getEmployees = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/employee/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setEmployeeCount(res.data.data.maxRecords);
          setEmployeeData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getEmployeesWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member/employee/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setEmployeeCount(res.data.data.maxRecords);
          setEmployeeData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(employeeCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getEmployees(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };
    setOptions(temp);
    setPages(0);
    getEmployeesWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.fullname) {
      temp.fullname = filter.fullname;
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.id) {
      temp.id = filter.id;
    }
    if (filter.email) {
      temp.email = filter.email;
    }
    if (filter.role) {
      temp.role = filter.role;
    }
    console.log('temp', temp);
    setOptions(temp);
    setPages(0);
    getEmployees(temp);
  };
  useEffect(() => {
    if (getEmployeesAccess) {
      getEmployees(options);
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (getEmployeesAccess) {
      const temp = { ...options, size, page: 0 };
      setPages(0);
      setOptions(temp);
      getEmployees(temp);
    } else {
      setIsLoading(false);
    }
  }, [size]);

  const getPath = (role, id) => {
    switch (role) {
      case 'SALES_STAFF':
        return `/dashboard/general/employees/sales-staff/${id}`;
      case 'SALES_MANAGER':
        return `/dashboard/general/employees/lead-manager/${id}`;
      case 'PROJECT_MANAGER':
        return `/dashboard/general/employees/project-manager/${id}`;
      case 'PROJECT_ENGINEER':
        return `/dashboard/general/employees/project-engineer/${id}`;
      case 'WORKER':
        return `/dashboard/general/employees/worker/${id}`;
      case 'ACCOUNT_MANAGER':
        return `/dashboard/general/employees/accountant/${id}`;
      case 'WAREHOUSE_MANAGER':
        return `/dashboard/general/employees/warehouse-manager/${id}`;
      default:
        return `/dashboard/general/employees/view/${id}`;
    }
  };

  return (
    <>
      <Page title="Employees">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <PageHeader title="Employees" />
              {getEmployeesAccess && <Filter applyFilters={applyFilters} filterValues={options} />}

              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack> */}
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search employee..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    {createEmployeesAccess && (
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        onClick={() => navigate('/dashboard/general/employees/add')}
                      >
                        Employee
                      </Button>
                    )}
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCellCustom>Sl No</TableCellCustom>
                          <TableCellCustom>Member</TableCellCustom>
                          <TableCellCustom>Role</TableCellCustom>
                          <TableCellCustom>Description</TableCellCustom>
                          <TableCellCustom>Member Since</TableCellCustom>
                          <TableCellCustom>ID</TableCellCustom>
                          <TableCellCustom>Status</TableCellCustom>
                          <TableCellCustom>Action</TableCellCustom>
                          {/* <TableCellCustom>Actions</TableCellCustom> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeeData?.map((item, id) => (
                          <StyledTableRow key={id}>
                            <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                            <TableCellCustom>
                              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                  src={
                                    (item?.profilePic && process.env.REACT_APP_API_URL + item.profilePic) ||
                                    'https://www.w3schools.com/howto/img_avatar.png'
                                  }
                                  sx={{ width: 40, height: 40 }}
                                />
                                <Box sx={{ marginLeft: 1 }}>
                                  <Typography variant="subtitle2">{item?.fullname}</Typography>
                                  <Typography variant="caption">{item.email}</Typography>
                                </Box>
                              </Box> */}
                              <EmployeeProfileAvatar
                                profilePic={item?.profilePic}
                                firstLine={item?.fullname}
                                secondLine={item?.email || item?.phone}
                                role={item?.role}
                                id={item?._id}
                              />
                            </TableCellCustom>
                            <TableCellCustom sx={{ minWidth: '160px', maxWidth: '180px' }}>
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: '100px' }}>
                                <Chip
                                  icon={<Iconify sx={{ width: 20, height: 20 }} icon={setChipIcon(item.role)} />}
                                  label={
                                    item?.role === 'SALES_STAFF' ? 'SALES STAFF' : item?.role?.replaceAll('_', ' ')
                                  }
                                  sx={{ margin: 0.3 }}
                                  color={setChipColor(item.role)}
                                />
                              </Box>
                            </TableCellCustom>
                            <TableCellCustom sx={{ minWidth: '160px', maxWidth: '180px' }}>
                              {item?.description}
                            </TableCellCustom>
                            <TableCellCustom sx={{ minWidth: '110px', maxWidth: '120px' }}>
                              {moment(item.createdAt).format('DD MMM YYYY')}
                            </TableCellCustom>
                            <TableCellCustom style={{ cursor: 'pointer', width: '80px' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Employee ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <ContentCopy color="primary" />
                                    <Typography color="primary.main" variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCellCustom>
                            <TableCellCustom>
                              {writeEmployeesAccess ? (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={item.isBlocked}
                                    onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    <MenuItem value={'false'}>🟢 Active</MenuItem>
                                    <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                                  </Select>
                                  {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                                </FormControl>
                              ) : (
                                <Chip
                                  label={item.isBlocked ? 'Blocked' : 'Active'}
                                  variant="outlined"
                                  size="small"
                                  color={item.isBlocked ? 'error' : 'success'}
                                />
                              )}
                            </TableCellCustom>
                            <TableCellCustom>
                              <Box>
                                <Link to={getPath(item.role, item._id)}>
                                  <Button
                                    variant="contained"
                                    startIcon={<RemoveRedEyeIcon />}
                                    size="small"
                                    color="viewButton"
                                  >
                                    View
                                  </Button>
                                </Link>
                              </Box>
                            </TableCellCustom>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={employeeCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
