import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import axios from 'axios';

const Popup1 = (props) => {
  const [response, setResponse] = React.useState('');
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/task/status-response`, {
        id: props.item._id,
        response,
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(false, err.response.data.message);
      });
  };

  const open = true;
  useEffect(() => {
    setResponse(props.item.response);
  }, [props.item]);

  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Update Response</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack
              mt={2}
              spacing={2}
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '500px',
                },
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                id="response"
                label="Response"
                value={response}
                type="text"
                fullWidth
                onChange={(e) => setResponse(e.target.value)}
              />
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus} variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup1;
