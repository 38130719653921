import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { ArrowBack, ContentCopy, Delete } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import Filter from './filters/TransactionseVendor';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function TransactionsVendor({ vendorId }) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [transactionData, setTransactionData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [transactionCount, setTransactionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTransactions(temp);
  };

  const getTransactions = (options) => {
    const temp = { ...options, vendorId };
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/transaction/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('Transactions', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTransactionCount(res.data.data.maxRecords);
          setTransactionData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTransactionsWithoutLoading = (options) => {
    const temp = { ...options, vendorId };
    axios
      .get(`${process.env.REACT_APP_API_URL}/transaction/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setTransactionCount(res.data.data.maxRecords);
          setTransactionData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, transactionName: e.target.value };
    setOptions(temp);
    setPages(0);
    getTransactionsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.transactionName.length) {
      temp.transactionName = filter.transactionName;
    }
    if (filter.transactionType.length) {
      temp.transactionType = filter.transactionType.trim();
    }
    if (filter.ledgerId.length) {
      temp.ledgerId = filter.ledgerId;
    }
    if (filter.vendorId) {
      temp.vendorId = filter.vendorId;
    }
    if (filter.billingId.length) {
      temp.billingId = filter.billingId;
    }
    if (filter.purchaseId.length) {
      temp.purchaseId = filter.purchaseId;
    }
    if (filter.salaryId.length) {
      temp.salaryId = filter.salaryId;
    }
    if (filter.journalId) {
      temp.journalId = filter.journalId;
    }
    if (filter.paymentReference) {
      temp.paymentReference = filter.paymentReference;
    }
    if (filter.remarks) {
      temp.remarks = filter.remarks;
    }
    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentStatus) {
      temp.paymentStatus = filter.paymentStatus;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getTransactions(temp);
  };

  useEffect(() => {
    getTransactions(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search transaction..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </Stack>
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              <Filter applyFilters={applyFilters} />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Transaction Date</TableCell>
                    <TableCell>Payment Date</TableCell>
                    <TableCell>Ledger</TableCell>
                    <TableCell>Transaction ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>{Moment(item.createdAt).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{Moment(item.paymentDate).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{item.ledgerData?.name}</TableCell>
                      <TableCell>
                        <Stack direction="column" spacing={0.5} width="80px">
                          <CopyToClipboard text={item._id} onCopy={() => toast.info('Transaction ID copied')}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}
                              justifyContent="flex-start"
                              sx={{ cursor: 'pointer' }}
                            >
                              <ContentCopy color="primary" />
                              <Typography color="primary.main" variant="p">
                                Copy ID
                              </Typography>
                            </Stack>
                          </CopyToClipboard>
                        </Stack>
                      </TableCell>
                      <TableCell>{item.transactionType}</TableCell>
                      <TableCell>{item.amount}</TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Link to={`/dashboard/accounts/payments/view-transaction/${item._id}`}>
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEyeIcon />
                            </Icon>
                          </Link> 
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getTransactions(temp);
            }}
            component={'div'}
            count={transactionCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
