import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Add, Cancel, Close, DeleteForever } from '@mui/icons-material';
import moment from 'moment';
import { convertToWords } from '../utils/commonFunctions';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

function PurchaseEditForm({ purchase, setEditMode }) {
  const navigate = useNavigate();
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [company, setCompany] = useState({});
  const [vendor, setVendor] = useState({});
  const [files, setFiles] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [paymentTerm, setPaymentTerm] = useState('');
  const [notes, setNotes] = useState('');
  const [purchaseOrders, setPurchaseOrders] = useState([{}]);
  const [currentGrandTotalInWords, setCurrentGrandTotalInWords] = useState('');

  const convertToParseFloat = (value) => parseFloat(value).toFixed(2);

  const purchaseSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
  });

  const formik = useFormik({
    initialValues: {
      _id: purchase._id,
      invoiceDate: moment(purchase.invoiceDate).format('YYYY-MM-DD'),
      subTotal: purchase.subTotal,
      grandTotal: purchase.grandTotal,
      items: purchase.items?.map((item) => ({
        _id: item._id,
        productId: item.productId,
        quantity: item.quantity,
        unitPrice: item.unitPrice,
        tax: item.tax.percentage,
        taxObject: item.tax,
        taxName: item.tax.name,
        total: item.total,
        name: item.name,
      })),
      vendorId: purchase.vendorId,
      totalTax: purchase.totalTax,
      billNumber: purchase.billNumber,
      termsAndConditions: purchase.termsAndConditions,
      attachments: purchase.attachments,
      paymentTerms: purchase.paymentTerms,
      notes: purchase.notes,
      purchaseOrderId: purchase.purchaseOrderId,
      grandTotalInWords: purchase.grandTotalInWords,
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      try {
        if (!values.items.length) {
          return toast.error('Please add atleast one item');
        }
        const noTaxFound = values.items.find((item) => item.noTaxFound);
        if (noTaxFound) {
          toast.error('Please select a valid tax for all items');
          return false;
        }
        if (currentGrandTotalInWords === values.grandTotalInWords) {
          delete values.grandTotalInWords;
        }
        const formValues = {
          ...values,
          subTotal: convertToParseFloat(values.subTotal),
          grandTotal: convertToParseFloat(values.grandTotal),
        };
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/purchase`, {
          ...formValues,
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate('/dashboard/accounts/purchase');
        return null; // Add return statement here
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        return null; // Add return statement here
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (purchase) {
      formik.setFieldValue('invoiceDate', moment(purchase?.invoiceDate).format('YYYY-MM-DD'));
      formik.setFieldValue('billNumber', purchase?.billNumber);
      formik.setFieldValue('vendorId', purchase?.vendorId);
      formik.setFieldValue('subTotal', purchase?.subTotal);
      formik.setFieldValue('grandTotal', purchase?.grandTotal);
      formik.setFieldValue('totalTax', purchase?.totalTax);
      formik.setFieldValue('termsAndConditions', purchase?.termsAndConditions);
      formik.setFieldValue('paymentTerms', purchase?.paymentTerms);
      formik.setFieldValue('notes', purchase?.notes);
      formik.setFieldValue('purchaseOrderId', purchase?.purchaseOrderId);
      formik.setFieldValue('grandTotalInWords', purchase?.grandTotalInWords);
      formik.setFieldValue('attachments', purchase?.attachments);
      formik.setFieldValue('items', purchase?.items);
      formik.setFieldValue('purchaseOrderId', purchase?.purchaseOrderId);
      formik.setFieldValue('grandTotalInWords', purchase?.grandTotalInWords);
      formik.setFieldValue(
        'items',
        purchase?.items?.map((item) => ({
          _id: item._id,
          productId: item.productId,
          quantity: item.quantity,
          unitPrice: item.unitPrice,
          tax: item.tax.percentage,
          taxObject: item.tax,
          taxName: item.tax.name,
          total: item.total,
          name: item.name,
        }))
      );
    }

    setCurrentGrandTotalInWords(purchase?.grandTotalInWords);
  }, [purchase]);

  useEffect(() => {
    const checkIfSomeItemsMissingTaxId = values.items.some((item) => !item.taxId);
    if (checkIfSomeItemsMissingTaxId && taxes.length > 0) {
      const newItems = [...values.items];
      newItems.forEach((item) => {
        console.log(item, 'item');
        const tax = taxes.find((tax) => tax.name === item.taxName && tax.percentage === item.taxObject.percentage);
        if (tax) {
          console.log(tax, 'tax');
          item.taxId = tax._id;
        } else {
          item.noTaxFound = true;
        }
      });
    } else {
      const newItems = [...values.items];
      newItems.forEach((item) => {
        item.noTaxFound = false;
      });
    }
  }, [taxes, values.items]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    let totalTax = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.unitPrice) * Number(item.quantity);
      totalTax += Number(item.unitPrice) * Number(item.quantity) * (Number(item.tax) / 100);
      grandTotal += Number(item.total);
    });
    const grandTotalInWords = convertToWords(grandTotal);
    formik.setFieldValue('grandTotalInWords', grandTotalInWords);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
    formik.setFieldValue('totalTax', totalTax);
  }, [values.items, values.taxId, taxes]);

  const getTaxes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tax/active/all`);
      console.log(res.data.data);
      setTaxes(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTaxes();
  }, []);

  const totalCalculator = (quantity, price, tax) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    total += (total * Number(tax)) / 100;
    return total.toFixed(5);
  };

  const taxCalculator = (price, tax) => {
    let totalTax = 0;
    totalTax = (price * Number(tax)) / 100;
    return totalTax.toFixed(2);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack
          direction={'row'}
          px={5}
          py={3}
          sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
          justifyContent={'space-between'}
        >
          {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
          <Stack justifyContent="center" spacing={2} direction={'column'}>
            <Stack direction={'row'} spacing={2}>
              <StyledTextField
                label="Invoice Date"
                size="small"
                type="date"
                {...getFieldProps('invoiceDate')}
                error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                helperText={touched.invoiceDate && errors.invoiceDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyledTextField
                label="Bill Number"
                size="small"
                type="text"
                {...getFieldProps('billNumber')}
                error={Boolean(touched.billNumber && errors.billNumber)}
                helperText={touched.billNumber && errors.billNumber}
              />
            </Stack>
          </Stack>
          <Button
            startIcon={<Close />}
            variant="text"
            color="light"
            size="small"
            onClick={() => setEditMode(false)}
          >
            Cancel
          </Button>
        </Stack>

        <Grid container>
          <Grid
            item
            xs={12}
            margin={1}
            sx={{
              borderRadius: '5px',
            }}
          >
            <Stack p={5}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Tax</TableCell>
                      <TableCell>Tax Amount</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ width: '50px' }}>{index + 1}</TableCell>
                        <TableCell
                          sx={{
                            minWidth: '250px',
                          }}
                        >
                          <TextField
                            fullWidth
                            value={item?.name || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = { ...newItems[index], name: e.target.value };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '120px',
                          }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            value={item.unitPrice || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                unitPrice: Number(e.target.value),
                                total: totalCalculator(item.quantity, Number(e.target.value), item.tax),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell sx={{ width: '120px' }}>
                          <TextField
                            fullWidth
                            type="number"
                            value={item.quantity || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                quantity: Number(e.target.value),
                                total: totalCalculator(Number(e.target.value), item.unitPrice, item.tax),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>{item.quantity * item.unitPrice}</Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={5} alignItems="center">
                            <Autocomplete
                              sx={{
                                minWidth: '150px',
                                maxWidth: '200px',
                              }}
                              fullWidth
                              id="asynchronous-demo"
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => `${option.name} `}
                              getOptionSelected={(option, value) => option.name === value.name}
                              options={taxes}
                              defaultValue={{ name: item.taxName }}
                              onChange={(_, value) => {
                                if (value) {
                                  const newItems = [...values.items];
                                  newItems[index] = {
                                    ...newItems[index],
                                    tax: value.percentage,
                                    taxId: value._id,
                                    total: totalCalculator(item.quantity, item.unitPrice, value.percentage),
                                  };
                                  formik.setFieldValue('items', newItems);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tax"
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.name}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                      {option.percentage} %
                                    </Typography>
                                  </Stack>
                                </li>
                              )}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '120px',
                          }}
                        >
                          <Typography>
                            {item.unitPrice &&
                              item.tax &&
                              item.quantity &&
                              taxCalculator(Number(item.unitPrice) * Number(item.quantity), item.tax)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              const newItems = [...values.items];
                              newItems.splice(index, 1);
                              formik.setFieldValue('items', newItems);
                            }}
                            variant="outlined"
                            color="error"
                          >
                            <DeleteForever />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    const newItems = [...values.items];
                    newItems.push({
                      name: '',
                      unitPrice: 0,
                      tax: 0,
                      quantity: 0,
                      total: 0,
                    });
                    formik.setFieldValue('items', newItems);
                  }}
                >
                  Add Item
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
          <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Sub Total(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.subTotal && parseFloat(values.subTotal).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Total VAT(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.totalTax && parseFloat(values.totalTax).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.grandTotal && parseFloat(values.grandTotal).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Grand Total In Words</Typography>
          <TextField
            fullWidth
            value={values.grandTotalInWords}
            onChange={(e) => formik.setFieldValue('grandTotalInWords', e.target.value)}
          />
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Payment Terms
          </Typography>
          {values?.paymentTerms?.map((term, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${term}`}</Typography>
              <IconButton
                onClick={() => {
                  const newPaymentTerms = [...values.paymentTerms];
                  newPaymentTerms.splice(index, 1);
                  formik.setFieldValue('paymentTerms', newPaymentTerms);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField
              label="Payment Term"
              fullWidth
              value={paymentTerm}
              onChange={(e) => setPaymentTerm(e.target.value)}
            />
            <IconButton
              onClick={() => {
                formik.setFieldValue('paymentTerms', [...values.paymentTerms, paymentTerm]);
                setPaymentTerm('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Notes
          </Typography>
          {values?.notes?.map((note, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${note}`}</Typography>
              <IconButton
                onClick={() => {
                  const newNotes = [...values.notes];
                  newNotes.splice(index, 1);
                  formik.setFieldValue('notes', newNotes);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField label="Note" fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
            <IconButton
              onClick={() => {
                formik.setFieldValue('notes', [...values.notes, notes]);
                setNotes('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
        <Box p={5} sx={{ textAlign: 'right' }}>
          <Button type="button" variant="contained" onClick={() => resetForm()}>
            Reset
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting} sx={{ marginLeft: 2 }}>
            Submit
          </Button>
        </Box>
      </Form>
    </FormikProvider>
  );
}

export default PurchaseEditForm;
