import * as React from 'react';
import axios from 'axios';
import { Formik, Form, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  Avatar,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';

import avatar from '../../assets/avatar.png';

const AddTask = ({ callApi, projectId }) => {
  const { accType, _id } = useSelector((state) => state.user.userInfo);
  const isAdmin = ['ADMIN', 'SUPER_ADMIN'].includes(accType);
  const isManager = accType === 'PROJECT_MANAGER';
  const [members, setMembers] = React.useState([]);

  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = React.useState([]);
  const [admins, setAdmins] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProjectMembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/project/all`, {
        params: {
          projectId,
        },
      });
      console.log(res.data.data);
      setEmployees(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAdmins = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/all/active/list`);
      console.log(res.data.data);
      setAdmins(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`);
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`);
      console.log(res.data.data);
      setEmployees(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const taskSchema = Yup.object().shape({
    description: Yup.string().required('Required'),
    assignedTo: Yup.string().required('Required'),
    dueDate: Yup.date().required('Required'),
    assignedBy: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      description: '',
      projectId: projectId || '',
      assignedTo: '',
      assignedBy: '',
      dueDate: '',
    },
    validationSchema: taskSchema,
    onSubmit: async (values, actions) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/task`, values);
        actions.setSubmitting(false);
        toast.success(response.data.message);
        handleClose();
        callApi();
      } catch (error) {
        console.log(error);
        actions.setSubmitting(false);
        toast.error(error.response.data.message);
      }
    },
  });

  const { values, handleChange, handleSubmit, errors, touched } = formik;

  React.useEffect(() => {
    if (values.projectId) {
      getProjectMembers();
    } else {
      getMembers();
    }
  }, [values.projectId]);

  React.useEffect(() => {
    getProjects();
    getAdmins();
  }, []);

  React.useEffect(() => {
    const adminArray = admins.map((admin) => ({
      fullname: admin.fullname,
      _id: admin._id,
      role: admin?.accType,
      email: admin.email,
      profilePic: admin.profilePic,
    }));
    const employeeArray = employees.map((employee) => ({
      fullname: employee.fullname,
      _id: employee._id,
      role: employee?.role,
      email: employee.email || '',
      profilePic: employee.profilePic,
    }));

    const merged = [...employeeArray, ...adminArray];
    const sorted = merged.sort((a, b) => a.fullname.localeCompare(b.fullname));
    setMembers(sorted);
  }, [admins, employees]);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Add />}>
            Task
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Task</DialogTitle>
            <DialogContent>
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2} mt={0.5}>
                    <Grid item xs={12}>
                      <TextField
                        multiline
                        required
                        fullWidth
                        id="description"
                        name="description"
                        label="Description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.description && formik.touched.description)}
                        helperText={
                          formik.errors.description && formik.touched.description && formik.errors.description
                        }
                      />
                    </Grid>
                    {!projectId && (
                      <Grid item xs={12}>
                        <Autocomplete
                          id="asynchronous-demo"
                          fullWidth
                          open={open3}
                          onOpen={() => {
                            setOpen3(true);
                          }}
                          onClose={() => {
                            setOpen3(false);
                          }}
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          options={projects}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('projectId', value._id);
                            } else {
                              formik.setFieldValue('projectId', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(formik.touched.projectId && formik.errors.projectId)}
                              helperText={formik.touched.projectId && formik.errors.projectId}
                              label="Project"
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {isAdmin && (
                      <Grid item xs={6}>
                        <Autocomplete
                          fullWidth
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                          getOptionLabel={(option) => option.fullname}
                          getOptionSelected={(option, value) => option.fullname === value.fullname}
                          options={members}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('assignedBy', value._id);
                            } else {
                              formik.setFieldValue('assignedBy', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Assigned By"
                              InputProps={{
                                ...params.InputProps,
                              }}
                              error={Boolean(formik.errors.assignedBy && formik.touched.assignedBy)}
                              helperText={
                                formik.errors.assignedBy && formik.touched.assignedBy && formik.errors.assignedBy
                              }
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                  src={
                                    (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar
                                  }
                                />
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.fullname}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                    {option.email}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                    {option.role?.replaceAll('_', ' ')}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) => option.fullname === value.fullname}
                        options={members}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('assignedTo', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Assigned To"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(formik.errors.assignedTo && formik.touched.assignedTo)}
                            helperText={formik.touched.assignedTo && formik.errors.assignedTo}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                src={
                                  (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar
                                }
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.email}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.role?.replaceAll('_', ' ')}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        id="dueDate"
                        name="dueDate"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Due Date"
                        type="date"
                        value={formik.values.dueDate}
                        onChange={formik.handleChange}
                        error={Boolean(formik.errors.dueDate && formik.touched.dueDate)}
                        helperText={formik.touched.dueDate && formik.errors.dueDate}
                      />
                    </Grid>
                  </Grid>
                  <DialogActions>
                    <Stack mt={1} direction="row" spacing={2} justifyContent="end">
                      <Button onClick={handleClose}>Cancel</Button>
                      <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                        Add
                      </LoadingButton>
                    </Stack>
                  </DialogActions>
                </Form>
              </FormikProvider>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddTask;
