
import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { TextField, Button, Stack, MenuItem, Grid, Card, Collapse, IconButton, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters, vendorId }) => {
  const [open, setOpen] = React.useState(false);
  const [memberRoles, setMemberRoles] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      _id: filterValues._id,
      vendorId: filterValues.vendorId,
      invoiceNumber: filterValues.invoiceNumber,
      isPaymentCompleted: filterValues.isPaymentCompleted,
      txnId: filterValues.txnId,
      phone: filterValues.phone,
      email: filterValues.email,
      startDate: filterValues?.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
      endDate: filterValues?.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const getMemberRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/list`);
      if (!response.data.isError) {
        console.log(response.data.data);
        setMemberRoles(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getMemberRoles();
  }, []);

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="_id" name="_id" label="Bill ID" {...getFieldProps('id')} />
                </Grid>
                {!vendorId && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      fullWidth
                      id="vendorId"
                      name="vendorId"
                      label="Supplier ID"
                      {...getFieldProps('vendorId')}
                    />
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="invoiceNumber"
                    name="invoiceNumber"
                    label="Invoice Number"
                    {...getFieldProps('invoiceNumber')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="txnId"
                    name="Transaction ID"
                    label="Transaction ID"
                    {...getFieldProps('txnId')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    fullWidth
                    id="isPaymentCompleted"
                    name="isPaymentCompleted"
                    label="Payment Status"
                    {...getFieldProps('isPaymentCompleted')}
                  >
                    <MenuItem value="true">Completed</MenuItem>
                    <MenuItem value="false">Pending</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="phone" name="phone" label="Phone" {...getFieldProps('phone')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="email" name="email" label="Email" {...getFieldProps('email')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="startDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('startDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="endDate"
                    name="endDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('endDate')}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          id: filterValues.id,
                          fullname: filterValues.fullname,
                          isBlocked: filterValues.isBlocked,
                          email: filterValues.email,
                          phone: filterValues.phone,
                          startDate: filterValues?.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
                          endDate: filterValues?.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
