import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Add, Cancel, CancelOutlined, Close, DeleteForever } from '@mui/icons-material';
import moment from 'moment';
import styled from '@emotion/styled';
import { convertToWords, formatInvoiceNumber } from '../utils/commonFunctions';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

function BillEditForm({ bill, setEditMode, getBill }) {
  const navigate = useNavigate();
  const [sourcesLoading, setSourcesLoading] = useState(false);
  const [taxes, setTaxes] = useState([]);

  const [customers, setCustomers] = useState([]);
  const [company, setCompany] = useState({});
  const [customer, setCustomer] = useState({});
  const [projects, setProjects] = useState([]);
  const [quotaions, setQuotations] = useState([]);
  const [items, setItems] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [paymentTerm, setPaymentTerm] = useState('');
  const [notes, setNotes] = useState('');
  const [currentGrandTotalInWords, setCurrentGrandTotalInWords] = useState('');

  const convertToParseFloat = (value) => parseFloat(value).toFixed(2);

  const billSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
  });

  const formik = useFormik({
    initialValues: {
      _id: bill._id,
      invoiceDate: moment(bill.invoiceDate).format('YYYY-MM-DD'),
      customerId: bill.customerId,
      subTotal: bill.subTotal,
      grandTotal: bill.grandTotal,
      totalTax: bill.totalTax,
      items: bill.items?.map((item) => ({
        _id: item._id,
        productId: item.productId,
        qtoItemId: item.qtoItemId,
        quantity: item.quantity,
        unitPrice: item.originalUnitPrice,
        progressiveInvoiceValue: item.progressiveInvoiceValue,
        progressiveInvoiceUnitPrice: item.unitPrice,
        tax: item.tax.percentage,
        taxObject: item.tax,
        taxName: item.tax.name,
        total: item.total,
        name: item.name,
        customerId: item.customerId,

        // name: '',
        // unitPrice: 0,
        // progressiveInvoiceValue: 100,
        // progressiveInvoiceUnitPrice: 0,
        // tax: 0,
        // taxId: '',
        // quantity: 0,
        // total: 0,
      })),
      dueDate: moment(bill.dueDate).format('YYYY-MM-DD'),
      projectId: bill.projectId?._id,
      quotationId: bill.quotationId?._id,
      poDate: bill.poDate ? moment(bill.poDate).format('YYYY-MM-DD') : '',
      poNumber: bill.poNumber,
      paymentTerms: bill.paymentTerms,
      notes: bill.notes,
      grandTotalInWords: bill.grandTotalInWords,
    },
    validationSchema: billSchema,
    onSubmit: async () => {
      try {
        //  check if any item has no tax
        const noTaxFound = values.items.find((item) => item.noTaxFound);
        if (noTaxFound) {
          toast.error('Please select a valid tax for all items');
          return;
        }
        if (currentGrandTotalInWords === values.grandTotalInWords) {
          delete values.grandTotalInWords;
        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/billing`, {
          ...values,
          subTotal: convertToParseFloat(values.subTotal),
          grandTotal: convertToParseFloat(values.grandTotal),
          items: values?.items?.map((item) => ({
            ...item,
            unitPrice: item.progressiveInvoiceUnitPrice,
            originalUnitPrice: item.unitPrice,
            progressiveInvoiceValue: item.progressiveInvoiceValue,
          })),
          paymentTerms: values.paymentTerms.length ? values.paymentTerms : [paymentTerm],
          notes: values.notes.length ? values.notes : [notes],
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        getBill();
        setEditMode(false);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (bill) {
      formik.setFieldValue('invoiceDate', moment(bill.invoiceDate).format('YYYY-MM-DD'));
      formik.setFieldValue('dueDate', moment(bill.dueDate).format('YYYY-MM-DD'));
      formik.setFieldValue('projectId', bill.projectId?._id);
      formik.setFieldValue('quotationId', bill.quotationId?._id);
      formik.setFieldValue('poDate', bill.poDate ? moment(bill.poDate).format('YYYY-MM-DD') : '');
      formik.setFieldValue('poNumber', bill.poNumber);
      formik.setFieldValue('paymentTerms', bill.paymentTerms);
      formik.setFieldValue('notes', bill.notes);
      formik.setFieldValue('grandTotalInWords', bill.grandTotalInWords);
      formik.setFieldValue('customerId', bill.customerId);
      formik.setFieldValue('subTotal', bill.subTotal);
      formik.setFieldValue('grandTotal', bill.grandTotal);
      formik.setFieldValue('totalTax', bill.totalTax);
      formik.setFieldValue(
        'items',
        bill.items?.map((item) => ({
          _id: item._id,
          productId: item.productId,
          qtoItemId: item.qtoItemId,
          quantity: item.quantity,
          unitPrice: item.originalUnitPrice,
          progressiveInvoiceValue: item.progressiveInvoiceValue,
          progressiveInvoiceUnitPrice: item.unitPrice,
          tax: item.tax.percentage,
          taxObject: item.tax,
          taxName: item.tax.name,
          total: item.total,
          name: item.name,
          customerId: item.customerId,
        }))
      );
    }
    setCurrentGrandTotalInWords(bill.grandTotalInWords);
  }, [bill]);

  useEffect(() => {
    const checkIfSomeItemsMissingTaxId = values.items.some((item) => !item.taxId);
    if (checkIfSomeItemsMissingTaxId && taxes.length > 0) {
      const newItems = [...values.items];
      newItems.forEach((item) => {
        console.log(item, 'item');
        const tax = taxes.find((tax) => tax.name === item.taxName && tax.percentage === item.taxObject.percentage);
        if (tax) {
          console.log(tax, 'tax');
          item.taxId = tax._id;
        } else {
          item.noTaxFound = true;
        }
      });
    } else {
      const newItems = [...values.items];
      newItems.forEach((item) => {
        item.noTaxFound = false;
      });
    }
  }, [taxes, values.items]);

  const getCompany = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`);
      console.log(res.data.data, 'company');
      setCompany(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/accountSettings`);
      console.log(res.data.data, 'accountSettings');
      setAccountSettings(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaxes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tax/active/all`);
      console.log(res.data.data);
      setTaxes(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotationItems = async (quotationId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/items/list`, {
        params: {
          id: quotationId,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setItems(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (values.quotationId) {
      getQuotationItems(values.quotationId);
    }
  }, [values.quotationId]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    let totalTax = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.quantity) * Number(item.progressiveInvoiceUnitPrice);
      grandTotal += Number(item.total);
      totalTax += Number(item.quantity) * Number(item.progressiveInvoiceUnitPrice) * (Number(item.tax) / 100);
    });
    const grandTotalInWords = convertToWords(grandTotal);
    formik.setFieldValue('grandTotalInWords', grandTotalInWords);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
    formik.setFieldValue('totalTax', totalTax);
  }, [values.items]);

  const totalCalculator = (quantity, price, tax) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    total += (total * Number(tax)) / 100;
    return total.toFixed(2);
  };

  const taxCalculator = (price, tax) => {
    let totalTax = 0;
    totalTax = (price * Number(tax)) / 100;
    return totalTax.toFixed(2);
  };

  useEffect(() => {
    getTaxes();
    getCompany();
    getAccountSettings();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack
          direction={'row'}
          px={5}
          py={3}
          sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
          justifyContent={'space-between'}
        >
          <Stack justifyContent="center" spacing={2} direction={'column'}>
            <Stack direction={'row'} spacing={2}>
              <StyledTextField
                label="Invoice Date"
                size="small"
                type="date"
                {...getFieldProps('invoiceDate')}
                error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                helperText={touched.invoiceDate && errors.invoiceDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyledTextField
                label="Due Date"
                size="small"
                type="date"
                {...getFieldProps('dueDate')}
                error={Boolean(touched.dueDate && errors.dueDate)}
                helperText={touched.dueDate && errors.dueDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={'column'} spacing={1} alignItems={'center'}>
            <Button color="light" onClick={() => setEditMode(false)} size="small" startIcon={<CancelOutlined />}>
              Cancel
            </Button>
          </Stack>
        </Stack>
        <Grid container rowGap={2} mt={5}>
          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                From
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{company.companyName}</Typography>
                <Typography>{company.address}</Typography>
                <Typography>{company.pin}</Typography>
                <Typography>{company.phone}</Typography>
                <Typography>{company.email}</Typography>
                <Typography>{`TRN: ${accountSettings?.trn}`}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                To
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{bill?.to?.name}</Typography>
                <Typography>{bill?.to?.address}</Typography>
                <Typography>{bill?.to?.phone}</Typography>
                <Typography>{bill?.to?.email}</Typography>
                {bill?.to?.trn && <Typography>{`TRN: ${bill?.to?.trn}`}</Typography>}
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                Bank Details
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{`Bank name: ${accountSettings?.bankName}`}</Typography>
                <Typography>{`IBAN: ${accountSettings?.iban}`}</Typography>
                <Typography>{`Bank Account number: ${accountSettings?.bankAccountNumber}`}</Typography>
                <Typography>{`Beneficiary Name: ${accountSettings?.beneficiaryName}`}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack pt={5} px={5}>
              <Stack paddingLeft={4} spacing={2}>
                <TextField
                  type="date"
                  label="PO Date"
                  size="small"
                  {...getFieldProps('poDate')}
                  error={Boolean(touched.poDate && errors.poDate)}
                  helperText={touched.poDate && errors.poDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="PO Number"
                  size="small"
                  {...getFieldProps('poNumber')}
                  error={Boolean(touched.poNumber && errors.poNumber)}
                  helperText={touched.poNumber && errors.poNumber}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            margin={1}
            sx={{
              borderRadius: '5px',
            }}
          >
            <Stack p={5}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No.</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>P.I. Unit Price</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>VAT</TableCell>
                      <TableCell>VAT Amount</TableCell>
                      <TableCell>Progressive Invoice Value(%)</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Stack justifyContent={'start'} sx={{ height: '100%' }}>
                            <Autocomplete
                              sx={{
                                minWidth: '150px',
                              }}
                              id="asynchronous-demo"
                              freeSolo // allow free input
                              onInputChange={(e, value) => {
                                const newItems = [...values.items];
                                newItems[index] = { ...newItems[index], name: value, qtoItemId: '' };
                                formik.setFieldValue('items', newItems);
                              }}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option, value) => option.name === value.name}
                              options={items}
                              defaultValue={{ name: item.name }}
                              onChange={(_, value) => {
                                if (value) {
                                  console.log(value, 'value');
                                  const newItems = [...values.items];
                                  newItems[index] = {
                                    ...newItems[index],
                                    name: value.name,
                                    qtoItemId: value._id || '',
                                    unitPrice: value.unitPrice || 0,
                                    progressiveInvoiceUnitPrice: value.unitPrice || 0,
                                    progressiveInvoiceValue: 100,
                                    quantity: value.quantity || 0,
                                    tax: newItems[index].tax || 0,
                                    taxId: newItems[index].taxId || '',
                                    total: totalCalculator(
                                      value.quantity || 0,
                                      value.unitPrice || 0,
                                      newItems[index].tax || 0
                                    ),
                                  };
                                  formik.setFieldValue('items', newItems);
                                } else {
                                  console.log('no value');
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.name}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                      {option.unitPrice} AED
                                    </Typography>
                                  </Stack>
                                </li>
                              )}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          <TextField
                            disabled={!item.name}
                            fullWidth
                            type="number"
                            value={item.quantity || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                quantity: Number(e.target.value),
                                total: totalCalculator(
                                  Number(e.target.value),
                                  item.progressiveInvoiceUnitPrice || 0,
                                  item.tax
                                ),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '120px',
                          }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            value={item.unitPrice || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                unitPrice: Number(e.target.value),
                                progressiveInvoiceUnitPrice:
                                  (Number(e.target.value) * item.progressiveInvoiceValue) / 100,
                                total: totalCalculator(
                                  item.quantity,
                                  (Number(e.target.value) * item.progressiveInvoiceValue) / 100,
                                  item.tax
                                ),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>{item.progressiveInvoiceUnitPrice}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{item.quantity * item.progressiveInvoiceUnitPrice}</Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={5} alignItems="center">
                            <Autocomplete
                              sx={{
                                minWidth: '110px',
                                maxWidth: '200px',
                              }}
                              fullWidth
                              id="asynchronous-demo"
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => `${option.name}`}
                              getOptionSelected={(option, value) => option.name === value.name}
                              options={taxes}
                              defaultValue={{ name: item.taxName }}
                              onChange={(_, value) => {
                                if (value) {
                                  const newItems = [...values.items];
                                  newItems[index] = {
                                    ...newItems[index],
                                    tax: value.percentage,
                                    taxId: value._id,
                                    total: totalCalculator(
                                      item.quantity,
                                      item.progressiveInvoiceUnitPrice,
                                      value.percentage
                                    ),
                                  };
                                  formik.setFieldValue('items', newItems);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Tax"
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                  error={item.noTaxFound}
                                  helperText={item.noTaxFound && 'Please select a valid tax'}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.name}
                                    </Typography>
                                  </Stack>
                                </li>
                              )}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {item.progressiveInvoiceUnitPrice &&
                              item.unitPrice &&
                              item.tax &&
                              item.quantity &&
                              taxCalculator(Number(item.progressiveInvoiceUnitPrice) * Number(item.quantity), item.tax)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <TextField
                            sx={{
                              width: '100px',
                            }}
                            type="number"
                            value={item.progressiveInvoiceValue || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                progressiveInvoiceValue: Number(e.target.value),
                                progressiveInvoiceUnitPrice: (Number(e.target.value) * item.unitPrice) / 100,
                                total: totalCalculator(
                                  item.quantity,
                                  (Number(e.target.value) * item.unitPrice) / 100,
                                  item.tax
                                ),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              const newItems = [...values.items];
                              newItems.splice(index, 1);
                              formik.setFieldValue('items', newItems);
                            }}
                            variant="outlined"
                            color="error"
                          >
                            <DeleteForever />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    const newItems = [...values.items];
                    newItems.push({
                      name: '',
                      unitPrice: 0,
                      progressiveInvoiceValue: 100,
                      progressiveInvoiceUnitPrice: 0,
                      qtoItemId: '',
                      tax: 0,
                      taxId: '',
                      quantity: 0,
                      total: 0,
                    });
                    formik.setFieldValue('items', newItems);
                  }}
                >
                  Add Item
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
          {/* <Box sx={{ flex: '1' }}></Box> */}
          <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Sub Total(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.subTotal && parseFloat(values.subTotal).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Total VAT(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.totalTax && parseFloat(values.totalTax).toFixed(2)}
              </Typography>
            </Stack>
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.grandTotal && parseFloat(values.grandTotal).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Grand Total In Words</Typography>
          <TextField
            fullWidth
            value={values.grandTotalInWords}
            onChange={(e) => formik.setFieldValue('grandTotalInWords', e.target.value)}
          />
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Payment Terms
          </Typography>
          {values?.paymentTerms?.map((term, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${term}`}</Typography>
              <IconButton
                onClick={() => {
                  const newPaymentTerms = [...values.paymentTerms];
                  newPaymentTerms.splice(index, 1);
                  formik.setFieldValue('paymentTerms', newPaymentTerms);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField
              label="Payment Term"
              fullWidth
              value={paymentTerm}
              onChange={(e) => setPaymentTerm(e.target.value)}
            />
            <IconButton
              onClick={() => {
                formik.setFieldValue('paymentTerms', [...values.paymentTerms, paymentTerm]);
                setPaymentTerm('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Notes
          </Typography>
          {values?.notes?.map((note, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${note}`}</Typography>
              <IconButton
                onClick={() => {
                  const newNotes = [...values.notes];
                  newNotes.splice(index, 1);
                  formik.setFieldValue('notes', newNotes);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField label="Note" fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
            <IconButton
              onClick={() => {
                formik.setFieldValue('notes', [...values.notes, notes]);
                setNotes('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
        <Stack p={5} sx={{ textAlign: 'right' }} justifyContent={'end'} spacing={5} direction={'row'}>
          <Button type="button" variant="contained" onClick={() => resetForm()}>
            Reset
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Submit
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default BillEditForm;
