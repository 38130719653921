import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import * as Yup from 'yup';

import avatar from '../../assets/avatar.png';

const AddRemoveMaterialFromProject = ({ handleSuccess, open, item, mode, material, setOpen }) => {
  const URLpath = mode === 'add' ? 'addToProject' : 'removeFromProject';
  const matches = useMediaQuery('(max-width:600px)');
  const [supervisors, setSupervisors] = useState([]);

  const materialTrasferSchema = Yup.object().shape({
    materialId: Yup.string().required('material is required'),
    projectId: Yup.string().required('Project is required'),
    quantity: Yup.number().required('Quantity is required'),
    date: Yup.date().required('Date is required'),
    description: mode === 'add' ? Yup.string() : Yup.string().required('Description for return is required'),
  });

  const formik = useFormik({ 
    initialValues: {
      materialId: material._id,
      projectId: item?.projectId,
      quantity: '',
      date: moment().format('YYYY-MM-DD'),
      description: '',
      unit: material.unit,
      supervisor: '',
    },
    validationSchema: materialTrasferSchema,
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/materialFlowLog/${URLpath}`, values);
        console.log(res.data);
        if (!res.data.isError) {
          handleSuccess();
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const getSupervisors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'SUPERVISOR',
        },
      });
      console.log(res.data.data);
      setSupervisors(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSupervisors();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{mode === 'add' ? 'Add Material' : 'Return Material'}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}> 
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Material" value={material.name} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Date"
                    type="date"
                    {...getFieldProps('date')}
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Project Name" value={item?.projectName} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth label="Unit" value={material.unit} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    {...getFieldProps('quantity')}
                    error={Boolean(touched.quantity && errors.quantity)}
                    helperText={touched.quantity && errors.quantity}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    onChange={(_, value) => {
                      console.log('Selected Value:', value);
                      formik.setFieldValue('supervisor', value ? value._id : null);
                    }}
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={supervisors}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supervisor"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="primary" type="submit" loading={isSubmitting} variant="contained">
                  {mode === 'add' ? 'Add' : 'Return'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddRemoveMaterialFromProject;
