import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { AppCurrentVisits, BarGraph } from '../sections/@dashboard/app';
import ProjectToolUsage from '../components/ProjectToolUsage';
import WarehouseAnalyticsComponent from './WarehouseAnalyticsComponent';

// ----------------------------------------------------------------------

export default function WarehouseAnalytics() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
      
        <WarehouseAnalyticsComponent />
      </Container>
    </Page>
  );
}
