import { Box, Grid, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import moment from 'moment';
import Iconify from '../Iconify';

function Attachments({ attachments, type }) { 

  function mimeTypeToIcon(mimeType) {
    switch (mimeType) {
      case 'application/pdf':
        return 'vscode-icons:file-type-pdf2';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'vscode-icons:file-type-word';
      case 'image/png' || 'image/jpeg' || 'image/jpg' || 'image/gif' || 'image/svg+xml' || 'image/webp':
        return 'flat-color-icons:picture';
      default:
        return 'solar:document-add-bold-duotone';
    }
  }

  const downloadDoc = async (e, name) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/${type}/download`, {
        params: {
          file: name,
        },
      });
      window.open(res.data.data.url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{
        borderRadius: '6px',
        padding: 3,
        backgroundColor: '#fff',
        width: '100%',
      }}
    >
      <Grid container spacing={2}>
        {attachments?.map((attachment, index) => (
          <Grid item xs={12} lg={4} key={index}>
            <Stack
              padding={2}
              sx={{
                border: '1px solid #B3B3B3',
                borderRadius: '6px',
                cursor: 'pointer',
                height: '100%',
                width: '100%',
              }}
              justifyContent={'center'}
              onClick={(e) => downloadDoc(e, attachment?.fileUrl)}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2} sx={{ width: '100%' }}>
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '40px', height: '40px' }}>
                  <Iconify icon={mimeTypeToIcon(attachment?.mimeType)} width={40} height={40} />
                </Stack>
                <Stack direction={'column'} spacing={1} overflow={'hidden'} sx={{ width: '100%' }}>
                  <Typography>
                    {attachment?.originalName.length > 40
                      ? `${attachment?.originalName.slice(0, 40)}...`
                      : attachment?.originalName}
                  </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ width: '100%' }}
                  >
                    <Typography>{moment(attachment?.createdAt).format('DD/MM/YYYY')}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default Attachments;
