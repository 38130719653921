import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, IconButton } from '@mui/material';
// mock
import { useSelector } from 'react-redux';
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import LogoSmall from '../../components/LogoSmall';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
import StateContext from '../../components/Context/Context';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { dispatch, state } = useContext(StateContext);
  const { profilePic, fullname, accType, role } = useSelector((state) => state.user.userInfo);

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ py: 3, pl: 2, mb: 4, position: 'relative', bgcolor: (theme) => theme.palette.background.paper }}>
        {state ? <Logo sx={{ height: '50px' }} /> : <LogoSmall sx={{ height: '50px' }} />}
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}> */}
      {/* <Link underline="none" component={RouterLink} to="/dashboard/profile">
          <AccountStyle
            sx={{
              ...(!state && { background: 'none', paddingX: 0 }), 
              transition: state ? 'all .5s ease-in-out' : 'all 0s ease-in-out',
            }}
          >
            <Avatar
              src={(profilePic && process.env.REACT_APP_API_URL + profilePic) || account.photoURL}
              alt="photoURL"
            />
            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: 'text.primary',
                  visibility: state ? 'visible' : 'hidden',
                  opacity: state ? 1 : 0,
                  transition: state ? 'all 0s ease-in-out' : 'all 0s ease-in-out',
                }}
              >
                {account?.displayName && account.displayName}
              </Typography>
            </Box> 
          </AccountStyle>
        </Link> */}
      {/* </Box> */}
      <NavSection navConfig={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}

          {/* <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button> */}
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: { lg: !state ? 80 : 240 },
        transition: 'all .3s ease-in-out',
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {
              width: state ? 240 : 80,
              transition: 'all .3s ease-in-out',
              bgcolor: (theme) => theme.palette.macinsIndigo.dark,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: state ? 240 : 80,
              bgcolor: (theme) => theme.palette.macinsIndigo.dark,
              borderRightStyle: 'dashed',
              transition: 'all .3s ease-in-out',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
