// material
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import Projects from '../components/Projects';

export default function ProjectsOfProjectEngineer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const engineerId = searchParams.get('engineerId');

  return (
    <Page title="Engineer Projects">
      <Container maxWidth="xl">
        <PageHeader title="Engineer Projects" />
        <Projects engineerId={engineerId} />
      </Container>
    </Page>
  );
}
