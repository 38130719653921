import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { FilePond, File, registerPlugin } from 'react-filepond';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const ProjectPurchaseUpload = () => {
  const location = useLocation();
  const project = location.state?.project;
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);

  const purchaseSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    purchaseDate: Yup.string().required('Purchase Date is required'),
  });

  const formik = useFormik({
    initialValues: {
      purchaseDate: '',
      title: '',
      remarks: '',
      projectId: project?._id,
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      const data = new FormData();
      files.forEach((item) => {
        data.append('files', item.file);
      });
      data.append('projectId', project?._id);
      data.append('purchaseDate', formik.values.purchaseDate);
      data.append('remarks', formik.values.remarks);
      data.append('title', formik.values.title);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/purchaseUpload/admin`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const attachments = [];
    files.forEach((item) => {
      attachments.push(item.file);
    });
    console.log(attachments);
    formik.setFieldValue('attachments', attachments);
  }, [files]);

  return (
    <Page title="Purchase Upload">
      <Container maxWidth="xl">
      <PageHeader title="Purchase Upload" />
        <Card>
          <Box>
            <Stack direction={'row'} px={5} py={3} sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}>
              <Typography variant="h6" fontWeight={'bold'}>
                {project?.name}
              </Typography>
            </Stack>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container justifyContent={'center'} spacing={2} mt={2} padding={2}>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      label="Title"
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      label="Purchase Date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                      {...getFieldProps('purchaseDate')}
                      error={Boolean(touched.purchaseDate && errors.purchaseDate)}
                      helperText={touched.purchaseDate && errors.purchaseDate}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Remarks"
                      {...getFieldProps('remarks')}
                      error={Boolean(touched.remarks && errors.remarks)}
                      helperText={touched.remarks && errors.remarks}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={10}
                      credits={false}
                      // server="/api"
                      name="files"
                      labelIdle="Drag & Drop images"
                    />
                  </Grid>
                </Grid>
                <Box p={5} sx={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button type="button" variant="contained" sx={{ marginLeft: 2 }} onClick={() => resetForm()}>
                    Reset
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default ProjectPurchaseUpload;
