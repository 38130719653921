import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import MemberRoles from '../components/MemberRoles';
import CompanyAddress from '../components/CompanyAddress';

import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import VendorTypes from '../components/VendorTypes';

export default function GeneralsSettings() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const accessGenralSettings = hasAccessOf('general.settings');

  return (
    <>
      <Page title="Generals Settings">
        {accessGenralSettings && (
          <Container maxWidth="xl">
            <PageHeader title="General Settings" />
            <Card>
              <Box sx={{ width: '100%', marginTop: '20px' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                      <Tab label="Employee Roles" value="1" />
                      <Tab label="Supplier Types" value="2" />
                      <Tab label="Company Details" value="3" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <MemberRoles />
                  </TabPanel>
                  <TabPanel value="2">
                    <VendorTypes />
                  </TabPanel>
                  <TabPanel value="3">
                    <CompanyAddress />
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>
          </Container>
        )}
      </Page>
    </>
  );
}
