import React from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { Grid, Stack, Typography } from '@mui/material';
import LoadingIcon from '../smallComponents/LoadingIcon';
import FileUploadModal from '../popups/FileUploadModal';
import Attachments from './Attachments';

function AttachMentsContainer({ type }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [attachments, setAttachments] = React.useState([]);

  const getAttachments = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/${type}/files`, {
        params: {
          id,
        },
      });
      console.log(res);
      setIsLoading(false);
      if (!res.data.isError) {
        setAttachments(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAttachments();
  }, []);

  return (
    <div>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Grid container spacing={5} rowGap={2}>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" justifyContent="start" mb={2} spacing={2}>
                <Typography variant="h6">
                  {attachments && attachments.length ? 'Attachments' : 'No Attachments'}
                </Typography>
                <FileUploadModal getAttachments={getAttachments} type={type} />
              </Stack>
              {!!attachments?.length && <Attachments attachments={attachments} type={type} />}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default AttachMentsContainer;
