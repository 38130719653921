import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
// material
import TabCard from '../smallComponents/TabCard';
import hasAccessOf from '../../store/hasAccessOf';
import LoadingIcon from '../smallComponents/LoadingIcon';

// components

export default function ProjectDetailsPageTabs({ project }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const isAdmin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const quotationReadAccess = hasAccessOf('project.qto.r');
  const workerReadAccess = hasAccessOf('project.worker.r');
  const materialReadAccess = hasAccessOf('project.material.r');
  const toolReadAccess = hasAccessOf('project.tool.r');
  const accountReadAccess = hasAccessOf('project.account.r');

  const [assessment, setAssessment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (project?.status === 'ASSESSMENT') {
      setAssessment(true);
    }
    if (project._id) {
      setIsLoading(false);
    }
  }, [project]);

  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Grid container spacing={2}>
            {quotationReadAccess && (
              <Grid item xs={12} md={4} lg={2.4}>
                <TabCard
                  title="Quotation"
                  total={12}
                  color="greenish"
                  icon={'bxs:spreadsheet'}
                  navigate={`/dashboard/project/quotation?projectId=${project._id}`}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                title="Files"
                total={12}
                color="success"
                icon={'teenyicons:attachment-solid'}
                navigate={`/dashboard/project/attachment?projectId=${project._id}`}
              />
            </Grid>

            {workerReadAccess && !assessment && (
              <Grid item xs={12} md={4} lg={2.4}>
                <TabCard
                  title="Workers"
                  total={12}
                  color="orangee"
                  icon={'icon-park-solid:worker'}
                  navigate={`/dashboard/project/worker?projectId=${project._id}`}
                />
              </Grid>
            )}

            {toolReadAccess && (
              <Grid item xs={12} md={4} lg={2.4}>
                <TabCard
                  title="Tools"
                  color="macinsIndigo"
                  total={12}
                  icon={'mingcute:tool-fill'}
                  navigate={`/dashboard/project/tool?projectId=${project._id}`}
                />
              </Grid>
            )}

            {materialReadAccess && (
              <Grid item xs={12} md={4} lg={2.4}>
                <TabCard
                  title="Material"
                  total={12}
                  color="purplish"
                  icon={'mingcute:inventory-fill'}
                  navigate={`/dashboard/project/material?projectId=${project._id}`}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                title="Tasks"
                color="navy"
                total={12}
                icon={'grommet-icons:task'}
                navigate={`/dashboard/project/task?projectId=${project._id}`}
              />
            </Grid>

            {!assessment && (
              <Grid item xs={12} md={4} lg={2.4}>
                <TabCard
                  title="Accounts"
                  color="balanced"
                  total={12}
                  icon={'carbon:account'}
                  navigate={`/dashboard/project/account?projectId=${project._id}`}
                />
              </Grid>
            )}

            {/* {!assessment && (
      <Grid item xs={12} md={4} lg={2.4}>
        <TabCard title="Accounts" color="balanced" total={12} icon={'map:accounting'} />
      </Grid>
    )} */}
          </Grid>
        </>
      )}
    </>
  );
}
