// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import ProjectAnalyticsComponent from './ProjectAnalyticsComponent';

// ----------------------------------------------------------------------

export default function ProjectAnalytics() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        
        <ProjectAnalyticsComponent />
      </Container>
    </Page>
  );
}
