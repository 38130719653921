// material
import { Container } from '@mui/material';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import Projects from '../components/Projects';

export default function ProjectsWithApprovedStatus() {
  const approved = "true";
  return (
    <Page title="Projects">
      <Container maxWidth="xl">
        <PageHeader title="Projects" />
        <Projects approved={approved} />
      </Container>
    </Page>
  );
}
