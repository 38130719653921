import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import axios from 'axios';

const Popup= (props) => {
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/task/status-response`, {
        id: props.item._id,
        status: props.status,
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(false, err.response.data.message);
      });
  };

  const open = true;

  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack spacing={2}>
              <Typography>
                Are you sure you want to change the status of the task to{' '}
                {props.status && props.status.replaceAll('_', ' ')} ?
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Popup;
