import React, { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Card, Container, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import axios from 'axios';

import Page from '../components/Page';
import CheckBoxGroup from '../components/smallComponents/CheckBoxGroup';
import PageHeader from '../components/smallComponents/PageHeader';

function AddMemberRole() {
  const navigate = useNavigate();
  const [general, setGeneral] = useState({
    settings: { r: false, w: false, x: false },
    employee: { r: false, w: false, x: false },
    customer: { r: false, w: false, x: false },
    vendor: { r: false, w: false, x: false },
    admin: { r: false, w: false, x: false },
  });

  const [crm, setCrm] = useState({
    settings: { r: false, w: false, x: false },
    lead: { r: false, w: false, x: false },
    manager: { r: false, w: false, x: false },
    teleCaller: { r: false, w: false, x: false },
  });

  const [project, setProject] = useState({
    project: { r: false, w: false, x: false },
    qto: { r: false, w: false, x: false },
    worker: { r: false, w: false, x: false },
    material: { r: false, w: false, x: false },
    tool: { r: false, w: false, x: false },
  });

  const [warehouse, setWarehouse] = useState({
    tool: { r: false, w: false, x: false },
    material: { r: false, w: false, x: false },
    purchase: { r: false, w: false, x: false },
  });

  const memberRoleSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
      isBlocked: 'false',
    },
    validationSchema: memberRoleSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/memberRole`, {
          ...values,
          accessList: {
            general,
            crm,
            project,
            warehouse,
          },
        });
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        resetForm();
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Page title="Add Member Role">
      <Container maxWidth="xl">
        <PageHeader title="Add Member Role" />
        <Card>
          <Box padding={2}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8} md={9} lg={9}>
                    <TextField
                      fullWidth
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <TextField select fullWidth label="Status" {...getFieldProps('isBlocked')}>
                      <MenuItem value={'false'}>Active</MenuItem>
                      <MenuItem value={'true'}>Blocked</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth label="Description" multiline {...getFieldProps('description')} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <CheckBoxGroup name="General" accessList={general} setAccessList={setGeneral} />
                      <CheckBoxGroup name="CRM" accessList={crm} setAccessList={setCrm} />
                      <CheckBoxGroup name="Project" accessList={project} setAccessList={setProject} />
                      <CheckBoxGroup name="Warehouse" accessList={warehouse} setAccessList={setWarehouse} />
                    </Grid>
                  </Grid>
                </Grid>
                <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
                  <Button onClick={() => navigate(-1)}>Cancel</Button>
                  <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                    Add Role
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

export default AddMemberRole;
