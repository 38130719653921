// material
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import Projects from '../components/Projects';

export default function ProjectsOfProjectManager() {
  const [searchParams, setSearchParams] = useSearchParams();
  const managerId = searchParams.get('managerId');

  return (
    <Page title="Manager Projects">
      <Container maxWidth="xl">
        <PageHeader title="Manager Projects" />
        <Projects managerId={managerId} />
      </Container>
    </Page>
  );
}
