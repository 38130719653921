import {
  Box,
  CircularProgress,
  Container,
  Stack,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import EmployeeBasicInfo from '../components/EmployeeBasicInfo';
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';
import LeadManagerDetailPageTabs from '../components/cardTabs/LeadManagerDetailPageTabs';

export default function ViewLeadManager() {
  const { accType } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState({});

  const getEmployee = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member`, {
        params: {
          id,
          role: 'SALES_MANAGER',
        },
      });
      console.log('employee', res);
      setEmployee(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployee();
  }, [id]);

  return (
    <Page title="Sales Manager">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="Sales Manager" />
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Box id="basic-info" marginBottom={1}>
              <EmployeeBasicInfo getEmployee={getEmployee} employee={employee} />
            </Box>
            <LeadManagerDetailPageTabs leadManager={employee} />
          </Stack>
        </Container>
      )}
    </Page>
  );
}
