import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
// material
import TabCard from '../smallComponents/TabCard';

// components

export default function AccountantDetailPageTabs({ accountant }) {

  return (
    <>
      <>
        {/* <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              count={projectCount}
              title="Projects"
              color="greenish"
              icon={'icons8:parallel-tasks'}
              // navigate={`/dashboard/crm/telecaller?managerId=${accountant._id}`}
            />
          </Grid>
        </Grid> */}
      </>
    </>
  );
}
