import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';

const DeleteWorkEntryModal = ({
  getAllWorkEntris,
  open,
  setOpen,
  selectedEntry,
  setSelectedEntry,
  project,
  options,
}) => {
  const matches = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(false);

  const removeEntry = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/workEntry/delete`, {
        id: selectedEntry._id,
      });
      if (!res.data.isError) {
        setOpen(false);
        setSelectedEntry(null);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      getAllWorkEntris(options);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEntry(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>Delete Work Entry</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <Container>
            <Typography>
              {`Are you sure you want to delete this work entry of ${selectedEntry?.workerData?.fullname} for ${moment(
                selectedEntry?.date
              ).format('DD MMM YYYY')}?`}
            </Typography>
          </Container>
          <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
            <Button onClick={handleClose}>No</Button>
            <LoadingButton color="primary" loading={loading} variant="contained" onClick={removeEntry}>
              Yes
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeleteWorkEntryModal;
