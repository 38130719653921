import { ArrowBack, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import Popup1 from '../components/popups/LeadDelete';
import ProjectBasicInfo from '../components/ProjectBasicInfo';
import ProjectDetailsPageTabs from '../components/cardTabs/ProjectDetailsPageTabs';
import PageHeader from '../components/smallComponents/PageHeader';
import SingleProjectAnalytics from '../components/SingleProjectAnalytics';
import hasAccessOf from '../store/hasAccessOf';
import ProjectMemberInfo from '../components/ProjectMemberInfo';

export default function ViewProject() {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({});

  const projectWriteAccess = hasAccessOf('project.project.w');

  const getProject = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id,
        },
      });
      if (!res.data.isError) {
        setProject(res.data.data);
        setIsLoading(false);
      } else {
        toast.error('No project found with access for you.');
        navigate('/dashboard/project/approved');
      }
      console.log('project', res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <Page title="View Project">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title={project?.name} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={7} lg={8}>
              <Box id="basic-info" marginBottom={1}>
                <ProjectBasicInfo project={project} getProject={getProject} projectWriteAccess={projectWriteAccess} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={5} lg={4}>
              <Box id="basic-info" marginBottom={1}>
                <ProjectMemberInfo project={project} getProject={getProject} projectWriteAccess={projectWriteAccess} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box id="basic-info" marginBottom={1}>
                <ProjectDetailsPageTabs project={project} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box id="basic-info" marginBottom={1}>
                <SingleProjectAnalytics project={project} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </Page>
  );
}
