import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll, Edit, ViewList } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Purchase';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import { StyledTableRow } from '../theme/styledComponents';
// import Popup from './popups/PurchaseStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

function PurchaseTable({ status, vendorId }) {
  const navigate = useNavigate();
  const [purchaseData, setPurchaseData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [purchaseCount, setPurchaseCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, isPaymentCompleted: status === 'completed' });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getPurchases(temp);
  };

  const getPurchases = (options) => {
    const temp = { ...options, vendorId };
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/purchase/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setPurchaseCount(res.data.data.maxRecords);
          setPurchaseData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getPurchasesWithoutLoading = (options) => {
    const temp = { ...options, vendorId };
    axios
      .get(`${process.env.REACT_APP_API_URL}/purchase/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setPurchaseCount(res.data.data.maxRecords);
          setPurchaseData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, companyName: e.target.value };
    setOptions(temp);
    setPages(0);
    getPurchasesWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    setName('');
    const temp = { ...options, page: 0 };

    if (filter._id) {
      temp._id = filter._id;
    }
    if (filter.isPaymentCompleted.length) {
      temp.isPaymentCompleted = filter.isPaymentCompleted.trim();
    }
    if (filter.invoiceNumber) {
      temp.invoiceNumber = filter.invoiceNumber;
    }
    if (filter.vendorId) {
      temp.vendorId = filter.vendorId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.txnId) {
      temp.txnId = filter.txnId;
    }
    if (filter.phone) {
      temp.phone = filter.phone;
    }
    if (filter.email) {
      temp.email = filter.email;
    }

    setOptions(temp);
    setPages(0);
    getPurchases(temp);
  };
  useEffect(() => {
    getPurchases(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getPurchases(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <>
              <Filter applyFilters={applyFilters} vendorId={vendorId} filterValues={options} />

              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search Company name..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={() => navigate('/dashboard/accounts/purchase/add-purchase')}
                  >
                    Add Purchase
                  </Button>
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom> Date</TableCellCustom>
                        <TableCellCustom>Bill Number</TableCellCustom>
                        {!vendorId && <TableCellCustom>Supplier</TableCellCustom>}
                        <TableCellCustom>Contact</TableCellCustom>
                        <TableCellCustom>Grand Total</TableCellCustom>
                        {status === 'pending' && <TableCellCustom>Paid </TableCellCustom>}
                        {status === 'pending' && <TableCellCustom>Pending </TableCellCustom>}
                        <TableCellCustom>View</TableCellCustom>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {purchaseData?.map((item, id) => (
                       <StyledTableRow key={id}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{moment(item?.invoiceDate).format('DD-MM-YYYY')}</TableCellCustom>
                          <TableCell style={{ cursor: 'pointer' }}>
                            <Stack direction="column" spacing={0.5}>
                              <CopyToClipboard text={item.billNumber} onCopy={() => toast.info('Bill Number copied')}>
                                <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                  <Typography variant="p">{item.billNumber}</Typography>
                                  <ContentCopy color="primary" />
                                </Stack>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          {!vendorId && (
                            <TableCellCustom>
                              <Stack justifyContent={'center'} spacing={0.5}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={0.5}
                                  justifyContent="flex-start"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => navigate(`/dashboard/general/vendors/view-vendor/${item.vendorId}`)}
                                >
                                  <Typography variant="p">{item?.billFrom}</Typography>
                                </Stack>
                              </Stack>
                            </TableCellCustom>
                          )}
                          <TableCellCustom>
                            <Stack justifyContent={'center'}>
                              <Typography variant="p">{item?.billFromEmail}</Typography>
                              <Typography variant="p">{item?.billFromPhone}</Typography>
                            </Stack>
                          </TableCellCustom>
                          <TableCellCustom>{item.grandTotal}</TableCellCustom>
                          {status === 'pending' && <TableCellCustom>{item.paidAmount}</TableCellCustom>}
                          {status === 'pending' && <TableCellCustom>{item.pendingAmount}</TableCellCustom>}
                          <TableCellCustom>
                            <Box>
                              <Link to={`/dashboard/accounts/purchase/view-purchase/${item.invoiceNumber}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Box>
                          </TableCellCustom>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={purchaseCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </Container>
        </>
      )}
    </>
  );
}

export default PurchaseTable;
