import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

const SharedWithClientModal = ({ getQuote, open, setOpen, quoteId }) => {
  const matches = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(false);

  const finaliseQuote = async () => {
    try {
      setLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/quote/details`, {
        id: quoteId,
        quotationStatus: 'SHARED_WITH_CLIENT',
      });
      if (!res.data.isError) {
        setOpen(false);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
      getQuote();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>Update Quote status</DialogTitle>
      <DialogContent paddingTop="20px">
        <DialogContentText>
          <Typography>Are you sure you want to update the status of this quote to "Shared with Client"?</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small">
          No
        </Button>
        <LoadingButton color="primary" loading={loading} variant="contained" onClick={finaliseQuote} size="small">
          Yes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SharedWithClientModal;
