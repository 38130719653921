import { useState } from 'react';
import { Grid } from '@mui/material';
// material
import TabCard from '../smallComponents/TabCard';
import hasAccessOf from '../../store/hasAccessOf';

// components

export default function TeleCallerDetailPageTabs({ telecaller, managerId }) {
  const leadManagerReadAccess = hasAccessOf('crm.manager.r');
  const leadsReadAccess = hasAccessOf('crm.lead.r');

  return (
    <>
      <Grid container spacing={2}>
        {managerId && leadManagerReadAccess && (
          <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              title="Sales Manager"
              color="greenish"
              icon={'mdi:account-tie'}
              navigate={`/dashboard/general/employees/lead-manager/${managerId}`}
            />
          </Grid>
        )}
        {leadsReadAccess && (
          <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              title="RFQs"
              color="orangee"
              icon={'mdi:leads'}
              navigate={`/dashboard/general/employees/sales-staff/leads?salesStaffId=${telecaller._id}`}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
