import { Box, Card, Container, IconButton, Stack, Tab, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import WorkEntries from '../components/WorkEntries';
import ProjectWorkerSumamry from '../components/ProjectWorkerSummary';
import PageHeader from '../components/smallComponents/PageHeader';
import ProjectItemWorkSummary from '../components/ProjectItemWorkSummary';

function ProjectWorkers() {
  const navigate = useNavigate();
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Project Workers">
      <Container maxWidth="xl">
        <PageHeader title={'Project Workers'} />
        <Card>
          <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Work Log" value="1" />
                  <Tab label="Summary" value="2" />
                  <Tab label="Work hours in items" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <WorkEntries />
              </TabPanel>
              <TabPanel value="2">
                <ProjectWorkerSumamry />
              </TabPanel>
              <TabPanel value="3">
                <ProjectItemWorkSummary />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

export default ProjectWorkers;
