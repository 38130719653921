// component
import { Box } from '@mui/material';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const permission = ['c', 'r', 'w', 'd'];

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    values: ['dashboard.generalSummary', 'dashboard.projectSummary', 'dashboard.leadSummary'],
    paths: [
      'dashboard.generalSummary.c',
      'dashboard.generalSummary.r',
      'dashboard.generalSummary.w',
      'dashboard.generalSummary.d',
      'dashboard.projectSummary.c',
      'dashboard.projectSummary.r',
      'dashboard.projectSummary.w',
      'dashboard.projectSummary.d',
      'dashboard.leadSummary.c',
      'dashboard.leadSummary.r',
      'dashboard.leadSummary.w',
      'dashboard.leadSummary.d',
      'dashboard.crmSummary.c',
      'dashboard.crmSummary.r',
      'dashboard.crmSummary.w',
      'dashboard.crmSummary.d',
      'dashboard.warehouseSummary.c',
      'dashboard.warehouseSummary.r',
      'dashboard.warehouseSummary.w',
      'dashboard.warehouseSummary.d',
      'dashboard.accountSummary.c',
      'dashboard.accountSummary.r',
      'dashboard.accountSummary.w',
      'dashboard.accountSummary.d',
    ],
  },
  {
    title: 'tasks',
    path: '/dashboard/tasks',
    icon: getIcon('fluent:task-list-square-20-filled'),
    paths: ['tasks.tasks.c', 'tasks.tasks.r', 'tasks.tasks.w', 'tasks.tasks.d'],
  },
  {
    title: 'general',
    path: '/dashboard/general',
    icon: getIcon('iconamoon:home-fill'),
    paths: [
      'general.admin.c',
      'general.admin.r',
      'general.admin.w',
      'general.admin.d',
      'general.vendor.c',
      'general.vendor.r',
      'general.vendor.w',
      'general.vendor.d',
      'general.customer.c',
      'general.customer.r',
      'general.customer.w',
      'general.customer.d',
      'general.employee.c',
      'general.employee.r',
      'general.employee.w',
      'general.employee.d',
      'general.settings.c',
      'general.settings.r',
      'general.settings.w',
      'general.settings.d',
    ],

    children: [
      {
        title: 'analytics',
        path: '/dashboard/general/analytics',
        icon: 'eva:pie-chart-2-fill',
        paths: ['dashboard.generalSummary.w'],
      },
      {
        title: 'employees',
        path: '/dashboard/general/employees',
        icon: 'clarity:employee-solid',
        paths: ['general.employee.c', 'general.employee.r', 'general.employee.w', 'general.employee.d'],
      },
      {
        title: 'customers',
        path: '/dashboard/general/customers',
        icon: 'clarity:users-solid',
        paths: ['general.customer.c', 'general.customer.r', 'general.customer.w', 'general.customer.d'],
      },
      {
        title: 'suppliers',
        path: '/dashboard/general/vendors',
        icon: 'clarity:users-solid',
        paths: ['general.vendor.c', 'general.vendor.r', 'general.vendor.w', 'general.vendor.d'],
      },
      {
        title: 'Admins',
        path: '/dashboard/general/admin',
        icon: 'eos-icons:admin',
        paths: ['general.admin.c', 'general.admin.r', 'general.admin.w', 'general.admin.d'],
      },
      {
        title: 'settings',
        path: '/dashboard/general/settings',
        icon: 'solar:settings-bold',
        paths: ['general.settings.c', 'general.settings.r', 'general.settings.w', 'general.settings.d'],
      },
    ],
  },

  {
    title: 'CRM',
    path: '/dashboard/crm',
    icon: getIcon('fa6-solid:handshake'),
    paths: [
      'crm.lead.c',
      'crm.lead.r',
      'crm.lead.w',
      'crm.lead.d',
      'crm.manager.c',
      'crm.manager.r',
      'crm.manager.w',
      'crm.manager.d',
      'crm.teleCaller.c',
      'crm.teleCaller.r',
      'crm.teleCaller.w',
      'crm.teleCaller.d',
      'crm.settings.c',
      'crm.settings.r',
      'crm.settings.w',
      'crm.settings.d',
    ],
    children: [
      {
        title: 'analytics',
        path: '/dashboard/crm/analytics',
        icon: 'eva:pie-chart-2-fill',
        paths: ['dashboard.crmSummary.w', 'dashboard.leadSummary.w'],
      },
      {
        title: 'RFQ',
        path: '/dashboard/crm/lead',
        icon: 'mdi:leads',
        paths: ['crm.lead.r', 'crm.lead.w', 'crm.lead.d'],
      },
      {
        title: 'manager',
        path: '/dashboard/crm/leadmanager',
        icon: 'mdi:account-tie',
        paths: ['crm.manager.r', 'crm.manager.w', 'crm.manager.d'],
      },
      {
        title: 'sales staff',
        path: '/dashboard/crm/telecaller',
        icon: 'ph:phone-call-fill',
        paths: ['crm.teleCaller.r', 'crm.teleCaller.w', 'crm.teleCaller.d'],
      },
      {
        title: 'reports',
        path: '/dashboard/crm/reports',
        icon: 'icon-park-solid:table-report',
        paths: ['crm.lead.r', 'crm.lead.w', 'crm.lead.d'],
      },
      {
        title: 'settings',
        path: '/dashboard/crm/settings',
        icon: 'solar:settings-bold',
        paths: ['crm.settings.r', 'crm.settings.w', 'crm.settings.d'],
      },
    ],
  },
  {
    title: 'warehouse',
    path: '/dashboard/warehouse',
    icon: getIcon('material-symbols-light:warehouse'),
    paths: [
      'warehouse.stock.c',
      'warehouse.stock.r',
      'warehouse.stock.w',
      'warehouse.stock.d',
      'warehouse.material.c',
      'warehouse.material.r',
      'warehouse.material.w',
      'warehouse.material.d',
      'warehouse.tool.c',
      'warehouse.tool.r',
      'warehouse.tool.w',
      'warehouse.tool.d',
    ],
    children: [
      {
        title: 'analytics',
        path: '/dashboard/warehouse/analytics',
        icon: 'eva:pie-chart-2-fill',
        paths: ['dashboard.warehouseSummary.w'],
      },
      {
        title: 'materials',
        path: '/dashboard/warehouse/material',
        icon: 'mingcute:inventory-fill',
        paths: ['warehouse.material.c', 'warehouse.material.r', 'warehouse.material.w', 'warehouse.material.d'],
      },
      {
        title: 'tools',
        path: '/dashboard/warehouse/tool',
        icon: 'mingcute:tool-fill',
        paths: ['warehouse.tool.c', 'warehouse.tool.r', 'warehouse.tool.w', 'warehouse.tool.d'],
      },
      {
        title: 'stock',
        path: '/dashboard/warehouse/stock?tab=1',
        icon: 'fa6-solid:boxes-stacked',
        paths: ['warehouse.stock.c', 'warehouse.stock.r', 'warehouse.stock.w', 'warehouse.stock.d'],
      },
    ],
  },
  {
    title: 'projects',
    path: '/dashboard/project',
    icon: getIcon('fluent:tasks-app-20-filled'),
    paths: [
      'project.qto.c',
      'project.qto.r',
      'project.qto.w',
      'project.qto.d',
      'project.boq.c',
      'project.boq.r',
      'project.boq.w',
      'project.boq.d',
      'project.quotation.c',
      'project.quotation.r',
      'project.quotation.w',
      'project.quotation.d',
      'project.worker.c',
      'project.worker.r',
      'project.worker.w',
      'project.worker.d',
      'project.material.c',
      'project.material.r',
      'project.material.w',
      'project.material.d',
      'project.tool.c',
      'project.tool.r',
      'project.tool.w',
      'project.tool.d',
      'project.settings.c',
      'project.settings.r',
      'project.settings.w',
      'project.settings.d',
      'project.project.c',
      'project.project.r',
      'project.project.w',
      'project.project.d',
    ],
    children: [
      {
        title: 'analytics',
        path: '/dashboard/project/analytics',
        icon: 'eva:pie-chart-2-fill',
        paths: ['dashboard.projectSummary.w'],
      },
      {
        title: 'Assessment',
        path: '/dashboard/project/assessment',
        icon: 'icon-park-solid:read-book',
        paths: ['project.project.c', 'project.project.r', 'project.project.w', 'project.project.d'],
      },
      {
        title: 'Approved',
        path: '/dashboard/project/approved',
        icon: 'f7:checkmark-seal-fill',
        paths: ['project.project.c', 'project.project.r', 'project.project.w', 'project.project.d'],
      },
      {
        title: 'Notifications',
        path: '/dashboard/project/notifications',
        icon: 'tdesign:notification-filled',
        paths: ['project.project.c', 'project.project.r', 'project.project.w', 'project.project.d'],
      },
      {
        title: 'settings',
        path: '/dashboard/project/settings',
        icon: 'solar:settings-bold',
        paths: ['project.project.c', 'project.project.r', 'project.project.w', 'project.project.d'],
      },
    ],
  },
  {
    title: 'purchase order',
    path: '/dashboard/purchaseOrder',
    icon: getIcon('bxs:cart-alt'),
    paths: ['purchaseOrder.purchaseOrder.c', 'purchaseOrder.purchaseOrder.r', 'purchaseOrder.purchaseOrder.w'],
  },
  {
    title: 'accounts',
    path: '/dashboard/accounts',
    icon: getIcon('ic:sharp-account-balance-wallet'),
    paths: [
      'account.ledger.c',
      'account.ledger.r',
      'account.ledger.w',
      'account.ledger.d',
      'account.bill.c',
      'account.bill.r',
      'account.bill.w',
      'account.bill.d',
      'account.purchase.c',
      'account.purchase.r',
      'account.purchase.w',
      'account.purchase.d',
      'account.journal.c',
      'account.journal.r',
      'account.journal.w',
      'account.journal.d',
      'account.payment.c',
      'account.payment.r',
      'account.payment.w',
      'account.payment.d',
      'account.transaction.c',
      'account.transaction.r',
      'account.transaction.w',
      'account.transaction.d',
      'account.report.c',
      'account.report.r',
      'account.report.w',
      'account.report.d',
      'account.settings.c',
      'account.settings.r',
      'account.settings.w',
      'account.settings.d',
    ],
    children: [
      {
        title: 'analytics',
        path: '/dashboard/accounts/analytics',
        icon: 'eva:pie-chart-2-fill',
        paths: ['dashboard.accountSummary.w'],
      },
      {
        title: 'ledgers',
        path: '/dashboard/accounts/ledger',
        icon: 'gridicons:book',
        paths: ['account.ledger.c', 'account.ledger.r', 'account.ledger.w', 'account.ledger.d'],
      },
      {
        title: 'Invoice',
        path: '/dashboard/accounts/bill',
        icon: 'mdi:invoice-list',
        paths: ['account.bill.c', 'account.bill.r', 'account.bill.w', 'account.bill.d'],
      },
      {
        title: 'purchase',
        path: '/dashboard/accounts/purchase',
        icon: 'bxs:cart-alt',
        paths: ['account.purchase.c', 'account.purchase.r', 'account.purchase.w', 'account.purchase.d'],
      },
      // {
      //   title: 'salary',
      //   path: '/dashboard/accounts/salary',
      //   icon: 'carbon:money',
      //   paths: ['project.project.c', 'project.project.r', 'project.project.w', 'project.project.d'],
      // },
      {
        title: 'journal',
        path: '/dashboard/accounts/transaction',
        icon: 'mdi:journal',
        paths: ['account.journal.c', 'account.journal.r', 'account.journal.w', 'account.journal.d'],
      },
      {
        title: 'pending payments',
        path: '/dashboard/accounts/pending-payments',
        icon: 'teenyicons:money-solid',
        paths: ['account.payment.c', 'account.payment.r', 'account.payment.w', 'account.payment.d'],
      },
      {
        title: 'transactions',
        path: '/dashboard/accounts/payments',
        icon: 'grommet-icons:transaction',
        paths: ['account.transaction.c', 'account.transaction.r', 'account.transaction.w', 'account.transaction.d'],
      },
      {
        title: 'reports',
        path: '/dashboard/accounts/reports',
        icon: 'icon-park-solid:table-report',
        paths: ['account.report.c', 'account.report.r', 'account.report.w', 'account.report.d'],
      },
      {
        title: 'settings',
        path: '/dashboard/accounts/settings',
        icon: 'solar:settings-bold',
        paths: ['account.settings.c', 'account.settings.r', 'account.settings.w', 'account.settings.d'],
      },
    ],
  },
];

export default navConfig;
