import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';

import { toast } from 'react-toastify';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ArrowBack, ExpandMore } from '@mui/icons-material';
import avatar from '../assets/avatar.png';
import Iconify from './Iconify';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic, edit, onEditClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        position: 'relative',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        {profilePic && <Avatar src={profilePic} alt={data} style={{ height: '40px', width: '40px' }} color="primary" />}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
      {edit && (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: '100%', borderRadius: '5px 0px 0px 5px', position: 'absolute', right: '10px' }}
          background={background}
          padding={1}
        >
          <IconButton onClick={onEditClick}>
            <Iconify icon={'mdi:pencil'} style={{ height: '25px', width: '25px' }} />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}

export default function ViewQtoBasicDetails({ data, getQto }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);

  const formik = useFormik({
    initialValues: {
      referenceNumber: data.referenceNumber,
      id,
      submissionDeadline: moment(data.submissionDeadline).format('YYYY-MM-DD'),
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/qto/details/`, values);
        toast(res.data.message);
        setIsEditing(false);
        getQto();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    formik.setValues({
      id,
      referenceNumber: data.referenceNumber,
    });
  }, [data]);

  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="end" alignItems="center" mb={1} sx={{ width: '100%' }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/dashboard/project/approved/view/${data?.projectId}`)}
          startIcon={<ArrowBack />}
        >
          Go to Project
        </Button>
      </Stack>
      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <DetailItem
            icon={'icons8:parallel-tasks'}
            title={'Project Name'}
            data={data?.projectName}
            sx={{
              height: '100%',
              minHeight: '65px',
              borderRadius: '5px',
            }}
          />
        </Grid>
      </Grid>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Basic Details
          </Typography>
        </AccordionSummary>{' '}
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                title="Customer"
                data={data.customerId?.fullname}
                icon={'mdi:account'}
                profilePic={process.env.REACT_APP_API_URL + data.customerId?.profilePic}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem title="Location" data={data.location} icon={'mdi:map-marker'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem title="Quotation ID" data={data.quotationId} icon={'emojione-monotone:id-button'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem title="QTO Status" data={data.qtoStatus} icon={'mdi:account'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                title="QTO Prepared By"
                data={data.qtoPreparedBy?.fullname}
                icon={'mdi:account'}
                profilePic={process.env.REACT_APP_API_URL + data.qtoPreparedBy?.profilePic}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                title="QTO Approved By"
                data={data.qtoApprovedBy?.fullname}
                icon={'mdi:account'}
                profilePic={process.env.REACT_APP_API_URL + data.qtoApprovedBy?.profilePic}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem title="BOQ Status" data={data.boqStatus} icon={'mingcute:question-fill'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem title="Quotation Status" data={data.quotationStatus} icon={'mingcute:question-fill'} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem title="Version" data={data.version} icon={'tabler:versions-filled'} />
            </Grid>
            {!isEditing ? (
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem
                  title="Reference Number"
                  data={data.referenceNumber}
                  icon={'f7:number-square-fill'}
                  edit="edit"
                  onEditClick={() => setIsEditing(true)}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <TextField
                          type="text"
                          label="Reference Number"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: '100%' }}
                          {...getFieldProps('referenceNumber')}
                        />
                        <Button variant="outlined" onClick={() => setIsEditing(false)} size="small">
                          Cancel
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} size="small">
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Grid>
              </>
            )}
            {!isEditing1 ? (
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem
                  title="Submission Deadline"
                  data={data?.submissionDeadline ? moment(data.submissionDeadline).format('YYYY-MM-DD') : 'N/A'}
                  icon={'mdi:calendar'}
                  edit="edit"
                  onEditClick={() => setIsEditing1(true)}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <TextField
                          type="date"
                          label="Submission Deadline"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: '100%' }}
                          {...getFieldProps('submissionDeadline')}
                        />
                        <Button variant="outlined" onClick={() => setIsEditing1(false)} size="small">
                          Cancel
                        </Button>
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting} size="small">
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
