import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import avatar from '../../assets/avatar.png';

const AddQto = (props) => {
  const { getQtos, open, setOpen, project } = props;

  const [engineers, setEngineers] = useState([]);
  const matches = useMediaQuery('(max-width:600px)');

  const quotationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project Name is required'),
    referenceNumber: Yup.string().optional(),
    // qtoPreparedBy: Yup.string().required('QTO Prepared By is required'),
  });

  const formik = useFormik({
    initialValues: {
      projectName: '',
      projectId: project?._id,
      referenceNumber: '',
      // qtoPreparedBy: '',
    },
    validationSchema: quotationSchema,
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation`, values);
        console.log(res.data);
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getQtos();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (project && project?.name) {
      formik.setFieldValue('projectName', project.name);
      formik.setFieldValue('projectId', project._id);
      setEngineers(project?.engineers);
    }
    console.log(project?.engineers);

  }, [project]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{'Add QTO'}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    disabled
                    label="Project Name"
                    {...getFieldProps('projectName')}
                    error={Boolean(touched.projectName && errors.projectName)}
                    helperText={touched.projectName && errors.projectName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Reference Number"
                    {...getFieldProps('referenceNumber')}
                    error={Boolean(touched.referenceNumber && errors.referenceNumber)}
                    helperText={touched.referenceNumber && errors.referenceNumber}
                  />
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="primary" type="submit" loading={isSubmitting}>
                  {'Submit'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddQto;
