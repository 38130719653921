import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FilePond, File, registerPlugin } from 'react-filepond';
import moment from 'moment';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import Iconify from './Iconify';
import hasAccessOf from '../store/hasAccessOf';

function ProjectAttachmentsComp() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const projectWriteAccess = hasAccessOf('project.project.w');

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [project, setProject] = useState({});

  function mimeTypeToIcon(mimeType) {
    switch (mimeType) {
      case 'application/pdf':
        return 'vscode-icons:file-type-pdf2';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'vscode-icons:file-type-word';
      case 'image/png' || 'image/jpeg' || 'image/jpg' || 'image/gif' || 'image/svg+xml' || 'image/webp':
        return 'flat-color-icons:picture';
      default:
        return 'solar:document-add-bold-duotone';
    }
  }

  const downloadDoc = async (e, name) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead/file/download`, {
        params: {
          file: name,
        },
      });
      window.open(res.data.data.url);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addFile = async () => {
    try {
      if (!files[0]) {
        toast.error('Please select a file');
        return;
      }
      const formData = new FormData();
      formData.append('file', files[0].file);
      formData.append('projectId', projectId);
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/project/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast(res.data.message);
      setFiles([]);
      getAttachments();
      setOpen(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const getProject = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProject(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAttachments = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/file`, {
        params: {
          id: projectId,
        },
      });
      if (!res.data.isError) {
        console.log(res.data.data);
        setAttachments(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAttachments();
    getProject();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/*  project name */}
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 1 }}>Project: {project?.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 1 }}>Attachments</Typography>
          {projectWriteAccess && (
            <Button variant="contained" onClick={() => setOpen(true)} startIcon={<Add />}>
              Attachments
            </Button>
          )}
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
              <Box>
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={false}
                  credits={false}
                  // server="/api"
                  name="file"
                  labelIdle="Drag & Drop File"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" sx={{ mt: 1, mr: 1 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ mt: 1 }} onClick={addFile}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </Grid>
      {attachments.length === 0 && (
        <Grid item xs={12}>
          <Typography textAlign={'center'}>No Attachments</Typography>
        </Grid>
      )}
      {attachments?.map((attachment, index) => (
        <Grid item xs={12} key={index}>
          <Stack
            padding={2}
            sx={{
              border: '1px solid #B3B3B3',
              borderRadius: '6px',
              cursor: 'pointer',
              height: '100%',
              width: '100%',
            }}
            justifyContent={'center'}
            onClick={(e) => downloadDoc(e, attachment?.fileUrl)}
          >
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              alignItems={{
                xs: 'flex-start',
                sm: 'center',
              }}
              justifyContent={{
                xs: 'flex-start',
                sm: 'space-between',
              }}
              spacing={2}
              sx={{ width: '100%' }}
            >
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <Stack alignItems={'center'} justifyContent={'center'} sx={{ width: '40px', height: '40px' }}>
                  <Iconify icon={mimeTypeToIcon(attachment?.mimeType)} width={40} height={40} />
                </Stack>
                <Stack direction={'column'} spacing={1} overflow={'hidden'}>
                  <Typography
                    sx={{
                      color: '#0B132B',
                      fontFamily: 'Sora',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      letterSpacing: '-0.11px',
                    }}
                  >
                    {attachment?.originalName.length > 40
                      ? `${attachment?.originalName.slice(0, 40)}...`
                      : attachment?.originalName}
                  </Typography>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ width: '100%' }}
                  >
                    <Typography
                      sx={{
                        color: '#4F4F4F',
                        fontFamily: 'Sora',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal',
                        letterSpacing: '-0.11px',
                      }}
                    >
                      {moment(attachment?.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar src={attachment?.userId?.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'} />
                <Stack direction="column" alignItems="flex-start">
                  <Typography
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {attachment?.userId?.fullname || 'ADMIN'}
                  </Typography>
                  {attachment?.userId?.role && (
                    <Chip label={attachment?.userId?.role.replaceAll('_', ' ')} size="small" />
                  )}
                  {attachment?.userId?.accType && (
                    <Chip label={attachment?.userId?.accType.replaceAll('_', ' ')} size="small" />
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Grid> 
      ))}
    </Grid>
  );
}

export default ProjectAttachmentsComp;
 