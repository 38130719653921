import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Add } from '@mui/icons-material';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';

const AddCustomer = ({ lead, callApi }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkPhone = async (phone) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/phone/available`, {
        params: {
          phone,
        },
      });
      console.log(response.data.data, 'phone available');
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const customerSchema = Yup.object().shape({
    fullname: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address'),
  });

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Add />}>
            Customer
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{'Add Customer'}</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>{'Add details of the customer'}.</DialogContentText>
              <Formik
                initialValues={{
                  fullname: lead?.fullname || '',
                  email: lead?.email || '',
                  phone: lead?.phone || '',
                  address: lead?.address || '',
                  leadId: lead?._id || '',
                  companyName: lead?.companyName || '',
                  businessType: lead?.businessType || '',
                  notes: '',
                  contactPerson: '',
                  contactPersonPhone: '',
                  trn: '',
                }}
                validationSchema={customerSchema}
                onSubmit={async (values, actions) => {
                  const phoneAvailable = await checkPhone(values.phone);
                  if (!phoneAvailable && values.phone) {
                    actions.setFieldError('phone', 'Phone number is not available');
                    actions.setSubmitting(false);
                    return;
                  }
                  try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/customer`, values);
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="fullname"
                          name="fullname"
                          label="Name"
                          value={formik.values.fullname}
                          onChange={formik.handleChange}
                          error={Boolean(formik.errors.fullname)}
                          helperText={formik.errors.fullname}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="email"
                          name="email"
                          label="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="phone"
                          name="phone"
                          label="Phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          error={Boolean(formik.errors.phone)}
                          helperText={formik.errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="address"
                          name="address"
                          multiline
                          label="Address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="companyName"
                          name="companyName"
                          label="Company Name"
                          value={formik.values.companyName}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="businessType"
                          name="businessType"
                          label="Business Type"
                          value={formik.values.businessType}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="contactPerson"
                          name="contactPerson"
                          label="Contact Person"
                          value={formik.values.contactPerson}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="contactPersonPhone"
                          name="contactPersonPhone"
                          label="Contact Person Phone"
                          value={formik.values.contactPersonPhone}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="trn"
                          name="trn"
                          label="TRN"
                          value={formik.values.trn}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="notes"
                          name="notes"
                          multiline
                          label="Notes"
                          value={formik.values.notes}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                        Submit
                      </LoadingButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddCustomer;
