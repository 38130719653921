import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

function ApproveRejectBoq({ boq, getBoq }) {
  const [isLoading, setIsLoading] = React.useState(false);
  console.log(boq)

  const updateStatus = async (status) => {
    setIsLoading(true);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/boq/details`, {
        id: boq._id,
        boqStatus: status,
      });
      console.log(res);
      if (res.data.isError === false) {
        toast.success(res.data.message);
        getBoq();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} sx={{ padding: '10px' }}>
      <LoadingButton
        variant="contained"
        startIcon={<ThumbUp />}
        onClick={() => updateStatus('APPROVED')}
        loading={isLoading}
      >
        Approve
      </LoadingButton>
      <LoadingButton
        variant="contained"
        startIcon={<ThumbDown />}
        onClick={() => updateStatus('REJECTED')}
        loading={isLoading}
      >
        Reject
      </LoadingButton>
    </Stack>
  );
}

export default ApproveRejectBoq;
