import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppCurrentVisits, BarGraph } from '../sections/@dashboard/app';
import hasAccessOf from '../store/hasAccessOf';
import ProjectToolUsage from '../components/ProjectToolUsage';

// ----------------------------------------------------------------------

export default function AccountAnalyticsComponent() {
  const theme = useTheme();

  const trueValue = true;

  const projectStatusGraphAccess = hasAccessOf('dashboard.projectSummary.r');

  const [vendorPaymentPieData, setVendorPaymentPieData] = useState([]);
  const [isLoadingsetVendorPaymentPieData, setIsLoadingVendorPaymentPieData] = useState(true);

  const [customerPaymentPieData, setCustomerPaymentPieData] = useState([]);
  const [isLoadingCustomerPaymentPieData, setIsLoadingCustomerPaymentPieData] = useState(true);

  const [vatCreditDebitPieData, setVatCreditDebitPieData] = useState([]);
  const [isLoadingVatCreditDebitPieData, setIsLoadingVatCreditDebitPieData] = useState(true);

  const [vendorPurchaseBarData, setVendorPurchaseBarData] = useState({ chartLabels: [], chartData: [] });
  const [vendorPaymentBarData, setVendorPaymentBarData] = useState({ chartLabels: [], chartData: [] });

  const [customerBillBarData, setCustomerBillBarData] = useState({ chartLabels: [], chartData: [] });
  const [customerPaymentBarData, setCustomerPaymentBarData] = useState({ chartLabels: [], chartData: [] });

  const getVendorPaymentPieData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/vendor/payment`);
      console.log(res.data.data);
      setVendorPaymentPieData(res.data.data);
      setIsLoadingVendorPaymentPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerPaymentPieData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/payment`);
      console.log(res.data.data);
      setCustomerPaymentPieData(res.data.data);
      setIsLoadingCustomerPaymentPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVatCreditDebitPieData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/vat/credit-debit`);
      console.log(res.data.data);
      setVatCreditDebitPieData(res.data.data);
      setIsLoadingVatCreditDebitPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorPurchaseBarData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/purchase/invoice-paid-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setVendorPurchaseBarData(res?.data?.data?.graphDataCredit);
      setVendorPaymentBarData(res?.data?.data?.graphDataDebit);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerBillBarData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/bill-paid-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setCustomerBillBarData(res?.data?.data?.graphDataCredit);
      setCustomerPaymentBarData(res?.data?.data?.graphDataDebit);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendorPaymentPieData();
    getCustomerPaymentPieData();
    getVatCreditDebitPieData();
  }, []);

  return (
    <Grid container spacing={3} rowGap={4}>
      <Grid item xs={12}>
        <Typography variant="h4" >
          Account Analytics
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <AppCurrentVisits
          title="Supplier"
          hideStartDate={'hide'}
          chartData={vendorPaymentPieData}
          graphloader={isLoadingsetVendorPaymentPieData}
          getNewData={getVendorPaymentPieData}
          chartColors={['#323a64', '#875c00']}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <AppCurrentVisits
          title="Customer"
          hideStartDate={'hide'}
          chartData={customerPaymentPieData}
          graphloader={isLoadingCustomerPaymentPieData}
          getNewData={getCustomerPaymentPieData}
          chartColors={['#323a64', '#875c00']}
        />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <AppCurrentVisits
          title="VAT Credit/Debit"
          hideStartDate={'hide'}
          centerHide={trueValue}
          chartData={vatCreditDebitPieData}
          graphloader={isLoadingVatCreditDebitPieData}
          getNewData={getVatCreditDebitPieData}
          chartColors={['#323a64', '#875c00']}
        />
      </Grid>

      {/* vendor bar */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Purchase</Typography>
          </Grid>
          <Grid item xs={12}>
            <BarGraph
              xaxisPlot={'date'}
              callAPI={getVendorPurchaseBarData}
              // title="Purchase"
              unit="AED"
              chartLabels={[...vendorPurchaseBarData.chartLabels, ...vendorPaymentBarData.chartLabels]}
              chartData={[
                {
                  name: 'Purchase Amount',
                  type: 'column',
                  fill: 'solid',
                  data: [...vendorPurchaseBarData.chartData],
                  color: theme.palette.navy.main,
                },
                {
                  name: 'Payment Amount',
                  type: 'column',
                  fill: 'solid',
                  data: [...vendorPaymentBarData.chartData],
                  color: theme.palette.coral.main,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* customer bar */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Sales</Typography>
          </Grid>
          <Grid item xs={12}>
            <BarGraph
              xaxisPlot={'date'}
              callAPI={getCustomerBillBarData}
              // title="Sales"
              unit="AED"
              chartLabels={[...customerPaymentBarData.chartLabels, ...customerBillBarData.chartLabels]}
              chartData={[
                {
                  name: 'Invoice Amount',
                  type: 'column',
                  fill: 'solid',
                  data: [...customerPaymentBarData.chartData],
                  color: theme.palette.navy.main,
                },
                {
                  name: 'Payment Amount',
                  type: 'column',
                  fill: 'solid',
                  data: [...customerBillBarData.chartData],
                  color: theme.palette.coral.main,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* {projectStatusGraphAccess && (
      <Grid item xs={12} md={12}>
        <BarGraph
          xaxisPlot={'category'}
          callAPI={getManpowerBarGraphData}
          title="Manpower"
          unit="Hrs"
          chartLabels={[...manpowerBarGraphData.chartLabels]}
          chartData={[
            {
              name: 'Manpower',
              type: 'column',
              fill: 'solid',
              data: [...manpowerBarGraphData.chartData],
              color: theme.palette.navy.main,
            },
          ]}
        />
      </Grid>
    )} */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppCurrentVisits
        title="Current Visits"
        chartData={[
          { label: 'America', value: 4344 },
          { label: 'Asia', value: 5435 },
          { label: 'Europe', value: 1443 },
          { label: 'Africa', value: 4443 },
        ]}
        chartColors={[
          theme.palette.primary.main,
          theme.palette.chart.blue[0],
          theme.palette.chart.violet[0],
          theme.palette.chart.yellow[0],
        ]}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={8}>
      <AppConversionRates
        title="Conversion Rates"
        subheader="(+43%) than last year"
        chartData={[
          { label: 'Italy', value: 400 },
          { label: 'Japan', value: 430 },
          { label: 'China', value: 448 },
          { label: 'Canada', value: 470 },
          { label: 'France', value: 540 },
          { label: 'Germany', value: 580 },
          { label: 'South Korea', value: 690 },
          { label: 'Netherlands', value: 1100 },
          { label: 'United States', value: 1200 },
          { label: 'United Kingdom', value: 1380 },
        ]}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppCurrentSubject
        title="Current Subject"
        chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
        chartData={[
          { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
          { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
          { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
        ]}
        chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={8}>
      <AppNewsUpdate
        title="News Update"
        list={[...Array(5)].map((_, index) => ({
          id: faker.datatype.uuid(),
          title: faker.name.jobTitle(),
          description: faker.name.jobTitle(),
          image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
          postedAt: faker.date.recent(),
        }))}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppOrderTimeline
        title="Order Timeline"
        list={[...Array(5)].map((_, index) => ({
          id: faker.datatype.uuid(),
          title: [
            '1983, orders, $4220',
            '12 Invoices have been paid',
            'Order #37745 from September',
            'New order placed #XF-2356',
            'New order placed #XF-2346',
          ][index],
          type: `order${index + 1}`,
          time: faker.date.past(),
        }))}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppTrafficBySite
        title="Traffic by Site"
        list={[
          {
            name: 'FaceBook',
            value: 323234,
            icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
          },
          {
            name: 'Google',
            value: 341212,
            icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
          },
          {
            name: 'Linkedin',
            value: 411213,
            icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
          },
          {
            name: 'Twitter',
            value: 443232,
            icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
          },
        ]}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={8}>
      <AppTasks
        title="Tasks"
        list={[
          { id: '1', label: 'Create FireStone Logo' },
          { id: '2', label: 'Add SCSS and JS files if required' },
          { id: '3', label: 'Stakeholder Meeting' },
          { id: '4', label: 'Scoping & Estimations' },
          { id: '5', label: 'Sprint Showcase' },
        ]}
      />
    </Grid> */}
    </Grid>
  );
}
