import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  OutlinedInput,
  useMediaQuery,
  Avatar,
  Menu,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  Chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll, Delete, Edit, MoreVert } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from './filters/Vendor';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Popup from './popups/VendorStatusChange';
import EditWorkEntryModal from './popups/EditWorkEntryModal';
import DeleteWorkEntryModal from './popups/DeleteWorkEntryModal';
import hasAccessOf from '../store/hasAccessOf';
import Iconify from './Iconify';
import Export from './filters/WorkEntryExport';
import DetailItem from './smallComponents/DetailItem';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function WorkEntries({ type }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const matches = useMediaQuery('(max-width:600px)');
  const [workerData, setWorkerData] = useState();
  const [totalWorkHrs, setTotalWorkHrs] = useState(0);
  const [pages, setPages] = useState(0);
  const [workerCount, setWorkerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, projectId });
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [project, setProject] = useState({});
  const [workers, setWorkers] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState({});
  const [isLoading1, setIsLoading1] = useState(false);
  const [quotaions, setQuotations] = useState([]);
  const [items, setItems] = useState([]);

  const workerAssignAccss = hasAccessOf('project.worker.c');
  const workEntryEditAccess = hasAccessOf('project.worker.w');
  const workEntryReadAccess = hasAccessOf('project.worker.r');

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const addWorkEntrySchema = Yup.object().shape({
    projectId: Yup.string().required('Project is required'),
    workerIds: Yup.array().required('Worker is required'),
    startDate: Yup.string().required('Start date is required'),
    endDate: Yup.string().required('End date is required'),
  });

  const formik = useFormik({
    initialValues: {
      projectId,
      quotationId: '',
      quotationItemId: '',
      workerIds: '',
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).format('YYYY-MM-DD'),
    },
    validationSchema: addWorkEntrySchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/workEntry`, values);
        if (!res.data.isError) {
          closeAddWorkerModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getAllWorkEntris(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const openAddWorkEntryModal = () => {
    setOpen(true);
  };

  const closeAddWorkerModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getAllWorkEntris(temp);
  };

  const getQuotations = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/list`, {
        params: {
          projectId,
          quotationStatus: 'APPROVED',
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setQuotations(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotationItems = async (quotationId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/items/list`, {
        params: {
          id: quotationId,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setItems(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllWorkEntris = (options) => {
    if (!workEntryReadAccess) return;
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/workEntry/all`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setWorkerCount(res.data.data.maxRecords);
          setWorkerData(res.data.data.records);
          setTotalWorkHrs(res.data.data.totalWorkHrs);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const exportLead = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/workEntry/export`, options);
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const getProject = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProject(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWorkers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/workers`, {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setWorkers(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditMenu = () => {
    setOpenEditModal(true);
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    setOpenDeleteModal(true);
    setAnchorEl(null);
  };

  const handleRowsPerPage = (event) => {
    setSize(event.target.value);
    const temp = { ...options, size: event.target.value, page: 0 };
    setPages(0);
    setOptions(temp);
    getAllWorkEntris(temp);
  };

  const handleGetWorkEntriesByDateRange = () => {
    const temp = { ...options, page: 0, size: 10, startDate, endDate };
    setOptions(temp);
    getAllWorkEntris(temp);
  };

  useEffect(() => {
    getAllWorkEntris(options);
    getProject();
    getWorkers();
    getQuotations();
  }, []);

  useEffect(() => {
    if (values.quotationId) {
      getQuotationItems(values.quotationId);
    }
  }, [values.quotationId]);

  useEffect(() => {
    if (options.quotationId) {
      getQuotationItems(options.quotationId);
    }
  }, [options.quotationId]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Add  Dialog */}
          <Dialog open={open} onClose={closeAddWorkerModal} fullScreen={matches}>
            <DialogTitle>Add Worker</DialogTitle>
            <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
              <DialogContent paddingTop="20px">
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Autocomplete
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                          getOptionLabel={(option) => option.fullname}
                          getOptionSelected={(option, value) => option.fullname === value.fullname}
                          options={workers}
                          onChange={(_, values) => {
                            if (values) {
                              formik.setFieldValue(
                                'workerIds',
                                values.map((value) => value._id)
                              );
                            }
                          }}
                          multiple
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={index}
                                avatar={
                                  <Avatar
                                    src={option.profilePic || 'https://www.w3schools.com/howto/img_avatar2.png'}
                                  />
                                }
                                label={option.fullname}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Workers"
                              size="small"
                              sx={{
                                minWidth: '250px',
                              }}
                              InputProps={{
                                ...params.InputProps,
                              }}
                              error={Boolean(touched.workerIds && errors.workerIds)}
                              helperText={touched.workerIds && errors.workerIds}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 35, height: 35, cursor: 'pointer' }}
                                  src={
                                    (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) ||
                                    'https://www.w3schools.com/howto/img_avatar2.png'
                                  }
                                />
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.fullname}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                    {option.email}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.quotationId === value.quotationId}
                          getOptionLabel={(option) => option.quotationId}
                          getOptionSelected={(option, value) => option.quotationId === value.quotationId}
                          options={quotaions}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('quotationId', value._id);
                            } else {
                              formik.setFieldValue('quotationId', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                width: '180px',
                              }}
                              label="Quotation"
                              size="small"
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.quotationId}
                                </Typography>
                              </Stack>
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {' '}
                        <Autocomplete
                          sx={{
                            minWidth: '150px',
                          }}
                          size="small"
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          getOptionLabel={(option) => option.name}
                          getOptionSelected={(option, value) => option.name === value.name}
                          options={items}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('quotationItemId', value._id);
                            } else {
                              formik.setFieldValue('quotationItemId', '');
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              size="small"
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                              }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.name}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                  {option.unitPrice} AED
                                </Typography>
                              </Stack>
                            </li>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Start Date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          {...getFieldProps('startDate')}
                          error={Boolean(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label="End Date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          type="date"
                          {...getFieldProps('endDate')}
                          error={Boolean(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                        />
                      </Grid>
                    </Grid>

                    <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                      <Button onClick={closeAddWorkerModal}>Cancel</Button>
                      <LoadingButton color="primary" type="submit" loading={isSubmitting} variant="contained">
                        Add
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Box>
          </Dialog>
          {/* Add  dialog end */}
          {/* Edit  Dialog */}
          <EditWorkEntryModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            selectedEntry={selectedEntry}
            getAllWorkEntris={getAllWorkEntris}
            options={options}
            setSelectedEntry={setSelectedEntry}
            project={project?.name}
          />
          {/* Edit  dialog end */}
          {/* Delete  Dialog */}
          <DeleteWorkEntryModal
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            selectedEntry={selectedEntry}
            getAllWorkEntris={getAllWorkEntris}
            options={options}
            setSelectedEntry={setSelectedEntry}
            project={project?.name}
          />
          {/* Delete  dialog end */}
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="end" alignItems={'center'} spacing={2}>
              <Export />
              <LoadingButton
                endIcon={<Iconify icon="ph:export-bold" />}
                variant="outlined"
                color="primary"
                onClick={() => exportLead(options)}
                loading={isLoading1}
                size="small"
              >
                Export
              </LoadingButton>
            </Stack>
            <Grid container spacing={2} marginY={2}>
              <Grid item xs={12} lg={8}>
                <DetailItem
                  icon={'icons8:parallel-tasks'}
                  title={'Project Name'}
                  data={project?.name}
                  sx={{
                    height: '100%',
                    minHeight: '65px',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem
                  icon={'fluent:people-team-28-regular'}
                  title={'Total Work Hours'}
                  data={`${parseFloat(totalWorkHrs).toFixed(2)}`}
                  sx={{
                    height: '100%',
                    minHeight: '65px',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="space-between" py={2}>
              <Stack spacing={2} direction={'row'}>
                <TextField
                  fullWidth
                  size="small"
                  label="Start Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  sx={{
                    maxWidth: '200px',
                  }}
                />
                <TextField
                  fullWidth
                  size="small"
                  label="End Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  sx={{
                    maxWidth: '200px',
                  }}
                />
                {/* select quotationId and set it in options.quotationId */}
                <Autocomplete
                  id="asynchronous-demo"
                  value={quotaions.find((option) => option._id === options.quotationId)}
                  isOptionEqualToValue={(option, value) => option.quotationId === value.quotationId}
                  getOptionLabel={(option) => option.quotationId}
                  getOptionSelected={(option, value) => option.quotationId === value.quotationId}
                  options={quotaions}
                  onChange={(_, value) => {
                    if (value) {
                      setOptions({ ...options, quotationId: value._id });
                    } else {
                      setOptions({ ...options, quotationId: '' });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Quotation"
                      size="small"
                      sx={{
                        minWidth: '150px',
                      }}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack direction={'column'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                          {option.quotationId}
                        </Typography>
                      </Stack>
                    </li>
                  )}
                />
                {/* select quotationItemId and set it in options.quotationItemId */}
                <Autocomplete
                  sx={{
                    minWidth: '150px',
                  }}
                  size="small"
                  value={items.find((option) => option._id === options.quotationItemId)}
                  id="asynchronous-demo"
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.name === value.name}
                  options={items}
                  onChange={(_, value) => {
                    if (value) {
                      setOptions({ ...options, quotationItemId: value._id });
                    } else {
                      setOptions({ ...options, quotationItemId: '' });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack direction={'column'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                          {option.name}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: '1' }}>
                          {option.unitPrice} AED
                        </Typography>
                      </Stack>
                    </li>
                  )}
                />
                <Button
                  onClick={() => {
                    if (startDate && endDate && startDate > endDate) {
                      toast.error('Start date should be less than end date');
                    } else {
                      handleGetWorkEntriesByDateRange();
                    }
                  }}
                  variant="contained"
                  size="small"
                >
                  Submit
                </Button>
              </Stack> 
              {workerAssignAccss && (
                <Button variant="contained" onClick={openAddWorkEntryModal} startIcon={<AddIcon />} size="small">
                  Assign Worker
                </Button>
              )}
            </Stack>

            <>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Date</TableCellCustom>
                        <TableCellCustom>Item</TableCellCustom>
                        <TableCellCustom>Worker</TableCellCustom>
                        <TableCellCustom>Email </TableCellCustom>
                        <TableCellCustom>Work Hrs</TableCellCustom>
                        {workEntryEditAccess && <TableCellCustom>Action</TableCellCustom>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workerData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{moment(item?.date).format('DD-MM-YYYY')}</TableCellCustom>
                          <TableCellCustom>{item?.quotationData?.itemName}</TableCellCustom>
                          <TableCellCustom>
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Avatar
                                src={
                                  (item?.profilePic && process.env.REACT_APP_API_URL + item.profilePic) ||
                                  'https://www.w3schools.com/howto/img_avatar.png'
                                }
                                sx={{ width: 40, height: 40 }}
                              />
                              <Typography>{item?.workerData?.fullname}</Typography>
                            </Stack>
                          </TableCellCustom>
                          <TableCellCustom>{item?.workerData?.email}</TableCellCustom>
                          <TableCellCustom>{item?.workHours}</TableCellCustom>
                          {workEntryEditAccess && (
                            <TableCellCustom sx={{ width: '100px' }}>
                              <Stack direction="row" spacing={2}>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="basic-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => {
                                    setAnchorEl(e.currentTarget);
                                    setSelectedEntry(item);
                                  }}
                                >
                                  <MoreVert />
                                </IconButton>

                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  open={openMenu}
                                  onClose={handleCloseMenu}
                                  MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                  }}
                                >
                                  <MenuItem onClick={handleEditMenu}>
                                    <ListItemIcon>
                                      <Edit fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                  </MenuItem>
                                  <MenuItem onClick={handleDeleteMenu}>
                                    <ListItemIcon>
                                      <Delete fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" />
                                  </MenuItem>
                                </Menu>
                              </Stack>
                            </TableCellCustom>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  handleRowsPerPage(e);
                }}
                component={'div'}
                count={workerCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </Container>
        </>
      )}
    </>
  );
}
