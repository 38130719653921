import React from 'react';
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import ProjectAccountSummary from '../components/ProjectAccountSummary';
import ProjectBills from '../components/ProjectBills';

function ProjectAccouts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [quotations, setQuotations] = React.useState([]);
  const [quotationId, setQuotationId] = React.useState('All');

  const getQuotations = async (customerId, projectId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/list`, {
        params: {
          projectId,
          quotationStatus: 'APPROVED',
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        //  add a all option to the list
        const allOption = {
          quotationId: 'All',
        };
        const quotationList = res.data.data;
        quotationList.unshift(allOption);
        setQuotations(quotationList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (projectId) {
      getQuotations(null, projectId);
    }
  }, [projectId]);

  return (
    <Page title="Project Accounts">
      <Container maxWidth="xl">
        <PageHeader title="Project Accounts" />
        <ProjectAccountSummary setQuotationId={setQuotationId} quotations={quotations} quotationId={quotationId} />
        <ProjectBills quotationId={quotationId} />
      </Container>
    </Page>
  );
}

export default ProjectAccouts;
