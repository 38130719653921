import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const DeleteModal = ({ open, setOpen, url, navigateTo, message, title }) => {
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:600px)');
  const [loading, setLoading] = useState(false);

  const removeEntry = async () => {
    try {
      setLoading(true);
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/${url}`);
      if (!res.data.isError) {
        setOpen(false);
        navigate(navigateTo);
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{title}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <Container>
            <Typography>{message}</Typography>
          </Container>
          <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
            <Button onClick={handleClose}>No</Button>
            <LoadingButton color="primary" loading={loading} variant="contained" onClick={removeEntry}>
              Yes
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeleteModal;
