import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { BarGraph, AppWidgetSummary } from '../sections/@dashboard/app';
import hasAccessOf from '../store/hasAccessOf';
import { rfqStatusOrder } from '../utils/commonFunctions';
import CrmAnalyticsComponent from './CrmAnalyticsComponent';

// ----------------------------------------------------------------------

export default function CrmAnalytics() {
  const theme = useTheme();
  const [counts, setCounts] = useState({});
  const leadSummaryAccess = hasAccessOf('dashboard.leadSummary.r');

  const [leadBarGraphData, setLeadBarGraphData] = useState({ chartLabels: [], chartData: [] });
  const [leadBargraphType, setLeadBargraphType] = useState('WEEK');

  const [customerBarGraphData, setCustomerBarGraphData] = useState({ chartLabels: [], chartData: [] });
  const [customerBargraphType, setCustomerBargraphType] = useState('WEEK');

  const [leadCountByStatus, setLeadCountByStatus] = useState([]);
  const leadCountsAccess = hasAccessOf('dashboard.leadSummary.r');

  const getCounts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/summary`);
      setCounts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setLeadBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadCountByStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/count/status`);
      if (!res.data.isError && res?.data?.data?.data?.length > 0) {
        //  sort by status order also add missig status with total 0
        const sortedData = rfqStatusOrder.map((status) => {
          const item = res.data.data.data.find((item) => item.status === status);
          return item || { status, total: 0 };
        });
        setLeadCountByStatus(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setCustomerBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (leadSummaryAccess) {
      getCounts();
    }
    if (leadCountsAccess) {
      getLeadCountByStatus();
    }
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <CrmAnalyticsComponent />
      </Container>
    </Page>
  );
}
