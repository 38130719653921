import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeBasicInfo from '../components/EmployeeBasicInfo';
import Page from '../components/Page';
import AssignManager from '../components/AssignManager';

import PageHeader from '../components/smallComponents/PageHeader';
import TeleCallerDetailPageTabs from '../components/cardTabs/TeleCallerDetailPageTabs';
import UpdateManager from '../components/UpdateManager';

export default function ViewTelecaller() {
  const { accType } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [telecaller, setTelecaller] = useState({});
  const [telecallersManager, setTelecallersManager] = useState({});

  const getEmployee = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/sales-staff`, {
        params: {
          id,
        },
      });
      setTelecaller(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTelecallersManager = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/member/leadManager`, {
        params: {
          id,
        },
      });
      console.log('telecalersManger', res.data.data);
      if (res.data.data.managerExists) {
        setTelecallersManager(res.data.data.manager);
      } else {
        setTelecallersManager(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmployee();
    getTelecallersManager();
  }, [id]);

  return (
    <Page title="Sales Staff">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="Sales Staff" />
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Box id="basic-info" marginBottom={1}>
              <EmployeeBasicInfo getEmployee={getEmployee} employee={telecaller} />
            </Box>
            {!telecallersManager && <AssignManager getTelecallersManager={getTelecallersManager} />}
            {telecallersManager && (
              <UpdateManager getTelecallersManager={getTelecallersManager} manager={telecallersManager?._id} />
            )}
            <TeleCallerDetailPageTabs telecaller={telecaller} managerId={telecallersManager?._id} />
          </Stack>
        </Container>
      )}
    </Page>
  );
}
