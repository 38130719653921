import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Container,
  Stack,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EmployeeBasicInfo from '../components/EmployeeBasicInfo';
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';

export default function ViewWareHouseManager() {
  const { accType } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState({});
  const [employeeRole, setEmployeeRole] = useState([]);
  const [isTelecaller, setIsTelecaller] = useState(false);
  const [isLeadManager, setIsLeadManager] = useState(false);
  const [telecallersManager, setTelecallersManager] = useState({});

  const getEmployeesAccess = hasAccessOf('general.employee.r');

  const getEmployee = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/warehouse-manager`, {
        params: {
          id,
        },
      });
      console.log('employee', res);
      setEmployee(res.data.data);
      setEmployeeRole(res.data.data.role);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTelecallersManager = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/member/leadManager`, {
        params: {
          id,
        },
      });
      console.log('telecalersManger', res.data.data);
      if (res.data.data.managerExists) {
        setTelecallersManager(res.data.data.manager);
      } else {
        setTelecallersManager(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (getEmployeesAccess) {
      getEmployee();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (employeeRole === 'SALES_STAFF' && admin) {
      setIsTelecaller(true);
      getTelecallersManager();
    } else {
      setIsTelecaller(false);
    }
    if (employeeRole === 'SALES_MANAGER') {
      setIsLeadManager(true);
    } else {
      setIsLeadManager(false);
    }
  }, [employeeRole, admin]);

  return (
    <Page title="Warehouse Manager ">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="Warehouse Manager" />
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Box id="basic-info" marginBottom={1}>
              <EmployeeBasicInfo getEmployee={getEmployee} employee={employee} />
            </Box>
          </Stack>
        </Container>
      )}
    </Page>
  );
}
