import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Autocomplete, Box, Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import Page from '../components/Page';
import CheckBoxGroup from '../components/smallComponents/CheckBoxGroup';
import PageHeader from '../components/smallComponents/PageHeader';

function AddEmployee() {
  const navigate = useNavigate();

  const hideForNow = true;

  const [roles, setRoles] = useState([]);

  const [general, setGeneral] = useState({
    settings: { r: false, w: false, x: false },
    employee: { r: false, w: false, x: false },
    customer: { r: false, w: false, x: false },
    vendor: { r: false, w: false, x: false },
    admin: { r: false, w: false, x: false },
  });

  const [crm, setCrm] = useState({
    settings: { r: false, w: false, x: false },
    lead: { r: false, w: false, x: false },
    manager: { r: false, w: false, x: false },
    teleCaller: { r: false, w: false, x: false },
  });

  const [project, setProject] = useState({
    project: { r: false, w: false, x: false },
    qto: { r: false, w: false, x: false },
    worker: { r: false, w: false, x: false },
    material: { r: false, w: false, x: false },
    tool: { r: false, w: false, x: false },
  });

  const [warehouse, setWarehouse] = useState({
    tool: { r: false, w: false, x: false },
    material: { r: false, w: false, x: false },
    purchase: { r: false, w: false, x: false },
  });

  const [generalAvailable, setGeneralAvailable] = useState(false);
  const [crmAvailable, setCrmAvailable] = useState(false);
  const [projectAvailable, setProjectAvailable] = useState(false);
  const [warehouseAvailable, setWarehouseAvailable] = useState(false);

  const employeeSchema = Yup.object().shape({
    fullname: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Email is invalid')
      .when('role', {
        is: (role) => role !== 'WORKER' && role !== 'SUPERVISOR',
        then: Yup.string().required('Email is required'),
      }),
    password: Yup.string().when('role', {
      is: (role) => role !== 'WORKER' && role !== 'SUPERVISOR',
      then: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password must be at most 20 characters'),
    }),
    role: Yup.string().required('Role is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      role: '',
      phone: '',
      description: '',
    },
    validationSchema: employeeSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/member/employee`, {
          ...values,
          accessList: { general, crm, project, warehouse },
        });
        if (!res.data.isError) {
          toast.success(res.data.message);
          navigate('/dashboard/general/employees');
        } else {
          toast.error(res.data.message);
        }
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getRoles = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/list`);
      console.log('res', res);
      if (!res.data.isError) {
        setRoles(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function isAnyValueTrue(obj) {
    let isAnyTrue = false;

    Object.values(obj).forEach((nestedObj) => {
      if (Object.values(nestedObj).some((value) => value === true)) {
        isAnyTrue = true;
      }
    });

    return isAnyTrue;
  }

  const getMemberRole = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole`, {
        params: {
          id,
        },
      });
      if (!res.data.isError) {
        setGeneralAvailable(isAnyValueTrue(res.data.data.accessList.general));
        setCrmAvailable(isAnyValueTrue(res.data.data.accessList.crm));
        setProjectAvailable(isAnyValueTrue(res.data.data.accessList.project));
        setWarehouseAvailable(isAnyValueTrue(res.data.data.accessList.warehouse));
        setGeneral(res.data.data.accessList.general);
        setCrm(res.data.data.accessList.crm);
        setProject(res.data.data.accessList.project);
        setWarehouse(res.data.data.accessList.warehouse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (values.role) {
      const selectedRoleId = roles.find((role) => role.name === values.role)?._id;
      getMemberRole(selectedRoleId);
    }
  }, [values.role]);

  return (
    <Page title="Add Employee">
      <Container maxWidth="xl">
        <PageHeader title="Add Employee" />
        <Card>
          <Box p={2}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      multiple={false}
                      id="tags-standard"
                      options={roles}
                      getOptionLabel={(option) =>
                        option?.name === 'SALES_STAFF' ? 'SALES STAFF' : option?.name?.replaceAll('_', ' ')
                      }
                      onChange={(e, value) => {
                        formik.setFieldValue('role', value.name);
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Role"
                          placeholder="Role"
                          error={Boolean(touched.role && errors.role)}
                          helperText={touched.role && errors.role}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      label="Fullname"
                      {...getFieldProps('fullname')}
                      error={Boolean(touched.fullname && errors.fullname)}
                      helperText={touched.fullname && errors.fullname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      required={values.role !== 'WORKER' && values.role !== 'SUPERVISOR'}
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Phone" {...getFieldProps('phone')} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth label="Description" {...getFieldProps('description')} />
                  </Grid>
                  {values.role && values.role !== 'WORKER' && values.role !== 'SUPERVISOR' && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        required={values.role !== 'WORKER' && values.role !== 'SUPERVISOR'}
                        type="password"
                        label="Password"
                        {...getFieldProps('password')}
                        error={Boolean(
                          touched.password &&
                            values.role &&
                            values.role !== 'WORKER' &&
                            values.role !== 'SUPERVISOR' &&
                            errors.password
                        )}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                  )}
                  {values?.role && !hideForNow && (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {generalAvailable && (
                          <CheckBoxGroup name="General" accessList={general} setAccessList={setGeneral} />
                        )}
                        {crmAvailable && <CheckBoxGroup name="CRM" accessList={crm} setAccessList={setCrm} />}
                        {projectAvailable && (
                          <CheckBoxGroup name="Project" accessList={project} setAccessList={setProject} />
                        )}
                        {warehouseAvailable && (
                          <CheckBoxGroup name="Warehouse" accessList={warehouse} setAccessList={setWarehouse} />
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                  <LoadingButton color="primary" type="submit" loading={isSubmitting} variant="contained">
                    Add
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

export default AddEmployee;
