import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import AddQuotationNote from './popups/AddQuotationNote';

function QuotationNotes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [notes, setNotes] = React.useState([]);

  const getNotes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/notes`, {
        params: {
          id,
        },
      }); 
      if (!res.data.isError) {
        setNotes(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getNotes();
  }, []);

  return (
    <Stack spacing={2}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'start'} spacing={2}>
        <Typography variant={'h6'}>{notes.length > 0 ? 'Notes' : 'No Notes'}</Typography>
        <AddQuotationNote callApi={getNotes} />
      </Stack>
      <Box
        sx={{
          borderRadius: '6px',
          padding: 3,
          backgroundColor: '#fff',
          width: '100%',
        }}
      >
        <Stack spacing={2}>
          {notes.map((note, index) => (
            <Box key={index}>
              <Typography>{note?.note}</Typography>
              <Typography variant={'caption'}>{moment(note?.createdAt).format('DD MMM YYYY')}</Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
}

export default QuotationNotes;
