import { BorderColor, Close } from '@mui/icons-material';
import { Avatar, Box, Button, Chip, IconButton, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function LeadNoteCard({ note, getLead, hideAddNote }) {
  const [edit, setEdit] = useState(false);
  const [editedNote, setEditedNote] = useState(note.note);

  const editNote = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/leadNote`, {
        id: note?._id,
        note: editedNote,
      });
      toast(res.data.message);
      setEdit(false);
      getLead();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
      sx={{
        boxShadow: '0px 1px 2px rgba(60, 64, 67, 0.3), 0px 1px 3px rgba(60, 64, 67, 0.15)',
        borderRadius: '5px',
        bgcolor: 'background.paper',
        padding: 2,
        marginBottom: 2,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Avatar src={note?.noterData?.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'} />
          <Stack direction="column" alignItems="flex-start">
            <Typography variant="subtitle1">{note?.noterData?.fullname || 'ADMIN'}</Typography>
            {note?.noterData?.role && <Chip label={note?.noterData?.role.replaceAll('_', ' ')} size="small" />}
          </Stack>
        </Stack>
        {!hideAddNote && <IconButton onClick={() => setEdit(!edit)}>{edit ? <Close /> : <BorderColor />}</IconButton>}
      </Stack>

      {!edit ? (
        <Typography
          sx={{
            marginTop: 1,
            fontStyle: 'italic',
          }}
        >
          {note?.note}
        </Typography>
      ) : (
        <Stack spacing={2} my={2}>
          <TextField
            multiline
            fullWidth
            placeholder="Add note here"
            variant="outlined"
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
          />
          <Stack direction="row" alignItems="center" justifyContent="end">
            <Button
              variant="contained"
              onClick={editNote}
              sx={{
                float: 'right',
                width: 'fit-content',
              }}
            >
              Update
            </Button>
          </Stack>
        </Stack>
      )}

      <Stack direction="row" alignItems="center" justifyContent="end" sx={{ marginTop: 1 }}>
        <Typography variant="subtitle2">{moment(note?.updatedAt).format('DD MMM YYYY')}</Typography>
      </Stack>
    </Stack>
  );
}
