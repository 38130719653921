import { useState, useEffect } from 'react';
import axios from 'axios';
// material
import {
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Container,
  Typography,
  TextField,
  Grid,
  IconButton,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { Box } from '@mui/system';
import { Add, BorderColor, ContentCopy } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import hasAccessOf from '../store/hasAccessOf';

// components

function AddAccountsDetails(props) {
  const matches = useMediaQuery('(max-width:600px)');
  const formik = useFormik({
    initialValues: {
      bankName: '',
      iban: '',
      bankAccountNumber: '',
      beneficiaryName: '',
      trn: '',
    },
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/utils/accounts`, values);
        if (!res.data.isError) {
          props.closeAddAccountDetailsModal(res.data.message);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        props.getAccountDetails();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Dialog open={props.open} onClose={props.closeAddAccountDetailsModal} fullScreen={matches}>
      <DialogTitle>Bank Account and TRN</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth label="Bank Name" {...getFieldProps('bankName')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline label="IBAN" {...getFieldProps('iban')} />
                </Grid>

                <Grid item xs={6} md={4}>
                  <TextField fullWidth multiline label="Bank Account Number" {...getFieldProps('bankAccountNumber')} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField fullWidth multiline label="Beneficiary Name" {...getFieldProps('beneficiaryName')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth multiline label="TRN" {...getFieldProps('trn')} />
                </Grid>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={props.closeAddAccountDetailsModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

function AccountSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const keyOrder = ['bankName', 'iban', 'bankAccountNumber', 'beneficiaryName', 'trn'];
  const keyLabels = {
    bankName: 'Bank Name',
    iban: 'IBAN',
    bankAccountNumber: 'Bank Account Number',
    beneficiaryName: 'Beneficiary Name',
    trn: 'TRN',
  };

  const [accountDetailsExist, setAccountDetailsExist] = useState(false);

  const companyDetailsWriteAccess = hasAccessOf('general.settings.c');

  const openAddAccountDetailsModal = () => {
    setOpen(true);
  };

  const closeAddAccountDetailsModal = () => {
    setOpen(false);
  };

  const getAccountDetails = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/accountSettings`)
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setAccountDetails(res.data.data);
          if (res.data.data) {
            setAccountDetailsExist(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAccountDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <AddAccountsDetails
            open={open}
            closeAddAccountDetailsModal={closeAddAccountDetailsModal}
            getAccountDetails={getAccountDetails}
            accountDetails={accountDetails}
          />
          <Container maxWidth="xl">
            <Stack marginBottom={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Bank and TRN
                </Typography>
                {companyDetailsWriteAccess && (
                  <IconButton onClick={openAddAccountDetailsModal}>
                    {accountDetailsExist ? <BorderColor /> : <Add />}
                  </IconButton>
                )}
              </Stack>
              <Grid container spacing={2} component={Paper} sx={{ p: 2 }}>
                {keyOrder.map((key) => (
                  <Grid key={key} item xs={12} sm={6}>
                    <Typography variant="body1" fontWeight="bold">
                      {keyLabels[key]}:
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {accountDetails[key]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Container>
        </>
      )}
    </>
  );
}

export default AccountSettings;
