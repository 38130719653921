import axios from 'axios';
import { BorderColor, Close, Delete, Edit } from '@mui/icons-material';
import { Box, Button, CircularProgress, Grid, Icon, IconButton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LeadEditForm from './LeadEditForm';
import LeadToCustomer from './popups/LeadToCustomer';
import hasAccessOf from '../store/hasAccessOf';

export default function LeadBasicInfo({ lead, getLead }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const leadWriteAccess = hasAccessOf('crm.lead.w');

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  const getStatusColor = (statusName) => {
    const statusMapping = {
      Pending: '#fff100',
      Ongoing: '#fff100',
      'Decision Pending': '#fff100',
      Interested: '#16c60c',
      'Not Interested': '#e81224',
    };
    return statusMapping[statusName] || 'white';
  };

  useEffect(() => {
    if (lead && lead?.fullname) setIsLoading(false);
  }, [lead]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box
            sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
            paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
            paddingTop={1}
            paddingBottom={2}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
              {leadWriteAccess &&
                (!lead.customer ? (
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <LeadToCustomer lead={lead} name={'To New Customer'} type={'convert'} callApi={getLead} />
                  </Stack>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => navigate(`/dashboard/general/customers/view/${lead.customer}`)}
                  >
                    View Customer
                  </Button>
                ))}
              <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box
                    sx={{
                      width: 14,
                      height: 24,
                      backgroundColor: getStatusColor(lead?.status?.name),
                      padding: 0,
                      margin: 0,
                    }}
                  />
                  <Typography variant="h5">{lead?.status?.name}</Typography>
                </Stack>
                {leadWriteAccess && (
                  <IconButton onClick={() => setEditMode(!editMode)}>
                    {!editMode ? <BorderColor /> : <Close />}
                  </IconButton>
                )}
              </Stack>
            </Stack>
            {leadWriteAccess && (
              <LeadEditForm lead={lead} getLead={getLead} setEditMode={setEditMode} editMode={editMode} />
            )}
          </Box>
        </>
      )}
    </>
  );
}
