import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
// material
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Autocomplete,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

export default function VendorEditForm({ vendor, getVendor, setEditMode, editMode }) {
  const [vendorTypes, setVendorTypes] = useState([]);
  console.log(vendor, 'vendor');
  const formik = useFormik({
    initialValues: {
      id: vendor?._id,
      constactPerson: vendor?.constactPerson,
      companyName: vendor?.companyName,
      email: vendor?.email,
      phone1: vendor?.phone1,
      phone2: vendor?.phone2,
      companyAddress: vendor?.companyAddress,
      notes: vendor?.notes,
      isBlocked: vendor?.isBlocked,
      vendorTypes: vendor?.vendorTypes?.map((v) => v._id),
      trn: vendor?.trn,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/vendor`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getVendor();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const getVendorType = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendorType/list`);
      if (!res.data.isError) {
        setVendorTypes(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  useEffect(() => {
    getVendorType();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Company Name"
              {...getFieldProps('companyName')}
              error={Boolean(touched.companyName && errors.companyName)}
              helperText={touched.companyName && errors.companyName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              fullWidth
              id="asynchronous-demo"
              value={vendorTypes.filter((s) => values.vendorTypes.includes(s._id))}
              onChange={(_, value) => {
                formik.setFieldValue('vendorTypes', value ? value.map((v) => v._id) : []);
              }}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              options={vendorTypes}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Supplier Types"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  error={Boolean(touched.vendorTypes && errors.vendorTypes)}
                  helperText={touched.vendorTypes && errors.vendorTypes}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Contact Person" {...getFieldProps('contactPerson')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Email" {...getFieldProps('email')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Phone 1" {...getFieldProps('phone1')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="Phone 2" {...getFieldProps('phone2')} />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth multiline label="Company Address" {...getFieldProps('companyAddress')} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth select label="Status" {...getFieldProps('isBlocked')}>
              <MenuItem value={'true'}>Blocked</MenuItem>
              <MenuItem value={'false'}>Active</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField fullWidth label="TRN" {...getFieldProps('trn')} />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="file"
              fullWidth
              label="Profile Pic"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                formik.setFieldValue('image', e.target.files[0]);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth multiline label="Notes" {...getFieldProps('notes')} />
          </Grid>
        </Grid>
        {editMode && (
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}
