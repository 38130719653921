import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
} from '@mui/material';
import { ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
// import Filter from './filters/Vendor';

// components
import Scrollbar from './Scrollbar';
import Export from './filters/WorkEntrySummaryExportOfItem';
import Iconify from './Iconify';
import DetailItem from './smallComponents/DetailItem';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function ProjectItemWorkSummary() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const [itemWorkSummaryData, setItemWorkSummaryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [itemWorkSummaryCount, setItemWorkSummaryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [project, setProject] = useState({});
  const [totalWorkHrs, setTotalWorkHrs] = useState(0);
  const [isLoading1, setIsLoading1] = useState(false);

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getWorkerSumamry(temp);
  };

  const getWorkerSumamry = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/workEntry/summary/quotationItem`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setItemWorkSummaryCount(res.data.data.maxRecords);
          setItemWorkSummaryData(res.data.data.records);
          setTotalWorkHrs(res.data.data.totalWorkHrs);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWorkerSumamryWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/workEntry/summary/quotationItem`, {
        params: {
          ...options,
          projectId, 
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setItemWorkSummaryCount(res.data.data.maxRecords);
          setItemWorkSummaryData(res.data.data.records);
          setTotalWorkHrs(res.data.data.totalWorkHrs);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getProject = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProject(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportWorkEntrySummary = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/workEntry/summary/quotationItem/export`, {
        ...options,
        projectId,
      });
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter._id.length) {
      temp._id = filter._id;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.contactPerson) {
      temp.contactPerson = filter.contactPerson;
    }
    if (filter.companyName) {
      temp.companyName = filter.companyName;
    }
    if (filter.email) {
      temp.email = filter.email;
    }
    if (filter.phone1) {
      temp.phone1 = filter.phone1;
    }
    if (filter.phone2) {
      temp.phone2 = filter.phone2;
    }
    if (filter.companyAddress) {
      temp.companyAddress = filter.companyAddress;
    }

    setOptions(temp);
    setPages(0);
    getWorkerSumamry(temp);
  };
  useEffect(() => {
    getWorkerSumamry(options);
    getProject();
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getWorkerSumamry(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
              <Export />
              <LoadingButton
                endIcon={<Iconify icon="ph:export-bold" />}
                variant="outlined"
                color="primary"
                onClick={() => exportWorkEntrySummary(options)}
                loading={isLoading1}
                size="small"
              >
                Export
              </LoadingButton>
            </Stack>

            <Grid container spacing={2} marginY={2}>
              <Grid item xs={12} lg={8}>
                <DetailItem
                  icon={'icons8:parallel-tasks'}
                  title={'Project Name'}
                  data={project?.name}
                  sx={{
                    height: '100%',
                    minHeight: '65px',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem
                  icon={'fluent:people-team-28-regular'}
                  title={'Total Work Hours'}
                  data={`${parseFloat(totalWorkHrs).toFixed(2)}`}
                  sx={{
                    height: '100%',
                    minHeight: '65px',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
            </Grid>
            <Stack py={2}>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Quotation ID</TableCellCustom>
                        <TableCellCustom> Item</TableCellCustom>
                        <TableCellCustom>Work Hours</TableCellCustom>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemWorkSummaryData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{item?.quotationId}</TableCellCustom>
                          <TableCellCustom>{item?.itemName}</TableCellCustom>
                          <TableCellCustom>{item?.totalWorkHrs}</TableCellCustom>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={itemWorkSummaryCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Stack>
          </Container>
        </>
      )}
    </>
  );
}
