import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material';
import { Add, ArrowBack, ContentCopy, Delete } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import PageHeader from './smallComponents/PageHeader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function RFQsQuotations() {
  const { id } = useParams();
  const [qtoData, setQtoData] = useState();
  const [pages, setPages] = useState(0);
  const [qtoCount, setQtoCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getQtos(temp);
  };

  const getQtos = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation/lead`, {
        params: {
          leadId: id,
          ...options,
        },
      })
      .then((res) => {
        console.log('Payments', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setQtoCount(res.data.data.maxRecords);
          setQtoData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getQtos(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {qtoData && qtoData.length > 0 && (
            <Stack spacing={2}>
              <Typography variant="h4">Quotations</Typography>
              <Card>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Quotation ID</TableCell>
                          <TableCell>Projet Name</TableCell>
                          <TableCell>Quotation Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {qtoData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>
                              <Stack direction="column" spacing={0.5} width="120px">
                                <CopyToClipboard text={item.quotationId} onCopy={() => toast.info('QTO ID copied')}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={0.5}
                                    justifyContent="flex-start"
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <ContentCopy color="primary" />
                                    <Typography variant="p">{item.quotationId}</Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell> 
                            <TableCell>{item.projectData?.name}</TableCell>
                            <TableCell>{item.quotationStatus}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                    const temp = { ...options, size: e.target.value, page: 0 };
                    setPages(0);
                    setOptions(temp);
                    getQtos(temp);
                  }}
                  component={'div'}
                  count={qtoCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Stack>
          )}
        </>
      )}
    </>
  );
}
