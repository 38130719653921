import { useState } from 'react';
// material
import { Card, Container, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import 'react-toastify/dist/ReactToastify.css';

// components
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';
import ToolsTable from '../components/ToolsTable';
import ToolTransaferLogs from '../components/ToolTransferLogs';

export default function Tools() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Tools">
      <Container maxWidth="xl">
        <PageHeader title={'Tools'} />
        <Card>
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Tools" value="1" />
                  <Tab label="In-Out" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <ToolsTable />
              </TabPanel>
              <TabPanel value="2">
                <ToolTransaferLogs type={"all"}/>
              </TabPanel> 
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
