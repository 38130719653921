import { useState } from 'react';
// material
import { Card, Container, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import 'react-toastify/dist/ReactToastify.css';

// components
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';
import MaterialsTable from '../components/MaterialsTable';
import MaterialTransaferLogs from '../components/MaterialTransferLogs';

export default function Materials() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Materials"> 
      <Container maxWidth="xl">
        <PageHeader title={'Materials'} />
        <Card>
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Materials" value="1" />
                  <Tab label="In-Out" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <MaterialsTable />
              </TabPanel>
              <TabPanel value="2">
                <MaterialTransaferLogs type={"all"}/>
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
