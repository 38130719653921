import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { Box } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';

import Scrollbar from './Scrollbar';
import LoadingIcon from './smallComponents/LoadingIcon';

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function MemberTasks({ setIsTasks }) {
  const [taskData, setTaskData] = useState();
  const [pages, setPages] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTasks(temp);
  };

  const getTasks = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/all-of-member`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTaskCount(res.data.data.maxRecords);
          if (res.data.data.maxRecords === 0) {
            setIsTasks(false);
          }
          setTaskData(res.data.data.records);
          setIsLoading(false);
        } 
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTasks(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTasks(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <>
          <LoadingIcon />
        </>
      ) : (
        <Card>
          <Box padding={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} padding={2}>
              <Typography variant="h5">Your Tasks</Typography>
            </Stack>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCellCustom>Sl No</TableCellCustom>
                      <TableCell>Project Name</TableCell>
                      <TableCell>Work Order Number</TableCell>
                      <TableCell>Not Started</TableCell>
                      <TableCell>In Progress</TableCell>
                      <TableCell>Final Stage</TableCell>
                      {/* <TableCell>Completed</TableCell> */}
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {taskData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                        <TableCell sx={{ minWidth: 200 }}>{item.project}</TableCell>
                        <TableCell>{item?.workOrderNumber}</TableCell>
                        <TableCell>{item.notStarted}</TableCell>
                        <TableCell>{item.inProgress}</TableCell>
                        <TableCell>{item.finalStage}</TableCell>
                        <TableCell>
                          <Box>
                            <Link to={item?._id ? `/dashboard/project/task?projectId=${item._id}` : '/dashboard/tasks'}>
                              <Icon sx={{ color: 'gray' }}>
                                <RemoveRedEyeIcon />
                              </Icon>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
              }}
              component={'div'}
              count={taskCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Box>
        </Card>
      )}
    </>
  );
}
