import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Add, AddCircle, CheckCircle, RemoveCircle, Replay, ResetTvRounded, Save } from '@mui/icons-material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';

import Scrollbar from './Scrollbar';
import UpdateMainItemStatus from './popups/UpdateMainItemStatus';
import UpdateMainItemDescription from './popups/UpdateMainItemDescription';

const StyledTableCell = styled(TableCell)(({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    fontWeight: 'bold',
    textAlign: 'center',
    border: '0.5px solid #d49292',
    borderLeft: cellBorderLeft || '0.5px solid #d49292',
    borderRight: cellBorderRight || '0.5px solid #d49292',
    fontSize: 14,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
    border: '0.5px solid #d49292',
    borderLeft: cellBorderLeft || '0.5px solid #d49292',
    borderRight: cellBorderRight || '0.5px solid #d49292',
    fontWeight: fontWeight || 'normal',
    backgroundColor: bgcolor || '#fff',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

const unitArray = ['Nos', 'LS', 'LM', 'Cartn', 'Trips', 'Lth', 'KW', 'SqM', 'CuM', 'RM', 'Rolls', 'Items'];

const getStatusColor1 = (statusName) => {
  const statusMapping = {
    NOT_STARTED: '#ff0000',
    IN_PROGRESS: '#ffff00',
    FINAL_STAGE: '#00b0f0',
    COMPLETED: '#00b050',
  };
  return statusMapping[statusName] || '';
};

function QtoTable({ getQto, item, qto, viewOnly, slNumber }) {
  const { accType } = useSelector((state) => state.user.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [openStatusChangePopup, setOpenStatusChangePopup] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [departmentsLoading, setDepartmenstLoading] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [members, setMembers] = useState([]);

  const formik = useFormik({
    initialValues: {
      items: [],
      departmentId: item?.departmentId || '',
    },
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/quotation/qto`, { ...values, id });
        console.log(res.data);
        toast.success(res.data.message);
        getQto();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getDepartments = async () => {
    try {
      setDepartmenstLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/department/all/active`);
      console.log('department list', res.data.data.status);
      if (!res.data.isError) {
        if (res.data.data.status) {
          setDepartments(res.data.data.status);
          const others = res.data.data.status.filter((item) => item.name === 'Other')[0];
          console.log(others);
        }
      }
      setDepartmenstLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectMembers = async (projectId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/project/all`, {
        params: {
          projectId,
        },
      });
      console.log(res.data.data);
      setMembers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCreatedBy = (itemId, createdBy) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/quotation/qto/item`, {
        itemId,
        id,
        createdBy,
      })
      .then((res) => {
        handleClose();
        toast.success(res.data.message);
        getQto();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleClose = (success, message) => {
    console.log(success, message, 'success, message');
    setOpenStatusChangePopup(false);
    setSelectedIndex();
    setSelectedIndex1();
    if (success === true) {
      toast.success(message);
      getQto();
    } else {
      toast.error(message);
    }
  };

  const handleOpenStatusChangePopup = (status, index) => {
    setSelectedStatus(status);
    setSelectedIndex(index);
    setOpenStatusChangePopup(true);
  };

  const handleOpenDescriptionChangePopup = (index) => {
    setSelectedIndex1(index);
  };

  const createSubItem = (key) => {
    const items = [...values.items];
    const subItem = {
      name: '',
      length: '',
      width: '',
      height: '',
      procurementUnit: 'Nos',
      procurementQuantity: '0',
      procurementUnitPrice: '0',
      procurementTotalPrice: '0',
      workType: '',
      workHour: '0',
      workPricePerHour: '0',
      totalWorkPrice: '0',
      grandTotal: '0',
      cum: '0',
      remarks: '',
    };
    items[key].subItems.push(subItem);
    formik.setFieldValue('items', items);
  };

  const checkIfNumber = (value) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) {
      return 0;
    }
    const newValue = parseFloat(value).toFixed(2);
    return newValue;
  };

  /// //// total of main item ////
  const calculateTotal = (key) => {
    const items = [...values.items];
    let total = 0;
    for (let i = 0; i < items[key].subItems.length; i += 1) {
      total += parseFloat(items[key].subItems[i].grandTotal);
    }
    items[key].total = total;
    items[key].unitPrice = checkIfNumber(total) / parseFloat(items[key].quantity);
    return total;
  };

  const onBlurMainQuantity = (e, key) => {
    const items = [...values.items];
    items[key].quantity = e.target.innerText;
    items[key].unitPrice = checkIfNumber(values.items[key].totalPrice) / parseFloat(items[key].quantity);
    formik.setFieldValue('items', items);
  };

  const onChangeSubItem = (value, key, key1, field) => {
    const items = [...values.items];
    items[key].subItems[key1][field] = value;
    formik.setFieldValue('items', items);
  };

  const calculateCum = () => {
    const items = [...values.items];
    let cum = 0;
    for (let i = 0; i < items.length; i += 1) {
      for (let j = 0; j < items[i].subItems.length; j += 1) {
        cum += parseFloat(items[i].subItems[j].grandTotal);
        items[i].subItems[j].cum = cum;
      }
    }
  };

  /// //// total cum of main item ////
  const calculateTotalCum = () => {
    const items = [...values.items];
    let totalCum = 0;
    for (let i = 0; i < items.length; i += 1) {
      totalCum += parseFloat(items[i].totalPrice);
      items[i].totalCum = totalCum.toFixed(2);
    }
  };

  const calculateTotalInitially = (items, key) => {
    let total = 0;
    for (let i = 0; i < items[key].subItems.length; i += 1) {
      total += parseFloat(items[key].subItems[i].grandTotal);
    }
    return total;
  };

  const calculateCumInitially = (items) => {
    let cum = 0;
    for (let i = 0; i < items.length; i += 1) {
      for (let j = 0; j < items[i].subItems.length; j += 1) {
        cum += parseFloat(items[i].subItems[j].grandTotal);
        items[i].subItems[j].cum = cum;
      }
    }
    return cum;
  };

  const calculateTotalCumInitially = (items) => {
    let totalCum = 0;
    for (let i = 0; i < items.length; i += 1) {
      totalCum += parseFloat(items[i].totalPrice);
      items[i].totalCum = totalCum;
    }
    return totalCum.toFixed(2);
  };

  const setFormValues = (data) => {
    const items = [...data.items];
    for (let i = 0; i < items.length; i += 1) {
      items[i].total = calculateTotalInitially(items, i);
      items[i].totalCum = calculateTotalCumInitially(items);
      for (let j = 0; j < items[i].subItems.length; j += 1) {
        items[i].subItems[j].cum = calculateCumInitially(items);
      }
    }
    formik.setFieldValue('items', items);
    formik.setFieldValue('remarks', data.remarks);
    formik.setFieldValue('termsAndConditions', data.termsAndConditions);
    formik.setFieldValue('exclusions', data.exclusions);
    formik.setFieldValue('clientNotes', data.clientNotes);
  };

  useEffect(() => {
    calculateCum();
    calculateTotalCum();
  }, [values.items]);

  useEffect(() => {
    getDepartments();
  }, []);

  useEffect(() => {
    if (item) {
      setFormValues(item);
    }
  }, [item]);

  useEffect(() => {
    if (qto?.projectId) {
      getProjectMembers(qto.projectId);
    }
  }, [qto]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {!viewOnly && (
          <>
            {!item?.departmentId ? (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  width: '100%',
                }}
              >
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{`${slNumber}.`}</Typography>
                <Autocomplete
                  fullWidth
                  id="asynchronous-demo"
                  open={open3}
                  onOpen={() => {
                    setOpen3(true);
                  }}
                  onClose={() => {
                    setOpen3(false);
                  }}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={departments}
                  loading={departmentsLoading}
                  onChange={(_, value) => {
                    if (value) {
                      formik.setFieldValue('departmentId', value._id);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      required
                      error={Boolean(touched.source && errors.source)}
                      helperText={touched.source && errors.source}
                      label="Department"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {departmentsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Stack>
            ) : (
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                {`${slNumber}. ${item?.departmentName}`}
              </Typography>
            )}
          </>
        )}
        <Scrollbar sx={{ marginTop: '5px', paddingBottom: '10px' }}>
          <TableContainer component={Paper} sx={{ padding: '3px', minWidth: '2300px', marginBottom: '20px' }}>
            <Table
              sx={{
                '& .MuiTableCell-root': {
                  padding: '3px',
                },
                border: '3px solid #000',
              }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell rowSpan={2} sx={{ width: '70px' }}>
                    Sl No.
                  </StyledTableCell>
                  <StyledTableCell rowSpan={2} sx={{ minWidth: '400px' }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell colSpan={3} cellBorderLeft="3px solid #000" cellBorderRight="3px solid #000">
                    Dimensions
                  </StyledTableCell>
                  <StyledTableCell colSpan={2} cellBorderLeft="3px solid #000">
                    Procure
                  </StyledTableCell>
                  <StyledTableCell> Rate</StyledTableCell>
                  <StyledTableCell cellBorderRight="3px solid #000"> Amount</StyledTableCell>
                  <StyledTableCell colSpan={2}>Work Force</StyledTableCell>
                  <StyledTableCell> Rate</StyledTableCell>
                  <StyledTableCell cellBorderRight="3px solid #000"> Amount</StyledTableCell>
                  <StyledTableCell colSpan={2} cellBorderRight="3px solid #000">
                    Total(AED)
                  </StyledTableCell>
                  <StyledTableCell rowSpan={2} sx={{ minWidth: '400px' }}>
                    Notes
                  </StyledTableCell>
                  <StyledTableCell rowSpan={2} sx={{ minWidth: '80px' }}>
                    Status
                  </StyledTableCell>
                  <StyledTableCell rowSpan={2} sx={{ minWidth: '200px' }}>
                    Description
                  </StyledTableCell>
                  <StyledTableCell rowSpan={2} sx={{ minWidth: '200px' }}>
                    Created By
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell cellBorderLeft="3px solid #000"> Length</StyledTableCell>
                  <StyledTableCell> Width</StyledTableCell>
                  <StyledTableCell cellBorderRight="3px solid #000"> Height</StyledTableCell>
                  <StyledTableCell sx={{ minWidth: '90px' }}> Unit</StyledTableCell>
                  <StyledTableCell> Quantity</StyledTableCell>
                  <StyledTableCell> AED/Unit</StyledTableCell>
                  <StyledTableCell cellBorderRight="3px solid #000"> AED</StyledTableCell>
                  <StyledTableCell> Type</StyledTableCell>
                  <StyledTableCell> #Hrs</StyledTableCell>
                  <StyledTableCell> AED/Hr</StyledTableCell>
                  <StyledTableCell cellBorderRight="3px solid #000"> AED</StyledTableCell>
                  <StyledTableCell> LINE</StyledTableCell>
                  <StyledTableCell cellBorderRight="3px solid #000"> CUM</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.items.map((item, key) => (
                  <React.Fragment key={key}>
                    <TableRow key={key}>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        <Stack direction="row" spacing={0.5} alignItems={'center'}>
                          {!viewOnly && (
                            <AddCircle
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                createSubItem(key);
                              }}
                            />
                          )}
                          <Typography>{key + 1}</Typography>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        <Stack direction="row" spacing={0.5} alignItems={'center'}>
                          <>
                            {!viewOnly ? (
                              <>
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  size="small"
                                  multiline
                                  sx={{
                                    '& .MuiInputBase-root': {
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                    },
                                    '& .MuiInput-underline:before': {
                                      borderBottom: 'none',
                                    },
                                  }}
                                  {...getFieldProps(`items.${key}.name`)}
                                />
                                <RemoveCircle
                                  sx={{ cursor: 'pointer' }}
                                  color="error"
                                  onClick={() => {
                                    const items = [...values.items];
                                    items.splice(key, 1);
                                    formik.setFieldValue('items', items);
                                  }}
                                />
                              </>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.name}
                              </Typography>
                            )}
                          </>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell cellBorderLeft="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {!viewOnly ? (
                          <TextField
                            fullWidth
                            variant="standard"
                            size="small"
                            select
                            sx={{
                              '& .MuiInputBase-root': {
                                fontSize: '14px',
                                fontWeight: 'bold',
                              },
                              '& .MuiInput-underline:before': {
                                borderBottom: 'none',
                              },
                              width: '100%',
                            }}
                            {...getFieldProps(`items.${key}.unit`)}
                          >
                            {unitArray.map((unit) => (
                              <MenuItem key={unit} value={unit}>
                                {unit}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                            }}
                          >
                            {item.unit}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        fontWeight="bold"
                        contentEditable={!viewOnly}
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          onBlurMainQuantity(e, key);
                        }}
                        bgcolor="#cbeeff"
                        sx={{ maxWidth: '100px' }}
                      >
                        {item.quantity}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {checkIfNumber(item.unitPrice)}
                      </StyledTableCell>
                      <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                        {checkIfNumber(item.total)}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold" bgcolor="#cbeeff">
                        {''}
                      </StyledTableCell>
                      <StyledTableCell cellBorderRight="3px solid #000" fontWeight="bold" bgcolor="#cbeeff">
                        {!!values.items[key - 1] && checkIfNumber(values.items[key - 1].totalCum)}
                      </StyledTableCell>
                      <StyledTableCell
                        contentEditable={!viewOnly}
                        suppressContentEditableWarning
                        onBlur={(e) => {
                          const items = [...values.items];
                          items[key].remarks = e.target.innerText;
                          formik.setFieldValue('items', items);
                        }}
                        fontWeight="bold"
                      >
                        {item.remarks}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold">
                        {!viewOnly ? (
                          <TextField
                            select
                            fullWidth
                            value={item?.status}
                            size="small"
                            onChange={(e) => {
                              if (viewOnly) return;
                              handleOpenStatusChangePopup(e.target.value, key);
                            }}
                            sx={{
                              '& .MuiSelect-select': {
                                py: 0.5,
                                px: 1,
                                pr: '24px !important',
                                borderRadius: '4px !important',
                                bgcolor: 'transparent !important',
                                border: '1px solid #bdbdbd !important',
                                '&:focus': {
                                  bgcolor: 'transparent !important',
                                  borderRadius: '4px !important',
                                  border: '1px solid #bdbdbd !important',
                                },
                              },
                            }}
                          >
                            <MenuItem value={'NOT_STARTED'}>
                              <Stack direction="row" spacing={0.5} alignItems="center">
                                <Box
                                  sx={{
                                    width: 14,
                                    height: 14,
                                    backgroundColor: getStatusColor1('NOT_STARTED'),
                                    borderRadius: '50%',
                                  }}
                                />
                                <Box>Not Started</Box>
                              </Stack>
                            </MenuItem>

                            <MenuItem value={'IN_PROGRESS'}>
                              <Stack direction="row" spacing={0.5} alignItems="center">
                                <Box
                                  sx={{
                                    width: 14,
                                    height: 14,
                                    backgroundColor: getStatusColor1('IN_PROGRESS'),
                                    borderRadius: '50%',
                                  }}
                                />
                                <Box>In Progress</Box>
                              </Stack>
                            </MenuItem>
                            <MenuItem value={'FINAL_STAGE'}>
                              <Stack direction="row" spacing={0.5} alignItems="center">
                                <Box
                                  sx={{
                                    width: 14,
                                    height: 14,
                                    backgroundColor: getStatusColor1('FINAL_STAGE'),
                                    borderRadius: '50%',
                                  }}
                                />
                                <Box>Final Stage</Box>
                              </Stack>
                            </MenuItem>
                            <MenuItem value={'COMPLETED'}>
                              <Stack direction="row" spacing={0.5} alignItems="center">
                                <Box
                                  sx={{
                                    width: 14,
                                    height: 14,
                                    backgroundColor: getStatusColor1('COMPLETED'),
                                    borderRadius: '50%',
                                  }}
                                />
                                <Box>Completed</Box>
                              </Stack>
                            </MenuItem>
                          </TextField>
                        ) : (
                          <Typography>{item.status}</Typography>
                        )}
                        {selectedIndex === key && openStatusChangePopup && (
                          <UpdateMainItemStatus item={item} handleClose={handleClose} status={selectedStatus} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold">
                        <>
                          {viewOnly ? (
                            <>
                              {item?.description ? (
                                <Tooltip title="Click to edit description">
                                  <Typography
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      if (viewOnly) return;
                                      handleOpenDescriptionChangePopup(key);
                                    }}
                                  >
                                    {item?.description}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Click to add description">
                                  <Chip
                                    icon={<Add />}
                                    label="Description"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      if (viewOnly) return;
                                      handleOpenDescriptionChangePopup(key);
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </>
                          ) : (
                            <Typography>{item.description}</Typography>
                          )}
                        </>
                        {selectedIndex1 === key && (
                          <UpdateMainItemDescription item={item} getQto={getQto} setSelectedIndex={setSelectedIndex1} />
                        )}
                      </StyledTableCell>
                      <StyledTableCell fontWeight="bold">
                        {!viewOnly ? (
                          <Autocomplete
                            fullWidth
                            size="small"
                            id="asynchronous-demo"
                            isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                            getOptionLabel={(option) => option.fullname}
                            getOptionSelected={(option, value) => option.fullname === value.fullname}
                            options={members}
                            defaultValue={item?.createdBy || null}
                            onChange={(_, value) => {
                              if (value && value._id) {
                                updateCreatedBy(item._id, value._id);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                  <Avatar
                                    sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                    src={option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic}
                                  >
                                    {option?.profilePic ? null : option?.fullname?.charAt(0)}
                                  </Avatar>
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.fullname}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      {option.email}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      {option.role?.replaceAll('_', ' ')}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </li>
                            )}
                          />
                        ) : (
                          <>
                            {item?.createdBy && (
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 30, height: 30, cursor: 'pointer' }}
                                  src={
                                    item?.createdBy.profilePic &&
                                    process.env.REACT_APP_API_URL + item.createdBy.profilePic
                                  }
                                >
                                  {item?.createdBy.profilePic ? null : item?.createdBy.fullname?.charAt(0)}
                                </Avatar>
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {item.createdBy.fullname}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1.1', fontSize: '12px' }}>
                                    {item.createdBy.role?.replaceAll('_', ' ')}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}
                          </>
                        )}
                      </StyledTableCell>
                    </TableRow>
                    {item.subItems.map((item1, key1) => (
                      <>
                        <StyledTableRow key={key1}>
                          <StyledTableCell>
                            <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                              {!viewOnly && (
                                <RemoveCircle
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const items = [...values.items];
                                    items[key].subItems.splice(key1, 1);
                                    formik.setFieldValue('items', items);
                                  }}
                                  color="error"
                                />
                              )}
                              {`${key + 1}.${key1 + 1}`}
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000">
                            {!viewOnly ? (
                              <TextField
                                id={`${key}#${key1}#name`}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const nextCell = document.getElementById(`${key}#${key1 + 1}#name`);
                                    if (nextCell) {
                                      nextCell.focus();
                                    }
                                  }
                                }}
                                fullWidth
                                variant="standard"
                                size="small"
                                sx={{
                                  '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                  },
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                }}
                                onChange={(e) => {
                                  onChangeSubItem(e.target.value, key, key1, 'name');
                                }}
                                value={item1.name}
                              />
                            ) : (
                              <Typography>{item1.name}</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {!viewOnly ? (
                              <TextField
                                id={`${key}#${key1}#length`}
                                variant="standard"
                                size="small"
                                sx={{
                                  '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                  },
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                  width: '100%',
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const nextCell = document.getElementById(`${key}#${key1 + 1}#length`);
                                    if (nextCell) {
                                      nextCell.focus();
                                    }
                                  }
                                }}
                                onChange={(e) => {
                                  onChangeSubItem(e.target.value, key, key1, 'length');
                                }}
                                value={item1.length}
                              />
                            ) : (
                              <Typography>{item1.length}</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {!viewOnly ? (
                              <TextField
                                id={`${key}#${key1}#width`}
                                variant="standard"
                                size="small"
                                sx={{
                                  '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                  },
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                  width: '100%',
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const nextCell = document.getElementById(`${key}#${key1 + 1}#width`);
                                    if (nextCell) {
                                      nextCell.focus();
                                    }
                                  }
                                }}
                                onChange={(e) => {
                                  onChangeSubItem(e.target.value, key, key1, 'width');
                                }}
                                value={item1.width}
                              />
                            ) : (
                              <Typography>{item1.width}</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000">
                            {!viewOnly ? (
                              <TextField
                                id={`${key}#${key1}#height`}
                                variant="standard"
                                size="small"
                                sx={{
                                  '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                  },
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                  width: '100%',
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const nextCell = document.getElementById(`${key}#${key1 + 1}#height`);
                                    if (nextCell) {
                                      nextCell.focus();
                                    }
                                  }
                                }}
                                onChange={(e) => {
                                  onChangeSubItem(e.target.value, key, key1, 'height');
                                }}
                                value={item1.height}
                              />
                            ) : (
                              <Typography>{item1.height}</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {!viewOnly ? (
                              <TextField
                                fullWidth
                                variant="standard"
                                size="small"
                                select
                                sx={{
                                  '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                  },
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                }}
                                {...getFieldProps(`items.${key}.subItems.${key1}.procurementUnit`)}
                              >
                                {unitArray.map((unit) => (
                                  <MenuItem key={unit} value={unit}>
                                    {unit}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <Typography>{item1.procurementUnit}</Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '90px' }}>
                            <TextField
                              id={`${key}#${key1}#procurementQuantity`}
                              variant="standard"
                              size="small"
                              sx={{
                                '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                },
                                '& .MuiInput-underline:before': {
                                  borderBottom: 'none',
                                },
                                width: '100%',
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  const nextCell = document.getElementById(`${key}#${key1 + 1}#procurementQuantity`);
                                  if (nextCell) {
                                    nextCell.focus();
                                  }
                                }
                              }}
                              onChange={(e) => {
                                if (viewOnly) return;
                                const items = [...values.items];
                                items[key].subItems[key1].procurementQuantity = e.target.value;
                                items[key].subItems[key1].procurementTotalPrice =
                                  parseFloat(items[key].subItems[key1].procurementQuantity) *
                                  parseFloat(items[key].subItems[key1].procurementUnitPrice);
                                items[key].subItems[key1].grandTotal =
                                  parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                  parseFloat(items[key].subItems[key1].totalWorkPrice);
                                const totalPrice = calculateTotal(key);
                                items[key].totalPrice = totalPrice;
                                formik.setFieldValue('items', items);
                              }}
                              value={item1.procurementQuantity}
                            />
                          </StyledTableCell>
                          <StyledTableCell name="procurementUnitPrice" sx={{ width: '90px' }}>
                            <TextField
                              variant="standard"
                              id={`${key}#${key1}#procurementUnitPrice`}
                              size="small"
                              sx={{
                                '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                },
                                '& .MuiInput-underline:before': {
                                  borderBottom: 'none',
                                },
                                width: '100%',
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  const nextCell = document.getElementById(`${key}#${key1 + 1}#procurementUnitPrice`);
                                  if (nextCell) {
                                    nextCell.focus();
                                  }
                                }
                              }}
                              onChange={(e) => {
                                if (viewOnly) return;
                                const items = [...values.items];
                                items[key].subItems[key1].procurementUnitPrice = e.target.value;
                                items[key].subItems[key1].procurementTotalPrice =
                                  parseFloat(items[key].subItems[key1].procurementQuantity) *
                                  parseFloat(items[key].subItems[key1].procurementUnitPrice);
                                items[key].subItems[key1].grandTotal =
                                  parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                  parseFloat(items[key].subItems[key1].totalWorkPrice);
                                const totalPrice = calculateTotal(key);
                                items[key].totalPrice = totalPrice;
                                formik.setFieldValue('items', items);
                              }}
                              value={item1.procurementUnitPrice}
                            />
                          </StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000" name="procurementTotalPrice">
                            {item1.procurementTotalPrice}
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '90px' }}>
                            <TextField
                              id={`${key}#${key1}#workType`}
                              variant="standard"
                              size="small"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  const nextCell = document.getElementById(`${key}#${key1 + 1}#workType`);
                                  if (nextCell) {
                                    nextCell.focus();
                                  }
                                }
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                },
                                '& .MuiInput-underline:before': {
                                  borderBottom: 'none',
                                },
                                width: '100%',
                              }}
                              onChange={(e) => {
                                if (viewOnly) return;
                                onChangeSubItem(e.target.value, key, key1, 'workType');
                              }}
                              value={item1.workType}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '70px' }}>
                            <TextField
                              id={`${key}#${key1}#workHour`}
                              variant="standard"
                              size="small"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  const nextCell = document.getElementById(`${key}#${key1 + 1}#workHour`);
                                  if (nextCell) {
                                    nextCell.focus();
                                  }
                                }
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                },
                                '& .MuiInput-underline:before': {
                                  borderBottom: 'none',
                                },
                                width: '100%',
                              }}
                              onChange={(e) => {
                                if (viewOnly) return;
                                const items = [...values.items];
                                items[key].subItems[key1].workHour = e.target.value;
                                items[key].subItems[key1].totalWorkPrice =
                                  parseFloat(items[key].subItems[key1].workHour) *
                                  parseFloat(items[key].subItems[key1].workPricePerHour);
                                items[key].subItems[key1].grandTotal =
                                  parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                  parseFloat(items[key].subItems[key1].totalWorkPrice);
                                const totalPrice = calculateTotal(key);
                                items[key].totalPrice = totalPrice;
                                formik.setFieldValue('items', items);
                              }}
                              value={item1.workHour}
                            />
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: '90px' }}>
                            <TextField
                              id={`${key}#${key1}#workPricePerHour`}
                              variant="standard"
                              size="small"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  const nextCell = document.getElementById(`${key}#${key1 + 1}#workPricePerHour`);
                                  if (nextCell) {
                                    nextCell.focus();
                                  }
                                }
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                  fontSize: '14px',
                                },
                                '& .MuiInput-underline:before': {
                                  borderBottom: 'none',
                                },
                                width: '100%',
                              }}
                              onChange={(e) => {
                                if (viewOnly) return;
                                const items = [...values.items];
                                items[key].subItems[key1].workPricePerHour = e.target.value;
                                items[key].subItems[key1].totalWorkPrice =
                                  parseFloat(items[key].subItems[key1].workHour) *
                                  parseFloat(items[key].subItems[key1].workPricePerHour);
                                items[key].subItems[key1].grandTotal =
                                  parseFloat(items[key].subItems[key1].procurementTotalPrice) +
                                  parseFloat(items[key].subItems[key1].totalWorkPrice);
                                const totalPrice = calculateTotal(key);
                                items[key].totalPrice = totalPrice;
                                formik.setFieldValue('items', items);
                              }}
                              value={item1.workPricePerHour}
                            />
                          </StyledTableCell>
                          <StyledTableCell name="totalWorkPrice" cellBorderRight="3px solid #000">
                            {item1.totalWorkPrice}
                          </StyledTableCell>
                          <StyledTableCell>{item1.grandTotal}</StyledTableCell>
                          <StyledTableCell cellBorderRight="3px solid #000">{item1.cum}</StyledTableCell>
                          <StyledTableCell>
                            {!viewOnly ? (
                              <TextField
                                id={`${key}#${key1}#remarks`}
                                variant="standard"
                                size="small"
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    const nextCell = document.getElementById(`${key}#${key1 + 1}#remarks`);
                                    if (nextCell) {
                                      nextCell.focus();
                                    }
                                  }
                                }}
                                sx={{
                                  '& .MuiInputBase-root': {
                                    fontSize: '14px',
                                  },
                                  '& .MuiInput-underline:before': {
                                    borderBottom: 'none',
                                  },
                                }}
                                onChange={(e) => {
                                  onChangeSubItem(e.target.value, key, key1, 'remarks');
                                }}
                                value={item1.remarks}
                              />
                            ) : (
                              <Typography>{item1.remarks}</Typography>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))}
                    {
                      <TableRow>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell fontWeight="bold">{`Total (Sl. No${key + 1})`}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell fontWeight="bold">
                          {checkIfNumber(values.items[key].totalPrice)}
                        </StyledTableCell>
                        <StyledTableCell fontWeight="bold">{checkIfNumber(values.items[key].totalCum)}</StyledTableCell>
                        <StyledTableCell>{''}</StyledTableCell>
                        <StyledTableCell colSpan={3}>{''}</StyledTableCell>
                      </TableRow>
                    }
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        {!viewOnly && (
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems={'center'}>
            {((accType !== 'PROJECT_ENGINEER' &&
              (qto?.quotationStatus === 'NA' || qto?.quotationStatus === 'PENDING') &&
              qto.qtoStatus !== 'APPROVED') ||
              (accType === 'PROJECT_ENGINEER' && (qto?.qtoStatus === 'NA' || qto?.qtoStatus === 'REJECTED'))) && (
              <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                {values.items.length < 1 ? (
                  <Button
                    variant="contained"
                    sx={{
                      height: '40px',
                    }}
                    onClick={() => {
                      const items = [
                        {
                          name: '',
                          unit: '',
                          quantity: '',
                          rate: '',
                          totalPrice: '',
                          totalCum: '',
                          subItems: [],
                        },
                      ];
                      formik.setFieldValue('items', items);
                    }}
                  >
                    Start Adding Items
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    sx={{
                      height: '40px',
                    }}
                    onClick={() => {
                      const items = [
                        ...values.items,
                        {
                          name: '',
                          unit: '',
                          quantity: '',
                          rate: '',
                          totalPrice: '',
                          totalCum: '',
                          subItems: [],
                        },
                      ];
                      formik.setFieldValue('items', items);
                    }}
                  >
                    Add More Items
                  </Button>
                )}
              </Stack>
            )}

            {((accType !== 'PROJECT_ENGINEER' &&
              (qto?.quotationStatus === 'NA' || qto?.quotationStatus === 'PENDING') &&
              qto.qtoStatus !== 'APPROVED') ||
              (accType === 'PROJECT_ENGINEER' && (qto?.qtoStatus === 'NA' || qto?.qtoStatus === 'REJECTED'))) && (
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} sx={{ padding: '10px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    height: '40px',
                  }}
                  onClick={() => {
                    getQto();
                  }}
                  startIcon={<Replay />}
                >
                  Reset Form
                </Button>
                <LoadingButton
                  type="submit"
                  sx={{
                    height: '40px',
                  }}
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                  startIcon={<Save />}
                >
                  Save
                </LoadingButton>
              </Stack>
            )}
          </Stack>
        )}
      </Form>
    </FormikProvider>
  );
}

export default QtoTable;
