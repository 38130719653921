import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Tab } from '@mui/material';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import PaymentsVendor from './PaymentsVendor';
import PurchaseTable from './PurchaseTable';

function VendorAccountTables({ vendor }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Card>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label=" Payments" value="1" />
            <Tab label="Pending Invoices" value="2" />
            <Tab label="Paid Invoices" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PaymentsVendor vendorId={vendor?.id} />{' '}
        </TabPanel>
        <TabPanel value="2">
          <PurchaseTable status={'pending'} vendorId={vendor._id} />
        </TabPanel>
        <TabPanel value="3">
          <PurchaseTable status={'completed'} vendorId={vendor._id} />
        </TabPanel>
      </TabContext>
    </Card>
  );
}

export default VendorAccountTables;
