import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ReactToPrint from 'react-to-print';
import { FilePond, File, registerPlugin } from 'react-filepond';

import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { Add, Attachment, Delete, DeleteForever, Edit, Print } from '@mui/icons-material';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import EditPurchaseOrderForm from '../components/EditPurchaseOrderForm';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import Logo from '../components/Logo';
import { formatNumberLpoReference } from '../utils/commonFunctions';
import LoadingIcon from '../components/smallComponents/LoadingIcon';
import DeleteModal from '../components/popups/DeleteModal';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

const ViewPurchaseOrder = () => {
  const { id } = useParams();
  const componentRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [purchaseOrderData, setpurchaseOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  const getPurchaseOrder = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseOrder/one`, {
        params: {
          _id: id,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        console.log(res);
        setpurchaseOrderData(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadDoc = async (e, name) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchaseOrder/attachment-download`, {
        params: {
          file: name,
        },
      });
      window.open(res.data.data.url);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  return (
    <Page title="View Purchase Order">
      <Container maxWidth="xl">
        <PageHeader title="View Purchase Order" />
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Card>
            {editMode && (
              <EditPurchaseOrderForm
                purchaseOrderData={purchaseOrderData}
                setEditMode={setEditMode}
                getPurchaseOrder={getPurchaseOrder}
              />
            )}
            {!editMode && (
              <Box paddingBottom={5}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  px={5}
                  py={3}
                  sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                >
                  {/* <Box sx={{ flex: '1' }}>Logo</Box> */}

                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                        PO Reference:
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                        {formatNumberLpoReference(purchaseOrderData?.poReference)}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                        Order Date:
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                        {moment(purchaseOrderData.orderDate).format('DD-MM-YYYY')}
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                        Required Delivery Date:
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                        {purchaseOrderData?.requiredDeliveryDate &&
                          moment(purchaseOrderData.requiredDeliveryDate).format('DD-MM-YYYY')}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    {purchaseOrderData?.status === 'PENDING' && (
                      <Button color="error" onClick={() => setOpenDelete(true)} size="small" startIcon={<Delete />}>
                        Delete
                      </Button>
                    )}
                    <DeleteModal
                      open={openDelete}
                      setOpen={setOpenDelete}
                      url={`purchaseOrder?_id=${purchaseOrderData?._id}`}
                      navigateTo="/dashboard/purchaseOrder"
                      message="Are you sure you want to delete this purchase order?"
                      title="Delete Purchase Order"
                    />
                    {purchaseOrderData?.status === 'PENDING' && (
                      <Button 
                        variant="text"
                        onClick={() => setEditMode(true)}
                        color="light"
                        size="small"
                        startIcon={<Edit />}
                        className="print-hide"
                      >
                        Edit
                      </Button>
                    )}
                    <ReactToPrint
                      trigger={() => (
                        <Button variant="text" color="light" startIcon={<Print />} size="small">
                          Print
                        </Button>
                      )}
                      content={() => componentRef.current}
                      copyStyles
                    />
                  </Stack>
                </Stack>
                <Grid container rowGap={2} mt={5}>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                        Supplier:
                      </Typography>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          sx={{ width: 35, height: 35, cursor: 'pointer' }}
                          src={
                            (purchaseOrderData?.vendorId?.profilePic &&
                              process.env.REACT_APP_API_URL + purchaseOrderData?.vendorId?.profilePic) ||
                            'https://www.w3schools.com/howto/img_avatar2.png'
                          }
                        />
                        <Stack direction={'column'}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                            {purchaseOrderData?.vendorId?.companyName}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1' }}>
                            {purchaseOrderData?.vendorId?.email}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                        Project:
                      </Typography>
                      <Typography>{purchaseOrderData?.projectId?.name}</Typography>
                    </Stack>
                  </Grid>
                  {purchaseOrderData?.projectId?.workOrderNumber && (
                    <Grid item xs={12}>
                      <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                          Work Order Number:
                        </Typography>
                        <Typography>{purchaseOrderData?.projectId?.workOrderNumber}</Typography>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                        Quotation :
                      </Typography>
                      <Typography>{purchaseOrderData.quotationId?.quotationId}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Supplier
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{purchaseOrderData?.to?.name}</Typography>
                        <Typography>{purchaseOrderData?.to?.address}</Typography>
                        <Typography>{purchaseOrderData?.to?.contactPerson}</Typography>
                        <Typography>{purchaseOrderData?.to?.email}</Typography>
                        <Typography>{purchaseOrderData?.to?.phone}</Typography>
                        {purchaseOrderData?.to?.trn && <Typography>{`TRN: ${purchaseOrderData?.to?.trn}`}</Typography>}
                        {purchaseOrderData?.to?.attn && (
                          <Typography>{`Attn: ${purchaseOrderData?.to?.attn}`}</Typography>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Invoice To
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{purchaseOrderData.from.name}</Typography>
                        <Typography>{purchaseOrderData.from.address}</Typography>
                        <Typography>{purchaseOrderData.from.pin}</Typography>
                        <Typography>{purchaseOrderData.from.phone}</Typography>
                        <Typography>{purchaseOrderData.from.email}</Typography>
                        <Typography>{`TRN: ${purchaseOrderData.from?.trn}`}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack pt={5} px={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Reference Number
                      </Typography>
                      <Typography>{purchaseOrderData.referenceNumber}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack p={5}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sl No</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Unit Price</TableCell>
                              <TableCell>Total</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {purchaseOrderData.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  <Stack justifyContent={'start'} sx={{ height: '100%' }}>
                                    <Typography>{item.name}</Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell sx={{ width: '100px' }}>
                                  <Typography>{item.quantity}</Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: '120px',
                                  }}
                                >
                                  <Typography>{item.unitPrice}</Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography>{item.quantity * item.unitPrice}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
                  {/* <Box sx={{ flex: '1' }}></Box> */}
                  <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
                    {/* <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Sub Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {purchaseOrderData.subTotal && parseFloat(purchaseOrderData.subTotal).toFixed(2)}
                      </Typography>
                    </Stack> */}
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {purchaseOrderData.grandTotal && parseFloat(purchaseOrderData.grandTotal).toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction={'row'} paddingX={5} justifyContent={'end'} paddingY={5}>
                  <Stack direction={'row'} spacing={5} justifyContent={'space-between'}>
                    <Typography variant="body2" fontWeight={'bold'}>
                      {purchaseOrderData?.grandTotalInWords}
                    </Typography>
                  </Stack>
                </Stack>

                {purchaseOrderData?.paymentTerms?.length > 0 && (
                  <Stack px={5} pt={5}>
                    <Typography sx={{ fontWeight: 600 }} gutterBottom>
                      Payment Terms
                    </Typography>
                    {purchaseOrderData?.paymentTerms?.map((term, index) => (
                      <Stack
                        direction={'row'}
                        spacing={2}
                        alignItems={'center'}
                        key={index}
                        justifyContent={'space-between'}
                      >
                        <Typography>{`${index + 1}. ${term}`}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}
                {/*  terms and conditions */}
                {purchaseOrderData.termsAndConditions?.length > 0 && (
                  <Stack px={5} pt={5}>
                    <Typography sx={{ fontWeight: 600 }} gutterBottom>
                      Terms and Conditions
                    </Typography>
                    {purchaseOrderData?.termsAndConditions?.map((term, index) => (
                      <Stack
                        direction={'row'}
                        spacing={2}
                        alignItems={'center'}
                        key={index}
                        justifyContent={'space-between'}
                      >
                        <Typography>{`${index + 1}. ${term}`}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}

                {purchaseOrderData?.notes?.length > 0 && (
                  <Stack px={5} pt={5}>
                    <Typography sx={{ fontWeight: 600 }} gutterBottom>
                      Notes
                    </Typography>
                    {purchaseOrderData?.notes?.map((note, index) => (
                      <Stack
                        direction={'row'}
                        spacing={2}
                        alignItems={'center'}
                        key={index}
                        justifyContent={'space-between'}
                      >
                        <Typography>{`${index + 1}. ${note}`}</Typography>
                      </Stack>
                    ))}
                  </Stack>
                )}

                <Stack px={5} pt={5} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  <Stack direction={'column'} spacing={2} alignItems={'flex-start'}>
                    <Typography sx={{ fontWeight: 600 }} gutterBottom>
                      Prepared By
                    </Typography>
                    <Typography>{purchaseOrderData?.preparedByInWords?.fullname}</Typography>
                    <Typography variant="caption">{purchaseOrderData?.preparedByInWords?.designation}</Typography>
                  </Stack>
                  <Stack direction={'column'} spacing={2} alignItems={'flex-end'}>
                    <Typography sx={{ fontWeight: 600 }} gutterBottom>
                      Approved By
                    </Typography>
                    <Typography>{purchaseOrderData?.approvedByInWords?.fullname}</Typography>
                    <Typography variant="caption">{purchaseOrderData?.approvedByInWords?.designation}</Typography>
                  </Stack>
                </Stack>
                <Stack spacing={1} sx={{ width: '100%' }} paddingX={2} paddingY={5}>
                  <Stack direction={'row'} alignItems="center">
                    <Attachment />
                    <Typography variant="h6" fontWeight={'bold'}>
                      Attachments
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} spacing={1} className="print-hide">
                    {purchaseOrderData?.attachments?.map((attachment, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        color="primary"
                        onClick={(e) => downloadDoc(e, attachment.fileUrl)}
                      >
                        {attachment.originalName}
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            )}
          </Card>
        )}
      </Container>
      {/*  Printable purchase */}
      {!isLoading && (
        <>
          <Stack
            direction={'column'}
            sx={{
              width: '1100px',
              height: '100%',
              padding: '20px',
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
            spacing={5}
            ref={componentRef}
            className="print-only"
          >
            {/* Head */}
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'start'}
              sx={{ width: '100%' }}
              pb={5}
            >
              <Stack direction={'column'} spacing={2} sx={{ height: '100%' }} flex={1}>
                <Logo />
                <Stack spacing={0.25} sx={{ maxWidth: '300px' }}>
                  <Typography sx={{ fontSize: '15px' }} fontWeight={'bold'}>
                    {purchaseOrderData?.from?.name}
                  </Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.from?.address}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.from?.phone}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.from?.email}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{`TRN:   ${purchaseOrderData?.from?.trn}`}</Typography>
                </Stack>
              </Stack>
              <Stack
                direction={'column'}
                spacing={2}
                sx={{ height: '100%' }}
                flex={1}
                alignItems={'flex-end'}
                justifyContent={'space-between'}
              >
                <Stack
                  direction={'column'}
                  alignItems={'flex-end'}
                  sx={{
                    height: '90px',
                  }}
                >
                  <Stack alignItems={'flex-end'}>
                    <Typography sx={{ fontSize: '35px', lineHeight: 1 }}>Purchase Order</Typography>
                    <Typography
                      variant="h5"
                      fontWeight={'bold'}
                      sx={{
                        lineHeight: '1',
                      }}
                    >
                      {`PO Ref#  ${formatNumberLpoReference(purchaseOrderData?.poReference)}`}
                    </Typography>
                  </Stack>
                </Stack>
                {/* <Stack direction={'column'} alignItems={'flex-end'}>
                <Typography sx={{ fontSize: '15px' }} fontWeight={'bold'}>
                  Balance Due
                </Typography>
                <Typography variant="h5" fontWeight={'bold'} gutterBottom>
                  {`AED ${parseFloat(purchase?.grandTotal - purchase?.paidAmount).toFixed(2)}`}
                </Typography>
              </Stack> */}
              </Stack>
            </Stack>

            {/*  bill from and date */}
            <Stack direction={'row'} justifyContent={'end'} alignItems={'start'} sx={{ width: '100%' }}>
              <Stack
                direction={'column'}
                spacing={2}
                sx={{ height: '100%' }}
                flex={1}
                alignItems={'start'}
                justifyContent={'end'}
              >
                <Stack direction={'column'} alignItems={'start'} justifyContent={'end'}>
                  <Typography sx={{ fontSize: '15px' }}> Supplier</Typography>
                  <Typography fontWeight={'bold'}>{purchaseOrderData?.to?.name}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.to?.address}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.to?.contactPerson}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.to?.phone}</Typography>
                  <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.to?.email}</Typography>
                  {purchaseOrderData?.to?.trn && (
                    <Typography sx={{ fontSize: '15px' }}>{`TRN:   ${purchaseOrderData?.to?.trn}`}</Typography>
                  )}
                  {purchaseOrderData?.to?.attn && (
                    <Typography sx={{ fontSize: '15px' }}>{`Attn:   ${purchaseOrderData?.to?.attn}`}</Typography>
                  )}
                </Stack>
              </Stack>
              <Stack direction={'column'} spacing={2} sx={{ height: '100%' }} flex={1} alignItems={'flex-end'}>
                <Stack direction={'column'} spacing={1}>
                  <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
                    <Typography sx={{ fontSize: '15px' }}>Order Date:</Typography>
                    <Typography sx={{ fontSize: '15px' }}>
                      {moment(purchaseOrderData?.orderDate).format('DD-MM-YYYY')}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
                    <Typography sx={{ fontSize: '15px' }}>Required Delivery Date:</Typography>
                    <Typography sx={{ fontSize: '15px' }}>
                      {purchaseOrderData?.requiredDeliveryDate &&
                        `with in ${moment(purchaseOrderData?.requiredDeliveryDate).format('DD-MM-YYYY')}`}
                    </Typography>
                  </Stack>
                  {/* reference Number */}
                  {purchaseOrderData?.referenceNumber && (
                    <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
                      <Typography sx={{ fontSize: '15px' }}>Reference Number:</Typography>
                      <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.referenceNumber}</Typography>
                    </Stack>
                  )}
                  {/* work order Number   */}
                  {purchaseOrderData?.projectId?.workOrderNumber && (
                    <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
                      <Typography sx={{ fontSize: '15px' }}>Work Order Number:</Typography>
                      <Typography sx={{ fontSize: '15px' }}>{purchaseOrderData?.projectId?.workOrderNumber}</Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>

            {/*  projct name */}

            {purchaseOrderData?.projectId?.name && (
              <Stack spacing={0.5} sx={{ width: '100%' }}>
                <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }} spacing={1}>
                  <Typography sx={{ fontSize: '15px' }}> Project:</Typography>
                  <Typography fontWeight={'bold'}>{purchaseOrderData?.projectId?.name}</Typography>
                </Stack>

                <Stack direction={'row'} alignItems={'center'} sx={{ width: '100%' }} spacing={1}>
                  <Typography sx={{ fontSize: '15px' }}> Quotation:</Typography>
                  <Typography fontWeight={'bold'}>{purchaseOrderData?.quotationId?.quotationId}</Typography>
                </Stack>
              </Stack>
            )}

            {/*  Table */}
            <TableContainer
              sx={{
                border: '1px solid #eceff3',
                borderRadius: '5px',
                overflow: 'hidden',
              }}
            >
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary?.main,
                  }}
                >
                  <TableRow>
                    <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                      Sl No
                    </TableCell>
                    <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                      Description
                    </TableCell>
                    <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                      Quantity
                    </TableCell>
                    <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                      Unit Price
                    </TableCell>
                    <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseOrderData?.items?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontSize: '15px' }}>{index + 1}</TableCell>
                      <TableCell sx={{ fontSize: '15px' }}>{item.name}</TableCell>
                      <TableCell sx={{ fontSize: '15px' }}>{item.quantity}</TableCell>
                      <TableCell sx={{ fontSize: '15px' }}>{parseFloat(item.unitPrice).toFixed(2)}</TableCell>
                      <TableCell sx={{ fontSize: '15px' }}>
                        {parseFloat(Number(item.unitPrice) * Number(item.quantity)).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/*  Total */}
            <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              sx={{ width: '100%' }}
              paddingX={5}
              paddingY={5}
            >
              <Stack justifyContent="center" spacing={2} sx={{ width: '400px' }} pr={4}>
                <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                  <Typography
                    fontWeight={'bold'}
                    sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}
                  >
                    Grand Total (AED):
                  </Typography>
                  <Typography
                    fontWeight={'bold'}
                    sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}
                  >
                    {parseFloat(purchaseOrderData?.grandTotal).toFixed(2)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            {/* grand total in words */}
            <Stack
              direction={'row'}
              justifyContent={'end'}
              alignItems={'center'}
              sx={{ width: '100%' }}
              px={9}
              paddingBottom={5}
            >
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}>
                {purchaseOrderData?.grandTotalInWords}
              </Typography>
            </Stack>

            {/*  payment terms */}
            {purchaseOrderData?.paymentTerms &&
              purchaseOrderData?.paymentTerms.length > 0 &&
              purchaseOrderData.paymentTerms[0]?.length > 0 && (
                <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
                  <Typography
                    fontWeight={'bold'}
                    sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}
                  >
                    Payment Terms
                  </Typography>
                  <Stack spacing={1}>
                    {purchaseOrderData?.paymentTerms?.map((term, index) => (
                      <Typography key={index} sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary }}>
                        {term}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              )}

            {/*  terms and conditions */}
            {purchaseOrderData?.termsAndConditions &&
              purchaseOrderData?.termsAndConditions.length > 0 &&
              purchaseOrderData.termsAndConditions[0]?.length > 0 && (
                <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
                  <Typography
                    fontWeight={'bold'}
                    sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}
                  >
                    Terms and Conditions
                  </Typography>
                  <Stack spacing={1}>
                    {purchaseOrderData?.termsAndConditions?.map((term, index) => (
                      <Typography key={index} sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary }}>
                        {term}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              )}

            {/*  notes */}
            {purchaseOrderData?.notes &&
              purchaseOrderData?.notes.length > 0 &&
              purchaseOrderData.notes[0]?.length > 0 && (
                <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
                  <Typography
                    fontWeight={'bold'}
                    sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}
                  >
                    Notes
                  </Typography>
                  <Stack spacing={1}>
                    {purchaseOrderData?.notes?.map((note, index) => (
                      <Typography key={index} sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary }}>
                        {note}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              )}

            {/*  prepared by and approved by */}
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ width: '100%' }}
              paddingY={5}
            >
              <Stack direction={'column'} spacing={2} alignItems={'flex-start'}>
                <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                  Prepared By
                </Typography>
                <Stack direction={'column'} alignItems={'flex-start'}>
                  <Typography>{purchaseOrderData?.preparedByInWords?.fullname}</Typography>
                  <Typography variant="caption">{purchaseOrderData?.preparedByInWords?.designation}</Typography>
                </Stack>
              </Stack>
              <Stack direction={'column'} spacing={2} alignItems={'flex-end'}>
                <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                  Approved By
                </Typography>
                <Stack direction={'column'} alignItems={'flex-end'}>
                  <Typography>{purchaseOrderData?.approvedByInWords?.fullname}</Typography>
                  <Typography variant="caption">{purchaseOrderData?.approvedByInWords?.designation}</Typography>
                </Stack>
              </Stack>
            </Stack>

            {/* This document is computer generated and does not require the signature or the Company's stamp in order to be considered valid */}
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              paddingTop={5}
              sx={{
                width: '100%',
                padding: '20px',
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: (theme) => theme.palette.text.secondary,
                  fontStyle: 'italic',
                }}
                textAlign={'center'}
              >
                This document is computer generated and does not require the signature or the Company's stamp in order
                to be considered valid.
              </Typography>
            </Stack>
          </Stack>
        </>
      )}
    </Page>
  );
};

export default ViewPurchaseOrder;
