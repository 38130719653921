import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField, Button, Stack, MenuItem, Grid, Card, Collapse, IconButton, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters }) => {
  const [open, setOpen] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      id: filterValues?.id,
      isBlocked: filterValues?.isBlocked,
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField id="id" name="id" fullWidth label="Tool ID" {...getFieldProps('id')} />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    fullWidth
                    id="isBlocked"
                    name="isBlocked"
                    label="Status"
                    {...getFieldProps('isBlocked')}
                  >
                    <MenuItem value="false">Active</MenuItem>
                    <MenuItem value="true">Blocked</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          id: '',
                          isBlocked: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
