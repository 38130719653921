import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppCurrentVisits, BarGraph } from '../sections/@dashboard/app';
import hasAccessOf from '../store/hasAccessOf';
import ProjectToolUsage from '../components/ProjectToolUsage';
import AccountAnalyticsComponent from './AccountAnalyticsComponent';

// ----------------------------------------------------------------------

export default function AccountAnalytics() {
  const theme = useTheme();

  const trueValue = true;

  const projectStatusGraphAccess = hasAccessOf('dashboard.projectSummary.r');

  const [vendorPaymentPieData, setVendorPaymentPieData] = useState([]);
  const [isLoadingsetVendorPaymentPieData, setIsLoadingVendorPaymentPieData] = useState(true);

  const [customerPaymentPieData, setCustomerPaymentPieData] = useState([]);
  const [isLoadingCustomerPaymentPieData, setIsLoadingCustomerPaymentPieData] = useState(true);

  const [vatCreditDebitPieData, setVatCreditDebitPieData] = useState([]);
  const [isLoadingVatCreditDebitPieData, setIsLoadingVatCreditDebitPieData] = useState(true);

  const [vendorPurchaseBarData, setVendorPurchaseBarData] = useState({ chartLabels: [], chartData: [] });
  const [vendorPaymentBarData, setVendorPaymentBarData] = useState({ chartLabels: [], chartData: [] });

  const [customerBillBarData, setCustomerBillBarData] = useState({ chartLabels: [], chartData: [] });
  const [customerPaymentBarData, setCustomerPaymentBarData] = useState({ chartLabels: [], chartData: [] });

  const getVendorPaymentPieData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/vendor/payment`);
      console.log(res.data.data);
      setVendorPaymentPieData(res.data.data);
      setIsLoadingVendorPaymentPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerPaymentPieData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/payment`);
      console.log(res.data.data);
      setCustomerPaymentPieData(res.data.data);
      setIsLoadingCustomerPaymentPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVatCreditDebitPieData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/vat/credit-debit`);
      console.log(res.data.data);
      setVatCreditDebitPieData(res.data.data);
      setIsLoadingVatCreditDebitPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendorPurchaseBarData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/purchase/invoice-paid-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setVendorPurchaseBarData(res?.data?.data?.graphDataCredit);
      setVendorPaymentBarData(res?.data?.data?.graphDataDebit);
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerBillBarData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/bill-paid-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setCustomerBillBarData(res?.data?.data?.graphDataCredit);
      setCustomerPaymentBarData(res?.data?.data?.graphDataDebit);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Page title="Account Analytics">
      <Container maxWidth="xl">
        <AccountAnalyticsComponent />
      </Container>
    </Page>
  );
}
