import { useState, useEffect } from 'react';
import axios from 'axios';
// material
import {
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  Container,
  Typography,
  TextField,
  Grid,
  IconButton,
  useMediaQuery,
  Paper,
} from '@mui/material';
import { Box } from '@mui/system';
import { Add, BorderColor, ContentCopy, Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/CompanyAddress';

// components

function AddTermsAndConditions(props) {
  const matches = useMediaQuery('(max-width:600px)');
  const { termsAndConditions } = props;
  const [termsArray, setTermsArray] = useState(termsAndConditions);
  const formik = useFormik({
    initialValues: {
      termsAndConditions,
    },
    onSubmit: async () => {
      try {
        const removedEmptyTerms = termsArray.filter((term) => term !== '');
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/utils/projectSettings`, {
          termsAndConditions: removedEmptyTerms,
        });
        if (!res.data.isError) {
          props.closeAddTermsAndConditionsModal(res.data.message);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        props.getTermsAndConditions();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleRemoveRow = (indexToRemove) => {
    const updatedTermsArray = termsArray.filter((_, index) => index !== indexToRemove);
    setTermsArray(updatedTermsArray);
  };

  return (
    <Dialog open={props.open} onClose={props.closeAddTermsAndConditionsModal} fullScreen={matches}>
      <DialogTitle>Terms and Conditions</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {termsArray.map((term, index) => (
                  <Grid item xs={12} key={index}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        fullWidth
                        multiline
                        label="Terms and Conditions"
                        value={term}
                        onChange={(e) => {
                          const tempTermsArray = [...termsArray];
                          tempTermsArray[index] = e.target.value;
                          setTermsArray(tempTermsArray);
                        }}
                      />
                      <IconButton onClick={() => handleRemoveRow(index)}>
                        <Remove />
                      </IconButton>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" sx={{ width: '100%' }}>
                <Button startIcon={<Add />} onClick={() => setTermsArray([...termsArray, ''])}>
                  row
                </Button>
              </Stack>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={props.closeAddTermsAndConditionsModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

function AddRemarks(props) {
  const matches = useMediaQuery('(max-width:600px)');
  const { remarks } = props;
  const [remarksArray, setRemarksArray] = useState(remarks);
  const formik = useFormik({
    initialValues: {
      remarks,
    },
    onSubmit: async () => {
      try {
        const removedEmptyRemarks = remarksArray.filter((remark) => remark !== '');
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/utils/projectSettings`, {
          remarks: removedEmptyRemarks,
        });
        if (!res.data.isError) {
          props.closeAddRemarksModal(res.data.message);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        props.getRemarks();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleRemoveRow = (indexToRemove) => {
    const updatedRemarksArray = remarksArray.filter((_, index) => index !== indexToRemove);
    setRemarksArray(updatedRemarksArray);
  };

  return (
    <Dialog open={props.open} onClose={props.closeAddRemarksModal} fullScreen={matches}>
      <DialogTitle>Remarks</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {remarksArray.map((remark, index) => (
                  <Grid item xs={12} key={index}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        fullWidth
                        multiline
                        label="Remarks"
                        value={remark}
                        onChange={(e) => {
                          const tempRemarksArray = [...remarksArray];
                          tempRemarksArray[index] = e.target.value;
                          setRemarksArray(tempRemarksArray);
                        }}
                      />
                      <IconButton onClick={() => handleRemoveRow(index)}>
                        <Remove />
                      </IconButton>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" sx={{ width: '100%' }}>
                <Button startIcon={<Add />} onClick={() => setRemarksArray([...remarksArray, ''])}>
                  row
                </Button>
              </Stack>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={props.closeAddRemarksModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

function AddExclusions(props) {
  const matches = useMediaQuery('(max-width:600px)');
  const { exclusions } = props;
  const [exclusionsArray, setExclusionsArray] = useState(exclusions);
  const formik = useFormik({
    initialValues: {
      exclusions,
    },
    onSubmit: async () => {
      try {
        const removedEmptyExclusions = exclusionsArray.filter((exclusion) => exclusion !== '');
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/utils/projectSettings`, {
          exclusions: removedEmptyExclusions,
        });
        if (!res.data.isError) {
          props.closeAddExclusionsModal(res.data.message);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        props.getExclusions();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleRemoveRow = (indexToRemove) => {
    const updatedExclusionsArray = exclusionsArray.filter((_, index) => index !== indexToRemove);
    setExclusionsArray(updatedExclusionsArray);
  };

  return (
    <Dialog open={props.open} onClose={props.closeAddExclusionsModal} fullScreen={matches}>
      <DialogTitle>Exclusions</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {exclusionsArray.map((exclusion, index) => (
                  <Grid item xs={12} key={index}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <TextField
                        fullWidth
                        multiline
                        label="Exclusions"
                        value={exclusion}
                        onChange={(e) => {
                          const tempExclusionsArray = [...exclusionsArray];
                          tempExclusionsArray[index] = e.target.value;
                          setExclusionsArray(tempExclusionsArray);
                        }}
                      />
                      <IconButton onClick={() => handleRemoveRow(index)}>
                        <Remove />
                      </IconButton>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" sx={{ width: '100%' }}>
                <Button startIcon={<Add />} onClick={() => setExclusionsArray([...exclusionsArray, ''])}>
                  row
                </Button>
              </Stack>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={props.closeAddExclusionsModal}>Cancel</Button>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

function QuotationSettings() {
  const [isLoading, setIsLoading] = useState(true);

  const [termsAndConditions, setTermsAndConditions] = useState([]);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const [termsAndConditionsExist, setTermsAndConditionsExist] = useState(false);

  const [remarks, setRemarks] = useState([]);
  const [remarksOpen, setRemarksOpen] = useState(false);
  const [remarksExist, setRemarksExist] = useState(false);

  const [exclusions, setExclusions] = useState([]);
  const [exclusionsOpen, setExclusionsOpen] = useState(false);
  const [exclusionsExist, setExclusionsExist] = useState(false);

  const getProjectSettings = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/projectSettings`)
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTermsAndConditions(res.data.data.termsAndConditions);
          setRemarks(res.data.data.remarks);
          setExclusions(res.data.data.exclusions);
          if (res.data.data.termsAndConditions) {
            setTermsAndConditionsExist(true);
          }
          if (res.data.data.remarks) {
            setRemarksExist(true);
          }
          if (res.data.data.exclusions) {
            setExclusionsExist(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProjectSettings();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <AddTermsAndConditions
            open={termsAndConditionsOpen}
            closeAddTermsAndConditionsModal={() => setTermsAndConditionsOpen(false)}
            getTermsAndConditions={getProjectSettings}
            termsAndConditions={termsAndConditions}
          />
          <AddRemarks
            open={remarksOpen}
            closeAddRemarksModal={() => setRemarksOpen(false)}
            getRemarks={getProjectSettings}
            remarks={remarks}
          />
          <AddExclusions
            open={exclusionsOpen}
            closeAddExclusionsModal={() => setExclusionsOpen(false)}
            getExclusions={getProjectSettings}
            exclusions={exclusions}
          />

          <Container maxWidth="xl">
            <Stack marginBottom={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Terms and Conditions
                </Typography>
                <IconButton onClick={() => setTermsAndConditionsOpen(true)}>
                  {termsAndConditionsExist ? <BorderColor /> : <Add />}
                </IconButton>
              </Stack>
              <Grid container spacing={2} component={Paper} sx={{ p: 2 }}>
                {termsAndConditions.map((term, index) => (
                  <Grid key={index} item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                      <Typography variant="body1" fontWeight="bold">
                        {` ${index + 1}:`}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {term}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>

            {/* remarks */}
            <Stack marginBottom={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Remarks
                </Typography>
                <IconButton onClick={() => setRemarksOpen(true)}>{remarksExist ? <BorderColor /> : <Add />}</IconButton>
              </Stack>
              <Grid container spacing={2} component={Paper} sx={{ p: 2 }}>
                {remarks.map((remark, index) => (
                  <Grid key={index} item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                      <Typography variant="body1" fontWeight="bold">
                        {` ${index + 1}:`}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {remark}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>

            {/* exclusions */}
            <Stack marginBottom={2}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                <Typography variant="h6" gutterBottom>
                  Exclusions
                </Typography>
                <IconButton onClick={() => setExclusionsOpen(true)}>
                  {exclusionsExist ? <BorderColor /> : <Add />}
                </IconButton>
              </Stack>
              <Grid container spacing={2} component={Paper} sx={{ p: 2 }}>
                {exclusions.map((exclusion, index) => (
                  <Grid key={index} item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
                      <Typography variant="body1" fontWeight="bold">
                        {` ${index + 1}:`}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {exclusion}
                      </Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Stack>
          </Container>
        </>
      )}
    </>
  );
}

export default QuotationSettings;
