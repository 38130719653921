import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import avatar from '../../assets/avatar.png';

const AddProjectAtCustomer = (props) => {
  const [managers, setManagers] = useState([]);
  const [leads, setLeads] = useState([]);
  const matches = useMediaQuery('(max-width:600px)');

  const { callApi, open, setOpen, customerId } = props;
  const quotationSchema = Yup.object().shape({
    name: Yup.string().required('Project Name is required'),
    location: Yup.string().required('Location is required'),
    customerId: Yup.string().required('Customer is required'),
    managers: Yup.array().required('Managers are required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      customerId,
      managers: '',
      lead: '',
    },
    validationSchema: quotationSchema,
    onSubmit: async () => {
      try {
        console.log(values);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/project`, values);
        console.log(res.data);
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        callApi();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getManagers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'PROJECT_MANAGER',
        },
      });
      console.log(res.data.data);
      setManagers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeads = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead/customer`, {
        params: {
          customerId,
        },
      });
      setLeads(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getManagers();
    getLeads();
  }, []);

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>{'Add Project'}</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Project Name"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Location"
                    {...getFieldProps('location')}
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="asynchronous-demo"
                    onChange={(_, value) => {
                      formik.setFieldValue('managers', value ? value.map((v) => v._id) : []);
                    }}
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={managers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Managers"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.managers && errors.managers)}
                        helperText={touched.managers && errors.managers}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 40, height: 40, cursor: 'pointer' }}
                            src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.fullname}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                              {option.role?.name?.replaceAll('_', ' ')}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                    getOptionLabel={(option) => option.fullname}
                    getOptionSelected={(option, value) => option.fullname === value.fullname}
                    options={leads}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('lead', value._id);
                      } else {
                        formik.setFieldValue('lead', '');
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Lead"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.lead && errors.lead)}
                        helperText={touched.lead && errors.lead}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction={'column'}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                            {option.fullname}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                            Title : {option.title}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                            {option.email}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                            {option.phone}
                          </Typography>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
              </Grid>

              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="primary" type="submit" loading={isSubmitting}>
                  {'Submit'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AddProjectAtCustomer;
