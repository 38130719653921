import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { FilePond, File, registerPlugin } from 'react-filepond';

import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { Add, DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import { convertToWords } from '../utils/commonFunctions';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white !important',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white !important',
  },
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInputAdornment-root': {
    color: 'white !important',
  },
  '& .MuiInputAdornment-positionEnd': {
    // Assuming the date picker icon is at the end
    '& .MuiIconButton-root': {
      color: 'white', // Change icon color to white
    },
  },
});

const AddPurchaseOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const vendorData = location.state;
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [vendors, setVendors] = useState([]);
  const [company, setCompany] = useState({});
  const [vendor, setVendor] = useState({});
  const [projects, setProjects] = useState([]);
  const [quotaions, setQuotations] = useState([]);
  const [items, setItems] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [paymentTerm, setPaymentTerm] = useState('');
  const [notes, setNotes] = useState('');
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [files, setFiles] = useState([]);

  const convertToParseFloat = (value) => parseFloat(value).toFixed(2);

  const purchaseOrderSchema = Yup.object().shape({
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
    orderDate: Yup.string().required('Order Date is required'),
    requiredDeliveryDate: Yup.string().required('Required Delivery Date is required'),
    vendorId: Yup.string().required('Supplier is required'),
  });

  const formik = useFormik({
    initialValues: {
      vendorId: '',
      projectId: '',
      quotationId: '',
      orderDate: moment().format('YYYY-MM-DD'),
      requiredDeliveryDate: '',
      items: [],
      subTotal: '',
      grandTotal: '',
      termsAndConditions: [],
      notes: [],
      paymentTerms: [],
      grandTotalInWords: '',
      referenceNumber: '',
      preparedBy: '',
      preparedByDesignation: '',
      approvedBy: '',
      approvedByDesignation: '',
      attn: '',
      attachments: [],
    },
    validationSchema: purchaseOrderSchema,
    onSubmit: async () => {
      console.log(values.paymentTerms);
      const data = new FormData();
      files.forEach((item) => {
        data.append('attachments', item.file);
      });
      data.append('vendorId', values.vendorId);
      data.append('projectId', values.projectId);
      data.append('quotationId', values.quotationId);
      data.append('orderDate', values.orderDate);
      data.append('requiredDeliveryDate', values.requiredDeliveryDate);
      data.append('subTotal', convertToParseFloat(values.subTotal));
      data.append('grandTotal', convertToParseFloat(values.grandTotal));
      data.append('grandTotalInWords', values.grandTotalInWords);
      data.append(
        'paymentTerms',
        values?.paymentTerms?.length > 0 ? JSON.stringify(values.paymentTerms) : JSON.stringify([paymentTerm])
      );
      data.append('notes', values?.notes?.length > 0 ? JSON.stringify(values.notes) : JSON.stringify([notes]));
      data.append(
        'termsAndConditions',
        values?.termsAndConditions?.length > 0
          ? JSON.stringify(values.termsAndConditions)
          : JSON.stringify([termsAndCondition])
      );
      data.append('referenceNumber', values.referenceNumber);
      data.append('preparedBy', values.preparedBy);
      data.append('preparedByDesignation', values.preparedByDesignation);
      data.append('approvedBy', values.approvedBy);
      data.append('approvedByDesignation', values.approvedByDesignation);
      data.append('attn', values.attn);
      data.append('items', JSON.stringify(values.items));

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/purchaseOrder`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getVendors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/active`);
      console.log(res.data.data, 'vendors');
      setVendors(res.data.data);
      setSourcesLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompany = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`);
      console.log(res.data.data, 'company');
      setCompany(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/accountSettings`);
      console.log(res.data.data, 'accountSettings');
      setAccountSettings(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendor = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, { params: { _id: id } });
      console.log(res.data.data);
      setVendor(res.data.data.vendor);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjects = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`);
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
        if (projectId) {
          const project = res.data.data.find((project) => project._id === projectId);
          if (project) {
            formik.setFieldValue('projectId', project._id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotations = async (projectId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/list`, {
        params: {
          projectId,
          quotationStatus: 'APPROVED',
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setQuotations(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotationItems = async (quotationId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/items/list`, {
        params: {
          id: quotationId,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setItems(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendors();
    getCompany();
    getAccountSettings();
    getProjects();
  }, []);

  useEffect(() => {
    if (values.vendorId) {
      getVendor(values.vendorId);
    }
  }, [values.vendorId]);

  useEffect(() => {
    if (values.vendorId && values.projectId) {
      getQuotations(values.projectId);
    }
  }, [values.projectId]);

  useEffect(() => {
    if (values.quotationId) {
      getQuotationItems(values.quotationId);
    }
  }, [values.quotationId]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.quantity) * Number(item.unitPrice);
      grandTotal += Number(item.total);
    });
    const grandTotalInWords = convertToWords(grandTotal);
    formik.setFieldValue('grandTotalInWords', grandTotalInWords);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
    console.log(items);
  }, [values.items]);

  const totalCalculator = (quantity, price) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    return total.toFixed(2);
  };

  return (
    <Page title="Add Purchase Order">
      <Container maxWidth="xl">
        <PageHeader title="Add Purchase Order" />
        <Card>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                  direction={'row'}
                  px={5}
                  py={3}
                  sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                >
                  {/* <Box sx={{ flex: '1' }}>Logo</Box> */}

                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2}>
                      <StyledTextField
                        label="Order Date"
                        size="small"
                        type="date"
                        //  CHANGE DATE ICON COLOR TO WHITE
                        {...getFieldProps('orderDate')}
                        error={Boolean(touched.orderDate && errors.orderDate)}
                        helperText={touched.orderDate && errors.orderDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <StyledTextField
                        label="Required Delivery Date"
                        size="small"
                        type="date"
                        {...getFieldProps('requiredDeliveryDate')}
                        error={Boolean(touched.requiredDeliveryDate && errors.requiredDeliveryDate)}
                        helperText={touched.requiredDeliveryDate && errors.requiredDeliveryDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
                <Grid container rowGap={2} mt={5}>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '120px' }}>
                        Supplier:
                      </Typography>
                      {!vendorData ? (
                        <Autocomplete
                          id="asynchronous-demo"
                          isOptionEqualToValue={(option, value) => option.companyName === value.companyName}
                          getOptionLabel={(option) => option.companyName}
                          getOptionSelected={(option, value) => option.companyName === value.companyName}
                          options={vendors}
                          onChange={(_, value) => {
                            if (value) {
                              formik.setFieldValue('vendorId', value._id);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              sx={{
                                minWidth: '250px',
                              }}
                              InputProps={{
                                ...params.InputProps,
                              }}
                              error={Boolean(touched.vendorId && errors.vendorId)}
                              helperText={touched.vendorId && errors.vendorId}
                            />
                          )}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Stack direction="row" spacing={2} alignItems="center">
                                <Avatar
                                  sx={{ width: 35, height: 35, cursor: 'pointer' }}
                                  src={
                                    (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) ||
                                    'https://www.w3schools.com/howto/img_avatar2.png'
                                  }
                                />
                                <Stack direction={'column'}>
                                  <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                    {option.companyName}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                    {option.contactPerson}
                                  </Typography>
                                  <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                    {option.phone1}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </li>
                          )}
                        />
                      ) : (
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 35, height: 35, cursor: 'pointer' }}
                            src={
                              (vendorData?.profilePic && process.env.REACT_APP_API_URL + vendorData.profilePic) ||
                              'https://www.w3schools.com/howto/img_avatar2.png'
                            }
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', color: 'white' }}>
                              {vendorData?.companyName}
                            </Typography>
                            <Typography variant="caption" sx={{ lineHeight: '1', color: 'white' }}>
                              {vendorData?.phone}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '120px' }}>
                        Project:
                      </Typography>
                      <Autocomplete
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.name === value.name}
                        options={projects}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('projectId', value._id);
                          } else {
                            formik.setFieldValue('projectId', '');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            sx={{
                              minWidth: '250px',
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction={'column'}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}
                              >
                                {option.name}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '120px' }}>
                        Quotation :
                      </Typography>
                      <Autocomplete
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.quotationId === value.quotationId}
                        getOptionLabel={(option) => option.quotationId}
                        getOptionSelected={(option, value) => option.quotationId === value.quotationId}
                        options={quotaions}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('quotationId', value._id);
                          } else {
                            formik.setFieldValue('quotationId', '');
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              width: '180px',
                            }}
                            size="small"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction={'column'}>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                {option.quotationId}
                              </Typography>
                            </Stack>
                          </li>
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '120px' }}>
                        Reference Number:
                      </Typography>
                      <TextField
                        size="small"
                        {...getFieldProps('referenceNumber')}
                        error={Boolean(touched.referenceNumber && errors.referenceNumber)}
                        helperText={touched.referenceNumber && errors.referenceNumber}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Supplier
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{vendor.companyName}</Typography>
                        <Typography>{vendor.contactPerson}</Typography>
                        <Typography>{vendor.companyAddress}</Typography>
                        <Typography>{vendor.email}</Typography>
                        <Typography>{vendor.phone1}</Typography>
                        {vendor?.trn && <Typography>{`TRN: ${vendor?.trn}`}</Typography>}
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                          <Typography>Attn:</Typography>
                          <TextField
                            size="small"
                            {...getFieldProps('attn')}
                            error={Boolean(touched.attn && errors.attn)}
                            helperText={touched.attn && errors.attn}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Invoice To
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{company.companyName}</Typography>
                        <Typography>{company.address}</Typography>
                        <Typography>{company.pin}</Typography>
                        <Typography>{company.phone}</Typography>
                        <Typography>{company.email}</Typography>
                        <Typography>{`TRN: ${accountSettings?.trn}`}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack p={5}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Sl No</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Unit Price</TableCell>
                              <TableCell>Total</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                  <Stack justifyContent={'start'} sx={{ height: '100%' }}>
                                    <Autocomplete
                                      sx={{
                                        minWidth: '150px',
                                      }}
                                      id="asynchronous-demo"
                                      freeSolo // allow free input
                                      onInputChange={(e, value) => {
                                        const newItems = [...values.items];
                                        newItems[index] = { ...newItems[index], name: value };
                                        formik.setFieldValue('items', newItems);
                                      }}
                                      isOptionEqualToValue={(option, value) => option.name === value.name}
                                      getOptionLabel={(option) => option.name}
                                      getOptionSelected={(option, value) => option.name === value.name}
                                      options={items}
                                      onChange={(_, value) => {
                                        if (value) {
                                          const newItems = [...values.items];
                                          newItems[index] = {
                                            ...newItems[index],
                                            name: value.name,
                                            unitPrice: value.unitPrice || 0,
                                            quantity: value.quantity || 0,
                                            total: totalCalculator(value.quantity || 0, value.unitPrice || 0),
                                          };
                                          formik.setFieldValue('items', newItems);
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          InputProps={{
                                            ...params.InputProps,
                                          }}
                                        />
                                      )}
                                      renderOption={(props, option) => (
                                        <li {...props}>
                                          <Stack direction={'column'}>
                                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                              {option.name}
                                            </Typography>
                                            <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                              {option.unitPrice} AED
                                            </Typography>
                                          </Stack>
                                        </li>
                                      )}
                                    />
                                  </Stack>
                                </TableCell>
                                <TableCell sx={{ width: '100px' }}>
                                  <TextField
                                    disabled={!item.name}
                                    fullWidth
                                    type="number"
                                    value={item.quantity || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        quantity: Number(e.target.value),
                                        total: totalCalculator(Number(e.target.value), item.unitPrice || 0),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: '120px',
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    type="number"
                                    value={item.unitPrice || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        unitPrice: Number(e.target.value),
                                        total: totalCalculator(item.quantity || 0, Number(e.target.value)),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Typography>{item.quantity * item.unitPrice}</Typography>
                                </TableCell>

                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      const newItems = [...values.items];
                                      newItems.splice(index, 1);
                                      formik.setFieldValue('items', newItems);
                                    }}
                                    variant="outlined"
                                    color="error"
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newItems = [...values.items];
                            newItems.push({
                              name: '',
                              unitPrice: 0,
                              quantity: 0,
                              total: 0,
                            });
                            formik.setFieldValue('items', newItems);
                          }}
                        >
                          Add Item
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
                  {/* <Box sx={{ flex: '1' }}></Box> */}
                  <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
                    {/* <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Sub Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.subTotal && parseFloat(values.subTotal).toFixed(2)}
                      </Typography>
                    </Stack> */}
                    <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
                      <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
                      <Typography sx={{ fontWeight: 600 }}>
                        {values.grandTotal && parseFloat(values.grandTotal).toFixed(2)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack px={5} pt={5} direction={'row'} spacing={2} justifyContent={'end'}>
                  <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{values?.grandTotalInWords}</Typography>
                </Stack>
                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Payment Terms
                  </Typography>
                  {values?.paymentTerms?.map((term, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${term}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newPaymentTerms = [...values.paymentTerms];
                          newPaymentTerms.splice(index, 1);
                          formik.setFieldValue('paymentTerms', newPaymentTerms);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField
                      label="Payment Term"
                      fullWidth
                      value={paymentTerm}
                      onChange={(e) => setPaymentTerm(e.target.value)}
                    />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('paymentTerms', [...values.paymentTerms, paymentTerm]);
                        setPaymentTerm('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>
                {/*  terms and conditions */}
                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Terms and Conditions
                  </Typography>
                  {values?.termsAndConditions?.map((term, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${term}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newTermsAndConditions = [...values.termsAndConditions];
                          newTermsAndConditions.splice(index, 1);
                          formik.setFieldValue('termsAndConditions', newTermsAndConditions);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField
                      label="Terms and Condition"
                      fullWidth
                      value={termsAndCondition}
                      onChange={(e) => setTermsAndCondition(e.target.value)}
                    />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('termsAndConditions', [...values.termsAndConditions, termsAndCondition]);
                        setTermsAndCondition('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>

                <Stack px={5} pt={5}>
                  <Typography sx={{ fontWeight: 600 }} gutterBottom>
                    Notes
                  </Typography>
                  {values?.notes?.map((note, index) => (
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                      key={index}
                      justifyContent={'space-between'}
                    >
                      <Typography>{`${index + 1}. ${note}`}</Typography>
                      <IconButton
                        onClick={() => {
                          const newNotes = [...values.notes];
                          newNotes.splice(index, 1);
                          formik.setFieldValue('notes', newNotes);
                        }}
                      >
                        <DeleteForever />
                      </IconButton>
                    </Stack>
                  ))}
                  <Stack direction={'row'} spacing={2} mt={2}>
                    <TextField label="Note" fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
                    <IconButton
                      onClick={() => {
                        formik.setFieldValue('notes', [...values.notes, notes]);
                        setNotes('');
                      }}
                    >
                      <Add />
                    </IconButton>
                  </Stack>
                </Stack>

                {/*  prepared by and approved By */}
                <Grid container rowGap={2} my={10}>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                          Prepared By:
                        </Typography>
                        <TextField
                          label="Prepared By"
                          size="small"
                          {...getFieldProps('preparedBy')}
                          error={Boolean(touched.preparedBy && errors.preparedBy)}
                          helperText={touched.preparedBy && errors.preparedBy}
                        />
                      </Stack>
                      <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                          Prepared By Designation:
                        </Typography>
                        <TextField
                          label="Prepared By Designation"
                          size="small"
                          {...getFieldProps('preparedByDesignation')}
                          error={Boolean(touched.preparedByDesignation && errors.preparedByDesignation)}
                          helperText={touched.preparedByDesignation && errors.preparedByDesignation}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={2}>
                      <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                          Approved By:
                        </Typography>
                        <TextField
                          label="Approved By"
                          size="small"
                          {...getFieldProps('approvedBy')}
                          error={Boolean(touched.approvedBy && errors.approvedBy)}
                          helperText={touched.approvedBy && errors.approvedBy}
                        />
                      </Stack>
                      <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                          Approved By Designation:
                        </Typography>
                        <TextField
                          label="Approved By Designation"
                          size="small"
                          {...getFieldProps('approvedByDesignation')}
                          error={Boolean(touched.approvedByDesignation && errors.approvedByDesignation)}
                          helperText={touched.approvedByDesignation && errors.approvedByDesignation}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>

                <Grid container justifyContent={'center'}>
                  <Grid item xs={10} mt={2}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={10}
                      credits={false}
                      // server="/api"
                      name="files"
                      labelIdle="Drag & Drop images"
                    />
                  </Grid>
                </Grid>
                <Stack p={5} sx={{ textAlign: 'right' }} justifyContent={'end'} spacing={5} direction={'row'}>
                  <Button type="button" variant="contained" onClick={() => resetForm()}>
                    Reset
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AddPurchaseOrder;
