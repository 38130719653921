import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField, Button, Stack, MenuItem, Grid, Card, Collapse, IconButton, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ applyFilters, filterValues }) => {
  const [open, setOpen] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      transactionName: filterValues.transactionName,
      transactionType: filterValues.transactionType,
      paymentReference: filterValues.paymentReference,
      paymentMode: filterValues.paymentMode,
      ledgerId: filterValues.ledgerId,
      employeeId: filterValues.employeeId,
      customerId: filterValues.customerId,
      vendorId: filterValues.vendorId,
      billingId: filterValues.billingId,
      purchaseId: filterValues.purchaseId,
      salaryId: filterValues.salaryId,
      fromDate: filterValues.fromDate,
      toDate: filterValues.toDate,
      _id: filterValues._id,
      paymentEndDate: filterValues.paymentEndDate,
      paymentStartDate: filterValues.paymentStartDate,
      txnEndDate: filterValues.txnEndDate,
      txnStartDate: filterValues.txnStartDate,
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="transactionName"
                    name="transactionName"
                    label="Transaction Name"
                    {...getFieldProps('transactionName')}
                    value={values.transactionName}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    select
                    id="transactionType"
                    name="transactionType"
                    label="Transaction Type"
                    {...getFieldProps('transactionType')}
                    value={values.transactionType}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="CREDIT">Credit</MenuItem>
                    <MenuItem value="DEBIT">Debit</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="paymentReference"
                    name="paymentReference"
                    label="Payment Reference"
                    {...getFieldProps('paymentReference')}
                    value={values.paymentReference}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    label="Payment Mode"
                    fullWidth
                    sx={{ width: '100%' }}
                    {...getFieldProps('paymentMode')}
                    value={values.paymentMode}
                  >
                    <MenuItem value="BANK_TRANSFER">BANK TRANSFER</MenuItem>
                    <MenuItem value="CASH">CASH</MenuItem>
                    <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                    <MenuItem value="CARD">CARD</MenuItem>
                    <MenuItem value="OTHER">OTHER</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="ledgerId"
                    name="ledgerId"
                    label="Ledger ID"
                    {...getFieldProps('ledgerId')}
                    value={values.ledgerId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="employeeId"
                    name="employeeId"
                    label="Employee ID"
                    {...getFieldProps('employeeId')}
                    value={values.employeeId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="customerId"
                    name="customerId"
                    label="Customer ID"
                    {...getFieldProps('customerId')}
                    value={values.customerId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="vendorId"
                    name="vendorId"
                    label="Supplier ID"
                    {...getFieldProps('vendorId')}
                    value={values.vendorId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="billingId"
                    name="billingId"
                    label="Invoice ID"
                    {...getFieldProps('billingId')}
                    value={values.billingId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="purchaseId"
                    name="purchaseId"
                    label="Purchase ID"
                    {...getFieldProps('purchaseId')}
                    value={values.purchaseId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="salaryId"
                    name="salaryId"
                    label="Salary ID"
                    {...getFieldProps('salaryId')}
                    value={values.salaryId}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="_id"
                    name="_id"
                    label="Transaction ID"
                    {...getFieldProps('_id')}
                    value={values._id}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="txnStartDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="txnStartDate"
                    fullWidth
                    label="Txn Start Date"
                    {...getFieldProps('txnStartDate')}
                    value={values.txnStartDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="txnEndDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="txnEndDate"
                    fullWidth
                    label="Txn End Date"
                    {...getFieldProps('txnEndDate')}
                    value={values.txnEndDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="paymentStartDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="paymentStartDate"
                    fullWidth
                    label="Payment Start Date"
                    {...getFieldProps('paymentStartDate')}
                    value={values.paymentStartDate}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="paymentEndDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="paymentEndDate"
                    fullWidth
                    label="Payment End Date"
                    {...getFieldProps('paymentEndDate')}
                    value={values.paymentEndDate}
                  />
                </Grid>
                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          transactionName: '',
                          transactionType: '',
                          paymentReference: '',
                          paymentMode: '',
                          ledgerId: '',
                          employeeId: '',
                          customerId: '',
                          vendorId: '',
                          billingId: '',
                          purchaseId: '',
                          salaryId: '',
                          fromDate: '',
                          toDate: '',
                          _id: '',
                          paymentEndDate: '',
                          paymentStartDate: '',
                          txnEndDate: '',
                          txnStartDate: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>{' '}
      </Collapse>
    </Box>
  );
};

export default Filter;
