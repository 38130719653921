import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
  Autocomplete,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Vendor';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/VendorStatusChange';

import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import { StyledTableRow } from '../theme/styledComponents';
import SupplierProfileAvatar from '../components/smallComponents/SupplierProfileAvatar';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function Vendors() {
  const matches = useMediaQuery('(max-width:600px)');
  const [vendorData, setVendorData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [vendorCount, setVendorCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [vendorTypes, setVendorTypes] = useState([]);

  const getVendorsAccess = hasAccessOf('general.vendor.r');
  const writeVendorsAccess = hasAccessOf('general.vendor.w');
  const createVendorsAccess = hasAccessOf('general.vendor.c');

  const vendorSchema = Yup.object().shape({
    companyName: Yup.string().required('Company Name is required'),
    phone1: Yup.string().required('Phone 1 is required'),
    vendorTypes: Yup.array().required('Vendor Type is required'),
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
      contactPerson: '',
      email: '',
      phone1: '',
      phone2: '',
      companyAddress: '',
      isBlocked: false,
      notes: '',
      vendorTypes: [],
    },
    validationSchema: vendorSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/vendor`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (!res.data.isError) {
          closeAddVendorModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getVendors(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleStatusChange1 = (event, item, index) => {
    console.log('item', item);
    if (`${item.isPopular}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getVendors(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddVendorModal = () => {
    setOpen(true);
  };

  const openExcelUploadModal = () => {
    // setOpen1(true);
  };
  const closeAddVendorModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getVendors(temp);
  };

  const getVendors = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/vendor/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setVendorCount(res.data.data.maxRecords);
          setVendorData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getVendorsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/vendor/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setVendorCount(res.data.data.maxRecords);
          setVendorData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getVendorType = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendorType/list`);
      if (!res.data.isError) {
        setVendorTypes(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(vendorCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getVendors(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, companyName: e.target.value };
    setOptions(temp);
    setPages(0);
    getVendorsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    const temp = { page: 0, size };
    if (filter._id) {
      temp._id = filter._id;
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.contactPerson) {
      temp.contactPerson = filter.contactPerson;
    }
    if (filter.companyName) {
      temp.companyName = filter.companyName;
    }
    if (filter.email) {
      temp.email = filter.email;
    }
    if (filter.phone1) {
      temp.phone1 = filter.phone1;
    }
    if (filter.phone2) {
      temp.phone2 = filter.phone2;
    }
    if (filter.companyAddress) {
      temp.companyAddress = filter.companyAddress;
    }
    if (filter.vendorType) {
      temp.vendorType = filter.vendorType;
    }

    setOptions(temp);
    setPages(0);
    getVendors(temp);
  };
  useEffect(() => {
    if (getVendorsAccess) {
      getVendors(options);
      getVendorType();
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (getVendorsAccess) {
      const temp = { ...options, size, page: 0 };
      setPages(0);
      setOptions(temp);
      getVendors(temp);
    } else {
      setIsLoading(false);
    }
  }, [size]);

  return (
    <>
      <Page title="Suppliers">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add vendor Dialog */}
            <Dialog open={open} onClose={closeAddVendorModal} fullScreen={matches}>
              <DialogTitle>Add Supplier</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Company Name"
                            required
                            {...getFieldProps('companyName')}
                            error={Boolean(touched.companyName && errors.companyName)}
                            helperText={touched.companyName && errors.companyName}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={vendorTypes}
                            getOptionLabel={(option) => option?.name?.replaceAll('_', ' ')}
                            onChange={(e, value) => {
                              formik.setFieldValue('vendorTypes', value ? value.map((v) => v._id) : []);
                            }}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label="Supplier Types"
                                placeholder="Supplier Types"
                                error={Boolean(touched.vendorTypes && errors.vendorTypes)}
                                helperText={touched.vendorTypes && errors.vendorTypes}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Contact Person" {...getFieldProps('contactPerson')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Email" {...getFieldProps('email')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            required
                            label="Phone 1"
                            {...getFieldProps('phone1')}
                            error={Boolean(touched.phone1 && errors.phone1)}
                            helperText={touched.phone1 && errors.phone1}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth label="Phone 2" {...getFieldProps('phone2')} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField fullWidth multiline label="Company Address" {...getFieldProps('companyAddress')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField fullWidth select label="Status" {...getFieldProps('isBlocked')}>
                            <MenuItem value={'true'}>Blocked</MenuItem>
                            <MenuItem value={'false'}>Active</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            type="file"
                            fullWidth
                            label="Profile Pic"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                              formik.setFieldValue('image', e.target.files[0]);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField fullWidth multiline label="Notes" {...getFieldProps('notes')} />
                        </Grid>
                      </Grid>

                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddVendorModal}>Cancel</Button>
                        <LoadingButton color="primary" type="submit" loading={isSubmitting} variant="contained">
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Box>
            </Dialog>
            {/* Add vendor dialog end */}
            <Container maxWidth="xl">
              <PageHeader title="Suppliers" />
              {getVendorsAccess && <Filter applyFilters={applyFilters} filterValues={options} />}

              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search vendor..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    {createVendorsAccess && (
                      <Button startIcon={<AddIcon />} variant="contained" onClick={openAddVendorModal}>
                        Add Supplier
                      </Button>
                    )}
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCellCustom>Sl No</TableCellCustom>
                          <TableCellCustom>Company</TableCellCustom>
                          <TableCellCustom>Type</TableCellCustom>
                          <TableCellCustom>Contact Person</TableCellCustom>
                          <TableCellCustom>Phone </TableCellCustom>
                          <TableCellCustom>ID</TableCellCustom>
                          <TableCellCustom>Status</TableCellCustom>
                          <TableCellCustom>Action</TableCellCustom>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vendorData?.map((item, id) => (
                          <StyledTableRow key={id}>
                            <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                            <TableCellCustom>
                              <SupplierProfileAvatar
                                profilePic={item?.profilePic}
                                firstLine={item?.companyName}
                                secondLine={item?.email}
                                id={item?._id}
                              />
                            </TableCellCustom>
                            <TableCell>
                              <Stack spacing={1}>
                                {item?.vendorTypes?.map((v, i) => (
                                  <Typography variant="p" key={i}>
                                    {v.name.replaceAll('_', ' ')}
                                  </Typography>
                                ))}
                              </Stack>
                            </TableCell>
                            <TableCellCustom>{item.contactPerson}</TableCellCustom>
                            <TableCellCustom sx={{ minWidth: '110px', maxWidth: '120px' }}>
                              <Stack>
                                <Typography variant="p">{item.phone1}</Typography>
                                <Typography variant="p">{item.phone2}</Typography>
                              </Stack>
                            </TableCellCustom>
                            <TableCellCustom>
                              <CopyToClipboard text={item?._id} onCopy={() => toast.success('Supplier ID Copied')}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={0.5}
                                  justifyContent="flex-start"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <ContentCopy color="primary" />
                                  <Typography color="primary.main" variant="p">
                                    Copy ID
                                  </Typography>
                                </Stack>
                              </CopyToClipboard>
                            </TableCellCustom>
                            <TableCellCustom>
                              {writeVendorsAccess ? (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={item.isBlocked}
                                    onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    <MenuItem value={'false'}>🟢 Active</MenuItem>
                                    <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                                  </Select>
                                  {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                                </FormControl>
                              ) : (
                                <Chip
                                  label={item.isBlocked ? 'Blocked' : 'Active'}
                                  color={item.isBlocked ? 'error' : 'success'}
                                  size="small"
                                />
                              )}
                            </TableCellCustom>
                            <TableCellCustom>
                              <Box>
                                <Link to={`/dashboard/general/vendors/view-vendor/${item._id}`}>
                                  <Button
                                    variant="contained"
                                    startIcon={<RemoveRedEyeIcon />}
                                    size="small"
                                    color="viewButton"
                                  >
                                    View
                                  </Button>
                                </Link>
                              </Box>
                            </TableCellCustom>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={vendorCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
