import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { AppWidgetSummary, BarGraph, HorizontalBarGraph } from '../sections/@dashboard/app';
import hasAccessOf from '../store/hasAccessOf';

// ----------------------------------------------------------------------

export default function GeneralAnalyticsComponent() {
  const generalSummaryAccess = hasAccessOf('dashboard.generalSummary.r');

  const [counts, setCounts] = useState(null);

  const getCounts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/general/summary`);
      setCounts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (generalSummaryAccess) {
      getCounts();
    }
  }, []);

  return (
    <Grid container spacing={3} rowGap={8}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
         
          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Sub Admins"
              total={counts?.subAdminCount}
              color="macinsIndigo"
              // icon={'ant-design:android-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Customers"
              total={counts?.customerCount}
              color="macinsIndigo"
              // icon={'ant-design:android-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Suppliers"
              total={counts?.vendorCount}
              color="macinsIndigo"
              // icon={'ant-design:apple-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Project Managers"
              total={counts?.pmCount}
              color="macinsIndigo"
              // icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Project Engineers"
              total={counts?.peCount}
              color="macinsIndigo"
              // icon={'ant-design:windows-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Site Engineers"
              total={counts?.siteEngineerCount}
              color="macinsIndigo"
              // icon={'ant-design:windows-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Ware House Managers"
              total={counts?.wareHouseManagerCount}
              color="macinsIndigo"
              // icon={'ant-design:windows-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Accountant Managers"
              total={counts?.accountManagerCount}
              color="macinsIndigo"
              // icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Sales Staffs"
              total={counts?.ssCount}
              color="macinsIndigo"
              // icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Supervisors"
              total={counts?.supervisorCount}
              color="macinsIndigo"
              // icon={'ant-design:bug-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
            <AppWidgetSummary
              title="Workers"
              total={counts?.workerCount}
              color="macinsIndigo"
              // icon={'ant-design:bug-filled'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
