import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
// material
import TabCard from '../smallComponents/TabCard';
import hasAccessOf from '../../store/hasAccessOf';

// components

export default function WorkerDetailPageTabs({ worker }) {
  const [projectCount, setProjectCount] = useState(0);

  const projectReadAccess = hasAccessOf('project.project.r');
  const workerReadAccess = hasAccessOf('project.worker.r');

  const getProjectCount = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/count/member`, {
        params: {
          id: worker._id,
          role: 'WORKER',
        },
      });
      console.log('projectCount', res.data);
      setProjectCount(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProjectCount();
  }, [worker]);

  return (
    <>
      <>
        <Grid container spacing={2}>
          {projectReadAccess && (
            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                count={projectCount}
                title="Projects"
                color="greenish"
                icon={'icons8:parallel-tasks'}
                navigate={`/dashboard/general/employees/worker/projects?workerId=${worker._id}`}
              />
            </Grid>
          )}
          {workerReadAccess && (
            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                // count={projectCount}
                title="Work History"
                color="balanced"
                icon={'iconamoon:history-fill'}
                navigate={`/dashboard/general/employees/worker/workEntry?workerId=${worker._id}`}
              />
            </Grid>
          )}
        </Grid>
      </>
    </>
  );
}
