import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { Stack, Button, TextField, Grid } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import CheckBoxGroup from './smallComponents/CheckBoxGroup';

export default function EmployeeEditForm({ employee, getEmployee, setEditMode }) {
  const [dashboard, setDashboard] = useState({
    generalSummary: { c: false, r: false, w: false, d: false },
    projectSummary: { c: false, r: false, w: false, d: false },
    leadSummary: { c: false, r: true, w: false, d: false },
  });

  const [general, setGeneral] = useState({
    settings: { c: false, r: false, w: false, d: false },
    employee: { c: false, r: false, w: false, d: false },
    customer: { c: false, r: false, w: false, d: false },
    vendor: { c: false, r: false, w: false, d: false },
    admin: { c: false, r: false, w: false, d: false },
  });

  const [profile, setProfile] = useState({
    leadManager: { c: false, r: true, w: false, d: false },
    telecaller: { c: false, r: true, w: false, d: false },
    projectManager: { c: false, r: false, w: false, d: false },
    projectEngineer: { c: false, r: false, w: false, d: false },
    accountant: { c: false, r: false, w: false, d: false },
    warehouseManager: { c: false, r: false, w: false, d: false },
    subAdmin: { c: false, r: false, w: false, d: false },
    worker: { c: false, r: false, w: false, d: false },
  });

  const [crm, setCrm] = useState({
    settings: { c: false, r: false, w: false, d: false },
    lead: { c: true, r: true, w: true, d: false },
    manager: { c: false, r: false, w: false, d: false },
    teleCaller: { c: false, r: true, w: false, d: false },
  });

  const [warehouse, setWarehouse] = useState({
    tool: { c: false, r: false, w: false, d: false },
    material: { c: false, r: false, w: false, d: false },
    stock: { c: false, r: false, w: false, d: false },
  });

  const [project, setProject] = useState({
    project: { c: false, r: false, w: false, d: false },
    qto: { c: false, r: false, w: false, d: false },
    boq: { c: false, r: false, w: false, d: false },
    quotation: { c: false, r: false, w: false, d: false },
    worker: { c: false, r: false, w: false, d: false },
    material: { c: false, r: false, w: false, d: false },
    tool: { c: false, r: false, w: false, d: false },
  });

  const [showAccessList, setShowAccessList] = useState(false);

  const memberSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must be at most 20 characters'),
  });

  const formik = useFormik({
    initialValues: {
      id: employee._id,
      fullname: employee.fullname,
      email: employee.email,
      password: '',
      profilePic: '',
      phone: employee.phone,
      description: employee.description,
    },
    validationSchema: memberSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_API_URL}/member/employee`,
          { ...values },
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        if (!res.data.isError) {
          setEditMode(false);
          getEmployee();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getEmployee();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (employee.accessList) {
      setGeneral(employee.accessList.general);
      setCrm(employee.accessList.crm);
      setProject(employee.accessList.project);
      setWarehouse(employee.accessList.warehouse);
    }
  }, [employee]);
  console.log(employee);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Fullname"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="file"
              InputLabelProps={{ shrink: true }}
              label="Profile Picture"
              onChange={(e) => formik.setFieldValue('profilePic', e.target.files[0])}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Button
              onClick={() => setShowAccessList(!showAccessList)}
              endIcon={showAccessList ? <ExpandLess /> : <ExpandMore />}
            >
              {showAccessList ? 'Hide Access List' : 'Show Access List'}
            </Button>
          </Grid> */}
          {/* {showAccessList && (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <CheckBoxGroup name="Profile" accessList={profile} setAccessList={setProfile} />
                <CheckBoxGroup name="General" accessList={general} setAccessList={setGeneral} />
                <CheckBoxGroup name="CRM" accessList={crm} setAccessList={setCrm} />
                <CheckBoxGroup name="Project" accessList={project} setAccessList={setProject} />
                <CheckBoxGroup name="Warehouse" accessList={warehouse} setAccessList={setWarehouse} />
                <CheckBoxGroup name="Dashboard" accessList={dashboard} setAccessList={setDashboard} />
              </Grid>
            </Grid>
          )} */}
        </Grid>
        <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
          <Button variant="contained" onClick={() => setEditMode(false)}>
            Cancel
          </Button>
          <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
