import { LoadingButton } from '@mui/lab';
import moment from 'moment';

import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { FilePond, File, registerPlugin } from 'react-filepond';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

const ReduceToolStock = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [tools, setTools] = useState([]);
  const [toolsLoading, setToolsLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);

  const convertToParseFloat = (value) => parseFloat(value).toFixed(5);

  const purchaseSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
  });

  const formik = useFormik({
    initialValues: {
      invoiceDate: moment().format('YYYY-MM-DD'),
      subTotal: '',
      grandTotal: '',
      items: [],
      attachments: [],
      remarks: '',
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      const data = new FormData();
      if (files?.length > 0) {
        files.forEach((item) => {
          data.append('attachments', item.file);
        });
      }
      data.append('invoiceDate', values.invoiceDate);
      data.append('subTotal', convertToParseFloat(values.subTotal));
      // eslint-disable-next-line no-restricted-globals
      data.append('grandTotal', convertToParseFloat(values.grandTotal));
      data.append('items', JSON.stringify(values.items));
      data.append('remarks', values.remarks);

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/toolTransaction/reduceStock`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate('/dashboard/warehouse/stock?tab=2');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getTools = async () => {
    try {
      setToolsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tool/active`);
      console.log(res.data.data, 'tools');
      setTools(res.data.data);
      setToolsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const totalCalculator = (quantity, price) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    return Number(total.toFixed(2));
  };

  useEffect(() => {
    getTools();
  }, []);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.total);
    });
    grandTotal = subTotal;
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
  }, [values.items]);

  useEffect(() => {
    const attachments = [];
    files.forEach((item) => {
      attachments.push(item.file);
    });
    console.log(attachments);
    formik.setFieldValue('attachments', attachments);
  }, [files]);

  return (
    <Page title="Add Purchase">
      <Container maxWidth="xl">
        <PageHeader title="Reduce Tool Stock" />
        <Card>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                  direction={'row'}
                  px={5}
                  py={3}
                  sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                >
                  {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2}>
                      <StyledTextField
                        label="Date"
                        size="small"
                        type="date"
                        {...getFieldProps('invoiceDate')}
                        error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                        helperText={touched.invoiceDate && errors.invoiceDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>

                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack p={5}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Item</TableCell>
                              {/* <TableCell>Unit Price</TableCell> */}
                              <TableCell>Quantity</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Autocomplete
                                    fullWidth
                                    sx={{
                                      minWidth: '250px',
                                    }}
                                    id="asynchronous-demo"
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => `${option.name} - ${option.unitPrice}`}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    options={tools}
                                    onChange={(_, value) => {
                                      if (value) {
                                        const newItems = [...values.items];
                                        newItems[index] = {
                                          ...newItems[index],
                                          toolId: value._id,
                                          unitPrice: value.unitPrice,
                                          total: totalCalculator(item.quantity, value.unitPrice),
                                        };
                                        formik.setFieldValue('items', newItems);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Tools"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li {...props}>
                                        <Stack direction={'column'}>
                                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                            {option.name}
                                          </Typography>
                                          <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                            {option.unitPrice}
                                          </Typography>
                                        </Stack>
                                      </li>
                                    )}
                                  />
                                </TableCell>
                                <TableCell sx={{ width: '120px' }}>
                                  <TextField
                                    fullWidth
                                    type="number"
                                    value={item.quantity || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        quantity: Number(e.target.value),
                                        total: totalCalculator(Number(e.target.value), item.unitPrice, item.tax),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      const newItems = [...values.items];
                                      newItems.splice(index, 1);
                                      formik.setFieldValue('items', newItems);
                                    }}
                                    variant="outlined"
                                    color="error"
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newItems = [...values.items];
                            newItems.push({
                              toolId: '',
                              unitPrice: 0,
                              quantity: 0,
                              total: 0,
                            });
                            formik.setFieldValue('items', newItems);
                          }}
                        >
                          Add Item
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {/* remarks */}
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Remarks
                      </Typography>
                      <Stack paddingLeft={4}>
                        <TextField
                          multiline
                          rows={4}
                          fullWidth
                          {...getFieldProps('remarks')}
                          error={Boolean(touched.remarks && errors.remarks)}
                          helperText={touched.remarks && errors.remarks}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
                  {/* <Stack justifyContent="center" spacing={2}>
                    <Stack direction={'row'} spacing={1}>
                      <TextField label="Sub Total" size="small" type="number" value={values.subTotal} />
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                      <TextField label="Grand Total" size="small" type="number" value={values.grandTotal} />
                    </Stack>
                  </Stack> */}
                </Stack>
                <Grid container justifyContent={'center'}>
                  <Grid item xs={10} mt={2}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={10}
                      credits={false}
                      // server="/api"
                      name="files"
                      labelIdle="Drag & Drop files"
                    />
                  </Grid>
                </Grid>
                <Box p={5} sx={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button type="button" variant="contained" sx={{ marginLeft: 2 }} onClick={() => resetForm()}>
                    Reset
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default ReduceToolStock;
