// import * as React from 'react';
// import Menu from '@mui/material/Menu';
// import { Formik, Form } from 'formik';
// import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
// import Iconify from '../Iconify';

// const Filter = ({ toggleFilter, applyFilters }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (
//     <>
//       <div>
//         <Box style={{ display: 'flex', justifyContent: 'end' }}>
//           <IconButton onClick={handleClick}>
//             <Iconify icon="ic:round-filter-list" />
//             <Typography variant="h6"> Filter</Typography>
//           </IconButton>

//           <Menu
//             id="basic-menu"
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//             MenuListProps={{
//               'aria-labelledby': 'basic-button',
//             }}
//           >
//             <Formik
//               initialValues={{
//                 transactionType: '',
//                 ledgerId: '',
//                 paymentStartDate: '',
//                 paymentEndDate: '',
//                 txnStartDate: '',
//                 txnEndDate: '',
//                 paymentMode: '',
//               }}
//               onSubmit={async (values) => {
//                 applyFilters(values);
//               }}
//             >
//               {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
//                 <Form style={{ padding: '10px' }}>
//                   <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         fullWidth
//                         id="ledgerId"
//                         name="ledgerId"
//                         label="Ledger ID"
//                         value={values.ledgerId}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         id="transactionType"
//                         name="transactionType"
//                         fullWidth
//                         label="Transaction Type"
//                         value={values.transactionType}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         type="date"
//                         id="paymentStartDate"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         name="paymentStartDate"
//                         fullWidth
//                         label="Payment Start Date"
//                         value={values.paymentStartDate}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         type="date"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         id="paymentEndDate"
//                         name="paymentEndDate"
//                         fullWidth
//                         label="Payment End Date"
//                         value={values.paymentEndDate}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         type="date"
//                         id="txnStartDate"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         name="txnStartDate"
//                         fullWidth
//                         label="Transaction Start Date"
//                         value={values.txnStartDate}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         type="date"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         id="txnEndDate"
//                         name="txnEndDate"
//                         fullWidth
//                         label="Transaction End Date"
//                         value={values.txnEndDate}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         select
//                         label="Payment Mode"
//                         id="paymentMode"
//                         name="paymentMode"
//                         value={values.paymentMode}
//                         onChange={handleChange}
//                         fullWidth
//                       >
//                         <MenuItem value="BANK_TRANSFER">BANK TRANSFER</MenuItem>
//                         <MenuItem value="CASH">CASH</MenuItem>
//                         <MenuItem value="CHEQUE">CHEQUE</MenuItem>
//                         <MenuItem value="CARD">CARD</MenuItem>
//                         <MenuItem value="OTHER">OTHER</MenuItem>
//                       </TextField>
//                     </Stack>
//                     <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
//                       Filter
//                     </Button>
//                   </Stack>
//                 </Form>
//               )}
//             </Formik>
//           </Menu>
//         </Box>
//       </div>
//     </>
//   );
// };

// export default Filter;

import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { TextField, Button, Stack, MenuItem, Grid, Card, Collapse, IconButton, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters }) => {
  const [open, setOpen] = React.useState(false);
  const [memberRoles, setMemberRoles] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      transactionType: filterValues.transactionType,
      ledgerId: filterValues.ledgerId,
      paymentStartDate: filterValues.paymentStartDate,
      paymentEndDate: filterValues.paymentEndDate ? moment(filterValues.paymentEndDate).format('YYYY-MM-DD') : '',
      txnStartDate: filterValues.txnStartDate ? moment(filterValues.txnStartDate).format('YYYY-MM-DD') : '',
      txnEndDate: filterValues.txnEndDate ? moment(filterValues.txnEndDate).format('YYYY-MM-DD') : '',
      paymentMode: filterValues.paymentMode ? moment(filterValues.paymentMode).format('YYYY-MM-DD') : '',
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;
  const getMemberRoles = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/list`);
      if (!response.data.isError) {
        console.log(response.data.data);
        setMemberRoles(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getMemberRoles();
  }, []);

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="ledgerId" name="ledgerId" label="Ledger ID" {...getFieldProps('ledgerId')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="transactionType"
                    name="transactionType"
                    label="Transaction Type"
                    {...getFieldProps('transactionType')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="paymentStartDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="paymentStartDate"
                    fullWidth
                    label="Payment Start Date"
                    {...getFieldProps('paymentStartDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="paymentEndDate"
                    name="paymentEndDate"
                    fullWidth
                    label="Payment End Date"
                    {...getFieldProps('paymentEndDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    id="txnStartDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="txnStartDate"
                    fullWidth
                    label="Transaction Start Date"
                    {...getFieldProps('txnStartDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="txnEndDate"
                    name="txnEndDate"
                    fullWidth
                    label="Transaction End Date"
                    {...getFieldProps('txnEndDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    label="Payment Mode"
                    id="paymentMode"
                    name="paymentMode"
                    {...getFieldProps('paymentMode')}
                    fullWidth
                  >
                    <MenuItem value="BANK_TRANSFER">BANK TRANSFER</MenuItem>
                    <MenuItem value="CASH">CASH</MenuItem>
                    <MenuItem value="CHEQUE">CHEQUE</MenuItem>
                    <MenuItem value="CARD">CARD</MenuItem>
                    <MenuItem value="OTHER">OTHER</MenuItem>
                  </TextField>
                </Grid>
                
                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          transactionType: '',
                          ledgerId: '',
                          paymentStartDate: '',
                          paymentEndDate: '',
                          txnStartDate: '',
                          txnEndDate: '',
                          paymentMode: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
