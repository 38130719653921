import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import * as Yup from 'yup';
import { useParams, useSearchParams } from 'react-router-dom';
import { Add } from '@mui/icons-material';

const AddQuotationNote = ({ callApi }) => {
  const [open, setOpen] = React.useState(false);
  const [searchParams,setSearchParams] = useSearchParams();
  const id = searchParams.get('id');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    note: Yup.string().required('Note is required'),
  });

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Add />} size={'small'}>
            Add
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Tool</DialogTitle>
            <DialogContent
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '500px',
                },
              }}
            >
              <Formik
                initialValues={{
                  note: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/notes`, {
                      ...values,
                      id,
                    });
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Stack spacing={2} mt={2}>
                      <TextField
                        fullWidth
                        id="note"
                        name="note"
                        label="Note"
                        {...formik.getFieldProps('note')}
                        error={formik.touched.note && Boolean(formik.errors.note)}
                        helperText={formik.touched.note && formik.errors.note}
                      />

                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                          Add
                        </Button>
                      </DialogActions>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddQuotationNote;
