import { useState } from 'react';
import { Grid } from '@mui/material';
// material
import TabCard from '../smallComponents/TabCard';
import hasAccessOf from '../../store/hasAccessOf';

// components

export default function LeadManagerDetailPageTabs({ leadManager }) {
  const telecallerReadAccess = hasAccessOf('crm.teleCaller.r');
  const leadReadAccess = hasAccessOf('crm.lead.r');

  return (
    <>
      <>
        <Grid container spacing={2}>
          {telecallerReadAccess && (
            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                title="Sales Staffs"
                color="greenish"
                icon={'material-symbols:call'}
                navigate={`/dashboard/general/employees/lead-manager/sales-staff?managerId=${leadManager._id}`}
              />
            </Grid>
          )}
          {leadReadAccess && (
            <Grid item xs={12} md={4} lg={2.4}>
              <TabCard
                title="RFQs"
                color="orangee"
                icon={'mdi:leads'}
                navigate={`/dashboard/general/employees/lead-manager/leads?leadManagerId=${leadManager._id}`}
              />
            </Grid>
          )}
        </Grid>
      </>
    </>
  );
}
