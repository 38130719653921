import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack, ExpandMore } from '@mui/icons-material';
import Iconify from './Iconify';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic, edit, onEditClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        position: 'relative',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        {profilePic && <Avatar src={profilePic} alt={data} style={{ height: '40px', width: '40px' }} color="primary" />}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
      {edit && (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ height: '100%', borderRadius: '5px 0px 0px 5px', position: 'absolute', right: '10px' }}
          background={background}
          padding={1}
        >
          <Iconify icon={'mdi:pencil'} style={{ height: '25px', width: '25px' }} onClick={onEditClick} />
        </Stack>
      )}
    </Stack>
  );
}

export default function ViewQuoteBasicDetails({ data }) {
  const navigate = useNavigate();
  return (
    <Box my={2}>
      <Stack direction="row" justifyContent="end" alignItems="center" mb={1} sx={{ width: '100%' }}>
        <Button
          variant="outlined"
          onClick={() => navigate(`/dashboard/project/approved/view/${data?.projectId}`)}
          startIcon={<ArrowBack />}
        >
          Go to Project
        </Button>
      </Stack>
      <Grid container spacing={2} marginY={2}>
        <Grid item xs={12}>
          <DetailItem
            icon={'icons8:parallel-tasks'}
            title={'Project Name'}
            data={data?.projectName}
            sx={{
              height: '100%',
              minHeight: '65px',
              borderRadius: '5px',
            }}
          />
        </Grid>
      </Grid>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            Basic Details
          </Typography>
        </AccordionSummary>{' '}
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon={'mdi:account-circle'}
                title={'Customer'}
                data={data.customerId?.fullname}
                background={'#f0f0f0'}
                sx={{ height: '100%' }}
                profilePic={data.customerId?.profilePic}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon={'mdi:map-marker'}
                title={'Location'}
                data={data.location}
                background={'#f0f0f0'}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon={'mdi:file-document'}
                title={'Quotation ID'}
                data={data.quotationId}
                background={'#f0f0f0'}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon={'mdi:file-document'}
                title={'Quotation Status'}
                data={data.quotationStatus}
                background={'#f0f0f0'}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon={'mdi:file-document'}
                title={'Version'}
                data={data.version}
                background={'#f0f0f0'}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon={'mdi:file-document'}
                title={'Reference Number'}
                data={data.referenceNumber}
                background={'#f0f0f0'}
                sx={{ height: '100%' }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
