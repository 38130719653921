import { useEffect, useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';

// components
import Page from '../components/Page';

import ToolPurchases from '../components/ToolPurchases';
import MaterialPurchases from '../components/MaterialPurchases';
import PageHeader from '../components/smallComponents/PageHeader';

export default function WarehousePurchase() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [value, setValue] = useState(searchParams.get('tab') || '1');

  const handleChange = (event, newValue) => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('tab', newValue);
    window.history.replaceState({}, '', newUrl);

    setValue(newValue);
  };

  useEffect(() => {
    const currentTab = searchParams.get('tab') || '1';
    setSearchParams({ tab: currentTab });
    setValue(currentTab);
  }, [searchParams]);

  return (
    <>
      <Page title="Stock">
        <Container maxWidth="xl">
          <PageHeader title="Stock" />
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Material" value="1" />
                    <Tab label="Tool" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <MaterialPurchases />
                </TabPanel>
                <TabPanel value="2">
                  <ToolPurchases />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
