import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Avatar,
  chip,
  Chip,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Admin';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/AdminStatusChange';
import hasAccessOf from '../store/hasAccessOf';
import CheckBoxGroup from '../components/smallComponents/CheckBoxGroup';
import { StyledTableRow } from '../theme/styledComponents';
import AdminProfileAvatar from '../components/smallComponents/AdminProfileAvatar';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '10px 8px',
}));

export default function Admins() {
  const matches = useMediaQuery('(max-width:600px)');
  const [adminData, setAdminData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const mobileRegExp = '^[6789][0-9]{9}';
  const [adminAccessList, setAdminAccessList] = useState();

  const [dashboard, setDashboard] = useState({});

  const [general, setGeneral] = useState({});

  const [profile, setProfile] = useState({});

  const [crm, setCrm] = useState({});

  const [warehouse, setWarehouse] = useState({});

  const [project, setProject] = useState({});

  const [account, setAccount] = useState({});

  const [purchaseOrder, setPurchaseOrder] = useState({});

  const [tasks, setTasks] = useState({});

  const getAdminsAccess = hasAccessOf('general.admin.r');
  const writeAdminsAccess = hasAccessOf('general.admin.w');
  const createAdminsAccess = hasAccessOf('general.admin.c');

  const adminSchema = Yup.object().shape({
    fullname: Yup.string().required('Fullname is required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(20, 'Password must be at most 20 characters'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      password: '',
      description: '',
      designation: '',
    },
    validationSchema: adminSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/admin/subadmin`, {
          ...values,
          accessList: { dashboard, general, profile, crm, warehouse, project, account, purchaseOrder, tasks },
        });
        if (!res.data.isError) {
          closeAddAdminModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getAdmins(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getMemberRole = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/accessList`, {
        params: {
          name: 'SUB_ADMIN',
        },
      });
      if (res.data.isError === false) {
        setAdminAccessList(res?.data?.data?.accessList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleStatusChange1 = (event, item, index) => {
    console.log('item', item);
    if (`${item.isPopular}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getAdmins(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddAdminModal = () => {
    setOpen(true);
  };
  const closeAddAdminModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getAdmins(temp);
  };

  const getAdmins = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/subAdmin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setAdminCount(res.data.data.maxRecords);
          setAdminData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getAdminsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/subAdmin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setAdminCount(res.data.data.maxRecords);
          setAdminData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(adminCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getAdmins(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };
    setOptions(temp);
    setPages(0);
    getAdminsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.fullname) {
      temp.fullname = filter.fullname.trim();
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.email) {
      temp.email = filter.email.trim();
    }

    setOptions(temp);
    setPages(0);
    getAdmins(temp);
  };
  useEffect(() => {
    if (getAdminsAccess) {
      getAdmins(options);
      getMemberRole();
    }
  }, []);

  useEffect(() => {
    console.log('adminAccessList', adminAccessList);
    if (adminAccessList?.dashboard) {
      setDashboard(adminAccessList.dashboard);
    }
    if (adminAccessList?.general) {
      setGeneral(adminAccessList.general);
    }
    if (adminAccessList?.profile) {
      setProfile(adminAccessList.profile);
    }
    if (adminAccessList?.crm) {
      setCrm(adminAccessList.crm);
    }
    if (adminAccessList?.warehouse) {
      setWarehouse(adminAccessList.warehouse);
    }
    if (adminAccessList?.project) {
      setProject(adminAccessList.project);
    }
    if (adminAccessList?.account) {
      setAccount(adminAccessList.account);
    }
    if (adminAccessList?.purchaseOrder) {
      setPurchaseOrder(adminAccessList.purchaseOrder);
    }
    if (adminAccessList?.tasks) {
      setTasks(adminAccessList.tasks);
    }
  }, [adminAccessList]);

  useEffect(() => {
    if (getAdminsAccess) {
      const temp = { ...options, size, page: 0 };
      setPages(0);
      setOptions(temp);
      getAdmins(temp);
    }
  }, [size]);

  return (
    <>
      <Page title="Admins">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : ( 
          <>
            {/* Add admin Dialog */}
            <Dialog open={open} onClose={closeAddAdminModal} fullScreen={matches}>
              <DialogTitle>Add Admin</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Fullname"
                            {...getFieldProps('fullname')}
                            error={Boolean(touched.fullname && errors.fullname)}
                            helperText={touched.fullname && errors.fullname}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            type="password"
                            label="Password"
                            {...getFieldProps('password')}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Designation"
                            {...getFieldProps('designation')}
                            error={Boolean(touched.designation && errors.designation)}
                            helperText={touched.designation && errors.designation}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Description"
                            {...getFieldProps('description')}
                            error={Boolean(touched.description && errors.description)}
                            helperText={touched.description && errors.description}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography fontWeight={'bold'}>Access List</Typography>
                            </Grid>
                            {/* <CheckBoxGroup name="Profile" accessList={profile} setAccessList={setProfile} /> */}
                            {/* <CheckBoxGroup name="General" accessList={general} setAccessList={setGeneral} /> */}
                            <CheckBoxGroup name="CRM" accessList={crm} setAccessList={setCrm} />
                            <CheckBoxGroup name="Project" accessList={project} setAccessList={setProject} />
                            <CheckBoxGroup
                              name="Purchase Order"
                              accessList={purchaseOrder}
                              setAccessList={setPurchaseOrder}
                            />
                            <CheckBoxGroup name="Warehouse" accessList={warehouse} setAccessList={setWarehouse} />
                            {/* <CheckBoxGroup name="Dashboard" accessList={dashboard} setAccessList={setDashboard} /> */}
                            <CheckBoxGroup name="Account" accessList={account} setAccessList={setAccount} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddAdminModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting} variant="contained">
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Box>
            </Dialog>
            {/* Add admin dialog end */}
            <Container maxWidth="xl">
              <PageHeader title="Admins" />
              <Filter applyFilters={applyFilters} filterValues={options} />

              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack> */}
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search admin..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    {createAdminsAccess && (
                      <Button startIcon={<AddIcon />} variant="contained" onClick={openAddAdminModal}>
                        Add Admin
                      </Button>
                    )}
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCellCustom>Sl No</TableCellCustom>
                          <TableCellCustom>Admin</TableCellCustom>
                          <TableCellCustom>Designation</TableCellCustom>
                          <TableCellCustom>Description</TableCellCustom>
                          <TableCellCustom>Member Since</TableCellCustom>
                          <TableCellCustom>ID</TableCellCustom>
                          <TableCellCustom>Status</TableCellCustom>
                          <TableCellCustom>Action</TableCellCustom>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {adminData?.map((item, id) => (
                          <StyledTableRow key={id}>
                            <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                            <TableCellCustom>
                              <AdminProfileAvatar
                                profilePic={item?.profilePic}
                                firstLine={item?.fullname}
                                secondLine={item?.email}
                                id={item._id}
                              />
                            </TableCellCustom>
                            <TableCellCustom>{item?.designation}</TableCellCustom>
                            <TableCellCustom>{item?.description}</TableCellCustom>
                            <TableCellCustom sx={{ minWidth: '110px', maxWidth: '120px' }}>
                              {moment(item.createdAt).format('DD MMM YYYY')}
                            </TableCellCustom>
                            <TableCellCustom style={{ cursor: 'pointer', width: '80px' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Admin ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <ContentCopy color="primary" />
                                    <Typography color="primary.main" variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCellCustom>
                            <TableCellCustom>
                              {writeAdminsAccess ? (
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={item.isBlocked}
                                    onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    <MenuItem value={'false'}>🟢 Active</MenuItem>
                                    <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                                  </Select>
                                  {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                                </FormControl>
                              ) : (
                                <Chip
                                  label={item.isBlocked ? 'Blocked' : 'Active'}
                                  variant="outlined"
                                  size="small"
                                  color={item.isBlocked ? 'error' : 'success'}
                                />
                              )}
                            </TableCellCustom>
                            <TableCellCustom>
                              <Box>
                                <Link to={`/dashboard/general/admin/view/${item._id}`}>
                                  <Button
                                    variant="contained"
                                    startIcon={<RemoveRedEyeIcon />}
                                    size="small"
                                    color="viewButton"
                                  >
                                    View
                                  </Button>
                                </Link>
                              </Box>
                            </TableCellCustom>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={adminCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
