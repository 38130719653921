import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  Box,
} from '@mui/material';
import {
  Add,
  AddCircle,
  ArrowBack,
  ContentCopy,
  Delete,
  Edit,
  Remove,
  RemoveCircle,
  RemoveRedEye,
} from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import AddMaterialToProject from './popups/AddMaterialToProject';
import AddRemoveMaterialFromProject from './popups/AddRemoveMaterialFromProject';
import hasAccessOf from '../store/hasAccessOf';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function MaterialUsageTable({ material, getMaterial }) {
  const { id } = useParams();

  const [materialData, setMaterialData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [materialDataCount, setMaterialDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openAddOrReturnPopup, setOpenAddOrReturnPopup] = useState(false);
  const [mode, setMode] = useState('add');

  const materialCreateAccess = hasAccessOf('warehouse.material.c');
  const materialReadAccess = hasAccessOf('warehouse.material.r');
  const materialWriteAccess = hasAccessOf('warehouse.material.w');
  const materialDeleteAccess = hasAccessOf('warehouse.material.d');

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getMaterialData(temp);
  };

  const getMaterialData = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/usage`, {
        params: {
          ...options,
          materialId: id,
        },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMaterialDataCount(res.data.data.maxRecords);
          setMaterialData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getMaterialDataWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/usage`, {
        params: {
          ...options,
          materialId: id,
        },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMaterialDataCount(res.data.data.maxRecords);
          setMaterialData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, qtoName: e.target.value };
    setOptions(temp);
    setPages(0);
    getMaterialDataWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.qtoType) {
      temp.qtoType = filter.qtoType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getMaterialData(temp);
  };

  useEffect(() => {
    getMaterialData(options);
  }, []);

  const handleSuccess = () => {
    setOpenAddOrReturnPopup(false);
    setOpenPopup(false);
    setSelectedIndex('');
    getMaterial();
    getMaterialData(options);
  };

  const handleMaterialAddRemoveSuccess = () => {
    setOpenPopup(false);
    getMaterial();
    getMaterialData(options);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search Project..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </Stack>

            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              {materialWriteAccess && (
                <Button startIcon={<Add />} variant="contained" onClick={() => setOpenPopup(true)}>
                  Add
                </Button>
              )}
              <AddMaterialToProject
                handleSuccess={handleMaterialAddRemoveSuccess}
                open={openPopup}
                setOpen={setOpenPopup}
                material={material}
              />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Projet Name</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Quantity</TableCell>
                    {materialWriteAccess && <TableCell sx={{ maxWidth: '180px' }}>Action</TableCell>}
                    <TableCell sx={{ maxWidth: '180px' }}>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>{item.projectName}</TableCell>
                      <TableCell>{item.unit}</TableCell>
                      <TableCell>{item.totalQuantity}</TableCell>
                      {materialWriteAccess && (
                        <TableCell sx={{ maxWidth: '180px' }}>
                          <Stack direction="row" spacing={2}>
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              startIcon={<AddCircle />}
                              onClick={() => {
                                setMode('add');
                                setSelectedIndex(id);
                                setOpenAddOrReturnPopup(true);
                              }}
                            >
                              Add
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              startIcon={<RemoveCircle />}
                              disabled={item.totalQuantity === 0}
                              onClick={() => {
                                setMode('remove');
                                setSelectedIndex(id);
                                setOpenAddOrReturnPopup(true);
                              }}
                            >
                              Return
                            </Button>
                          </Stack>
                          {selectedIndex === id && (
                            <AddRemoveMaterialFromProject
                              open={openAddOrReturnPopup}
                              mode={mode}
                              setOpen={setOpenAddOrReturnPopup}
                              item={item}
                              material={material}
                              handleSuccess={handleSuccess}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <Box>
                          <Link
                            to={`/dashboard/warehouse/material/history?materialId=${material._id}&&projectId=${item.projectId}`}
                          >
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEye />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getMaterialData(temp);
            }}
            component={'div'}
            count={materialDataCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
