import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Chip,
  Avatar,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close, ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Material';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import AddMaterial from './popups/AddMaterial';
import Popup from './popups/MaterialStatusChange';

import PageHeader from './smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import Export from './filters/MaterialEntryExport';
import { StyledTableRow } from '../theme/styledComponents';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 14px',
}));

export default function MaterialsTable() {
  const [materialData, setMaterialData] = useState([]);
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [materialCount, setMaterialCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [defunct, setDefunct] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const materialCreateAccess = hasAccessOf('warehouse.material.c');
  const materialReadAccess = hasAccessOf('warehouse.material.r');
  const materialWriteAccess = hasAccessOf('warehouse.material.w');
  const materialDeleteAccess = hasAccessOf('warehouse.material.d');

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getMaterials(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getMaterials(temp);
  };

  const getMaterials = (options) => {
    if (!materialReadAccess) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/material/all`, {
        params: {
          ...options,
          defunct,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMaterialCount(res.data.data.maxRecords);
          setMaterialData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getMaterialsWithoutLoading = (options) => {
    if (!materialReadAccess) {
      setIsLoading(false);
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/material/all`, {
        params: {
          ...options,
          defunct,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setMaterialCount(res.data.data.maxRecords);
          setMaterialData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getMaterialsWithoutLoading(temp);
  };

  const exportMaterial = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/material/export`, {
        ...options,
        defunct,
      });
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.name) {
      temp.name = filter.name;
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.id) {
      temp.id = filter.id;
      temp.name = '';
      setName('');
    }
    if (filter.description) {
      temp.description = filter.description;
    }

    setOptions(temp);
    setPages(0);
    getMaterials(temp);
  };
  useEffect(() => {
    getMaterials(options);
  }, [defunct]);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getMaterials(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction={'row'} justifyContent={'end'}>
            <Export />
            <LoadingButton
              endIcon={<Iconify icon="ph:export-bold" />}
              variant="outlined"
              color="primary"
              onClick={() => exportMaterial(options)}
              loading={isLoading1}
              size="small"
            >
              Export
            </LoadingButton>
          </Stack>
          <Filter applyFilters={applyFilters} filterValues={options} />
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search material name..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                endAdornment={
                  name.length > 0 && (
                    <Icon
                      onClick={() => {
                        setName('');
                        setOptions({ ...options, name: '' });
                        getMaterialsWithoutLoading({ ...options, name: '' });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Close />
                    </Icon>
                  )
                }
              />
              <FormControlLabel
                control={<Switch checked={defunct} onChange={(e) => setDefunct(e.target.checked)} />}
                label="Defunct"
              />
            </Stack>
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              {materialCreateAccess && (
                <AddMaterial lead={{}} name={'Add Material'} type={'add'} callApi={getMaterials} />
              )}
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellCustom>Sl No</TableCellCustom>
                    <TableCellCustom>Material</TableCellCustom>
                    <TableCellCustom>Description</TableCellCustom>
                    <TableCellCustom>Unit Price</TableCellCustom>
                    <TableCellCustom>Unit</TableCellCustom>
                    <TableCellCustom>Stock</TableCellCustom>
                    <TableCellCustom>Defunct</TableCellCustom>
                    <TableCellCustom>Status</TableCellCustom>
                    <TableCellCustom>Action</TableCellCustom>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialData?.map((item, id) => (
                    <StyledTableRow key={id}>
                      <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                      <TableCellCustom>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={item?.image && process.env.REACT_APP_API_URL + item.image}
                            sx={{ width: 40, height: 40 }}
                          >
                            {item?.name?.charAt(0).toUpperCase()}
                          </Avatar>
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.name}</Typography>
                          </Box>
                        </Box>
                      </TableCellCustom>
                      <TableCellCustom>
                        <Tooltip title={item.description} placement="top-start" arrow>
                          <Typography
                            variant="p"
                            sx={{
                              maxWidth: '150px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              cursor: 'pointer',
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Tooltip>
                      </TableCellCustom>
                      <TableCellCustom>{item.unitPrice}</TableCellCustom>
                      <TableCellCustom>{item.unit}</TableCellCustom>
                      <TableCellCustom>{item.stock}</TableCellCustom>
                      <TableCellCustom>{item?.defunctUnits}</TableCellCustom>
                      <TableCellCustom>
                        {materialWriteAccess ? (
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select
                              size="small"
                              value={item.isBlocked}
                              onChange={(e) => handleStatusChange(e, item, id)}
                            >
                              <MenuItem value={'false'}>🟢 Active</MenuItem>
                              <MenuItem value={'true'}>🔴 Blocked</MenuItem>
                            </Select>
                            {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                          </FormControl>
                        ) : (
                          <Chip
                            label={item.isBlocked ? 'Blocked' : 'Active'}
                            color={item.isBlocked ? 'error' : 'success'}
                            size="small"
                            variant="outlined"
                          />
                        )}
                      </TableCellCustom>
                      <TableCellCustom>
                        <Box>
                          <Link to={`/dashboard/warehouse/material/view/${item._id}`}>
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEyeIcon />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCellCustom>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
            }}
            component={'div'}
            count={materialCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
