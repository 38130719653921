import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material';
import { Add, ArrowBack, ContentCopy, Delete } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import AddQto from '../components/popups/AddQto';
import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function QuotationTable() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [qtoData, setQtoData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [qtoCount, setQtoCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [project, setProject] = useState({});

  const boqReadAccess = hasAccessOf('project.boq.r');
  const quoteReadAccess = hasAccessOf('project.quotation.r');
  const qtoWriteAccess = hasAccessOf('project.qto.w');

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getQtos(temp);
  };

  const getQtos = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation/all`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setQtoCount(res.data.data.maxRecords);
          setQtoData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getQtosWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation/all`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setQtoCount(res.data.data.maxRecords);
          setQtoData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getProject = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      });
      console.log('project', res);
      setProject(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, qtoName: e.target.value };
    setOptions(temp);
    setPages(0);
    getQtosWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.qtoType) {
      temp.qtoType = filter.qtoType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getQtos(temp);
  };

  useEffect(() => {
    getQtos(options);
    getProject();
  }, []);

  return (
    <Page title="Quotations">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title={'Quotations'} />
          <Card>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search qto..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack>
              <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                {qtoWriteAccess && (
                  <Button startIcon={<Add />} variant="contained" onClick={() => setOpen(true)}>
                    Add
                  </Button>
                )}
                <AddQto getQtos={getQtos} open={open} setOpen={setOpen} project={project} />
                {/* <Filter applyFilters={applyFilters} /> */}
              </Stack>
            </Stack>
            <Typography variant="h5" sx={{ marginX: 2, marginY: 1 }}>
              {project.name}
            </Typography>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Created On</TableCell>
                      <TableCell>Quotation ID</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell>QTO </TableCell>
                      <TableCell>BOQ </TableCell>
                      <TableCell>Quotation </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {qtoData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>
                        <TableCell>{Moment(item.createdAt).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>
                          <Stack direction="column" spacing={0.5} width="120px">
                            <CopyToClipboard text={item.quotationId} onCopy={() => toast.info('Quotation ID copied')}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={0.5}
                                justifyContent="flex-start"
                                sx={{ cursor: 'pointer' }}
                              >
                                <ContentCopy color="primary" />
                                <Typography variant="p">{item.quotationId}</Typography>
                              </Stack>
                            </CopyToClipboard>
                          </Stack>
                        </TableCell>
                        <TableCell>{item?.customerData?.fullname}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => navigate(`/dashboard/quotation/tabs?tab=qto&id=${item._id}`)}
                          >
                            {item.qtoStatus}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            disabled={!boqReadAccess}
                            size="small"
                            onClick={() =>
                              item?.qtoStatus === 'APPROVED'
                                ? navigate(`/dashboard/quotation/tabs?tab=boq&id=${item._id}`)
                                : toast.error('QTO not approved yet')
                            }
                          >
                            {item.boqStatus}
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            size="small"
                            disabled={!quoteReadAccess}
                            onClick={() =>
                              item?.boqStatus === 'APPROVED'
                                ? navigate(`/dashboard/quotation/tabs?tab=quote&id=${item._id}`)
                                : toast.error('BOQ not approved yet')
                            }
                          >
                            {item.quotationStatus}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
                const temp = { ...options, size: e.target.value, page: 0 };
                setPages(0);
                setOptions(temp);
                getQtos(temp);
              }}
              component={'div'}
              count={qtoCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
