import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ReactToPrint from 'react-to-print';
import styled from '@emotion/styled';
import axios from 'axios';
import { BorderColor, Delete, DeleteForever, Edit, Print, Report, WarningAmberOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Page from '../components/Page';
import BillEditForm from '../components/BillEditForm';
import TransactionsTable from '../components/TransactionsTable';
import PageHeader from '../components/smallComponents/PageHeader';
import Logo from '../components/Logo';
import { formatInvoiceNumber } from '../utils/commonFunctions';
import DeleteModal from '../components/popups/DeleteModal';

//  custom tablecell with paddingY 5px and paddingX 16px
const TableCellStyled = styled(TableCell)({
  padding: '7px 16px',
});

const ViewBill = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [editMode, setEditMode] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [bill, setBill] = useState({});
  const componentRef = React.useRef();

  const getBill = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/billing`, { params: { invoiceNumber: id } });
      console.log(res.data.data, 'bill');
      setBill(res.data.data.invoice);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBill();
  }, []);

  return (
    <Page title="View Invoice">
      <Container maxWidth="xl">
        <PageHeader title=" Invoice" />
        <Stack spacing={2}>
          <Card>
            <Box sx={{ padding: '10px' }}>
              {editMode && (
                <>
                  <BillEditForm bill={bill} setEditMode={setEditMode} getBill={getBill} />
                </>
              )}
              {!editMode && (
                <>
                  <Stack
                    direction={'row'}
                    px={5}
                    py={3}
                    sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                    justifyContent={'space-between'}
                  >
                    {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
                    <Stack direction={'row'} spacing={1} alignItems={'center'} sx={{ color: '#fff' }}>
                      <Stack justifyContent="center" direction={'column'}>
                        <Stack direction={'row'} spacing={2} alignItems="center">
                          <Typography variant="h5" fontWeight={'bold'}>
                            {bill?.invoiceNumber && formatInvoiceNumber(bill?.invoiceNumber)}
                          </Typography>
                          {/* if bill is overdue show an alert */}
                          {moment(bill?.dueDate).startOf('day').isBefore(moment().startOf('day')) && (
                            <Chip size="small" icon={<WarningAmberOutlined />} label="Overdue" color="error" />
                          )}
                        </Stack>
                        <Stack direction={'row'} spacing={2} alignItems="center">
                          <Typography variant="body2" fontWeight={'bold'}>
                            Quotation ID:
                          </Typography>
                          <Typography variant="body2">{bill?.quotationId?.quotationId}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Date:
                          </Typography>
                          <Typography variant="body2">{moment(bill?.invoiceDate).format('DD/MM/YYYY')}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                          <Typography variant="body2" fontWeight={'bold'}>
                            Due Date:
                          </Typography>
                          <Typography variant="body2">{moment(bill?.dueDate).format('DD/MM/YYYY')}</Typography>
                        </Stack>
                      </Stack>
                    </Stack>

                    <Stack direction={'column'} spacing={1} alignItems={'center'}>
                      {bill?.paidAmount === 0 && (
                        <Button color="error" onClick={() => setOpenDelete(true)} size="small" startIcon={<Delete />}>
                          Delete
                        </Button>
                      )}
                      <DeleteModal
                        open={openDelete}
                        setOpen={setOpenDelete}
                        url={`billing?invoiceNumber=${bill?.invoiceNumber}`}
                        navigateTo="/dashboard/accounts/bill"
                        message="Are you sure you want to delete this invoice?"
                        title="Delete Invoice"
                      />
                      {bill?.paidAmount === 0 && (
                        <Button color="light" onClick={() => setEditMode(true)} size="small" startIcon={<Edit />}>
                          Edit
                        </Button>
                      )}
                      <ReactToPrint
                        pageStyle={
                          '@page { size: A4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }'
                        }
                        trigger={() => (
                          <Button variant="text" color="light" startIcon={<Print />} size="small">
                            Print
                          </Button>
                        )}
                        content={() => componentRef.current}
                        copyStyles
                      />
                    </Stack>
                  </Stack>
                  <Grid container>
                    <Grid item xs={12}>
                      {/*  project name */}
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          Project Name
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{bill?.projectId?.name}</Typography>
                          {bill?.projectId?.workOrderNumber && (
                            <Typography>{`Work Order Number: ${bill?.projectId?.workOrderNumber}`}</Typography>
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          From
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{bill?.from?.name}</Typography>
                          <Typography>{bill?.from?.address}</Typography>
                          <Typography>{bill?.from?.pin}</Typography>
                          <Typography>{bill?.from?.phone}</Typography>
                          <Typography>{bill?.from?.email}</Typography>
                          <Typography>{`TRN: ${bill?.from?.trn}`}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          To
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{bill?.to?.name}</Typography>
                          <Typography>{bill?.to?.address}</Typography>
                          <Typography>{bill?.to?.phone}</Typography>
                          <Typography>{bill?.to?.email}</Typography>
                          {bill?.to?.trn && <Typography>{`TRN: ${bill?.to?.trn}`}</Typography>}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          Bank Details
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{`Bank Name: ${bill?.bankDetails?.bankName}`}</Typography>
                          <Typography>{`IBAN: ${bill?.bankDetails?.iban}`}</Typography>
                          <Typography>{`Bank Account Number: ${bill?.bankDetails?.bankAccountNumber}`}</Typography>
                          <Typography>{`Beneficiary Name: ${bill?.bankDetails?.beneficiaryName}`}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Stack paddingLeft={4} spacing={2}>
                          {bill?.poDate && (
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                              <Typography>PO Date:</Typography>
                              <Typography>{moment(bill?.poDate).format('DD/MM/YYYY')}</Typography>
                            </Stack>
                          )}
                          <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Typography>PO Number:</Typography>
                            <Typography>{bill?.poNumber}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      margin={1}
                      sx={{
                        borderRadius: '5px',
                      }}
                    >
                      <Stack p={5}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Invoice Value(%)</TableCell>
                                <TableCell>Invoice Value Price</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>VAT</TableCell>
                                <TableCell>VAT Amount</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {bill?.items?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                  <TableCell>{item.originalUnitPrice}</TableCell>
                                  <TableCell>{item.progressiveInvoiceValue}</TableCell>
                                  <TableCell>{item.unitPrice}</TableCell>
                                  <TableCell>
                                    {parseFloat(Number(item.unitPrice) * Number(item.quantity)).toFixed(2)}
                                  </TableCell>
                                  <TableCell>
                                    <Stack direction={'column'} spacing={1}>
                                      <Typography variant="body2">{item.tax?.percentage}%</Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell>
                                    {parseFloat(Number(item.tax?.amount) * Number(item?.quantity)).toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <Stack
                        direction={'row'}
                        pb={5}
                        justifyContent={'space-between'}
                        mr={10}
                        alignItems={'end'}
                        sx={{ width: '100%' }}
                      >
                        <Stack
                          direction={'row'}
                          spacing={10}
                          sx={{ width: '100%' }}
                          justifyContent={'center'}
                          alignItems={'end'}
                        >
                          <Typography variant="body2" fontWeight={'bold'}>
                            {bill?.grandTotalInWords}
                          </Typography>
                        </Stack>
                        <Stack justifyContent="center" spacing={2} sx={{ width: '400px' }}>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Sub Total (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {bill?.subTotal}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Tax (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {parseFloat(bill?.totalTax).toFixed(2)}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Grand Total (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {bill?.grandTotal}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Paid Amount (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {bill?.paidAmount}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Pending Amount (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {(parseFloat(bill?.grandTotal) - parseFloat(bill?.paidAmount)).toFixed(2)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                    {bill?.paymentTerms && bill?.paymentTerms.length > 0 && bill.paymentTerms[0]?.length > 0 && (
                      <Grid item xs={12}>
                        <Stack p={5} spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
                            Payment Terms
                          </Typography>
                          <Stack spacing={1}>
                            {bill?.paymentTerms?.map((term, index) => (
                              <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography minWidth={20}>{index + 1}.</Typography>
                                <Typography>{term}</Typography>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                    {/* Notes , which is also array */}
                    {bill?.notes && bill?.notes.length > 0 && bill.notes[0]?.length > 0 && (
                      <Grid item xs={12}>
                        <Stack p={5} spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
                            Notes
                          </Typography>
                          <Stack spacing={1}>
                            {bill?.notes?.map((note, index) => (
                              <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography minWidth={20}>{index + 1}.</Typography>
                                <Typography>{note}</Typography>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  {!bill.isPaymentCompleted && (
                    <Stack direction={'row'} p={2} justifyContent={'end'} sx={{ width: '100%' }}>
                      <Button
                        className="print-hide"
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          navigate(`/dashboard/accounts/pending-payments/bill/view-payment/${bill.customerId}?`, {
                            state: { invoice: bill },
                          })
                        }
                      >
                        Payment Received
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Box>
          </Card>
          {bill?.transactions && !!bill.transactions.length && <TransactionsTable billingId={bill._id} />}
        </Stack>
      </Container>

      {/*  printable invoice */}
      <>
        <Stack
          direction={'column'}
          sx={{
            width: '1100px',
            height: '100%',
            padding: '20px',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
          spacing={5}
          ref={componentRef}
          className="print-only"
        >
          {/*  head */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }} pb={5}>
            <Logo
              sx={{
                width: '180px',
              }}
            />
            <Stack direction={'column'} alignItems={'flex-end'}>
              <Typography
                variant="h3"
                fontWeight={'bold'}
                sx={{
                  color: (theme) => theme.palette.macinsIndigo.main,
                }}
              >
                INVOICE
              </Typography>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main }}>
                {bill?.invoiceNumber && `Invoice Number: ${formatInvoiceNumber(bill?.invoiceNumber)}`}
              </Typography>
            </Stack>
          </Stack>

          {/* from to */}

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }}>
            <Stack direction={'column'} spacing={1} alignItems={'flex-start'} sx={{ maxWidth: '300px' }}>
              <Typography
                fontWeight={'bold'}
                gutterBottom
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}
              >
                Billed To
              </Typography>
              <Stack spacing={0.25} alignItems={'flex-start'}>
                <Typography sx={{ fontSize: '15px' }}>{bill?.to?.name}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{bill?.to?.address}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{bill?.to?.phone}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{bill?.to?.email}</Typography>
                {bill?.to?.trn && <Typography sx={{ fontSize: '15px' }}>{`TRN:   ${bill?.to?.trn}`}</Typography>}
              </Stack>
            </Stack>
            <Stack direction={'column'} spacing={1} alignItems={'flex-end'} sx={{ maxWidth: '300px' }}>
              <Typography
                fontWeight={'bold'}
                gutterBottom
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}
              >
                Billed By
              </Typography>
              <Stack spacing={0.25} alignItems={'flex-end'}>
                <Typography textAlign={'end'} sx={{ fontSize: '15px' }}>
                  {bill?.from?.name}
                </Typography>
                <Typography textAlign={'end'} sx={{ fontSize: '15px' }}>
                  {bill?.from?.address}
                </Typography>
                <Typography textAlign={'end'} sx={{ fontSize: '15px' }}>
                  {bill?.from?.pin}
                </Typography>
                <Typography textAlign={'end'} sx={{ fontSize: '15px' }}>
                  {bill?.from?.phone}
                </Typography>
                <Typography textAlign={'end'} sx={{ fontSize: '15px' }}>
                  {bill?.from?.email}
                </Typography>
                <Typography textAlign={'end'} sx={{ fontSize: '15px' }}>{`TRN:   ${bill?.from?.trn}`}</Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  bank details and PO */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }}>
            <Stack direction={'column'} spacing={1} alignItems={'flex-start'}>
              <Typography
                fontWeight={'bold'}
                gutterBottom
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}
              >
                Bank Details
              </Typography>
              <Stack spacing={0.25} alignItems={'flex-start'}>
                <Typography sx={{ fontSize: '15px' }}>{`Bank Name:   ${bill?.bankDetails?.bankName}`}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{`IBAN:   ${bill?.bankDetails?.iban}`}</Typography>
                <Typography
                  sx={{ fontSize: '15px' }}
                >{`Bank Account Number:   ${bill?.bankDetails?.bankAccountNumber}`}</Typography>
                <Typography
                  sx={{ fontSize: '15px' }}
                >{`Beneficiary Name:   ${bill?.bankDetails?.beneficiaryName}`}</Typography>
              </Stack>
            </Stack>
            <Stack direction={'column'} spacing={1} alignItems={'flex-end'}>
              <Typography
                fontWeight={'bold'}
                gutterBottom
                sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}
              >
                Invoice Details
              </Typography>
              <Stack spacing={0.25} alignItems={'flex-end'}>
                <Typography sx={{ fontSize: '15px' }}>{`Invoice Date:   ${moment(bill?.invoiceDate).format(
                  'DD/MM/YYYY'
                )}`}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{`Due Date:   ${moment(bill?.dueDate).format(
                  'DD/MM/YYYY'
                )}`}</Typography>
                {bill?.poDate && (
                  <Typography sx={{ fontSize: '15px' }}>{`PO Date:   ${moment(bill?.poDate).format(
                    'DD/MM/YYYY'
                  )}`}</Typography>
                )}
                <Typography sx={{ fontSize: '15px' }}>{`PO Number:   ${bill?.poNumber}`}</Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  Project name */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'start'}
            sx={{ width: '100%' }}
            spacing={2}
          >
            <Stack direction={'column'} spacing={1} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}>
                Project Name
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>{bill?.projectId?.name}</Typography>
            </Stack>
            <Stack direction={'column'} spacing={1} alignItems={'flex-end'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '16px' }}>
                Work Order Number
              </Typography>
              <Typography sx={{ fontSize: '15px' }}>{bill?.projectId?.workOrderNumber}</Typography>
            </Stack>
          </Stack>

          {/*  items */}
          <TableContainer
            sx={{
              border: '1px solid #eceff3',
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) => theme.palette.primary?.main,
                }}
              >
                <TableRow>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Description
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Quantity
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Unit Price
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Invoice Value(%)
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Invoice Value Price
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Total
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    VAT
                  </TableCellStyled>
                  <TableCellStyled sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    VAT Amount
                  </TableCellStyled>
                </TableRow>
              </TableHead>
              <TableBody>
                {bill?.items?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: '15px' }}>{item.name}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{item.quantity}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{parseFloat(item.originalUnitPrice).toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{item.progressiveInvoiceValue}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{parseFloat(item.unitPrice).toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      {parseFloat(Number(item.unitPrice) * Number(item.quantity)).toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      <Stack direction={'column'} spacing={1}>
                        <Typography variant="body2">{item.tax?.percentage}%</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      {parseFloat(Number(item.tax?.amount) * Number(item?.quantity)).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/*  total */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'end'}
            sx={{ width: '100%' }}
            paddingY={5}
          >
            {/* grand total in words */}
            <Stack
              direction={'row'}
              justifyContent={'center'}
              alignItems={'end'}
              sx={{ width: '100%', paddingRight: '40px' }}
            >
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}>
                {bill?.grandTotalInWords}
              </Typography>
            </Stack>
            <Stack justifyContent="center" spacing={2} sx={{ width: '400px' }} pr={4}>
              <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  Sub Total (AED):
                </Typography>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  {parseFloat(bill?.subTotal).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  VAT (AED):
                </Typography>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  {parseFloat(bill?.totalTax).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}>
                  Grand Total (AED):
                </Typography>
                <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}>
                  {parseFloat(bill?.grandTotal).toFixed(2)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  payment terms */}
          {bill?.paymentTerms && bill?.paymentTerms.length > 0 && bill.paymentTerms[0]?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                Payment Terms
              </Typography>
              <Stack spacing={1}>
                {bill?.paymentTerms?.map((term, index) => (
                  <Typography key={index} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                    {term}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/*  notes */}
          {bill?.notes && bill?.notes.length > 0 && bill.notes[0]?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                Notes
              </Typography>
              <Stack spacing={1}>
                {bill?.notes?.map((note, index) => (
                  <Typography key={index} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                    {note}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/* authorised sign */}
          <Stack direction="row" sx={{ height: '100%' }} flex={1} paddingTop={10}>
            <Stack flex={1} sx={{ height: '140px' }} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: (theme) => theme.palette.text.primary,
                  fontStyle: 'italic',
                  textDecoration: 'underline',
                }}
              >
                for Macins technical services
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: (theme) => theme.palette.text.primary,
                  fontStyle: 'italic',
                  underline: 'true',
                  textDecoration: 'underline',
                }}
              >
                Authorized Signature
              </Typography>
            </Stack>
            <Stack flex={1} sx={{ height: '140px' }} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: (theme) => theme.palette.text.primary,
                  fontStyle: 'italic',
                  textDecoration: 'underline',
                }}
              >
                Recieved
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: (theme) => theme.palette.text.primary,
                  fontStyle: 'italic',
                  textDecoration: 'underline',
                }}
              >
                Authorized Signature
              </Typography>
            </Stack>
          </Stack>

          {/* This document is computer generated and does not require the signature or the Company's stamp in order to be considered valid */}
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingTop={5}
            sx={{
              width: '100%',
              padding: '20px',
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'lighter',
                color: (theme) => theme.palette.text.secondary,
                fontStyle: 'italic',
              }}
              textAlign={'center'}
            >
              This document is computer generated and does not require the signature or the Company's stamp in order to
              be considered valid.
            </Typography>
          </Stack>
        </Stack>
      </>
    </Page>
  );
};

export default ViewBill;
