const { Stack, Avatar, Typography } = require('@mui/material');
const { default: Iconify } = require('../Iconify');

function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default DetailItem;
