import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Stack,
  CircularProgress,
  Container,
  Typography,
  OutlinedInput,
  Box,
  TextField,
  MenuItem,
  Avatar,
  Grid,
} from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

import ProjectToolTransaferLogs from '../components/ProjectToolTransferLogs';
// import ProjectToolTransferLogs from '../components/ProjectToolTransferLogs';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function DetailItem({ icon, title, data, background, sx, onClick, profilePic, statusChange, onStatusChange }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
      paddingX={1}
      paddingY={0.5}
    >
      <Stack direction="row" alignItems="center" background={background} padding={profilePic ? 0 : 1}>
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      {statusChange === 'yes' ? (
        <>
          <TextField select label="Status" value={data} size="small" onChange={(e) => onStatusChange(e.target.value)}>
            <MenuItem value="false">Active</MenuItem>
            <MenuItem value="true">Blocked</MenuItem>
          </TextField>
        </>
      ) : (
        <Stack direction="column">
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
          <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default function ProjectToolView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const toolId = searchParams.get('toolId');
  const [isLoading, setIsLoading] = useState(true);
  const [tool, setTool] = useState({});
  const [toolUsage, setToolUsage] = useState({});
  const [project, setProject] = useState({});

  const getToolUsageData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/toolFlowLog/usageInProject/single`, {
        params: { toolId, projectId },
      });
      console.log(res);
      if (res.data.isError === false) {
        setToolUsage(res.data.data);
        setTool(res.data.data.toolData);
        setProject(res.data.data.projectData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (status) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/tool/status`, {
        toolId: tool._id,
        status,
      });
      console.log(res);
      if (res.data.isError === false) {
        toast.success(res.data.message);
        getToolUsageData();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getToolUsageData();
  }, []);

  return (
    <Page title="Project Tool Details">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="Project Tool Details" />
          <Stack direction={'column'} spacing={2}>
            <Card>
              <Box padding={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailItem
                      icon="icons8:parallel-tasks"
                      title="Project Name"
                      data={toolUsage.projectData.name}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      title="Tool Name"
                      profilePic={process.env.REACT_APP_API_URL + tool.image}
                      data={tool.name}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="ion:pricetags"
                      title="Unit Price"
                      data={tool.unitPrice}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Total In Use"
                      data={toolUsage.inUse}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon={tool.isBlocked ? 'icomoon-free:blocked' : 'gg:check-o'}
                      title="Status"
                      data={tool.isBlocked ? 'Blocked' : 'Active'}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                      statusChange={'yes'}
                      onStatusChange={handleStatusChange}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <DetailItem
                      icon="tool-symbols:description"
                      title="Description"
                      data={tool.description}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <ProjectToolTransaferLogs />
          </Stack>
        </Container>
      )}
    </Page>
  );
}
