import { useState } from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, CircularProgress, Stack, TextField, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../components/chart';
import { toTitleCase } from '../../../utils/commonFunctions';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 100;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function AppCurrentVisits({ title, graphloader, chartColors, chartData, centerHide, ...other }) {
  const theme = useTheme();

  const chartLabels = chartData.map(
    (i) => `${toTitleCase(i._id?.replaceAll('_', ' '))}:  ${fNumber(i.total)}`
  );
  const chartSeries = chartData.map((i) => i.total);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: {
      formatter: (val, opts) => {
        const value = opts.w.config.series[opts.seriesIndex];
        return `${fNumber(value)}`;
      },
      enabled: true,
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => {
            const name = seriesName.split(':');
            return `${name[0]}: `;
          },
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              formatter: (seriesName) => {
                const name = seriesName.split(':');
                return `${name[0]}`;
              },
            },
            value: {
              show: true,
              formatter: (seriesName) => {
                return `${fNumber(seriesName)}`;
              },
            },
            total: {
              show: !centerHide, 
              formatter: (w) => {
                const total = w.config.series.reduce((partialSum, a) => partialSum + a, 0);
                return `${fNumber(total)}`;
              },
            },
          },
        },
        expandOnClick: false,
      },
    },
  });

  return (
    <Card {...other}>
      <Stack direction={'column'} alignItems="center" padding={1} sx={{ width: '100%' }}>
        <Typography marginBottom={1.5} marginTop={1} variant="h5">
          {title}
        </Typography>
      </Stack>
      <ChartWrapperStyle dir="ltr">
        {!graphloader && <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />}
        {graphloader && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        )}
      </ChartWrapperStyle>
    </Card>
  );
}
