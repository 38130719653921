import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const user = JSON.parse(localStorage?.getItem('profile'));
const accessToken = localStorage?.getItem('accessToken');

export const logoutUser = createAsyncThunk('member/logout', async () => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/auth/member/logout`,
    {},
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  localStorage.clear();
  window.location = '/';
  return res;
});

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {
      fullname: user?.fullname || '',
      email: user?.email || '',
      accType: user?.accType || '',
      accessList: user?.accessList || {},
      role: user?.role || '',
      _id: user?._id || '',
      profilePic: user?.profilePic || '',
    },
    pending: null,
    error: false,
    errorMessage: null,
  },

  reducers: {
    updateUser: (state, action) => {
      state.userInfo.fullname = action.payload.fullname;
      state.userInfo.email = action.payload.email;
      state.userInfo.accType = action.payload.accType;
      state.userInfo.accessList = action.payload.accessList;
      state.userInfo.role = action.payload.role;
      state.userInfo._id = action.payload._id;
      state.profilePic = action.payload.profilePic;
    },

    removeUser: (state) => {
      state.userInfo.fullname = null;
      state.userInfo.email = null;
      state.userInfo.accType = null;
      state.userInfo.accessList = null;
      state.userInfo.role = null;
      state.userInfo._id = null;
      state.profilePic = null;
    },
  },
  extraReducers: {
    [logoutUser.pending]: (state) => {
      state.pending = true;
      state.error = false;
    },
    [logoutUser.fulfilled]: (state) => {
      state.pending = false;
      state.userInfo = {};
    },
    [logoutUser.rejected]: (state) => {
      state.pending = false;
      state.error = true;
    },
  },
});

export const { updateUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
