import { styled } from '@mui/system';
import { TableRow } from '@mui/material';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export { StyledTableRow };

const StyledGroupedTableRow = styled(TableRow)(({ theme, number }) => ({
  backgroundColor: number % 2 === 0 ? theme.palette.action.hover : 'white',
}));

export { StyledGroupedTableRow };
