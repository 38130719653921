import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  Box,
  Avatar,
  Grid,
} from '@mui/material';
import {
  Add,
  AddCircle,
  ArrowBack,
  ContentCopy,
  Delete,
  Remove,
  RemoveCircle,
  RemoveRedEye,
} from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components

import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import AddRemoveToolFromProject from '../components/popups/AddRemoveToolFromProject';
import AddToolToProject from '../components/popups/AddToolToProject';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import DetailItem from '../components/smallComponents/DetailItem';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function ProjectTools() {
  const [toolData, setToolData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [toolDataCount, setToolDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddOrReturnPopup, setOpenAddOrReturnPopup] = useState(false);
  const [openAddToProjectPopup, setOpenAddToProjectPopup] = useState(false);
  const [mode, setMode] = useState('add');
  const [selectedIndex, setSelectedIndex] = useState('');
  const [project, setProject] = useState({});

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getToolData(temp);
  };

  const getToolData = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/toolFlowLog/usageInProject`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setToolDataCount(res.data.data.maxRecords);
          setToolData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getToolDataWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/toolFlowLog/usageInProject`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setToolDataCount(res.data.data.maxRecords);
          setToolData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getProject = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProject(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, qtoName: e.target.value };
    setOptions(temp);
    setPages(0);
    getToolDataWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.qtoType) {
      temp.qtoType = filter.qtoType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getToolData(temp);
  };

  useEffect(() => {
    getToolData(options);
    getProject();
  }, []);

  const handleSuccess = () => {
    setOpenAddOrReturnPopup(false);
    setOpenAddToProjectPopup(false);
    setSelectedIndex('');
    getToolData(options);
  };

  return (
    <Page title="Project Tools">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Box padding={2} sx={{ maxWidth: '100%' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {/* <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search quote..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack> */}
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              {/* <Button startIcon={<Add />} variant="contained" onClick={() => setOpenAddToProjectPopup(true)}>
                    Add
                  </Button> */}
              {/* <AddToolToProject
                handleSuccess={handleSuccess}
                open={openAddToProjectPopup}
                setOpen={setOpenAddToProjectPopup}
                tool={tool}
              /> */}
              {/* <Filter applyFilters={applyFilters} /> */}
            </Stack>
          </Stack>
          <Grid container spacing={2} marginBottom={2}>
            <Grid item xs={12}>
              <DetailItem
                icon={'icons8:parallel-tasks'}
                title={'Project Name'}
                data={project?.name}
                sx={{
                  height: '100%',
                  minHeight: '65px',
                  borderRadius: '5px',
                }}
              />
            </Grid>
          </Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Tool</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>In Use</TableCell>
                    <TableCell sx={{ maxWidth: '180px' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {toolData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={item?.image && process.env.REACT_APP_API_URL + item.image}
                            sx={{ width: 40, height: 40 }}
                          >
                            {item?.toolName?.charAt(0).toUpperCase()}
                          </Avatar>
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.toolName}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{item.unitPrice}</TableCell>
                      <TableCell>{item.inUse}</TableCell>
                      <TableCell>
                        <Box>
                          <Link to={`/dashboard/project/tool/view?toolId=${item.toolId}&&projectId=${projectId}`}>
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEye />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getToolData(temp);
            }}
            component={'div'}
            count={toolDataCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </Box>
      )}
    </Page>
  );
}
