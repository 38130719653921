import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from './Page';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  BarGraph,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function SingleProjectAnalytics() {
  const theme = useTheme();
  const { id } = useParams();

  const [manpowerBarGraphData, setManpowerBarGraphData] = useState({ chartLabels: [], chartData: [] });

  const getManpowerBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/manpower/bar-graph/project`, {
        params: {
          graphType,
          startDate,
          endDate,
          projectId: id,
        },
      });
      console.log(res.data.data);
      setManpowerBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Typography variant="h5" sx={{ mb: 1 }}>
        Analytics
      </Typography>

      <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <BarGraph
              callAPI={getManpowerBarGraphData}
              xaxisPlot={'category'}
              title="Manpower"
              unit="Hrs"
              chartLabels={[...manpowerBarGraphData.chartLabels]}
              chartData={[
                {
                  name: 'Manpower',
                  type: 'column',
                  fill: 'solid',
                  data: [...manpowerBarGraphData.chartData],
                  color: theme.palette.navy.main,
                },
              ]}
            />
          </Grid>
      </Grid>
    </>
  );
}
