// import * as React from 'react';
// import Menu from '@mui/material/Menu';
// import axios from 'axios';
// import { Formik, Form } from 'formik';
// import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem, Autocomplete } from '@mui/material';
// import Iconify from '../Iconify';

// const Filter = ({ toggleFilter, applyFilters }) => {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [vendorTypes, setVendorTypes] = React.useState([]);

//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const getVendorType = async () => {
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendorType/list`);
//       if (!res.data.isError) {
//         setVendorTypes(res.data.data);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   React.useEffect(() => {
//     getVendorType();
//   }, []);

//   return (
//     <>
//       <div>
//         <Box style={{ display: 'flex', justifyContent: 'end' }}>
//           <IconButton onClick={handleClick}>
//             <Iconify icon="ic:round-filter-list" />
//             <Typography variant="h6"> Filter</Typography>
//           </IconButton>

//           <Menu
//             id="basic-menu"
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//             MenuListProps={{
//               'aria-labelledby': 'basic-button',
//             }}
//           >
//             <Formik
//               initialValues={{
//                 _id: '',
//                 contactPerson: '',
//                 companyName: '',
//                 email: '',
//                 phone1: '',
//                 phone2: '',
//                 companyAddress: '',
//                 isBlocked: '',
//                 vendorType: '',
//                 trn: '',
//               }}
//               onSubmit={async (values) => {
//                 applyFilters(values);
//               }}
//             >
//               {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
//                 <Form style={{ padding: '10px' }}>
//                   <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         id="_id"
//                         name="_id"
//                         fullWidth
//                         label="Supplier ID"
//                         value={values._id}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         id="companyName"
//                         name="companyName"
//                         fullWidth
//                         label="Company Name"
//                         value={values.companyName}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         id="contactPerson"
//                         name="contactPerson"
//                         fullWidth
//                         label="Contact Person"
//                         value={values.contactPerson}
//                         onChange={handleChange}
//                       />
//                       <Autocomplete
//                         multiple={false}
//                         fullWidth
//                         id="tags-standard"
//                         options={vendorTypes}
//                         getOptionLabel={(option) => option?.name?.replaceAll('_', ' ')}
//                         onChange={(e, value) => {
//                           setFieldValue('vendorType', value ? value._id : null);
//                         }}
//                         renderInput={(params) => (
//                           <TextField {...params} label="Supplier Type" placeholder="Supplier Type" />
//                         )}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         select
//                         fullWidth
//                         id="isBlocked"
//                         name="isBlocked"
//                         label="Status"
//                         value={values.isBlocked}
//                         onChange={handleChange}
//                       >
//                         <MenuItem value="true">Blocked</MenuItem>
//                         <MenuItem value="false">Active</MenuItem>
//                       </TextField>
//                       <TextField id="trn" name="trn" fullWidth label="TRN" value={values.trn} onChange={handleChange} />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         id="email"
//                         name="email"
//                         fullWidth
//                         label="Email"
//                         value={values.email}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         id="phone1"
//                         name="phone1"
//                         fullWidth
//                         label="Phone 1"
//                         value={values.phone1}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         id="phone2"
//                         name="phone2"
//                         fullWidth
//                         label="Phone 2"
//                         value={values.phone2}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Stack direction="row" spacing={1}>
//                       <TextField
//                         type="date"
//                         id="startDate"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         name="startDate"
//                         fullWidth
//                         label="Start Date"
//                         value={values.startDate}
//                         onChange={handleChange}
//                       />
//                       <TextField
//                         type="date"
//                         InputLabelProps={{
//                           shrink: true,
//                         }}
//                         id="endDate"
//                         name="endDate"
//                         fullWidth
//                         label="Start Date"
//                         value={values.endDate}
//                         onChange={handleChange}
//                       />
//                     </Stack>
//                     <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
//                       Filter
//                     </Button>
//                   </Stack>
//                 </Form>
//               )}
//             </Formik>
//           </Menu>
//         </Box>
//       </div>
//     </>
//   );
// };

// export default Filter;

import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import { TextField, Button, Stack, MenuItem, Grid, Card, Collapse, IconButton, Typography, Box } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters }) => {
  const [open, setOpen] = React.useState(false);
  const [vendorTypes, setVendorTypes] = React.useState([]);

  const formik = useFormik({
    initialValues: {
      _id: '',
      contactPerson: filterValues.contactPerson,
      companyName: filterValues.companyName,
      email: filterValues.email,
      phone1: filterValues.phone1,
      phone2: filterValues.phone2,
      companyAddress: filterValues.companyAddress,
      isBlocked: filterValues.isBlocked,
      vendorType: filterValues.vendorType,
      trn: filterValues.trn,
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getVendorType = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendorType/list`);
      if (!res.data.isError) {
        setVendorTypes(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getVendorType();
  }, []);

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="_id" name="_id" label="Supplier ID" {...getFieldProps('_id')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    {...getFieldProps('companyName')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="contactPerson"
                    name="contactPerson"
                    label="Contact Person"
                    {...getFieldProps('contactPerson')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    fullWidth
                    id="vendorType"
                    name="vendorType"
                    label="Supplier Type"
                    {...getFieldProps('vendorType')}
                  >
                    {vendorTypes.map((type) => (
                      <MenuItem key={type._id} value={type._id}>
                        {type?.name?.replaceAll('_', ' ')}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    select
                    fullWidth
                    id="isBlocked"
                    name="isBlocked"
                    label="Status"
                    {...getFieldProps('isBlocked')}
                  >
                    <MenuItem value="true">Blocked</MenuItem>
                    <MenuItem value="false">Active</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="trn" name="trn" label="TRN" {...getFieldProps('trn')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="email" name="email" label="Email" {...getFieldProps('email')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="phone1" name="phone1" label="Phone 1" {...getFieldProps('phone1')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="phone2" name="phone2" label="Phone 2" {...getFieldProps('phone2')} />
                </Grid>
                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          _id: '',
                          contactPerson: '',
                          companyName: '',
                          email: '',
                          phone1: '',
                          phone2: '',
                          companyAddress: '',
                          isBlocked: '',
                          vendorType: '',
                          trn: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
