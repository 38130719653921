import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Autocomplete,
  Avatar,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';

import avatar from '../../assets/avatar.png';

const AddMaterialToProject = ({ handleSuccess, open, setOpen, material }) => {
  const [projects, setProjects] = React.useState([]);
  const [open3, setOpen3] = React.useState(false);
  const [supervisors, setSupervisors] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    materialId: Yup.string().required('Material is required'),
    projectId: Yup.string().required('Project is required'),
    quantity: Yup.number().required('Quantity is required'),
    date: Yup.date().required('Date is required'),
    description: Yup.string(),
    unit: Yup.string().required('Unit is required'),
  });

  const getProjects = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
        params: {
          statuses: ['APPROVED', 'ONGOING'],
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
      } 
    } catch (error) {
      console.log(error);
    }
  };

  const getSupervisors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'SUPERVISOR',
        },
      });
      console.log(res.data.data);
      setSupervisors(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProjects();
    getSupervisors();
  }, []);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Material</DialogTitle>
            <DialogContent
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '500px',
                },
              }}
            >
              <Formik
                initialValues={{
                  materialId: material._id,
                  projectId: '',
                  quantity: '',
                  date: moment().format('YYYY-MM-DD'),
                  description: '',
                  unit: material.unit,
                  supervisor: '',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios.post(
                      `${process.env.REACT_APP_API_URL}/materialFlowLog/addToProject`,
                      values
                    );
                    actions.setSubmitting(false);
                    handleSuccess();
                    toast.success(response.data.message);
                    handleClose();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Stack spacing={2} mt={2}>
                      <Stack direction="row" spacing={2}>
                        <TextField value={material.name} fullWidth id="materialId" name="materialId" label="Material" />
                        <TextField
                          fullWidth
                          id="date"
                          name="date"
                          label="Date"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...formik.getFieldProps('date')}
                          error={formik.touched.date && Boolean(formik.errors.date)}
                          helperText={formik.touched.date && formik.errors.date}
                        />
                      </Stack>
                      <Autocomplete
                        id="asynchronous-demo"
                        fullWidth
                        open={open3}
                        onOpen={() => {
                          setOpen3(true);
                        }}
                        onClose={() => {
                          setOpen3(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={projects}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('projectId', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(formik.touched.projectId && formik.errors.projectId)}
                            helperText={formik.touched.projectId && formik.errors.projectId}
                            label="Project"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                      <Stack direction="row" spacing={2}>
                        <TextField
                          fullWidth
                          id="unit"
                          name="unit"
                          label="Unit"
                          {...formik.getFieldProps('unit')}
                          error={formik.touched.unit && Boolean(formik.errors.unit)}
                          helperText={formik.touched.unit && formik.errors.unit}
                        />
                        <TextField
                          fullWidth
                          id="quantity"
                          name="quantity"
                          label="Quantity"
                          {...formik.getFieldProps('quantity')}
                          error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                          helperText={formik.touched.quantity && formik.errors.quantity}
                        />
                      </Stack>

                      <Autocomplete
                        fullWidth
                        id="asynchronous-demo"
                        onChange={(_, value) => {
                          console.log('Selected Value:', value);
                          formik.setFieldValue('supervisor', value ? value._id : null);
                        }}
                        isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                        getOptionLabel={(option) => option.fullname}
                        getOptionSelected={(option, value) => option.fullname === value.fullname}
                        options={supervisors}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            label="Supervisor"
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                src={
                                  (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar
                                }
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.fullname}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                  {option.role?.name?.replaceAll('_', ' ')}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />

                      <TextField
                        fullWidth
                        multiline
                        id="description"
                        name="description"
                        label="Description"
                        {...formik.getFieldProps('description')}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                      />
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                          Add
                        </Button>
                      </DialogActions>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddMaterialToProject;
