import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ToolUsageTable from './ToolUsageTable';
import ToolTransaferLogs from './ToolTransferLogs';

// components

export default function ToolTableTab({ tool, getTool }) {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card>
      <Box sx={{ width: '100%' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Summary" value="1" />
              <Tab label="History" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ToolUsageTable tool={tool} getTool={getTool} />
          </TabPanel>
          <TabPanel value="2">
            <ToolTransaferLogs type={'tool'} />
          </TabPanel>
        </TabContext>
      </Box>
    </Card>
  );
}
