import moment from 'moment';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { Add, CancelOutlined, DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { convertToWords } from '../utils/commonFunctions';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white !important',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white !important',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white !important',
  },
  '& .MuiInputLabel-root': {
    color: 'white !important',
  },
  '& .MuiInputAdornment-root': {
    color: 'white !important',
  },
  '& .MuiInputAdornment-positionEnd': {
    // Assuming the date picker icon is at the end
    '& .MuiIconButton-root': {
      color: 'white', // Change icon color to white
    },
  },
});
function EditPurchaseOrderForm({ purchaseOrderData, getPurchaseOrder, setEditMode }) {
  const [company, setCompany] = useState({});
  const [vendor, setVendor] = useState({});
  const [items, setItems] = useState([]);
  const [accountSettings, setAccountSettings] = useState({});
  const [paymentTerm, setPaymentTerm] = useState('');
  const [notes, setNotes] = useState('');
  const [termsAndCondition, setTermsAndCondition] = useState('');
  const [files, setFiles] = useState([]);
  const [currentGrandTotalInWords, setCurrentGrandTotalInWords] = useState('');

  const purchaseOrderSchema = Yup.object().shape({
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
    orderDate: Yup.string().required('Order Date is required'),
    requiredDeliveryDate: Yup.string().required('Required Delivery Date is required'),
    vendorId: Yup.string().required('Supplier is required'),
  });

  const formik = useFormik({
    initialValues: {
      _id: purchaseOrderData?._id,
      vendorId: '',
      projectId: '',
      quotationId: '',
      orderDate: moment().format('YYYY-MM-DD'),
      requiredDeliveryDate: '',
      items: [],
      subTotal: '',
      grandTotal: '',
      termsAndConditions: [],
      notes: [],
      paymentTerms: [],
      grandTotalInWords: '',
      referenceNumber: '',
      preparedBy: '',
      preparedByDesignation: '',
      approvedBy: '',
      approvedByDesignation: '',
      attn: '', 
      attachments: [],
    },
    validationSchema: purchaseOrderSchema,
    onSubmit: async () => {
      try {
        if (values?.grandTotalInWords === currentGrandTotalInWords) {
          delete values.grandTotalInWords;
        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/purchaseOrder`, values);
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        getPurchaseOrder();
        setEditMode(false);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getQuotationItems = async (quotationId) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/items/list`, {
        params: {
          id: quotationId,
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setItems(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getVendor = async (id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, { params: { _id: id } });
      console.log(res.data.data);
      setVendor(res.data.data.vendor);
    } catch (error) {
      console.log(error);
    }
  };

  const getCompany = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`);
      console.log(res.data.data, 'company');
      setCompany(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountSettings = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/accountSettings`);
      console.log(res.data.data, 'accountSettings');
      setAccountSettings(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (purchaseOrderData) {
      formik.setFieldValue('_id', purchaseOrderData?._id);
      formik.setFieldValue('orderDate', moment(purchaseOrderData?.orderDate).format('YYYY-MM-DD'));
      formik.setFieldValue(
        'requiredDeliveryDate',
        moment(purchaseOrderData?.requiredDeliveryDate).format('YYYY-MM-DD')
      );
      formik.setFieldValue('quotationId', purchaseOrderData?.quotationId?._id);
      formik.setFieldValue('vendorId', purchaseOrderData?.vendorId?._id);
      formik.setFieldValue('subTotal', purchaseOrderData?.subTotal);
      formik.setFieldValue('grandTotal', purchaseOrderData?.grandTotal);
      formik.setFieldValue('grandTotalInWords', purchaseOrderData?.grandTotalInWords);
      formik.setFieldValue('paymentTerms', purchaseOrderData?.paymentTerms);
      formik.setFieldValue('notes', purchaseOrderData?.notes);
      formik.setFieldValue('termsAndConditions', purchaseOrderData?.termsAndConditions);
      formik.setFieldValue('referenceNumber', purchaseOrderData?.referenceNumber);
      formik.setFieldValue('preparedBy', purchaseOrderData?.preparedByInWords?.fullname);
      formik.setFieldValue('preparedByDesignation', purchaseOrderData?.preparedByInWords?.designation);
      formik.setFieldValue('approvedBy', purchaseOrderData?.approvedByInWords?.fullname);
      formik.setFieldValue('approvedByDesignation', purchaseOrderData?.approvedByInWords?.designation);
      formik.setFieldValue('attn', purchaseOrderData?.to?.attn);
      formik.setFieldValue('items', purchaseOrderData?.items);
      setCurrentGrandTotalInWords(purchaseOrderData?.grandTotalInWords);
    }
  }, [purchaseOrderData]);

  useEffect(() => {
    if (values.quotationId) {
      getQuotationItems(values.quotationId);
    }
  }, [values.quotationId]);

  useEffect(() => {
    if (values.vendorId) {
      getVendor(values.vendorId);
    }
  }, [values.vendorId]);

  useEffect(() => {
    getCompany();
    getAccountSettings();
  }, []);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.quantity) * Number(item.unitPrice);
      grandTotal += Number(item.total);
    });
    const grandTotalInWords = convertToWords(grandTotal);
    formik.setFieldValue('grandTotalInWords', grandTotalInWords);
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
    console.log(items);
  }, [values.items]);

  const totalCalculator = (quantity, price) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    return total.toFixed(2);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack
          direction={'row'}
          px={5}
          py={3}
          sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
          justifyContent={'space-between'}
        >

          <Stack justifyContent="center" spacing={2} direction={'column'}>
            <Stack direction={'row'} spacing={2}>
              <StyledTextField
                label="Order Date"
                size="small"
                type="date"
                //  CHANGE DATE ICON COLOR TO WHITE
                {...getFieldProps('orderDate')}
                error={Boolean(touched.orderDate && errors.orderDate)}
                helperText={touched.orderDate && errors.orderDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyledTextField
                label="Required Delivery Date"
                size="small"
                type="date"
                {...getFieldProps('requiredDeliveryDate')}
                error={Boolean(touched.requiredDeliveryDate && errors.requiredDeliveryDate)}
                helperText={touched.requiredDeliveryDate && errors.requiredDeliveryDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Stack>
          </Stack>
          <Stack direction={'column'} spacing={1} alignItems={'center'}>
            <Button color="light" onClick={() => setEditMode(false)} size="small" startIcon={<CancelOutlined />}>
              Cancel
            </Button>
          </Stack>
        </Stack>
        <Grid container rowGap={2} mt={5}>
          <Grid item xs={12}>
            <Stack px={5} direction="row" spacing={2} alignItems={'center'}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '120px' }}>
                Reference Number:
              </Typography>
              <TextField
                size="small"
                {...getFieldProps('referenceNumber')}
                error={Boolean(touched.referenceNumber && errors.referenceNumber)}
                helperText={touched.referenceNumber && errors.referenceNumber}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                Supplier
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{vendor.companyName}</Typography>
                <Typography>{vendor.contactPerson}</Typography>
                <Typography>{vendor.companyAddress}</Typography>
                <Typography>{vendor.email}</Typography>
                <Typography>{vendor.phone1}</Typography>
                {vendor?.trn && <Typography>{`TRN: ${vendor?.trn}`}</Typography>}
                <Stack direction="row" spacing={1} alignItems={'center'}>
                  <Typography>Attn:</Typography>
                  <TextField
                    size="small"
                    {...getFieldProps('attn')}
                    error={Boolean(touched.attn && errors.attn)}
                    helperText={touched.attn && errors.attn}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack px={5} pt={5}>
              <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                Invoice To
              </Typography>
              <Stack paddingLeft={4}>
                <Typography>{company.companyName}</Typography>
                <Typography>{company.address}</Typography>
                <Typography>{company.pin}</Typography>
                <Typography>{company.phone}</Typography>
                <Typography>{company.email}</Typography>
                <Typography>{`TRN: ${accountSettings?.trn}`}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            margin={1}
            sx={{
              borderRadius: '5px',
            }}
          >
            <Stack p={5}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Stack justifyContent={'start'} sx={{ height: '100%' }}>
                            <Autocomplete
                              sx={{
                                minWidth: '150px',
                              }}
                              id="asynchronous-demo"
                              freeSolo // allow free input
                              onInputChange={(e, value) => {
                                const newItems = [...values.items];
                                newItems[index] = { ...newItems[index], name: value };
                                formik.setFieldValue('items', newItems);
                              }}
                              isOptionEqualToValue={(option, value) => option.name === value.name}
                              getOptionLabel={(option) => option.name}
                              defaultValue={{ name: item.name }}
                              getOptionSelected={(option, value) => option.name === value.name}
                              options={items}
                              onChange={(_, value) => {
                                if (value) {
                                  const newItems = [...values.items];
                                  newItems[index] = {
                                    ...newItems[index],
                                    name: value.name,
                                    unitPrice: value.unitPrice || 0,
                                    quantity: value.quantity || 0,
                                    total: totalCalculator(value.quantity || 0, value.unitPrice || 0),
                                  };
                                  formik.setFieldValue('items', newItems);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                  }}
                                />
                              )}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.name}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                      {option.unitPrice} AED
                                    </Typography>
                                  </Stack>
                                </li>
                              )}
                            />
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ width: '100px' }}>
                          <TextField
                            disabled={!item.name}
                            fullWidth
                            type="number"
                            value={item.quantity || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                quantity: Number(e.target.value),
                                total: totalCalculator(Number(e.target.value), item.unitPrice || 0),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: '120px',
                          }}
                        >
                          <TextField
                            fullWidth
                            type="number"
                            value={item.unitPrice || ''}
                            onChange={(e) => {
                              const newItems = [...values.items];
                              newItems[index] = {
                                ...newItems[index],
                                unitPrice: Number(e.target.value),
                                total: totalCalculator(item.quantity || 0, Number(e.target.value)),
                              };
                              formik.setFieldValue('items', newItems);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography>{item.quantity * item.unitPrice}</Typography>
                        </TableCell>

                        <TableCell>
                          <IconButton
                            onClick={() => {
                              const newItems = [...values.items];
                              newItems.splice(index, 1);
                              formik.setFieldValue('items', newItems);
                            }}
                            variant="outlined"
                            color="error"
                          >
                            <DeleteForever />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    const newItems = [...values.items];
                    newItems.push({
                      name: '',
                      unitPrice: 0,
                      quantity: 0,
                      total: 0,
                    });
                    formik.setFieldValue('items', newItems);
                  }}
                >
                  Add Item
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
        <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
          <Stack justifyContent="center" sx={{ width: '300px' }} spacing={2} direction={'column'}>
           
            <Stack direction={'row'} sx={{ width: '100%' }} justifyContent={'space-between'}>
              <Typography sx={{ fontWeight: 600 }}>Grand Total(AED)</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {values.grandTotal && parseFloat(values.grandTotal).toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>Grand Total In Words</Typography>
          <TextField
            fullWidth
            value={values.grandTotalInWords}
            onChange={(e) => formik.setFieldValue('grandTotalInWords', e.target.value)}
          />
        </Stack>
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Payment Terms
          </Typography>
          {values?.paymentTerms?.map((term, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${term}`}</Typography>
              <IconButton
                onClick={() => {
                  const newPaymentTerms = [...values.paymentTerms];
                  newPaymentTerms.splice(index, 1);
                  formik.setFieldValue('paymentTerms', newPaymentTerms);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField
              label="Payment Term"
              fullWidth
              value={paymentTerm}
              onChange={(e) => setPaymentTerm(e.target.value)}
            />
            <IconButton
              onClick={() => {
                formik.setFieldValue('paymentTerms', [...values.paymentTerms, paymentTerm]);
                setPaymentTerm('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>
        {/*  terms and conditions */}
        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Terms and Conditions
          </Typography>
          {values?.termsAndConditions?.map((term, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${term}`}</Typography>
              <IconButton
                onClick={() => {
                  const newTermsAndConditions = [...values.termsAndConditions];
                  newTermsAndConditions.splice(index, 1);
                  formik.setFieldValue('termsAndConditions', newTermsAndConditions);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField
              label="Terms and Condition"
              fullWidth
              value={termsAndCondition}
              onChange={(e) => setTermsAndCondition(e.target.value)}
            />
            <IconButton
              onClick={() => {
                formik.setFieldValue('termsAndConditions', [...values.termsAndConditions, termsAndCondition]);
                setTermsAndCondition('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>

        <Stack px={5} pt={5}>
          <Typography sx={{ fontWeight: 600 }} gutterBottom>
            Notes
          </Typography>
          {values?.notes?.map((note, index) => (
            <Stack direction={'row'} spacing={2} alignItems={'center'} key={index} justifyContent={'space-between'}>
              <Typography>{`${index + 1}. ${note}`}</Typography>
              <IconButton
                onClick={() => {
                  const newNotes = [...values.notes];
                  newNotes.splice(index, 1);
                  formik.setFieldValue('notes', newNotes);
                }}
              >
                <DeleteForever />
              </IconButton>
            </Stack>
          ))}
          <Stack direction={'row'} spacing={2} mt={2}>
            <TextField label="Note" fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
            <IconButton
              onClick={() => {
                formik.setFieldValue('notes', [...values.notes, notes]);
                setNotes('');
              }}
            >
              <Add />
            </IconButton>
          </Stack>
        </Stack>

        {/*  prepared by and approved By */}
        <Grid container rowGap={2} my={10}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                  Prepared By:
                </Typography>
                <TextField
                  label="Prepared By"
                  size="small"
                  {...getFieldProps('preparedBy')}
                  error={Boolean(touched.preparedBy && errors.preparedBy)}
                  helperText={touched.preparedBy && errors.preparedBy}
                />
              </Stack>
              <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                  Prepared By Designation:
                </Typography>
                <TextField
                  label="Prepared By Designation"
                  size="small"
                  {...getFieldProps('preparedByDesignation')}
                  error={Boolean(touched.preparedByDesignation && errors.preparedByDesignation)}
                  helperText={touched.preparedByDesignation && errors.preparedByDesignation}
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                  Approved By:
                </Typography>
                <TextField
                  label="Approved By"
                  size="small"
                  {...getFieldProps('approvedBy')}
                  error={Boolean(touched.approvedBy && errors.approvedBy)}
                  helperText={touched.approvedBy && errors.approvedBy}
                />
              </Stack>
              <Stack px={5} direction="row" spacing={2} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1', minWidth: '70px' }}>
                  Approved By Designation:
                </Typography>
                <TextField
                  label="Approved By Designation"
                  size="small"
                  {...getFieldProps('approvedByDesignation')}
                  error={Boolean(touched.approvedByDesignation && errors.approvedByDesignation)}
                  helperText={touched.approvedByDesignation && errors.approvedByDesignation}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Stack p={5} sx={{ textAlign: 'right' }} justifyContent={'end'} spacing={5} direction={'row'}>
          <Button type="button" variant="contained" onClick={() => resetForm()}>
            Reset
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Submit
          </Button>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

export default EditPurchaseOrderForm;
