import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack, IconButton } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Page from '../components/Page';
import PendingBillingPayments from '../components/PendingBillingPayments';
import PendingPurchasePayments from '../components/PendingPurchasePayments';
import PendingSalaryPayments from '../components/PendingSalaryPayments';
import PendingJournalPayments from '../components/PendingJournalPayments';
import PageHeader from '../components/smallComponents/PageHeader';

export default function PendingPayments() {
  const [value, setValue] = useState('1');
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Pending Payments">
        <Container maxWidth="xl">
          <PageHeader title={'Pending Payments'} />
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Invoice" value="1" />
                    <Tab label="Purchase" value="2" />
                    {/* <Tab label="Salary" value="3" /> */}
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <PendingBillingPayments />
                </TabPanel>
                <TabPanel value="2">
                  <PendingPurchasePayments />
                </TabPanel>
                {/* <TabPanel value="3">
                  <PendingSalaryPayments />
                </TabPanel> */}
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
