import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/PurchaseOrder';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import { formatNumberLpoReference } from '../utils/commonFunctions';
import { StyledTableRow } from '../theme/styledComponents';
// import Popup from './popups/PurchaseOrderOrderstatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

function PurchaseOrderTable({ status }) {
  const navigate = useNavigate();
  const [purchaseorderData, setPurchaseOrderData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [purchaseorderCount, setPurchaseOrderCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, status });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getPurchaseOrderOrders(temp);
  };

  const getPurchaseOrderOrders = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/purchaseOrder/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setPurchaseOrderCount(res.data.data.maxRecords);
          setPurchaseOrderData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getPurchaseOrderOrdersWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/purchaseOrder/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setPurchaseOrderCount(res.data.data.maxRecords);
          setPurchaseOrderData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, companyName: e.target.value };
    setOptions(temp);
    setPages(0);
    getPurchaseOrderOrdersWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    setName('');
    const temp = { ...options, page: 0 };

    if (filter.poReference) {
      temp.poReference = filter.poReference;
    }
    if (filter.vendorId) {
      temp.vendorId = filter.vendorId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.referenceNumber) {
      temp.referenceNumber = filter.referenceNumber;
    }

    setOptions(temp);
    setPages(0);
    getPurchaseOrderOrders(temp);
  };
  useEffect(() => {
    getPurchaseOrderOrders(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getPurchaseOrderOrders(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <>
              <Filter applyFilters={applyFilters} filterValues={options} />

              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search Company name..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={() => navigate('/dashboard/purchaseOrder/add')}
                  >
                    Purchase Order
                  </Button>
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Order Date</TableCellCustom>
                        <TableCellCustom>Required Delivery Date</TableCellCustom>
                        <TableCellCustom>PO Reference</TableCellCustom>
                        <TableCellCustom>Supplier</TableCellCustom>
                        <TableCellCustom>Grand Total</TableCellCustom>
                        <TableCellCustom>View</TableCellCustom>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {purchaseorderData?.map((item, id) => (
                       <StyledTableRow key={id}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{moment(item?.orderDate).format('DD-MM-YYYY')}</TableCellCustom>
                          <TableCellCustom>{moment(item?.requiredDeliveryDate).format('DD-MM-YYYY')}</TableCellCustom>
                          <TableCellCustom>{formatNumberLpoReference(item?.poReference)}</TableCellCustom>
                          <TableCellCustom>{item?.vendor}</TableCellCustom>
                          <TableCellCustom>{item?.grandTotal}</TableCellCustom>
                          <TableCellCustom>
                            <Link to={`/dashboard/purchaseOrder/view/${item?._id}`}>
                              <Button variant="contained" color="primary" startIcon={<RemoveRedEyeIcon />} size="small">
                                View
                              </Button>
                            </Link>
                          </TableCellCustom>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={purchaseorderCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </Container>
        </>
      )}
    </>
  );
}

export default PurchaseOrderTable;
