import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Card,
  Chip,
  Box,
  Avatar,
  Typography,
} from '@mui/material';
import { Add, AddCircle, ArrowBack, ContentCopy, Delete, Edit, RemoveCircle } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Logs';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function ProjectMaterialTransferLogs() {
  const [searchParams, setSearchParams] = useSearchParams();
  const materialId = searchParams.get('materialId');
  const projectId = searchParams.get('projectId');
  const [logData, setLogData] = useState();
  const [pages, setPages] = useState(0);
  const [logCount, setLogCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getLogs(temp);
  };

  const getLogs = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/material`, {
        params: {
          ...options,
          materialId,
          projectId,
        },
      })
      .then((res) => {
        console.log('Payments', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLogCount(res.data.data.maxRecords);
          setLogData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.logType) {
      temp.logType = filter.logType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getLogs(temp);
  };

  useEffect(() => {
    getLogs(options);
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Card>
          

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Projet Name</TableCell>
                    <TableCell>Supervisor</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>{Moment(item.date).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>
                        <Chip
                          size="small"
                          icon={item?.type === 'WAREHOUSE_IN' ? <AddCircle /> : <RemoveCircle />}
                          label={item?.type === 'WAREHOUSE_IN' ? 'IN' : 'OUT'}
                          color={item?.type === 'WAREHOUSE_IN' ? 'successDarker' : 'errorDarker'}
                        />
                      </TableCell>
                      <TableCell>{item?.projectName}</TableCell>
                      <TableCell>
                        {item?.supervisor && (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={
                                (item?.supervisor?.profilePic &&
                                  process.env.REACT_APP_API_URL + item.supervisor?.profilePic) ||
                                'https://www.w3schools.com/howto/img_avatar.png'
                              }
                              sx={{ width: 40, height: 40 }}
                            />
                            <Box sx={{ marginLeft: 1 }}>
                              <Typography variant="subtitle2">{item?.supervisor?.fullname}</Typography>
                              <Typography variant="caption">{item?.supervisor?.email}</Typography>
                            </Box>
                          </Box>
                        )}
                      </TableCell>
                      <TableCell>{item?.unit}</TableCell>
                      <TableCell>{item?.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getLogs(temp);
            }}
            component={'div'}
            count={logCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </Card>
      )}
    </>
  );
}
