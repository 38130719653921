import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import axios from 'axios';

import Page from '../components/Page';
import CheckBoxGroupView from '../components/smallComponents/CheckBoxGroupView';
import PageHeader from '../components/smallComponents/PageHeader';

function ViewMemberRole() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [general, setGeneral] = useState({
    settings: { r: false, w: false, x: false },
    employee: { r: false, w: false, x: false },
    customer: { r: false, w: false, x: false },
    vendor: { r: false, w: false, x: false },
    admin: { r: false, w: false, x: false },
  });

  const [crm, setCrm] = useState({
    settings: { r: false, w: false, x: false },
    lead: { r: false, w: false, x: false },
    manager: { r: false, w: false, x: false },
    teleCaller: { r: false, w: false, x: false },
  });

  const [project, setProject] = useState({
    project: { r: false, w: false, x: false },
    qto: { r: false, w: false, x: false },
    worker: { r: false, w: false, x: false },
    material: { r: false, w: false, x: false },
    tool: { r: false, w: false, x: false },
  });

  const [warehouse, setWarehouse] = useState({
    tool: { r: false, w: false, x: false },
    material: { r: false, w: false, x: false },
    purchase: { r: false, w: false, x: false },
  });

  const [name, setName] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);

  const getMemberRole = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/memberRole/`, {
        params: { id },
      });
      if (!res.data.isError) {
        console.log(res.data.data);
        setName(res.data.data.name);
        setIsBlocked(res.data.data.isBlocked);
        setCrm(res.data.data.accessList.crm);
        setGeneral(res.data.data.accessList.general);
        setProject(res.data.data.accessList.project);
        setWarehouse(res.data.data.accessList.warehouse);
        setIsLoading(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getMemberRole();
  }, []);

  return (
    <Page title="Member Role">
      <Container maxWidth="xl">
        <PageHeader title="Member Role" />
        <Card>
          {isLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '50vh',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box padding={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8} md={9} lg={9}>
                  <TextField fullWidth label="Name" value={name} />
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                  <TextField fullWidth label="Status" value={isBlocked ? 'Blocked' : 'Active'} />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <CheckBoxGroupView name="General" accessList={general} />
                    <CheckBoxGroupView name="CRM" accessList={crm} />
                    <CheckBoxGroupView name="Project" accessList={project} />
                    <CheckBoxGroupView name="Warehouse" accessList={warehouse} />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Card>
      </Container>
    </Page>
  );
}

export default ViewMemberRole;
