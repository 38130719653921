import { useEffect } from 'react';
// routes
import Router from './routes';
import { StateProvider } from './components/Context/Context';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';

import { GlobalDebug } from './utils/RemoveConsoles';
import './app.css';

// ----------------------------------------------------------------------

export default function App() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') {
      GlobalDebug(false);
    }
  }, []);
  return (
    <StateProvider>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </ThemeProvider>
    </StateProvider>
  );
}
