import { Avatar, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const pictureLink = 'https://www.w3schools.com/howto/img_avatar.png';

const possibleRoles = [
  { name: 'EMPLOYEE', path: '/dashboard/general/employees/view' },
  { name: 'SALES_MANAGER', path: '/dashboard/general/employees/lead-manager' },
  { name: 'SALES_STAFF', path: '/dashboard/general/employees/sales-staff' },
  { name: 'PROJECT_MANAGER', path: '/dashboard/general/employees/project-manager' },
  { name: 'PROJECT_ENGINEER', path: '/dashboard/general/employees/project-engineer' },
  { name: 'ACCOUNTANT', path: '/dashboard/general/employees/accountant' },
  { name: 'WORKER', path: '/dashboard/general/employees/worker' },
];

EmployeeProfileAvatar.propTypes = {
  profilePic: PropTypes.string,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  role: PropTypes.string,
  id: PropTypes.string,
};

export default function EmployeeProfileAvatar({ profilePic, firstLine, secondLine, role, id }) {
  const navigate = useNavigate();

  const getPath = (role, id) => {
    const pagePath = possibleRoles.find((p) => p.name === role);
    if (pagePath) {
      return `${pagePath.path}/${id}`;
    }
    return `/dashboard/general/employees/view/${id}`;
  };

  const handleClick = () => {
    if (role && id) {
      navigate(getPath(role, id));
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        onClick={handleClick}
        src={(profilePic && process.env.REACT_APP_API_URL + profilePic) || pictureLink}
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
      />
      <Stack sx={{ marginLeft: 1 }} spacing={0.2}>
        <Typography variant="subtitle2">{firstLine}</Typography>
        <Typography variant="caption">{secondLine}</Typography>
      </Stack>
    </Box>
  );
}
