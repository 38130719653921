import { Autocomplete, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Iconify from './Iconify';
import LoadingIcon from './smallComponents/LoadingIcon';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
        <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ProjectAccountSummary({ quotations, setQuotationId, quotationId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');

  const [accountSummary, setAccountSummary] = useState({});
  const [quotationSummary, setQuotationSummary] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);

  const getProjectAccountSummary = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/payment/billing/project/summary`, {
        params: { projectId, quotationId },
      });
      console.log(res.data.data);
      if (!res.data.isError) {
        setAccountSummary(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectQuotationSummary = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/project/summary`, {
        params: { projectId, quotationId },
      });
      console.log(res.data.data);
      if (!res.data.isError) {
        console.log(res.data.data.data);
        setQuotationSummary(res.data.data.data);
      }
      setIsLoading1(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectAccountSummary();
    getProjectQuotationSummary();
  }, [quotationId]);

  return (
    <Card>
      <>
        {isLoading ? (
          <LoadingIcon />
        ) : (
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12} md={8} lg={9}>
              <DetailItem icon="mdi:account" title="Project" data={accountSummary?.projectData?.name} />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Stack
                alignItems="center"
                spacing={1}
                direction="row"
                sx={{
                  height: '100%',
                  background: (theme) => theme.palette.macinsIndigo.lighter,
                  minHeight: '65px',
                  borderRadius: '5px',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
                  background={'#f0f0f0'}
                  padding={1}
                >
                  <Iconify icon={'ss'} style={{ height: '25px', width: '25px' }} />
                </Stack>
                <Stack direction="column" sx={{ padding: 1 }}>
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>Quotation</Typography>
                  <Autocomplete
                    id="asynchronous-demo"
                    isOptionEqualToValue={(option, value) => option.quotationId === value.quotationId}
                    getOptionLabel={(option) => option.quotationId}
                    getOptionSelected={(option, value) => option.quotationId === value.quotationId}
                    defaultValue={quotations[0]}
                    options={quotations}
                    onChange={(_, value) => {
                      if (value) {
                        setQuotationId(value.quotationId);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          width: '180px',
                        }}
                        // label="Quotation"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction={'column'}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                            {option.quotationId}
                          </Typography>
                        </Stack>
                      </li>
                    )}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Quotation Sub Total"
                data={quotationSummary?.quotationTotal?.toFixed(2) || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Quotation Discount"
                data={quotationSummary?.quotationDiscount?.toFixed(2) || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Quotation Grand Total"
                data={(quotationSummary?.quotationTotal - quotationSummary?.quotationDiscount).toFixed(2) || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Invoice Sub Total"
                data={accountSummary?.subTotal?.toFixed(2) || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Invoice Tax"
                data={accountSummary?.totalTax?.toFixed(2) || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Recieved Amount"
                data={accountSummary?.paidAmount?.toFixed(2) || 0}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DetailItem
                icon="majesticons:money-line"
                title="Invoice Balance"
                data={accountSummary?.balanceAmount?.toFixed(2) || 0}
              />
            </Grid>
          </Grid>
        )}
      </>
    </Card>
  );
}

export default ProjectAccountSummary;
