import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CustomerAccountTables from '../components/CustomerAccountTables';
import Page from '../components/Page';
import LoadingIcon from '../components/smallComponents/LoadingIcon';
import PageHeader from '../components/smallComponents/PageHeader';

function CustomerAccounts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const customerId = searchParams.get('customerId');
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState({});

  const getCustomer = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/one`, {
        params: {
          id: customerId,
        },
      });
      console.log('customer', res);
      setCustomer(res.data.data.customer);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCustomer();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Page title="Customer Accounts">
          <Container maxWidth="xl">
            <PageHeader title="Customer Accounts" />
            <CustomerAccountTables customer={customer} />
          </Container>
        </Page>
      )}
    </>
  );
}

export default CustomerAccounts;
