import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  Chip,
} from '@mui/material';
import { Box } from '@mui/system';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from '../components/filters/TeleCallers';

// components
import { useSelector } from 'react-redux';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/TeleCallerStatusChange ';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function TeleCallers() {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const { id } = useParams();
  const [telecallersData, setLeadManagerData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [telecallersCount, setLeadManagerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0, id });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const mobileRegExp = '^[6789][0-9]{9}';

  const telecallersSchema = Yup.object().shape({
    telecallers: Yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      phone: '',
      telecallers: '',
      email: '',
      password: '',
    },
    validationSchema: telecallersSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/telecallers/admin`, values);
        if (!res.data.isError) {
          closeAddLeadManagerModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getTelecallers(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleStatusChange1 = (event, item, index) => {
    console.log('item', item);
    if (`${item.isPopular}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getTelecallers(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddLeadManagerModal = () => {
    setOpen(true);
  };
  const closeAddLeadManagerModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage, id };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTelecallers(temp);
  };

  const getTelecallers = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadTeam/member/telecallers`, {
        params: options,
      })
      .then((res) => {
        console.log('tele caller', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLeadManagerCount(res.data.data.maxRecords);
          setLeadManagerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTelecallersWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadTeam/member/telecallers`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setLeadManagerCount(res.data.data.maxRecords);
          setLeadManagerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(telecallersCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1, id };
    setOptions(temp);
    getTelecallers(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value, id };
    setOptions(temp);
    setPages(0);
    getTelecallersWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    // if (filter.name.length) {
    //   temp.name = filter.name;
    // }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.isPopular.length) {
      temp.isPopular = filter.isPopular;
    }

    setOptions(temp);
    setPages(0);
    getTelecallers(temp);
  };
  useEffect(() => {
    getTelecallers(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0, id };
    setPages(0);
    setOptions(temp);
    getTelecallers(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Box
          sx={{ boxShadow: '#d0cfcf 0px 1px 4px', borderRadius: '10px', bgcolor: 'background.paper' }}
          paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}
          paddingTop={1}
          paddingBottom={2}
        >
          {/* Add telecallers Dialog */}
          {/* <Dialog open={open} onClose={closeAddLeadManagerModal} fullScreen={matches}>
              <DialogTitle>Add Telecaller</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Firstname"
                            {...getFieldProps('firstname')}
                            error={Boolean(touched.firstname && errors.firstname)}
                            helperText={touched.firstname && errors.firstname}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Lastname"
                            {...getFieldProps('lastname')}
                            error={Boolean(touched.lastname && errors.lastname)}
                            helperText={touched.lastname && errors.lastname}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            select
                            label="RFQ manager"
                            {...getFieldProps('leadmanager')}
                            error={Boolean(touched.leadmanager && errors.leadmanager)}
                            helperText={touched.leadmanager && errors.leadmanager}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label="Phone"
                            {...getFieldProps('phone')}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Email"
                            {...getFieldProps('email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            select
                            label="Password"
                            {...getFieldProps('password')}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                          />
                        </Grid>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddLeadManagerModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting}>
                          Add Telecaller
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Box>
            </Dialog> */}
          {/* Add telecallers dialog end */}

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold', mb: 1 }}>Telecallers</Typography>
          </Stack>
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {/* <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack> */}
              <Stack direction="row" spacing={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search Telecaller..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack>
              <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                {/* <Button startIcon={<AddIcon />} variant="contained" onClick={openAddLeadManagerModal}>
                      Add Telecaller
                    </Button> */}

                {/* <Filter applyFilters={applyFilters} /> */}
              </Stack>
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Fullname</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {telecallersData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>
                        <TableCell>{item?.telecallerData?.fullname?.toUpperCase()}</TableCell>
                        <TableCell>{item?.telecallerData?.email}</TableCell>
                        <TableCell>
                          {admin && (
                            <FormControl sx={{ minWidth: 100 }}>
                              <Select
                                size="small"
                                value={item?.telecallerData?.isBlocked}
                                onChange={(e) => handleStatusChange(e, item?.telecallerData, id)}
                              >
                                <MenuItem value="false">Active</MenuItem>
                                <MenuItem value="true">Blocked</MenuItem>
                              </Select>
                              {selectedIndex === id && <Popup item={item?.telecallerData} handleClose={handleClose} />}
                            </FormControl>
                          )}
                          {!admin && (
                            <>
                              {item?.telecallerData?.isBlocked ? (
                                <Chip label="Blocked" color="error" />
                              ) : (
                                <Chip label="Active" color="success" />
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Link to={`/dashboard/employees/view/${item?.telecallerData?._id}`}>
                              <Icon sx={{ color: 'gray' }}>
                                <RemoveRedEyeIcon />
                              </Icon>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
              }}
              component={'div'}
              count={telecallersCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </>
        </Box>
      )}
    </>
  );
}
