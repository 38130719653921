import { Container } from '@mui/material';
import React from 'react';
import PageHeader from '../components/smallComponents/PageHeader';
import ProjectAttachmentsComp from '../components/ProjectAttachmentsComp';

function ProjectAttachments() {
  return (
    <Container maxWidth="xl">
      <PageHeader title="Project Attachments" />
      <ProjectAttachmentsComp />
    </Container>
  );
}

export default ProjectAttachments;
