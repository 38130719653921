import { Avatar, Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import { useNavigate } from 'react-router-dom';

const pictureLink = 'https://www.w3schools.com/howto/img_avatar.png';

const adminPath = '/dashboard/general/admin/view';

AdminProfileAvatar.propTypes = {
  profilePic: PropTypes.string,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  id: PropTypes.string,
};

export default function AdminProfileAvatar({ profilePic, firstLine, secondLine, id }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (id) {
      navigate(`${adminPath}/${id}`);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        onClick={handleClick}
        src={(profilePic && process.env.REACT_APP_API_URL + profilePic) || pictureLink}
        sx={{ width: 40, height: 40, cursor: 'pointer' }}
      />
      <Stack sx={{ marginLeft: 1 }} spacing={0.2}>
        <Typography variant="subtitle2">{firstLine}</Typography>
        <Typography variant="caption">{secondLine}</Typography>
      </Stack>
    </Box>
  );
}
