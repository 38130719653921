import React, { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Card,
  Typography,
  Avatar,
  Box,
  Grid,
  TextField,
  MenuItem,
  IconButton,
  Button,
} from '@mui/material';
import { BorderColor, Close } from '@mui/icons-material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';
import LoadingIcon from '../components/smallComponents/LoadingIcon';

import Iconify from '../components/Iconify';
import PageHeader from '../components/smallComponents/PageHeader';
import MaterialTableTab from '../components/MaterialTableTab';
import hasAccessOf from '../store/hasAccessOf';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic, statusChange, onStatusChange }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
      paddingX={1}
      paddingY={0.5}
    >
      <Stack direction="row" alignItems="center" background={background} padding={profilePic ? 0 : 1}>
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      {statusChange === 'yes' ? (
        <>
          <TextField select label="Status" value={data} size="small" onChange={(e) => onStatusChange(e.target.value)}>
            <MenuItem value="false">Active</MenuItem>
            <MenuItem value="true">Blocked</MenuItem>
          </TextField>
        </>
      ) : (
        <Stack direction="column">
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
          <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

function ViewMaterial() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [material, setMaterial] = useState({});
  const [image, setImage] = useState('');
  const [imageObject, setImageObject] = useState('');

  const [editMode, setEditMode] = useState(false);

  const materialCreateAccess = hasAccessOf('warehouse.material.c');
  const materialReadAccess = hasAccessOf('warehouse.material.r');
  const materialWriteAccess = hasAccessOf('warehouse.material.w');
  const materialDeleteAccess = hasAccessOf('warehouse.material.d');

  const getMaterial = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/material`, { params: { id } });
      console.log(res);
      if (res.data.isError === false) {
        setMaterial(res.data.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (status) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/material/status`, {
        materialId: material._id,
        status,
      });
      console.log(res);
      if (res.data.isError === false) {
        toast.success(res.data.message);
        getMaterial();
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const updateMaterialImage = async () => {
    if (!image) {
      toast.error('Please select an image');
      return;
    }
    const formData = new FormData(); 
    formData.append('image', image);
    formData.append('id', id);
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/material/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        toast.success(res.data.message);
        getMaterial();
        setEditMode(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getMaterial();
  }, []);

  useEffect(() => {
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setImageObject(objectUrl);
    }
  }, [image]);

  return (
    <Page title="View Material">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="View Material" />
          <Stack direction={'column'} spacing={2}>
            <Card>
              <Box padding={2}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
                  <Typography variant="h5">Material Details</Typography>
                  <IconButton onClick={() => setEditMode(!editMode)}>
                    {!editMode ? <BorderColor /> : <Close />}
                  </IconButton>
                </Stack>
                <Grid container spacing={2}>
                  {!editMode ? (
                    <Grid item xs={12} sm={6} md={4}>
                      <DetailItem
                        title="Name"
                        data={material.name}
                        background="#f0f0f0"
                        sx={{ minHeight: '65px' }}
                        profilePic={process.env.REACT_APP_API_URL + material.image}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} md={4}>
                      <Stack
                        alignItems="center"
                        spacing={1}
                        direction="row"
                        sx={{
                          height: '100%',
                          background: (theme) => theme.palette.macinsIndigo?.lighter,
                          minHeight: '65px',
                          borderRadius: '5px',
                        }}
                        paddingX={1}
                        paddingY={0.5}
                      >
                        <Stack direction="row" alignItems="center" background={'#f0f0f0'}>
                          <Avatar src={imageObject} alt={material.name} style={{ height: '100px', width: '100px' }} />
                        </Stack>
                        <Stack direction="column" spacing={1}>
                          <TextField
                            fullWidth
                            id="image"
                            name="image"
                            label="Image"
                            size="small"
                            type="file"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={!materialWriteAccess}
                            onChange={(e) => setImage(e.target.files[0])}
                          />
                          <Stack direction="row" justifyContent={'flex-end'}>
                            <Button size="small" variant="contained" color="primary" onClick={updateMaterialImage}>
                              Update
                            </Button>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="emojione-monotone:letter-u"
                      title="Unit"
                      data={material.unit}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {materialWriteAccess ? (
                      <DetailItem
                        icon={material.isBlocked ? 'icomoon-free:blocked' : 'gg:check-o'}
                        title="Status"
                        data={material.isBlocked}
                        background="#f0f0f0"
                        sx={{ minHeight: '65px' }}
                        statusChange={'yes'}
                        onStatusChange={handleStatusChange}
                      />
                    ) : (
                      <DetailItem
                        icon={material.isBlocked ? 'icomoon-free:blocked' : 'gg:check-o'}
                        title="Status"
                        data={material.isBlocked ? 'Blocked' : 'Active'}
                        background="#f0f0f0"
                        sx={{ minHeight: '65px' }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DetailItem
                      icon="ion:pricetags"
                      title="Unit Price"
                      data={material.unitPrice}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Stock"
                      data={material.stock}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Units Used"
                      data={material.totalQuantity}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Defunct Units"
                      data={material.defunctUnits}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem
                      icon="material-symbols:description"
                      title="Description"
                      data={material.description}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
            {/* <MaterialUsageTable material={material} getMaterial={getMaterial} /> */}
            <MaterialTableTab material={material} getMaterial={getMaterial} />
          </Stack>
        </Container>
      )}
    </Page>
  );
}

export default ViewMaterial;
