import { useNavigate } from 'react-router-dom';
import { ToWords } from 'to-words';

export function setChipColor(role) {
  switch (role) {
    case 'SALES_STAFF':
      return 'navy';
    case 'SALES_MANAGER':
      return 'greenish';
    case 'PROJECT_MANAGER':
      return 'primary';
    case 'PROJECT_ENGINEER':
      return 'macinsIndigo';
    case 'WORKER':
      return 'balanced';
    case 'ACCOUNT_MANAGER':
      return 'redish';
    case 'WAREHOUSE_MANAGER':
      return 'redish';
    default:
      return 'purplish';
  }
}

export function setChipIcon(role) {
  switch (role) {
    case 'SALES_STAFF':
      return 'mdi:phone-outline';
    case 'SALES_MANAGER':
      return 'mdi:account-tie';
    case 'PROJECT_MANAGER':
      return 'mdi:account-tie';
    case 'PROJECT_ENGINEER':
      return 'mdi:account-tie';
    case 'WORKER':
      return 'mdi:worker';
    case 'ACCOUNT_MANAGER':
      return 'carbon:account';
    case 'WAREHOUSE_MANAGER':
      return 'maki:warehouse';
    default:
      return 'mdi:account';
  }
}

export const rfqStatusOrder = ['Pending', 'Ongoing', 'Decision Pending', 'Interested', 'Not Interested', 'Regret'];

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function formatNumberLpoReference(num) {
  // Convert number to string
  const strNum = num.toString();

  // Determine the number of zeros needed
  const zeros = '0000';
  const numZeros = Math.max(0, zeros.length - strNum.length);

  // Add necessary zeros to the number
  const paddedNum = zeros.slice(0, numZeros) + strNum;

  // Return the formatted string
  return `LPO-${paddedNum}`;
}

export function formatInvoiceNumber(number) {
  // Convert sequence number to string
  const strSeqNum = number.toString();

  // Determine the number of zeros needed
  const zeros = '0000';
  const numZeros = Math.max(0, zeros.length - strSeqNum.length);

  // Add necessary zeros to the sequence number
  const paddedSeqNum = zeros.slice(0, numZeros) + strSeqNum;

  // Construct the final invoice number
  const invoiceNumber = `INV/${paddedSeqNum}MT`;

  // Return the invoice number
  return invoiceNumber;
}

export function convertToWords(number) {
  const toWords = new ToWords({
    localeCode: 'en-AE',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: 'AED',
        plural: 'Dirhams',
        singular: 'Dirham',
        symbol: 'AED',
        fractionalUnit: {
          name: 'Fils',
          plural: 'Fils',
          singular: 'Fils',
          symbol: 'Fils',
        },
      },
    },
  });
  return toWords.convert(number);
}
