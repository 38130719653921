import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Autocomplete,
  Avatar,
  Typography,
  MenuItem,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';

const AddVendorType = ({ callApi }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const taskSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    isBlocked: Yup.string().required('Required'),
  });

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Add />}>
          Supplier Type
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Supplier Type</DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  name: '',
                  isBlocked: 'false',
                }}
                validationSchema={taskSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/vendorType`, values);
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Grid container spacing={2} mt={0.5}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label="Name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={formik.touched.name && Boolean(formik.errors.name)}
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          select
                          fullWidth
                          id="isBlocked"
                          name="isBlocked"
                          label="Status"
                          value={formik.values.isBlocked}
                          onChange={formik.handleChange}
                          error={formik.touched.isBlocked && Boolean(formik.errors.isBlocked)}
                          helperText={formik.touched.isBlocked && formik.errors.isBlocked}
                        >
                          <MenuItem value="false">Active</MenuItem>
                          <MenuItem value="true">Blocked</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Stack mt={1} direction="row" spacing={2} justifyContent="end">
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                          Add
                        </LoadingButton>
                      </Stack>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddVendorType;
