import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Materialtip,
  Button,
  Menu,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import { Add, ContentCopy, CopyAll, Remove, RemoveRedEye } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/MaterialPurchase';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import hasAccessOf from '../store/hasAccessOf';
import { StyledTableRow } from '../theme/styledComponents';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 14px',
}));

export default function MaterialPurchases() {
  const navigate = useNavigate();
  const [materialpurchaseData, setMaterialPurchaseData] = useState([]);
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [materialpurchaseCount, setMaterialPurchaseCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const stockCreateAccess = hasAccessOf('warehouse.stock.c');
  const stockReadAccess = hasAccessOf('warehouse.stock.r');
  const stockWriteAccess = hasAccessOf('warehouse.stock.w');
  const stockDeleteAccess = hasAccessOf('warehouse.stock.d');

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getMaterialPurchases(temp);
  };

  const getMaterialPurchases = (options) => {
    if (!stockReadAccess) {
      setIsLoading(false);
      return false;
    }
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialTransaction/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMaterialPurchaseCount(res.data.data.maxRecords);
          setMaterialPurchaseData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getMaterialPurchasesWithoutLoading = (options) => {
    if (!stockReadAccess) {
      setIsLoading(false);
      return false;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialTransaction/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setMaterialPurchaseCount(res.data.data.maxRecords);
          setMaterialPurchaseData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };
    setOptions(temp);
    setPages(0);
    getMaterialPurchasesWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.billNumber) {
      temp.billNumber = filter.billNumber.trim();
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.vendorId) {
      temp.vendorId = filter.vendorId;
    }
    if (filter.type) {
      temp.type = filter.type;
    }

    setOptions(temp);
    setPages(0);
    getMaterialPurchases(temp);
  };
  useEffect(() => {
    getMaterialPurchases(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getMaterialPurchases(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Filter applyFilters={applyFilters} filterValues={options} />
          <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
            {stockCreateAccess && (
              <Button
                variant="contained"
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                startIcon={<Add />}
              >
                New
              </Button>
            )}
            <Menu
              open={menuOpen}
              onClose={() => setAnchorEl(null)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => navigate('/dashboard/warehouse/stock/material/add')}>
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="New Stock" />
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/warehouse/stock/material/remove')}>
                <ListItemIcon>
                  <Remove fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Defunct Stock" />
              </MenuItem>
            </Menu>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellCustom>Sl No</TableCellCustom>
                    <TableCellCustom>Date</TableCellCustom>
                    {/* <TableCellCustom>Invoice Number</TableCellCustom> */}
                    <TableCellCustom>Bill Number</TableCellCustom>
                    <TableCellCustom>Type</TableCellCustom>
                    <TableCellCustom>Supplier </TableCellCustom>
                    <TableCellCustom>Action</TableCellCustom>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {materialpurchaseData?.map((item, id) => (
                    <StyledTableRow key={id}>
                      <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                      <TableCellCustom>{moment(item.invoiceDate).format('DD/MM/YYYY')}</TableCellCustom>
                      <TableCellCustom>{item.billNumber}</TableCellCustom>
                      <TableCellCustom>{item?.type?.replaceAll('_', ' ')}</TableCellCustom>
                      <TableCellCustom style={{ cursor: 'pointer', width: '80px' }}>
                        {item?.vendorDetails && (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={
                                (item?.vendorDetails?.profilePic &&
                                  process.env.REACT_APP_API_URL + item.vendorDetails?.profilePic) ||
                                'https://www.w3schools.com/howto/img_avatar.png'
                              }
                              sx={{ width: 40, height: 40 }}
                            />
                            <Box sx={{ marginLeft: 1 }}>
                              <Typography variant="subtitle2">{item?.vendorDetails?.companyName}</Typography>
                              <Typography variant="caption">{item.vendorDetails?.email}</Typography>
                            </Box>
                          </Box>
                        )}
                      </TableCellCustom>
                      <TableCellCustom>
                        <Box>
                          <Link to={`/dashboard/warehouse/stock/material/${item._id}`}>
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEye />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCellCustom>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
            }}
            component={'div'}
            count={materialpurchaseCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
