import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TelecallersOfManager from '../components/TelecallersOfManager';
import EmployeeBasicInfo from '../components/EmployeeBasicInfo';
import Page from '../components/Page';
import ManagerOfTelecaller from '../components/ManagerOfTelecaller';
import AssignManager from '../components/AssignManager';
import EmployeeTables from '../components/EmployeeTables';

import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';

export default function ViewEmployee() {
  const { accType } = useSelector((state) => state.user.userInfo);
  const admin = accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN';

  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState({});
  const [employeeRole, setEmployeeRole] = useState([]);
  const [isTelecaller, setIsTelecaller] = useState(false);
  const [isLeadManager, setIsLeadManager] = useState(false);
  const [telecallersManager, setTelecallersManager] = useState({});

  const getEmployeesAccess = hasAccessOf('general.employee.r');
  const writeEmployeesAccess = hasAccessOf('general.employee.w');

  const getEmployee = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member`, {
        params: {
          id,
        },
      });
      console.log('employee', res);
      setEmployee(res.data.data);
      setEmployeeRole(res.data.data.role);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTelecallersManager = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/member/leadManager`, {
        params: {
          id,
        },
      });
      console.log('telecalersManger', res.data.data);
      if (res.data.data.managerExists) {
        setTelecallersManager(res.data.data.manager);
      } else {
        setTelecallersManager(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (getEmployeesAccess) {
      getEmployee();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (employeeRole === 'SALES_STAFF' && admin) {
      setIsTelecaller(true);
      getTelecallersManager();
    } else {
      setIsTelecaller(false);
    }
    if (employeeRole === 'SALES_MANAGER') {
      setIsLeadManager(true);
    } else {
      setIsLeadManager(false);
    }
  }, [employeeRole, admin]);

  return (
    <Page title="View Employee">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="View Employee" />
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Box id="basic-info" marginBottom={1}>
              <EmployeeBasicInfo getEmployee={getEmployee} employee={employee} managerId={telecallersManager?._id} />
            </Box>
            {/* {admin && (
              <Grid item xs={12}>
                {isTelecaller && telecallersManager && (
                  <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}>
                    <Box id="manager-of-telecaller" marginBottom={1}>
                      <ManagerOfTelecaller
                        getTelecallersManager={getTelecallersManager}
                        managerId={telecallersManager._id}
                      />
                    </Box>
                  </Box>
                )}
              </Grid>
            )} */}

            {isTelecaller && !telecallersManager && <AssignManager getTelecallersManager={getTelecallersManager} />}
            {/* <Grid item xs={12}>
              {isLeadManager && (
                <Box paddingX={{ xl: 2, lg: 2, md: 2, sm: 1, xs: 0.5 }}>
                  <Box id="telecallers-of-manager" marginBottom={1}>
                    <TelecallersOfManager />
                  </Box>
                </Box>
              )}
            </Grid> */}
            {/* <EmployeeTables /> */}
          </Stack>
        </Container>
      )}
    </Page>
  );
}
