import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from './Page';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  BarGraph,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function SingleWorkerAnalytics() {
  const theme = useTheme();
  const { id } = useParams();

  const [manpowerBarGraphData, setManpowerBarGraphData] = useState({ chartLabels: [], chartData: [] });
  const [manpowerBargraphType, setManpowerBargraphType] = useState('WEEK');

  const getManpowerBarGraphData = async (startDate,endDate,graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/manpower/bar-graph/worker`, {
        params: {
          graphType,
          startDate,
          endDate,
          workerId: id,
        },
      });
      console.log(res.data.data);
      setManpowerBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

 

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <BarGraph
          unit="Hrs"
            xaxisPlot={'category'}
            callAPI={getManpowerBarGraphData}
            title="Work Hours"
            chartLabels={[...manpowerBarGraphData.chartLabels]}
            chartData={[
              {
                name: 'Work Hours',
                type: 'column',
                fill: 'solid',
                data: [...manpowerBarGraphData.chartData],
                color: theme.palette.orangee.main,
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
}
