// @mui
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import GeneralAnalyticsComponent from './GeneralAnalyticsComponent';

// ----------------------------------------------------------------------

export default function GeneralAnalytics() {
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4"> Summary</Typography>
          </Grid>
        </Grid>
        <GeneralAnalyticsComponent />
      </Container>
    </Page>
  );
}
