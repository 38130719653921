import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import QuotationSettings from '../components/QuotationSettings';
import Departments from './Departments';

export default function ProjectSettings() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Page title="Project Settings">
        <Container maxWidth="xl">
          <PageHeader title="Project Settings" />
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Quotation " value="1" />
                    <Tab label="Departments" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <QuotationSettings />
                </TabPanel>
                <TabPanel value="2">
                  <Departments />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Container>
      </Page>
    </>
  );
}
