import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Avatar,
  Chip,
  Autocomplete,
} from '@mui/material';
import { Box } from '@mui/system';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { ArrowBack, ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/TeleCallers';

// components
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/TeleCallerStatusChange ';
import hasAccessOf from '../store/hasAccessOf';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function LeadManagersTeleCallers() {
  const navigate = useNavigate();
  const { accType, role: accountRole } = useSelector((state) => state.user.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const managerId = searchParams.get('managerId') || null;

  const matches = useMediaQuery('(max-width:600px)');
  const [telecallersData, setLeadManagerData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [telecallersCount, setLeadManagerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [leadManager, setLeadManager] = useState({});
  const [salesStaffs, setSalesStaffs] = useState([]);

  const teleCallerCreateAccess = hasAccessOf('crm.teleCaller.c');
  const teleCallerReadAccess = hasAccessOf('crm.teleCaller.r');
  const teleCallerWriteAccess = hasAccessOf('crm.teleCaller.w');
  const teleCallerDeleteAccess = hasAccessOf('crm.teleCaller.d');

  const telecallersSchema = Yup.object().shape({
    telecallerIds: Yup.array().min(1, 'Please select at least one telecaller'),
  });

  const formik = useFormik({
    initialValues: {
      managerId,
      telecallerIds: [],
    },
    validationSchema: telecallersSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/leadTeam/teleCallers`, values);
        if (!res.data.isError) {
          closeAddLeadManagerModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getTelecallers(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getManager = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member`, {
        params: {
          id: managerId,
        },
      });
      console.log('employee', res);
      setLeadManager(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getUnassignedSalesStaffs = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/leadTeam/telecallers/unassigned`, {
        params: {
          id: managerId,
        },
      });
      setSalesStaffs(res.data.data.records);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getTelecallers(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddLeadManagerModal = () => {
    setOpen(true);
  };
  const closeAddLeadManagerModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTelecallers(temp);
  };

  const getTelecallers = (options) => {
    if (!teleCallerReadAccess) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadTeam/telecaller/all`, {
        params: {
          ...options,
          managerId,
        },
      })
      .then((res) => {
        console.log('tele caller', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setLeadManagerCount(res.data.data.maxRecords);
          setLeadManagerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTelecallersWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/leadTeam/telecaller/all`, {
        params: {
          ...options,
          managerId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setLeadManagerCount(res.data.data.maxRecords);
          setLeadManagerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(telecallersCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getTelecallers(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };
    setOptions(temp);
    setPages(0);
    getTelecallersWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.fullname) {
      temp.fullname = filter.fullname;
    }
    if (filter.isBlocked) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.email) {
      temp.email = filter.email;
    }
    if (filter.id) {
      temp.id = filter.id;
    }

    setOptions(temp);
    setPages(0);
    getTelecallers(temp);
  };
  useEffect(() => {
    getTelecallers(options);
    getManager();
    getUnassignedSalesStaffs();
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTelecallers(temp);
  }, [size]);

  return (
    <>
      <Page title="Sales Staffs">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* Add telecallers Dialog */}
            <Dialog open={open} onClose={closeAddLeadManagerModal} fullScreen={matches}>
              <DialogTitle>Add Sales Staffs</DialogTitle>
              <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            fullWidth
                            id="asynchronous-demo"
                            onChange={(_, value) => {
                              formik.setFieldValue('telecallerIds', value ? value.map((v) => v._id) : []);
                            }}
                            isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                            getOptionLabel={(option) => option.fullname}
                            getOptionSelected={(option, value) => option.fullname === value.fullname}
                            options={salesStaffs}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label="Sales Staffs"
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                error={Boolean(touched.telecallerIds && errors.telecallerIds)}
                                helperText={touched.telecallerIds && errors.telecallerIds}
                              />
                            )}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                  <Avatar
                                    sx={{ width: 40, height: 40, cursor: 'pointer' }}
                                    src={option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic}
                                  />
                                  <Stack direction={'column'}>
                                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                      {option.fullname}
                                    </Typography>
                                    <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                                      {option.role?.name?.replaceAll('_', ' ')}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </li>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddLeadManagerModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting} variant="contained" color="primary">
                          Add
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Box>
            </Dialog>
            {/* Add telecallers dialog end */}
            <Container maxWidth="xl">
              <PageHeader title="Sales Staffs" />
              {teleCallerReadAccess && (
                <Filter applyFilters={applyFilters} hideManager={'true'} filterValues={options} />
              )}

              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack> */}
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search Sales Staff..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    {
                      <Button startIcon={<AddIcon />} variant="contained" onClick={openAddLeadManagerModal}>
                        Assign Sales Staffs
                      </Button>
                    }
                  </Stack>
                </Stack>
                <Stack direction="row" alignItems="center" padding={2} mb={2}>
                  <Typography variant="h6">{leadManager?.fullname}'s Sales Staffs</Typography>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Profile Pic</TableCell>
                          <TableCell sx={{ paddingLeft: 0 }}>Fullname</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Member Since</TableCell>
                          <TableCell>ID</TableCell>
                          {(accType === 'SUPER_ADMIN' || accType === 'SUB_ADMIN') && <TableCell>Status</TableCell>}
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {telecallersData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>
                              <Avatar
                                src={
                                  (item.profilePic && process.env.REACT_APP_API_URL + item.profilePic) ||
                                  'https://www.w3schools.com/howto/img_avatar.png'
                                }
                                sx={{ width: 40, height: 40 }}
                              />
                            </TableCell>
                            <TableCell sx={{ paddingLeft: 0 }}>{item?.fullname}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell sx={{ minWidth: '110px', maxWidth: '120px' }}>
                              {Moment(item.createdAt).format('DD MMM YYYY')}
                            </TableCell>
                            <TableCell style={{ cursor: 'pointer', width: '80px' }}>
                              <Stack direction="column" spacing={0.5} width="80px">
                                <CopyToClipboard text={item._id} onCopy={() => toast.info('Sales Staff ID copied')}>
                                  <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                    <ContentCopy color="primary" />
                                    <Typography color="primary.main" variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Chip
                                label={item.isBlocked ? 'Blocked' : 'Active'}
                                color={item.isBlocked ? 'error' : 'success'}
                                size="small"
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              <Box>
                                <Link to={`/dashboard/general/employees/sales-staff/${item._id}`}>
                                  <Button
                                    variant="contained"
                                    startIcon={<RemoveRedEyeIcon />}
                                    size="small"
                                    color="viewButton"
                                  >
                                    View
                                  </Button>
                                </Link>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={telecallersCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
