import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
// import CopyToClipboard from 'react-copy-to-clipboard';
import { ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { toast } from 'react-toastify';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import Popup from './popups/ProjectStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function ProjectsOfWorkerTable({ workerId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const managerId = searchParams.get('managerId') || null;
  const [status, setStatus] = useState('');

  const [worker, setWorker] = useState({});

  const matches = useMediaQuery('(max-width:600px)');
  const [projectsData, setProjectsData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.status}` === event.target.value) {
      return;
    }
    setStatus(event.target.value);
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getProjects(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getProjects(temp);
  };

  const getProjects = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/all/ofWorker`, {
        params: { ...options, workerId },
      })
      .then((res) => {
        console.log('tele caller', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setProjectsCount(res.data.data.maxRecords);
          setProjectsData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getProjectsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/all/ofWorker`, {
        params: { ...options, workerId },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProjectsCount(res.data.data.maxRecords);
          setProjectsData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getWorker = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member`, { params: { id: workerId, role: 'WORKER' } })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setWorker(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getWorker();
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getProjectsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { ...options, page: 0, size, managerId };
    if (filter.fullname.length) {
      temp.fullname = filter.fullname;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    if (filter.email.length) {
      temp.email = filter.email;
    }
    if (filter.id.length) {
      temp.id = filter.id;
    }
    if (filter.managerId.length) {
      temp.managerId = filter.managerId;
    }

    setOptions(temp);
    setPages(0);
    getProjects(temp);
  };
  useEffect(() => {
    getProjects(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getProjects(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Card>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search Project Name..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </Stack>
           
          </Stack>
          <Stack padding={2} direction="row" justifyContent="start" spacing={2} alignItems="center">
            <Typography variant="body1">Worker Name:</Typography>
            <Typography variant="h6">{worker?.fullname}</Typography>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Work Hours</TableCell>
                    <TableCell>Project ID</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectsData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell>{item?.workHours?.totalHours}</TableCell>
                      <TableCell style={{ cursor: 'pointer', width: '100px' }}>
                        <Stack direction="column" spacing={0.5} width="100px">
                          <CopyToClipboard text={item.projectId} onCopy={() => toast.info('Project ID copied')}>
                            <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                              <ContentCopy color="primary" />
                              <Typography variant="p">{item.projectId}</Typography>
                            </Stack>
                          </CopyToClipboard>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <FormControl sx={{ minWidth: 100 }}>
                          <Select size="small" value={item.status} onChange={(e) => handleStatusChange(e, item, id)}>
                            <MenuItem value="ASSESSMENT">Assessment</MenuItem>
                            <MenuItem value="APPROVED">Approved</MenuItem>
                            <MenuItem value="ONGOING">Ongoing</MenuItem>
                            <MenuItem value="PAUSED">Paused</MenuItem>
                            <MenuItem value="CANCELLED">Cancelled</MenuItem>
                            <MenuItem value="COMPLETED">Completed</MenuItem>
                          </Select>
                          {selectedIndex === id && <Popup item={item} handleClose={handleClose} status={status} />}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Link
                            to={`/dashboard/general/employees/worker/workEntry?workerId=${workerId}&projectId=${item._id}`}
                          >
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEyeIcon />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
            }}
            component={'div'}
            count={projectsCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </Card>
      )}
    </>
  );
}
