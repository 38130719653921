import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ProjectMaterials from './ProjectMaterials';
import PageHeader from '../components/smallComponents/PageHeader';
import MaterialsTableInProject from '../components/MaterialsTableInProject';

// components

export default function ProjectMaterialsTabs() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <PageHeader title="Project Materials" />
      <Card>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange}>
              <Tab label="Project Materials" value="1" />
              <Tab label="Materials List" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ProjectMaterials />
          </TabPanel> 
          <TabPanel value="2">
            <MaterialsTableInProject />
          </TabPanel>
        </TabContext>
      </Card>
    </Container>
  );
}
