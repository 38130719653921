import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material';
import { Add, ArrowBack, ContentCopy, Delete } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import PageHeader from '../components/smallComponents/PageHeader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Qto() {
  const navigate = useNavigate();

  const [qtoData, setQtoData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [qtoCount, setQtoCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getQtos(temp);
  };

  const getQtos = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation/all`, {
        params: options,
      })
      .then((res) => {
        console.log('Payments', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setQtoCount(res.data.data.maxRecords);
          setQtoData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getQtosWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/quotation/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setQtoCount(res.data.data.maxRecords);
          setQtoData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, qtoName: e.target.value };
    setOptions(temp);
    setPages(0);
    getQtosWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.qtoType) {
      temp.qtoType = filter.qtoType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getQtos(temp);
  };

  useEffect(() => {
    getQtos(options);
  }, []);

  return (
    <Page title="QTOs">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <PageHeader title={'QTOs'} />
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <SearchStyle
                    value={name}
                    onChange={onSearch}
                    placeholder="Search qto..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Quotation ID</TableCell>
                        <TableCell>Projet Name</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>QTO Status</TableCell>
                        <TableCell>BOQ Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {qtoData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>
                          <TableCell>{Moment(item.qtoPreparedDate).format('DD/MM/YYYY')}</TableCell>
                          <TableCell>
                            <Stack direction="column" spacing={0.5} width="80px">
                              <CopyToClipboard text={item.quotationId} onCopy={() => toast.info('QTO ID copied')}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={0.5}
                                  justifyContent="flex-start"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <ContentCopy color="primary" />
                                  <Typography variant="p">{item.quotationId}</Typography>
                                </Stack>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          <TableCell>{item.projectData?.name}</TableCell>
                          <TableCell>{item?.customerData?.fullname}</TableCell>
                          <TableCell>{item.qtoStatus}</TableCell>
                          <TableCell>{item.boqStatus}</TableCell>
                          <TableCell>
                            <Stack direction="row" spacing={1}>
                              <Link to={`/dashboard/quotation/tabs?tab=qto&id=${item._id}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                  const temp = { ...options, size: e.target.value, page: 0 };
                  setPages(0);
                  setOptions(temp);
                  getQtos(temp);
                }}
                component={'div'}
                count={qtoCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
