import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import moment from 'moment';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { FilePond, File, registerPlugin } from 'react-filepond';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';
import LoadingIcon from '../components/smallComponents/LoadingIcon';
import hasAccessOf from '../store/hasAccessOf';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

const ViewMaterialPurchase = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [company, setCompany] = useState({});
  const [vendor, setVendor] = useState({});
  const [files, setFiles] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [purchase, setPurchase] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const stockCreateAccess = hasAccessOf('warehouse.stock.c');
  const stockReadAccess = hasAccessOf('warehouse.stock.r');
  const stockWriteAccess = hasAccessOf('warehouse.stock.w');
  const stockDeleteAccess = hasAccessOf('warehouse.stock.d');

  const convertToParseFloat = (value) => parseFloat(value).toFixed(5);

  const purchaseSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
    vendorId: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      id,
      invoiceDate: '',
      vendorId: '',
      subTotal: '',
      grandTotal: '',
      items: [],
      billNumber: '',
      attachments: [],
      remarks: '',
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      const data = new FormData();
      if (files?.length > 0) {
        files.forEach((item) => {
          data.append('attachments', item.file);
        });
      }
      data.append('subTotal', convertToParseFloat(values.subTotal));
      // eslint-disable-next-line no-restricted-globals
      data.append('grandTotal', convertToParseFloat(values.grandTotal));
      data.append('items', JSON.stringify(values.items));
      data.append('remarks', values.remarks);
      data.append('id', id);

      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/materialTransaction/updateQuantity`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        getPurchase();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const totalCalculator = (quantity, price) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    return Number(total.toFixed(2));
  };

  const getPurchase = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/materialTransaction/one`, { params: { id } });
      console.log(res.data, 'purchase');
      if (!res.data.isError) {
        setPurchase(res.data.data);
        const { data } = res.data;
        formik.setFieldValue('invoiceDate', moment(data.invoiceDate).format('YYYY-MM-DD'));
        formik.setFieldValue('vendorId', data.vendorId?._id);
        formik.setFieldValue('subTotal', data.subTotal);
        formik.setFieldValue('grandTotal', data.grandTotal);
        formik.setFieldValue('items', data.items);
        formik.setFieldValue('billNumber', data.billNumber);
        formik.setFieldValue('remarks', data.remarks);
        setVendor(data.vendorId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchase();
    }
  }, [id]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.total);
    });
    grandTotal = subTotal;
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
  }, [values.items]);

  useEffect(() => {
    const attachments = [];
    if (files?.length > 0) {
      files.forEach((item) => {
        attachments.push(item.file);
      });
      console.log(attachments);
      formik.setFieldValue('attachments', attachments);
    }
  }, [files]);

  console.log(values?.source);
  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Page title={purchase?.type === 'WAREHOUSE_IN' ? 'Material Purchase' : 'Defunct Transaction'}>
          <Container maxWidth="xl">
            <PageHeader title={purchase?.type === 'WAREHOUSE_IN' ? 'Material Purchase' : 'Defunct Transaction'} />
            <Card>
              <Box>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack
                      direction={'row'}
                      px={5}
                      py={3}
                      sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                    >
                      {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
                      <Stack justifyContent="center" spacing={2} direction={'column'}>
                        <Stack direction={'row'} spacing={2}>
                          <StyledTextField
                            label=" Date"
                            size="small"
                            type="date"
                            // {...getFieldProps('invoiceDate')}
                            // error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                            // helperText={touched.invoiceDate && errors.invoiceDate}
                            value={values.invoiceDate}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          {purchase?.vendorDetails && (
                            <StyledTextField
                              label="Supplier"
                              size="small"
                              type="text"
                              value={purchase?.vendorDetails?.companyName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                          {purchase?.billNumber && (
                            <StyledTextField label="Bill Number" size="small" type="text" value={values.billNumber} />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                    <Grid container>
                      {vendor?.companyName && (
                        <Grid item xs={12} sm={6}>
                          <Stack px={5} pt={5}>
                            <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                              From
                            </Typography>
                            <Stack paddingLeft={4}>
                              <Typography>{vendor.companyName}</Typography>
                              <Typography>{vendor.contactPerson}</Typography>
                              <Typography>{vendor.companyAddress}</Typography>
                              <Typography>{vendor.email}</Typography>
                              <Typography>{vendor.phone1}</Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      )}
                      {/* <Grid item xs={12} sm={6}>
                        <Stack px={5} pt={5}>
                          <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                            To
                          </Typography>
                          <Stack paddingLeft={4}>
                            <Typography>{company.companyName}</Typography>
                            <Typography>{company.address}</Typography>
                            <Typography>{company.pin}</Typography>
                            <Typography>{company.phone}</Typography>
                            <Typography>{company.email}</Typography>
                          </Stack>
                        </Stack>
                      </Grid> */}
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        margin={1}
                        sx={{
                          borderRadius: '5px',
                        }}
                      >
                        <Stack p={5}>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Item</TableCell>
                                  <TableCell>Unit </TableCell>
                                  <TableCell>Quantity</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.items.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell>
                                      <TextField fullWidth type="text" value={`${item.name}-${item.unitPrice}`} />
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        width: '120px',
                                      }}
                                    >
                                      <TextField fullWidth type="text" value={item.unit || ''} />
                                    </TableCell>
                                    <TableCell sx={{ width: '120px' }}>
                                      <TextField fullWidth type="number" value={item.quantity || ''} />
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        {/* remarks */}
                        {values?.remarks && (
                          <Stack px={5} py={5}>
                            <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                              Remarks
                            </Typography>
                            <Stack paddingLeft={4}>
                              <TextField
                                multiline
                                fullWidth
                                {...getFieldProps('remarks')}
                                error={Boolean(touched.remarks && errors.remarks)}
                                helperText={touched.remarks && errors.remarks}
                              />
                            </Stack>
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                    {/* <Stack direction={'row'} paddingX={5} sx={{ width: '100%' }} justifyContent={'end'}>
                      <Stack justifyContent="center" spacing={2}>
                        <Stack direction={'row'} spacing={1}>
                          <TextField label="Sub Total" size="small" type="number" value={values.subTotal} />
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                          <TextField label="Grand Total" size="small" type="number" value={values.grandTotal} />
                        </Stack>
                      </Stack>
                    </Stack> */}
                  </Form>
                </FormikProvider>
              </Box>
            </Card>
          </Container>
        </Page>
      )}
    </>
  );
};

export default ViewMaterialPurchase;
