import { useState } from 'react';
import { Grid } from '@mui/material';
// material
import TabCard from '../smallComponents/TabCard';

// components

export default function VendorTabCards({ vendor }) {
  return (
    <>
      <>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={4} lg={2.4}>
            <TabCard
              title="Accounts"
              color="balanced"
              total={12}
              icon={'map:accounting'}
              navigate={`/dashboard/general/vendors/accounts?vendorId=${vendor._id}`}
            />
          </Grid> */}
        </Grid>
      </>
    </>
  );
}
