import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import VendorAccountTables from '../components/VendorAccountTables';
import Page from '../components/Page';
import LoadingIcon from '../components/smallComponents/LoadingIcon';
import PageHeader from '../components/smallComponents/PageHeader';

function VendorAccounts() {
  const [searchParams, setSearchParams] = useSearchParams();
  const vendorId = searchParams.get('vendorId');
  const [isLoading, setIsLoading] = useState(true);
  const [vendor, setVendor] = useState({});

  const getVendor = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, {
        params: {
          _id: vendorId,
        },
      });
      console.log('vendor', res);
      setVendor(res.data.data.vendor);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendor();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Page title="Supplier Accounts">
          <Container maxWidth="xl">
            <PageHeader title="Supplier Accounts" />
            <VendorAccountTables vendor={vendor} />
          </Container>
        </Page>
      )} 
    </>
  );
}

export default VendorAccounts;
