import * as React from 'react';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import {
  TextField,
  Button,
  Stack,
  MenuItem,
  Grid,
  Card,
  Collapse,
  IconButton,
  Typography,
  Box,
  Autocomplete,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Iconify from '../Iconify';

const Filter = ({ filterValues, applyFilters, approved, customer }) => {
  const [open, setOpen] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [tools, setTools] = React.useState([]);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      projectId: filterValues.projectId,
      customerName: filterValues.customerName,
      customerId: filterValues.customerId,
      manager: filterValues.manager,
      engineer: filterValues.engineer,
      type: filterValues.type,
      status: filterValues.status,
      workOrderNumber: filterValues.workOrderNumber,
      startDate: filterValues?.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : '',
      endDate: filterValues?.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : '',
    },
    onSubmit: async () => {
      applyFilters(values);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getProjects = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/list`, {
        params: {
          statuses: ['APPROVED', 'ONGOING', 'PAUSED', 'COMPLETED'],
        },
      });
      console.log(res);
      if (res.data.isError === false) {
        setProjects(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getTools = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tool/active`);
      console.log(res);
      if (res.data.isError === false) {
        setTools(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getProjects();
    getTools();
  }, []);

  return (
    <Box mb={2}>
      <Stack direction="row" spacing={2} justifyContent="end" alignItems="center" width="100%">
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon="ic:round-filter-list" />
          <Typography variant="h6"> Filter</Typography>
        </IconButton>
      </Stack>
      <Collapse in={open}>
        <Box sx={{ marginBottom: '10px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={1} padding={1}>
                {approved === 'true' ||
                  (customer && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField id="status" name="status" fullWidth label="Status" select {...getFieldProps('status')}>
                        {customer && <MenuItem value="ASSESSMENT">Assessment</MenuItem>}
                        <MenuItem value="APPROVED">Approved</MenuItem>
                        <MenuItem value="ONGOING">Ongoing</MenuItem>
                        <MenuItem value="PAUSED">Paused</MenuItem>
                        <MenuItem value="CANCELLED">Cancelled</MenuItem>
                        <MenuItem value="COMPLETED">Completed</MenuItem>
                      </TextField>
                    </Grid>
                  ))}

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="projectId"
                    name="projectId"
                    label="Project ID"
                    {...getFieldProps('projectId')}
                  />
                </Grid>

                {!customer && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      fullWidth
                      id="customerName"
                      name="customerName"
                      label="Customer Name"
                      {...getFieldProps('customerName')}
                    />
                  </Grid>
                )}
                {!customer && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      fullWidth
                      id="customerId"
                      name="customerId"
                      label="Customer ID"
                      {...getFieldProps('customerId')}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="manager" name="manager" label="Manager ID" {...getFieldProps('manager')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="engineer"
                    name="engineer"
                    label="Engineer ID"
                    {...getFieldProps('engineer')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="type" name="type" label="Type" {...getFieldProps('type')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField fullWidth id="status" name="status" label="Status" {...getFieldProps('status')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    fullWidth
                    id="workOrderNumber"
                    name="workOrderNumber"
                    label="Work Order Number"
                    {...getFieldProps('workOrderNumber')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="startDate"
                    name="startDate"
                    fullWidth
                    label="Start Date"
                    {...getFieldProps('startDate')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    id="endDate"
                    name="endDate"
                    fullWidth
                    label="End Date"
                    {...getFieldProps('endDate')}
                  />
                </Grid>

                <Grid item xs={12} justifyContent="center" display="flex">
                  <Stack direction="row" spacing={5}>
                    <Button
                      variant="contained"
                      style={{ marginTop: '10px' }}
                      onClick={() => {
                        resetForm();
                        applyFilters({
                          projectId: '',
                          customerName: '',
                          customerId: '',
                          manager: '',
                          engineer: '',
                          type: '',
                          status: '',
                          workOrderNumber: '',
                          startDate: '',
                          endDate: '',
                        });
                      }}
                    >
                      Reset
                    </Button>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
