import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  MenuItem,
  Autocomplete,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';

const LeadToCustomer = ({ lead, name, callApi }) => {
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState('start');
  const [customers, setCustomers] = React.useState([]);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [buttonLoading, setButtonLoading] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setType('start');
  };

  const checkPhone = async (phone) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customer/phone/available`, {
        params: {
          phone,
        },
      });
      console.log(response.data.data, 'phone available');
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/customer/active/list `);
      console.log(res.data.data);
      setCustomers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const LeadToExistingCustomerSubmit = async () => {
    try {
      setButtonLoading(true);
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/customer/lead`, {
        leadId: lead._id,
        customerId: selectedCustomer._id,
      });
      toast.success(response.data.message);
      setButtonLoading(false);
      handleClose();
      callApi();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const customerSchema = Yup.object().shape({
    fullname: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address'),
  });

  React.useEffect(() => {
    getCustomers();
  }, []);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Add />}>
            Customer
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Convert RFQ to Customer</DialogTitle>
            {type === 'start' && (
              <DialogContent>
                <Stack sx={{ minHeight: '100px' }} justifyContent={'center'} alignItems={'center'}>
                  <TextField
                    fullWidth
                    select
                    id="type"
                    name="type"
                    label="Select Type"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value="start">Select Type</MenuItem>
                    <MenuItem value="new">New Customer</MenuItem>
                    <MenuItem value="existing">Existing Customer</MenuItem>
                  </TextField>
                </Stack>
              </DialogContent>
            )}
            {type === 'new' && (
              <DialogContent>
                <DialogContentText sx={{ mb: 2 }}>'Enter customer details to convert lead'</DialogContentText>
                <Formik
                  initialValues={{
                    fullname: lead?.fullname || '',
                    email: lead?.email || '',
                    phone: lead?.phone || '',
                    address: lead?.address || '',
                    leadId: lead?._id || '',
                    companyName: lead?.companyName || '',
                    businessType: lead?.businessType || '',
                    notes: '',
                  }}
                  validationSchema={customerSchema}
                  onSubmit={async (values, actions) => {
                    const phoneAvailable = await checkPhone(values.phone);
                    if (!phoneAvailable && values.phone) {
                      actions.setFieldError('phone', 'Phone number is not available');
                      actions.setSubmitting(false);
                      return;
                    }
                    try {
                      const response = await axios.post(`${process.env.REACT_APP_API_URL}/customer/lead`, values);
                      actions.setSubmitting(false);
                      toast.success(response.data.message);
                      handleClose();
                      callApi();
                    } catch (error) {
                      console.log(error);
                      actions.setSubmitting(false);
                      toast.error(error.response.data.message);
                    }
                  }}
                >
                  {(formik) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="fullname"
                            name="fullname"
                            label="Name"
                            value={formik.values.fullname}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.fullname)}
                            helperText={formik.errors.fullname}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="phone"
                            name="phone"
                            label="Phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            error={Boolean(formik.errors.phone)}
                            helperText={formik.errors.phone}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="address"
                            name="address"
                            multiline
                            label="Address"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="companyName"
                            name="companyName"
                            label="Company Name"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            id="businessType"
                            name="businessType"
                            label="Business Type"
                            value={formik.values.businessType}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id="notes"
                            name="notes"
                            multiline
                            label="Notes"
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                          />
                        </Grid>
                      </Grid>
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                          Submit
                        </LoadingButton>
                      </DialogActions>
                    </Form>
                  )}
                </Formik>
              </DialogContent>
            )}
            {type === 'existing' && (
              <>
                <DialogContent>
                  <Stack
                    sx={{ minHeight: '120px', minWidth: '250px' }}
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={2}
                  >
                    <Autocomplete
                      fullWidth
                      id="asynchronous-demo"
                      onChange={(_, value) => {
                        console.log('Selected Value:', value);
                        setSelectedCustomer(value);
                      }}
                      isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                      getOptionLabel={(option) => option.fullname}
                      getOptionSelected={(option, value) => option.fullname === value.fullname}
                      options={customers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Customer"
                          InputProps={{
                            ...params.InputProps,
                          }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Stack direction={'column'}>
                              <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                {option.fullname}
                              </Typography>{' '}
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {option.companyName}
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {option.email}
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {option.phone}
                              </Typography>
                            </Stack>
                          </Stack>
                        </li>
                      )}
                    />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Stack direction="row" spacing={2} alignItems="center" justifyContent={'end'} sx={{ width: '100%' }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton variant="contained" loading={buttonLoading} onClick={LeadToExistingCustomerSubmit}>
                      Submit
                    </LoadingButton>
                  </Stack>
                </DialogActions>
              </>
            )}
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default LeadToCustomer;
