import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  Box,
} from '@mui/material';
import {
  Add,
  AddCircle,
  ArrowBack,
  ContentCopy,
  Delete,
  Remove,
  RemoveCircle,
  RemoveRedEye,
} from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import AddRemoveToolFromProject from './popups/AddRemoveToolFromProject';
import AddToolToProject from './popups/AddToolToProject';
import hasAccessOf from '../store/hasAccessOf';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function ToolUsageTable({ tool, getTool }) {
  const { id } = useParams();

  const [toolData, setToolData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [toolDataCount, setToolDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddOrReturnPopup, setOpenAddOrReturnPopup] = useState(false);
  const [openAddToProjectPopup, setOpenAddToProjectPopup] = useState(false);
  const [mode, setMode] = useState('add');
  const [selectedIndex, setSelectedIndex] = useState('');

  const toolCreateAccess = hasAccessOf('warehouse.tool.c');
  const toolReadAccess = hasAccessOf('warehouse.tool.r');
  const toolWriteAccess = hasAccessOf('warehouse.tool.w');
  const toolDeleteAccess = hasAccessOf('warehouse.tool.d');

  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getToolData(temp);
  };

  const getToolData = (options) => {
    if (!toolReadAccess) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/toolFlowLog/usage`, {
        params: {
          ...options,
          toolId: id,
        },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setToolDataCount(res.data.data.maxRecords);
          setToolData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getToolDataWithoutLoading = (options) => {
    if (!toolReadAccess) {
      setIsLoading(false);
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/toolFlowLog/usage`, {
        params: {
          ...options,
          toolId: id,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setToolDataCount(res.data.data.maxRecords);
          setToolData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, qtoName: e.target.value };
    setOptions(temp);
    setPages(0);
    getToolDataWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.qtoType) {
      temp.qtoType = filter.qtoType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getToolData(temp);
  };

  useEffect(() => {
    getToolData(options);
  }, []);

  const handleSuccess = () => {
    setOpenAddOrReturnPopup(false);
    setOpenAddToProjectPopup(false);
    setSelectedIndex('');
    getTool();
    getToolData(options);
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search quote..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </Stack>
            <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
              {toolWriteAccess && (
                <Button startIcon={<Add />} variant="contained" onClick={() => setOpenAddToProjectPopup(true)}>
                  Add
                </Button> 
              )}
              <AddToolToProject
                handleSuccess={handleSuccess}
                open={openAddToProjectPopup}
                setOpen={setOpenAddToProjectPopup}
                tool={tool}
              />
              {/* <Filter applyFilters={applyFilters} /> */}
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Projet Name</TableCell>
                    <TableCell>In Use</TableCell>
                    {toolWriteAccess && <TableCell sx={{ maxWidth: '180px' }}>Action</TableCell>}
                    <TableCell sx={{ maxWidth: '180px' }}>View</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {toolData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCell>{pages * size + (id + 1)}</TableCell>
                      <TableCell>{item.projectName}</TableCell>
                      <TableCell>{item.inUse}</TableCell>
                      {toolWriteAccess && (
                        <TableCell sx={{ maxWidth: '180px' }}>
                          <Stack direction="row" spacing={2}>
                            <Button
                              variant="contained"
                              color="success"
                              size="small"
                              startIcon={<AddCircle />}
                              onClick={() => {
                                setMode('add');
                                setSelectedIndex(id);
                                setOpenAddOrReturnPopup(true);
                              }}
                            >
                              Add
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              startIcon={<RemoveCircle />}
                              disabled={item.inUse === 0}
                              onClick={() => {
                                setMode('remove');
                                setSelectedIndex(id);
                                setOpenAddOrReturnPopup(true);
                              }}
                            >
                              Return
                            </Button>
                          </Stack>
                          {selectedIndex === id && (
                            <AddRemoveToolFromProject
                              open={openAddOrReturnPopup}
                              mode={mode}
                              setOpen={setOpenAddOrReturnPopup}
                              item={item}
                              tool={tool}
                              handleSuccess={handleSuccess}
                            />
                          )}
                        </TableCell>
                      )}
                      <TableCell>
                        <Box>
                          <Link
                            to={`/dashboard/warehouse/tool/history?toolId=${tool._id}&&projectId=${item.projectId}`}
                          >
                            <Icon sx={{ color: 'gray' }}>
                              <RemoveRedEye />
                            </Icon>
                          </Link>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
              const temp = { ...options, size: e.target.value, page: 0 };
              setPages(0);
              setOptions(temp);
              getToolData(temp);
            }}
            component={'div'}
            count={toolDataCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
