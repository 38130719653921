import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Container, Stack, Tab } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ViewQto from './ViewQto';
import ViewBoq from './ViewBoq';
import ViewQuote from './ViewQuote';
import Page from '../components/Page';
import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';

function QuotationTabs() {
  const boqReadAccess = hasAccessOf('project.boq.r');
  const quoteReadAccess = hasAccessOf('project.quotation.r');
  const qtoWriteAccess = hasAccessOf('project.qto.w');

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const id = searchParams.get('id');

  const [value, setValue] = useState(searchParams.get('tab') || 'qto');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getTitle = (value) => {
    switch (value) {
      case 'qto':
        return 'QTO';
      case 'boq':
        return 'BOQ';
      case 'quote':
        return 'Commercial Proposal';
      default:
        return 'Quotation';
    }
  };

  useEffect(() => {
    if (tab !== value) {
      setValue(tab);
    }
  }, [tab]);

  return (
    <Page title={`Quotation | ${getTitle(value)}`}> 
      <PageHeader title={`Quotation | ${getTitle(value)}`} />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            {qtoWriteAccess && <Tab label="QTO" value="qto" />}
            {boqReadAccess && <Tab label="BOQ" value="boq" />}
            {quoteReadAccess && <Tab label="Quotation" value="quote" />}
          </TabList>
        </Box>
        {qtoWriteAccess && (
          <TabPanel value="qto">
            <ViewQto />
          </TabPanel>
        )}
        {boqReadAccess && (
          <TabPanel value="boq">
            <ViewBoq />
          </TabPanel>
        )}
        {quoteReadAccess && (
          <TabPanel value="quote">
            <ViewQuote />
          </TabPanel>
        )}
      </TabContext>
    </Page>
  );
}

export default QuotationTabs;
