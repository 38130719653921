import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { AppCurrentVisits, BarGraph } from '../sections/@dashboard/app';
import hasAccessOf from '../store/hasAccessOf';
import ProjectToolUsage from '../components/ProjectToolUsage';

// ----------------------------------------------------------------------

export default function ProjectAnalyticsComponent({ hideToolUsage }) {
  const theme = useTheme();

  const projectStatusGraphAccess = hasAccessOf('dashboard.projectSummary.r');

  const [manpowerBarGraphData, setManpowerBarGraphData] = useState({ chartLabels: [], chartData: [] });

  const [projectStatusPieData, setProjectStatusPieData] = useState([]);
  const [isLoadingSetProjectStatusPieData, setIsLoadingSetProjectStatusPieData] = useState(true);

  const [taskStatusPieData, setTaskStatusPieData] = useState([]);
  const [isLoadingSetTaskStatusPieData, setIsLoadingSetTaskStatusPieData] = useState(true);

  const getManpowerBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/manpower/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setManpowerBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectStatusPieChartData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/project/status-count`);
      console.log(res.data.data);
      setProjectStatusPieData(res.data.data);
      setIsLoadingSetProjectStatusPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTaskStatusPieChartData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/task/status-count`);
      console.log(res.data.data);
      setTaskStatusPieData(res.data.data);
      setIsLoadingSetTaskStatusPieData(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectStatusPieChartData();
    getTaskStatusPieChartData();
  }, []);

  return (
    <Grid container spacing={3} rowGap={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Project Analytics</Typography>
      </Grid>

      {projectStatusGraphAccess && (
        <Grid item xs={12} md={6} lg={6}>
          <AppCurrentVisits
            title="Project Status"
            hideStartDate={'hide'}
            chartData={projectStatusPieData}
            graphloader={isLoadingSetProjectStatusPieData}
            getNewData={getProjectStatusPieChartData}
            chartColors={['#323a64', '#875c00']}
          />
        </Grid>
      )}

      {projectStatusGraphAccess && (
        <Grid item xs={12} md={6} lg={6}>
          <AppCurrentVisits
            title="Task Status"
            hideStartDate={'hide'}
            chartData={taskStatusPieData}
            graphloader={isLoadingSetTaskStatusPieData}
            getNewData={getTaskStatusPieChartData}
            chartColors={['#323a64', '#875c00']}
          />
        </Grid>
      )}

      {projectStatusGraphAccess && (
        <Grid item xs={12} md={12}>
          <BarGraph
            xaxisPlot={'category'}
            callAPI={getManpowerBarGraphData}
            title="Manpower"
            unit="Hrs"
            chartLabels={[...manpowerBarGraphData.chartLabels]}
            chartData={[
              {
                name: 'Manpower',
                type: 'column',
                fill: 'solid',
                data: [...manpowerBarGraphData.chartData],
                color: theme.palette.navy.main,
              },
            ]}
          />
        </Grid>
      )}

      {/* Project tool usage */}
      {!hideToolUsage && (
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Tools In Use</Typography>
            </Grid>
            <Grid item xs={12}>
              <ProjectToolUsage />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
