import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll, Edit, Person, RemoveRedEye } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Bill';
 
// components
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import { StyledGroupedTableRow } from '../theme/styledComponents';
// import Popup from './popups/BillStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

function TransactionsTable({ billingId, purchaseId, salaryId }) {
  const navigate = useNavigate();
  const [txnData, setTxnData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [billCount, setBillCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(1000);
  const [options, setOptions] = useState({ size, page: 0 });
  const [groupedTxnData, setGroupedTxnData] = useState([]);

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTransactions(temp);
  };

  const getTransactions = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/transaction/all`, {
        params: { ...options, billingId, purchaseId, salaryId, sort: 'paymentDate#asc' },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setBillCount(res.data.data.maxRecords);
          setTxnData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTransactionsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/transaction/all`, {
        params: { ...options, billingId, purchaseId, salaryId, sort: 'paymentDate#asc' },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setBillCount(res.data.data.maxRecords);
          setTxnData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, customerName: e.target.value };
    setOptions(temp);
    setPages(0);
    getTransactionsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    setName('');
    const temp = { page: 0, size };

    if (filter._id) {
      temp._id = filter._id;
    }
    if (filter.isPaymentCompleted.length) {
      temp.isPaymentCompleted = filter.isPaymentCompleted.trim();
    }
    if (filter.invoiceNumber) {
      temp.invoiceNumber = filter.invoiceNumber;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.txnId) {
      temp.txnId = filter.txnId;
    }
    if (filter.phone) {
      temp.phone = filter.phone;
    }
    if (filter.email) {
      temp.email = filter.email;
    }

    setOptions(temp);
    setPages(0);
    getTransactions(temp);
  };
  useEffect(() => {
    getTransactions(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTransactions(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Card sx={{ padding: '10px' }} className="print-hide">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Sl No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Ledger</TableCell>
                    <TableCell>Transaction Type</TableCell>
                    {/* <TableCell>Amount</TableCell> */}
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                  </TableRow>
                </TableHead>
                {txnData?.map((row, id) => (
                  <TableBody key={id}>
                    {row.transactions?.map((item, index) => (
                      <StyledGroupedTableRow number={id} key={id}>
                        <TableCell>{index === 0 && pages * size + (id + 1)}</TableCell>
                        <TableCell>{moment(item.paymentDate).format('DD/MM/YYYY')}</TableCell>
                        <TableCell>
                          <Stack direction="column" spacing={0.5}>
                            <Typography variant="p">{item.ledgerData?.name}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {item.transactionCategory && item.transactionCategory.replaceAll('_', ' ')}
                        </TableCell>
                        <TableCell>{item.transactionType === 'DEBIT' ? item.amount : ''}</TableCell>
                        <TableCell>{item.transactionType === 'CREDIT' ? item.amount : ''}</TableCell>
                      </StyledGroupedTableRow>
                    ))}
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          </Scrollbar>
          {/* <TablePagination
            rowsPerPageOptions={[ 20, 30,50]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
            }}
            component={'div'}
            count={billCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          /> */}
        </Card>
      )}
    </>
  );
}

export default TransactionsTable;
