import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Add } from '@mui/icons-material';
import { TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../Iconify';

const AddLedgerSubCategory = ({ ledgercategory, callApi }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const customerSchema = Yup.object().shape({
    _id: Yup.string().required('Required'),
    child: Yup.string().required('Required'),
  });

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Add />}>
            Category
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{'Add Sub Category'}</DialogTitle>
            <DialogContent>
              <Formik
                initialValues={{
                  _id: ledgercategory._id,
                }}
                validationSchema={customerSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios.post(
                      `${process.env.REACT_APP_API_URL}/ledgerCategory/addChild`,
                      values
                    );
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          sx={{
                            minWidth: 250,
                          }}
                          fullWidth
                          id="child"
                          name="child"
                          label="Sub Category"
                          value={formik.values.child}
                          onChange={formik.handleChange}
                          error={Boolean(formik.errors.child) && formik.touched.child}
                          helperText={formik.errors.child && formik.touched.child ? formik.errors.child : null}
                        />
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
                        Submit
                      </LoadingButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddLedgerSubCategory;
