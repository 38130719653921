import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll, Edit, Person } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Salary';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import AddorEditSalaryModal from './popups/AddorEditSalaryModal';
// import Popup from './popups/SalaryStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

function SalaryTable({ status, employeeId }) {
  const navigate = useNavigate();
  const [salaryData, setSalaryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [salaryCount, setSalaryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [selectedSalary, setSelectedSalary] = useState({});
  const [mode, setMode] = useState('add');

  const openAddSalaryModal = () => {
    setOpen(true);
  };

  const openEditSalaryModal = (item) => {
    setSelectedSalary(item);
    setOpen(true);
    setMode('edit');
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getSalaries(temp);
  };

  const getSalaries = (options) => {
    setIsLoading(true);
    const temp = { ...options, isPaymentCompleted: status === 'completed', employeeId };
    axios
      .get(`${process.env.REACT_APP_API_URL}/salary/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setSalaryCount(res.data.data.maxRecords);
          setSalaryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getSalariesWithoutLoading = (options) => {
    const temp = { ...options, isPaymentCompleted: status === 'completed', employeeId };
    axios
      .get(`${process.env.REACT_APP_API_URL}/salary/all`, {
        params: temp,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setSalaryCount(res.data.data.maxRecords);
          setSalaryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getSalariesWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter._id) {
      temp._id = filter._id;
    }
    if (filter.employeeId) {
      temp.employeeId = filter.employeeId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.txnId) {
      temp.txnId = filter.txnId;
    }
    if (filter.phone) {
      temp.phone = filter.phone;
    }
    if (filter.email) {
      temp.email = filter.email;
    }

    setOptions(temp);
    setPages(0);
    getSalaries(temp);
  };
  useEffect(() => {
    getSalaries(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getSalaries(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {/* Add salary Dialog */}
          <AddorEditSalaryModal
            open={open}
            setOpen={setOpen}
            getSalaries={getSalaries}
            mode={mode}
            setMode={setMode}
            setSelectedSalary={setSelectedSalary}
          />
          {/* Add salary dialog end */}
          {mode === 'edit' && (
            <AddorEditSalaryModal
              open={open}
              setOpen={setOpen}
              getSalaries={getSalaries}
              mode={mode}
              setMode={setMode}
              selectedSalary={selectedSalary}
              setSelectedSalary={setSelectedSalary}
            />
          )}

          <>
            <>
              <Stack direction="row" justifyContent={employeeId ? 'end' : 'space-between'} alignItems="center">
                {!employeeId && (
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search Employee..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                )}
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Button startIcon={<AddIcon />} variant="contained" onClick={openAddSalaryModal}>
                    Add Salary
                  </Button>

                  <Filter applyFilters={applyFilters} employeeId={employeeId} />
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCellCustom>Sl No</TableCellCustom>
                        <TableCellCustom>Salary Date</TableCellCustom>
                        {!employeeId && <TableCellCustom>Employee</TableCellCustom>}
                        <TableCellCustom>Contact</TableCellCustom>
                        <TableCellCustom>Total</TableCellCustom>
                        {status === 'pending' && <TableCellCustom>Paid </TableCellCustom>}
                        {status === 'pending' && <TableCellCustom>Pending  </TableCellCustom>}
                        <TableCellCustom>Action</TableCellCustom>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {salaryData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                          <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                          <TableCellCustom>{moment(item?.salaryDate).format('DD-MM-YYYY')}</TableCellCustom>
                          {!employeeId && (
                            <TableCellCustom>
                              <Stack justifyContent={'center'} spacing={0.5}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={0.5}
                                  justifyContent="flex-start"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => navigate(`/dashboard/general/employees/view/${item?.employeeId}`)}
                                >
                                  <Person />
                                  <Typography variant="p">{item?.fullname}</Typography>
                                </Stack>
                                <CopyToClipboard
                                  text={item.employeeId}
                                  onCopy={() => toast.info(' Employee ID copied')}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={0.5}
                                    justifyContent="flex-start"
                                    sx={{ cursor: 'pointer' }}
                                  >
                                    <ContentCopy color="primary" />
                                    <Typography color="primary.main" variant="p">
                                      Copy ID
                                    </Typography>
                                  </Stack>
                                </CopyToClipboard>
                              </Stack>
                            </TableCellCustom>
                          )}
                          <TableCellCustom>
                            <Stack justifyContent={'center'}>
                              <Typography variant="p">{item?.email}</Typography>
                              <Typography variant="p">{item?.phone}</Typography>
                            </Stack>
                          </TableCellCustom>
                          <TableCellCustom>{item.amount}</TableCellCustom>
                          {status === 'pending' && <TableCellCustom>{item.paidAmount}</TableCellCustom>}
                          {status === 'pending' && (
                            <TableCellCustom>{Number(item.amount) - Number(item.paidAmount)}</TableCellCustom>
                          )}
                          <TableCellCustom>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                              {!(status === 'completed') && (
                                <Icon
                                  sx={{ color: 'gray', cursor: 'pointer' }}
                                  onClick={() => openEditSalaryModal(item)}
                                >
                                  <Edit />
                                </Icon>
                              )}
                              <Link to={`/dashboard/accounts/salary/view-salary/${item._id}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <RemoveRedEyeIcon />
                                </Icon>
                              </Link>
                            </Stack>
                          </TableCellCustom>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => {
                  setSize(e.target.value);
                }}
                component={'div'}
                count={salaryCount}
                showFirstButton
                showLastButton
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </>
          </>
        </>
      )}
    </>
  );
}

export default SalaryTable;
