import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  OutlinedInput,
  useMediaQuery,
  Avatar,
  Menu,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  Card,
  Tab,
} from '@mui/material';
import { Box } from '@mui/system';
import { ContentCopy, CopyAll, Delete, Edit, MoreVert } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import EditWorkEntryModal from '../components/popups/EditWorkEntryModal';
import DeleteWorkEntryModal from '../components/popups/DeleteWorkEntryModal';
import PageHeader from '../components/smallComponents/PageHeader';
import WorkerWorkSummary from '../components/WorkerWorkSummary';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function WorkerWorkEntries() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const workerId = searchParams.get('workerId');
  const projectId = searchParams.get('projectId');
  const matches = useMediaQuery('(max-width:600px)');
  const [workerData, setWorkerData] = useState();
  const [pages, setPages] = useState(0);
  const [workerCount, setWorkerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [project, setProject] = useState({});
  const [worker, setWorker] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState({});

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getAllWorkEntris(temp);
  };

  const getAllWorkEntris = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/workEntry/all`, {
        params: {
          ...options,
          workerId,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setWorkerCount(res.data.data.maxRecords);
          setWorkerData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleGetWorkEntriesByDateRange = () => {
    const temp = { ...options, startDate, endDate };
    setOptions(temp);
    getAllWorkEntris(temp);
  };

  const getWorker = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/member`, { params: { id: workerId, role: 'WORKER' } })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setWorker(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditMenu = () => {
    setOpenEditModal(true);
    setAnchorEl(null);
  };

  const handleDeleteMenu = () => {
    setOpenDeleteModal(true);
    setAnchorEl(null);
  };

  const handleRowsPerPage = (event) => {
    setSize(event.target.value);
    const temp = { ...options, size: event.target.value, page: 0 };
    setPages(0);
    setOptions(temp);
    getAllWorkEntris(temp);
  };

  useEffect(() => {
    getAllWorkEntris(options);
    getWorker();
  }, []);

  return (
    <Page title="Work History">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <PageHeader title={'Work History'} />
            <Card>
              <Box sx={{ width: '100%' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                      <Tab label="Work Log" value="1" />
                      <Tab label="Summary" value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <>
                      {/* Edit vendor Dialog */}
                      <EditWorkEntryModal
                        open={openEditModal}
                        setOpen={setOpenEditModal}
                        selectedEntry={selectedEntry}
                        getAllWorkEntris={getAllWorkEntris}
                        options={options}
                        setSelectedEntry={setSelectedEntry}
                        project={selectedEntry?.projectData?.name}
                      />
                      {/* Edit vendor dialog end */}
                      {/* Delete vendor Dialog */}
                      <DeleteWorkEntryModal
                        open={openDeleteModal}
                        setOpen={setOpenDeleteModal}
                        selectedEntry={selectedEntry}
                        getAllWorkEntris={getAllWorkEntris}
                        options={options}
                        setSelectedEntry={setSelectedEntry}
                        project={selectedEntry?.projectData?.name}
                      />
                      {/* Delete vendor dialog end */}
                      <Box padding={2}>
                        {' '}
                        <Stack direction="row" alignItems="center" justifyContent="space-between" my={2}>
                          <Stack spacing={2} direction={'row'}>
                            <TextField
                              fullWidth
                              size="small"
                              label="Start Date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              type="date"
                              value={startDate}
                              onChange={(e) => {
                                setStartDate(e.target.value);
                              }}
                              sx={{ maxWidth: '200px' }}
                            />
                            <TextField
                              fullWidth
                              size="small"
                              label="End Date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              type="date"
                              value={endDate}
                              onChange={(e) => {
                                setEndDate(e.target.value);
                              }}
                              sx={{
                                maxWidth: '200px',
                              }}
                            />
                            <Button
                              onClick={() => {
                                if (startDate && endDate && startDate > endDate) {
                                  toast.error('Start date should be less than end date');
                                } else {
                                  handleGetWorkEntriesByDateRange();
                                }
                              }}
                              variant="contained"
                              size="small"
                            >
                              Submit
                            </Button>
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2} my={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar
                              src={
                                (worker?.profilePic && process.env.REACT_APP_API_URL + worker.profilePic) ||
                                'https://www.w3schools.com/howto/img_avatar.png'
                              }
                              sx={{ width: 40, height: 40 }}
                            /> 
                            <Stack>
                              <Typography sx={{ fontWeight: 'bold' }}>{worker?.fullname}</Typography>
                              <Typography sx={{ fontSize: '14px' }}>{worker?.email}</Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        <>
                          <Scrollbar> 
                            <TableContainer sx={{ minWidth: 800 }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCellCustom>Sl No</TableCellCustom>
                                    <TableCellCustom>Date</TableCellCustom>
                                    <TableCellCustom>Project</TableCellCustom>
                                    <TableCellCustom>Work Hrs</TableCellCustom>
                                    <TableCellCustom>Action</TableCellCustom>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {workerData?.map((item, id) => (
                                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                                      <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                                      <TableCellCustom>{moment(item?.date).format('DD-MM-YYYY')}</TableCellCustom>

                                      <TableCellCustom>{item?.projectData?.name}</TableCellCustom>
                                      <TableCellCustom>{item?.workHours}</TableCellCustom>
                                      <TableCellCustom sx={{ width: '100px' }}>
                                        <Stack direction="row" spacing={2}>
                                          <IconButton
                                            aria-label="more"
                                            aria-controls="basic-menu"
                                            aria-haspopup="true"
                                            onClick={(e) => {
                                              setAnchorEl(e.currentTarget);
                                              setSelectedEntry(item);
                                            }}
                                          >
                                            <MoreVert />
                                          </IconButton>

                                          <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                              vertical: 'center',
                                              horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                              vertical: 'top',
                                              horizontal: 'right',
                                            }}
                                            open={openMenu}
                                            onClose={handleCloseMenu}
                                            MenuListProps={{
                                              'aria-labelledby': 'basic-button',
                                            }}
                                          >
                                            <MenuItem onClick={handleEditMenu}>
                                              <ListItemIcon>
                                                <Edit fontSize="small" />
                                              </ListItemIcon>
                                              <ListItemText primary="Edit" />
                                            </MenuItem>
                                            <MenuItem onClick={handleDeleteMenu}>
                                              <ListItemIcon>
                                                <Delete fontSize="small" />
                                              </ListItemIcon>
                                              <ListItemText primary="Delete" />
                                            </MenuItem>
                                          </Menu>
                                        </Stack>
                                      </TableCellCustom>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Scrollbar>
                          <TablePagination
                            rowsPerPageOptions={[10, 20, 30]}
                            onRowsPerPageChange={(e) => {
                              handleRowsPerPage(e);
                            }}
                            component={'div'}
                            count={workerCount}
                            showFirstButton
                            showLastButton
                            onPageChange={handlePageChange}
                            rowsPerPage={size}
                            page={pages}
                          />
                        </>
                      </Box>
                    </>
                  </TabPanel>
                  <TabPanel value="2">
                    <WorkerWorkSummary />
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
