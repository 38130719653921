import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Add, AddCircle, CheckCircle, RemoveCircle, RestartAlt, Save } from '@mui/icons-material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';

import UpdateMainItemStatus from '../components/popups/UpdateMainItemStatus';
import UpdateMainItemDescription from '../components/popups/UpdateMainItemDescription';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import ViewQtoBasicDetails from '../components/ViewQtoBasicDetails';

import PageHeader from '../components/smallComponents/PageHeader';
import AttachMentsContainer from '../components/attachments/AttachMentsContainer';
import ApproveRejectQto from '../components/smallComponents/ApproveRejectQto';
import QtoTable from '../components/QtoTable';
import hasAccessOf from '../store/hasAccessOf';

const StyledTableCell = styled(TableCell)(({ theme, cellBorderLeft, cellBorderRight, fontWeight, bgcolor }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    fontWeight: 'bold',
    textAlign: 'center',
    border: '0.5px solid #d49292',
    borderLeft: cellBorderLeft || '0.5px solid #d49292',
    borderRight: cellBorderRight || '0.5px solid #d49292',
    fontSize: 14,
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'left',
    border: '0.5px solid #d49292',
    borderLeft: cellBorderLeft || '0.5px solid #d49292',
    borderRight: cellBorderRight || '0.5px solid #d49292',
    fontWeight: fontWeight || 'normal',
    backgroundColor: bgcolor || '#fff',
  },
}));

function ViewQto() {
  const qtoCreateAccess = hasAccessOf('project.qto.c');
  const qtoReadAccess = hasAccessOf('project.qto.r');
  const qtoWriteAccess = hasAccessOf('project.qto.w');
  const qtoDeleteAccess = hasAccessOf('project.qto.d');
  const trueValue = true;
  const falseValue = false;

  const { accType } = useSelector((state) => state.user.userInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [qto, setQto] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [groupedItems, setGroupedItems] = useState([]);
  const [quotationSettings, setQuotationSettings] = useState({});

  const qtoSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Required'),
      })
    ),
  });
  const formik = useFormik({
    initialValues: {
      remarks: [],
      termsAndConditions: [],
      exclusions: [],
      clientNotes: [],
    },
    validationSchema: qtoSchema,
    onSubmit: async () => {
      console.log(values);
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/qto/details`, { ...values, id });
        console.log(res.data);
        toast.success(res.data.message);
        getQto();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const submitQto = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/quotation/qto/details`, {
        id,
        qtoStatus: 'PENDING',
      });
      console.log(res.data);
      toast.success(res.data.message);
      getQto();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  /// //// total cum of indvidual subitem ////

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const setFormValues = (data) => {
    formik.setFieldValue('remarks', data.remarks);
    formik.setFieldValue('termsAndConditions', data.termsAndConditions);
    formik.setFieldValue('exclusions', data.exclusions);
    formik.setFieldValue('clientNotes', data.clientNotes);
  };

  const getQto = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/quotation/qto`, {
        params: {
          id,
        },
      });
      console.log(res.data.data);
      setFormValues(res.data.data);
      setQto(res.data.data);
      if (res.data.data?.groupedItems?.length) {
        setGroupedItems(res.data.data.groupedItems);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotationSettings = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/utils/projectSettings`)
      .then((res) => {
        setQuotationSettings(res.data.data);
        console.log('res', res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addNewDepartment = () => {
    const newGroupedItems = [...groupedItems];
    newGroupedItems.push({
      name: '',
      items: [],
    });
    setGroupedItems(newGroupedItems);
  };

  useEffect(() => {
    getQto();
    getQuotationSettings();
  }, []);

  const prefill = (item) => {
    if (qto && quotationSettings?.remarks && item === 'remarks') {
      formik.setFieldValue('remarks', quotationSettings?.remarks);
    }
    if (qto && quotationSettings?.termsAndConditions && item === 'termsAndConditions') {
      formik.setFieldValue('termsAndConditions', quotationSettings?.termsAndConditions);
    }
    if (qto && quotationSettings?.exclusions && item === 'exclusions') {
      formik.setFieldValue('exclusions', quotationSettings?.exclusions);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack spacing={5} marginBottom={10}>
            <Stack paddingBottom={5} spacing={5}>
              <ViewQtoBasicDetails data={qto} getQto={getQto} />
              <Typography variant="h6">Departments</Typography>
              {groupedItems.map((item, index) => (
                <QtoTable
                  key={index}
                  item={item}
                  slNumber={index + 1}
                  getQto={getQto}
                  qto={qto}
                  viewOnly={falseValue}
                />
              ))}
              {((accType !== 'PROJECT_ENGINEER' &&
                (qto?.quotationStatus === 'NA' || qto?.quotationStatus === 'PENDING') &&
                qto.qtoStatus !== 'APPROVED') ||
                (accType === 'PROJECT_ENGINEER' && (qto?.qtoStatus === 'NA' || qto?.qtoStatus === 'REJECTED'))) && (
                <Stack direction="row" spacing={1}>
                  <LoadingButton
                    variant="contained"
                    color="successDarker"
                    startIcon={<Add />}
                    onClick={addNewDepartment}
                  >
                    New Department
                  </LoadingButton>
                </Stack>
              )}
              <Typography variant="h6" sx={{ marginTop: '20px' }}>
                QTO Table
              </Typography>
              <QtoTable qto={qto} getQto={getQto} item={qto} viewOnly={trueValue} />
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <Box mt={5}>
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.termsAndConditions?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            TERMS & CONDITIONS
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.termsAndConditions?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const termsAndConditions = [...values.termsAndConditions];
                                      termsAndConditions.splice(index, 1);
                                      formik.setFieldValue('termsAndConditions', termsAndConditions);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const termsAndConditions = [...values.termsAndConditions];
                                  termsAndConditions[index] = e.target.innerText;
                                  formik.setFieldValue('termsAndConditions', termsAndConditions);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                {!values?.termsAndConditions?.length && (
                                  <Button
                                    size="small"
                                    startIcon={<RestartAlt />}
                                    variant="contained"
                                    color="warning"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      prefill('termsAndConditions');
                                    }}
                                  >
                                    Prefill
                                  </Button>
                                )}
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const termsAndConditions = [...values.termsAndConditions];
                                    termsAndConditions.push('');
                                    formik.setFieldValue('termsAndConditions', termsAndConditions);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.exclusions?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            EXCLUSIONS
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.exclusions?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const exclusions = [...values.exclusions];
                                      exclusions.splice(index, 1);
                                      formik.setFieldValue('exclusions', exclusions);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const exclusions = [...values.exclusions];
                                  exclusions[index] = e.target.innerText;
                                  formik.setFieldValue('exclusions', exclusions);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                {!values?.exclusions?.length && (
                                  <Button
                                    size="small"
                                    startIcon={<RestartAlt />}
                                    variant="contained"
                                    color="warning"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      prefill('exclusions');
                                    }}
                                  >
                                    Prefill
                                  </Button>
                                )}
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const exclusions = [...values.exclusions];
                                    exclusions.push('');
                                    formik.setFieldValue('exclusions', exclusions);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/*  remarks */}
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.remarks?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            REMARKS
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.remarks?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const remarks = [...values.remarks];
                                      remarks.splice(index, 1);
                                      formik.setFieldValue('remarks', remarks);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const remarks = [...values.remarks];
                                  remarks[index] = e.target.innerText;
                                  formik.setFieldValue('remarks', remarks);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                {!values?.remarks?.length && (
                                  <Button
                                    size="small"
                                    startIcon={<RestartAlt />}
                                    variant="contained"
                                    color="warning"
                                    sx={{ float: 'right' }}
                                    onClick={() => {
                                      prefill('remarks');
                                    }}
                                  >
                                    Prefill
                                  </Button>
                                )}
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const remarks = [...values.remarks];
                                    remarks.push('');
                                    formik.setFieldValue('remarks', remarks);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {/* client notes */}
                    <TableContainer
                      component={Paper}
                      sx={{ padding: '3px', marginBottom: '20px' }}
                      className={values?.clientNotes?.length ? '' : 'print-hide'}
                    >
                      <Table
                        sx={{
                          '& .MuiTableCell-root': {
                            padding: '3px',
                          },
                          borderLeft: '2px solid #490018',
                          borderRight: '2px solid #490018',
                          borderBottom: '2px solid #490018',
                        }}
                      >
                        <TableHead>
                          <StyledTableCell colSpan={11} textAlign="center">
                            NOTES
                          </StyledTableCell>
                        </TableHead>
                        <TableBody>
                          {values?.clientNotes?.map((item, index) => (
                            <TableRow key={index}>
                              <StyledTableCell sx={{ width: '80px' }}>
                                <Stack direction="row" spacing={0.5} alignItems={'center'} paddingLeft={2}>
                                  <RemoveCircle
                                    className="print-hide"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      const clientNotes = [...values.clientNotes];
                                      clientNotes.splice(index, 1);
                                      formik.setFieldValue('clientNotes', clientNotes);
                                    }}
                                  />
                                  {index + 1}
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell
                                colSpan={10}
                                contentEditable
                                onBlur={(e) => {
                                  const clientNotes = [...values.clientNotes];
                                  clientNotes[index] = e.target.innerText;
                                  formik.setFieldValue('clientNotes', clientNotes);
                                }}
                              >
                                {item}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                          <TableRow className="print-hide">
                            <StyledTableCell colSpan={11}>
                              <Stack direction="row" spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
                                <Button
                                  size="small"
                                  startIcon={<AddCircle />}
                                  variant="contained"
                                  color="successDarker"
                                  sx={{ float: 'right' }}
                                  onClick={() => {
                                    const clientNotes = [...values.clientNotes];
                                    clientNotes.push('');
                                    formik.setFieldValue('clientNotes', clientNotes);
                                  }}
                                >
                                  Row
                                </Button>
                              </Stack>
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  {((accType !== 'PROJECT_ENGINEER' &&
                    (qto?.quotationStatus === 'NA' || qto?.quotationStatus === 'PENDING') &&
                    qto.qtoStatus !== 'APPROVED') ||
                    (accType === 'PROJECT_ENGINEER' && (qto?.qtoStatus === 'NA' || qto?.qtoStatus === 'REJECTED'))) && (
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      spacing={1}
                      sx={{ padding: '10px' }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={isSubmitting}
                        onClick={() => {
                          formik.handleSubmit();
                        }}
                        startIcon={<Save />}
                      >
                        Save
                      </LoadingButton>
                    </Stack>
                  )}
                </Form>
              </FormikProvider>
            </Stack>

            {qto.qtoStatus === 'NA' && accType !== 'PROJECT_ENGINEER' && (
              <Stack direction="row" spacing={1} justifyContent="flex-end">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  startIcon={<CheckCircle />}
                  onClick={() => {
                    submitQto();
                  }}
                >
                  Submit QTO
                </LoadingButton>
              </Stack>
            )}

            {qto.qtoStatus === 'PENDING' && accType !== 'PROJECT_ENGINEER' && (
              <ApproveRejectQto qto={qto} getQto={getQto} />
            )}
          </Stack>

          <AttachMentsContainer type="qto" />
        </>
      )}
    </>
  );
}

export default ViewQto;
