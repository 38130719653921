import * as React from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import {
  TextField,
  Button,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from '@mui/material';
import * as Yup from 'yup';

const AddTool = ({ callApi }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    unitPrice: Yup.number()
      .required('Unit price is required')
      .positive('Unit price must be a positive number')
      .typeError('Unit price must be a number'),
    description: Yup.string(),
    isBlocked: Yup.string().required('Status is required'),
  });

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <Button variant="contained" onClick={handleClickOpen}>
            Add
          </Button>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Tool</DialogTitle>
            <DialogContent
              sx={{
                minWidth: {
                  xs: '100%',
                  sm: '500px',
                },
              }}
            >
              <Formik
                initialValues={{
                  name: '',
                  unitPrice: '',
                  description: '',
                  isBlocked: 'false',
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, actions) => {
                  try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/tool`, values, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });
                    actions.setSubmitting(false);
                    toast.success(response.data.message);
                    handleClose();
                    callApi();
                  } catch (error) {
                    console.log(error);
                    actions.setSubmitting(false);
                    toast.error(error.response.data.message);
                  }
                }}
              >
                {(formik) => (
                  <Form>
                    <Stack spacing={2} mt={2}>
                      <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Name"
                        {...formik.getFieldProps('name')}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                          fullWidth
                          id="unitPrice"
                          name="unitPrice"
                          label="Unit Price"
                          {...formik.getFieldProps('unitPrice')}
                          error={formik.touched.unitPrice && Boolean(formik.errors.unitPrice)}
                          helperText={formik.touched.unitPrice && formik.errors.unitPrice}
                        />
                        <TextField
                          fullWidth
                          type="file"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          id="image"
                          name="image"
                          label="Image"
                          onChange={(event) => {
                            formik.setFieldValue('image', event.currentTarget.files[0]);
                          }}
                        />
                      </Stack>
                      <TextField
                        select
                        fullWidth
                        id="isBlocked"
                        name="isBlocked"
                        label="Status"
                        {...formik.getFieldProps('isBlocked')}
                        error={formik.touched.isBlocked && Boolean(formik.errors.isBlocked)}
                        helperText={formik.touched.isBlocked && formik.errors.isBlocked}
                      >
                        <MenuItem value="false">Active</MenuItem>
                        <MenuItem value="true">Blocked</MenuItem>
                      </TextField>
                      <TextField
                        fullWidth
                        multiline
                        id="description"
                        name="description"
                        label="Description"
                        {...formik.getFieldProps('description')}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                      />
                      <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" disabled={formik.isSubmitting}>
                          Add
                        </Button>
                      </DialogActions>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </div>
    </>
  );
};

export default AddTool;
