import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import { BarGraph, AppWidgetSummary } from '../sections/@dashboard/app';
import hasAccessOf from '../store/hasAccessOf';
import { rfqStatusOrder } from '../utils/commonFunctions';

// ----------------------------------------------------------------------

export default function CrmAnalyticsComponent() {
  const theme = useTheme();
  const [counts, setCounts] = useState({});
  const leadSummaryAccess = hasAccessOf('dashboard.leadSummary.r');

  const [leadBarGraphData, setLeadBarGraphData] = useState({ chartLabels: [], chartData: [] });
  const [leadBargraphType, setLeadBargraphType] = useState('WEEK');

  const [customerBarGraphData, setCustomerBarGraphData] = useState({ chartLabels: [], chartData: [] });
  const [customerBargraphType, setCustomerBargraphType] = useState('WEEK');

  const [leadCountByStatus, setLeadCountByStatus] = useState([]);
  const leadCountsAccess = hasAccessOf('dashboard.leadSummary.r');

  const getCounts = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/summary`);
      setCounts(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setLeadBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeadCountByStatus = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/lead/count/status`);
      if (!res.data.isError && res?.data?.data?.data?.length > 0) {
        //  sort by status order also add missig status with total 0
        const sortedData = rfqStatusOrder.map((status) => {
          const item = res.data.data.data.find((item) => item.status === status);
          return item || { status, total: 0 };
        });
        setLeadCountByStatus(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/customer/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setCustomerBarGraphData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (leadSummaryAccess) {
      getCounts();
    }
    if (leadCountsAccess) {
      getLeadCountByStatus();
    }
  }, []);

  return (
    <Grid container spacing={3} rowGap={4}>
      <Grid item xs={12}>
        <Typography variant="h4">CRM Analytics</Typography>
      </Grid>
      {/* lead counts  by status */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {leadCountsAccess && (
            <Grid item xs={12}>
              <Typography variant="h5">RFQs By Status</Typography>
            </Grid>
          )}
          <>
            {leadCountsAccess &&
              leadCountByStatus.map((item) => (
                <Grid item xs={12} sm={6} md={3} lg={2} key={item.status}>
                  <AppWidgetSummary
                    title={item.status}
                    total={item.total}
                    color="navy"
                    // icon={'ant-design:android-filled'}
                  />
                </Grid>
              ))}
          </>
        </Grid>
      </Grid>
      {/* lead counts  by status end */}

      {leadSummaryAccess && (
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Customers</Typography>
            </Grid>
            <Grid item xs={12}>
              <BarGraph
                unit="Customers"
                xaxisPlot={'category'}
                callAPI={getCustomerBarGraphData}
                chartLabels={[...customerBarGraphData.chartLabels]}
                chartData={[
                  {
                    name: 'Customers',
                    type: 'column',
                    fill: 'solid',
                    data: [...customerBarGraphData.chartData],
                    color: theme.palette.bluee.main,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* RFQ summary */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">RFQ Summary</Typography>
          </Grid>
          {leadSummaryAccess && (
            <Grid item xs={12} sm={6} md={3} lg={2.4}>
              <AppWidgetSummary
                title="Today"
                total={counts?.today}
                color="navy"
                // icon={'ant-design:android-filled'}
              />
            </Grid>
          )}

          {leadSummaryAccess && (
            <Grid item xs={12} sm={6} md={3} lg={2.4}>
              <AppWidgetSummary
                title="Week"
                total={counts?.week}
                color="navy"
                // icon={'ant-design:apple-filled'}
              />
            </Grid>
          )}

          {leadSummaryAccess && (
            <Grid item xs={12} sm={6} md={3} lg={2.4}>
              <AppWidgetSummary
                title="Month"
                total={counts?.month}
                color="navy"
                // icon={'ant-design:windows-filled'}
              />
            </Grid>
          )}

          {leadSummaryAccess && (
            <Grid item xs={12} sm={6} md={3} lg={2.4}>
              <AppWidgetSummary
                title="Year"
                total={counts?.year}
                color="navy"
                // icon={'ant-design:bug-filled'}
              />
            </Grid>
          )}

          {leadSummaryAccess && (
            <Grid item xs={12} sm={6} md={3} lg={2.4}>
              <AppWidgetSummary
                title="Total"
                total={counts?.total}
                color="navy"
                // icon={'ant-design:bug-filled'}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {leadSummaryAccess && (
        <Grid item xs={12} md={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">RFQs</Typography>
            </Grid>
            <Grid item xs={12}>
              <BarGraph
                unit="RFQs"
                callAPI={getLeadBarGraphData}
                // subheader="(+43%) than last year"
                xaxisPlot={'category'}
                chartLabels={[...leadBarGraphData.chartLabels]}
                chartData={[
                  {
                    name: 'RFQs',
                    type: 'column',
                    fill: 'solid',
                    data: [...leadBarGraphData.chartData],
                    color: theme.palette.balanced.main,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppCurrentVisits
        title="Current Visits"
        chartData={[
          { label: 'America', value: 4344 },
          { label: 'Asia', value: 5435 },
          { label: 'Europe', value: 1443 },
          { label: 'Africa', value: 4443 },
        ]}
        chartColors={[
          theme.palette.primary.main,
          theme.palette.chart.blue[0],
          theme.palette.chart.violet[0],
          theme.palette.chart.yellow[0],
        ]}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={8}>
      <AppConversionRates
        title="Conversion Rates"
        subheader="(+43%) than last year"
        chartData={[
          { label: 'Italy', value: 400 },
          { label: 'Japan', value: 430 },
          { label: 'China', value: 448 },
          { label: 'Canada', value: 470 },
          { label: 'France', value: 540 },
          { label: 'Germany', value: 580 },
          { label: 'South Korea', value: 690 },
          { label: 'Netherlands', value: 1100 },
          { label: 'United States', value: 1200 },
          { label: 'United Kingdom', value: 1380 },
        ]}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppCurrentSubject
        title="Current Subject"
        chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
        chartData={[
          { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
          { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
          { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
        ]}
        chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={8}>
      <AppNewsUpdate
        title="News Update"
        list={[...Array(5)].map((_, index) => ({
          id: faker.datatype.uuid(),
          title: faker.name.jobTitle(),
          description: faker.name.jobTitle(),
          image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
          postedAt: faker.date.recent(),
        }))}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppOrderTimeline
        title="Order Timeline"
        list={[...Array(5)].map((_, index) => ({
          id: faker.datatype.uuid(),
          title: [
            '1983, orders, $4220',
            '12 Invoices have been paid',
            'Order #37745 from September',
            'New order placed #XF-2356',
            'New order placed #XF-2346',
          ][index],
          type: `order${index + 1}`,
          time: faker.date.past(),
        }))}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={4}>
      <AppTrafficBySite
        title="Traffic by Site"
        list={[
          {
            name: 'FaceBook',
            value: 323234,
            icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
          },
          {
            name: 'Google',
            value: 341212,
            icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
          },
          {
            name: 'Linkedin',
            value: 411213,
            icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
          },
          {
            name: 'Twitter',
            value: 443232,
            icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
          },
        ]}
      />
    </Grid> */}

      {/* <Grid item xs={12} md={6} lg={8}>
      <AppTasks
        title="Tasks"
        list={[
          { id: '1', label: 'Create FireStone Logo' },
          { id: '2', label: 'Add SCSS and JS files if required' },
          { id: '3', label: 'Stakeholder Meeting' },
          { id: '4', label: 'Scoping & Estimations' },
          { id: '5', label: 'Sprint Showcase' },
        ]}
      />
    </Grid> */}
    </Grid>
  );
}
