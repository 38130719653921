import moment from 'moment';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import ReactToPrint from 'react-to-print';
import { Attachment, BorderColor, Delete, DeleteForever, Edit, Print } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Page from '../components/Page';
import PurchaseEditForm from '../components/PurchaseEditForm';
import TransactionsTable from '../components/TransactionsTable';
import PageHeader from '../components/smallComponents/PageHeader';
import Logo from '../components/Logo';
import DeleteModal from '../components/popups/DeleteModal';

const ViewPurchase = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [editMode, setEditMode] = useState(false);
  const [purchase, setPurchase] = useState({});
  const componentRef = React.useRef();
  const [openDelete, setOpenDelete] = useState(false);

  const getPurchase = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchase`, { params: { invoiceNumber: id } });
      console.log(res.data.data, 'purchase');
      setPurchase(res.data.data.invoice);
    } catch (error) {
      console.log(error);
    }
  };

  const downloadDoc = async (e, name) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/purchase/attachment-download`, {
        params: {
          file: name,
        },
      });
      window.open(res.data.data.url);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPurchase();
  }, []);

  return (
    <Page title="View Purchase">
      <Container maxWidth="xl">
        <PageHeader title=" Purchase" />
        <Stack spacing={2}>
          <Card>
            <Box sx={{ padding: '10px' }}>
              {editMode && (
                <>
                  <PurchaseEditForm purchase={purchase} setEditMode={setEditMode} getPurchase={getPurchase} />
                </>
              )}
              {!editMode && (
                <>
                  <Stack
                    direction={'row'}
                    px={5}
                    py={3}
                    sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                    justifyContent={'space-between'}
                  >
                    {/* <Box sx={{ flex: '1' }}>Logo</Box> */}

                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                      <Stack>
                        {/* <Typography variant="h5" fontWeight={'bold'} color={'#fff'}>
                          {purchase?.invoiceNumber}
                        </Typography> */}
                        <Stack direction="row" spacing={1}>
                          <Typography variant="body2" fontWeight={'bold'} color={'#fff'}>
                            Bill Number:
                          </Typography>
                          <Typography variant="body2" color={'#fff'}>
                            {purchase?.billNumber}
                          </Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1}>
                          <Typography variant="body2" fontWeight={'bold'} color={'#fff'}>
                            Date:
                          </Typography>
                          <Typography variant="body2" color={'#fff'}>
                            {moment(purchase?.invoiceDate).format('DD/MM/YYYY')}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction={'column'} spacing={1} alignItems={'center'}>
                      {purchase?.paidAmount === 0 && (
                        <Button color="error" onClick={() => setOpenDelete(true)} size="small" startIcon={<Delete />}>
                          Delete
                        </Button>
                      )}
                      <DeleteModal
                        open={openDelete}
                        setOpen={setOpenDelete}
                        url={`purchase?invoiceNumber=${purchase?.invoiceNumber}`}
                        navigateTo="/dashboard/accounts/purchase"
                        message="Are you sure you want to delete this Purchase?"
                        title="Delete Purchase"
                      />
                      {purchase?.paidAmount === 0 && (
                        <Button
                          variant="text"
                          onClick={() => setEditMode(true)}
                          color="light"
                          size="small"
                          startIcon={<Edit />}
                          className="print-hide"
                        >
                          Edit
                        </Button>
                      )}
                      <ReactToPrint
                        trigger={() => (
                          <Button variant="text" color="light" startIcon={<Print />} size="small">
                            Print
                          </Button>
                        )}
                        content={() => componentRef.current}
                        copyStyles
                      />
                    </Stack>
                  </Stack>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          From
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{purchase?.from?.fullname}</Typography>
                          <Typography>{purchase?.from?.address}</Typography>
                          <Typography>{purchase?.from?.phone}</Typography>
                          <Typography>{purchase?.from?.email}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack px={5} pt={5}>
                        <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                          To
                        </Typography>
                        <Stack paddingLeft={4}>
                          <Typography>{purchase?.to?.name}</Typography>
                          <Typography>{purchase?.to?.address}</Typography>
                          <Typography>{purchase?.to?.pin}</Typography>
                          <Typography>{purchase?.to?.phone}</Typography>
                          <Typography>{purchase?.to?.email}</Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      margin={1}
                      sx={{
                        borderRadius: '5px',
                      }}
                    >
                      <Stack p={5}>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Taxable Amount</TableCell>
                                <TableCell>Current Tax</TableCell>
                                <TableCell>Tax</TableCell>
                                <TableCell>Total</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {purchase?.items?.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>{item.unitPrice}</TableCell>
                                  <TableCell>{item.quantity}</TableCell>
                                  <TableCell>{Number(item.unitPrice) * Number(item.quantity)}</TableCell>
                                  <TableCell>
                                    {item.tax?.name} ({item.tax?.percentage}%)
                                  </TableCell>
                                  <TableCell>
                                    {parseFloat(Number(item.tax?.amount) * Number(item.quantity)).toFixed(2)}
                                  </TableCell>
                                  <TableCell>{parseFloat(item.total).toFixed(2)}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack direction={'row'} pb={5} justifyContent={'space-between'} mx={10} alignItems={'end'}>
                        <Typography variant="body2" fontWeight={'bold'} paddingRight={2}>
                          {purchase?.grandTotalInWords}
                        </Typography>
                        <Stack justifyContent="center" spacing={2} sx={{ width: '400px' }}>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Sub Total (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {purchase?.subTotal}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Tax (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {purchase?.totalTax}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Grand Total (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {purchase?.grandTotal}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Paid (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {purchase?.paidAmount}
                            </Typography>
                          </Stack>
                          <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                            <Typography variant="body2" fontWeight={'bold'}>
                              Pending (AED):
                            </Typography>
                            <Typography variant="body2" fontWeight={'bold'}>
                              {(parseFloat(purchase?.grandTotal) - parseFloat(purchase?.paidAmount)).toFixed(2)}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                    {purchase?.paymentTerms &&
                      purchase?.paymentTerms.length > 0 &&
                      purchase.paymentTerms[0]?.length > 0 && (
                        <Grid item xs={12}>
                          <Stack p={5} spacing={2}>
                            <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
                              Payment Terms
                            </Typography>
                            <Stack spacing={1}>
                              {purchase?.paymentTerms?.map((term, index) => (
                                <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                                  <Typography minWidth={20}>{index + 1}.</Typography>
                                  <Typography>{term}</Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </Stack>
                        </Grid>
                      )}
                    {/* Notes , which is also array */}
                    {purchase?.notes && purchase?.notes.length > 0 && purchase.notes[0]?.length > 0 && (
                      <Grid item xs={12}>
                        <Stack p={5} spacing={2}>
                          <Typography sx={{ fontWeight: 'bold' }} gutterBottom>
                            Notes
                          </Typography>
                          <Stack spacing={1}>
                            {purchase?.notes?.map((note, index) => (
                              <Stack key={index} direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography minWidth={20}>{index + 1}.</Typography>
                                <Typography>{note}</Typography>
                              </Stack>
                            ))}
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                  <Stack spacing={1} sx={{ width: '100%' }} paddingX={2}>
                    <Stack direction={'row'} alignItems="center">
                      <Attachment />
                      <Typography variant="h6" fontWeight={'bold'}>
                        Attachments
                      </Typography>
                    </Stack>
                    <Stack direction={'row'} spacing={1} className="print-hide">
                      {console.log(purchase?.attachments)}
                      {purchase?.attachments?.map((attachment, index) => (
                        <Button
                          key={index}
                          variant="contained"
                          color="primary"
                          onClick={(e) => downloadDoc(e, attachment.fileUrl)}
                        >
                          {attachment.originalName}
                        </Button>
                      ))}
                    </Stack>
                  </Stack>
                  {!purchase?.isPaymentCompleted && (
                    <Stack direction={'row'} p={2} justifyContent={'end'} sx={{ width: '100%' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        className="print-hide"
                        onClick={() =>
                          navigate(`/dashboard/accounts/pending-payments/purchase/view-payment/${purchase.vendorId}?`, {
                            state: { invoice: purchase },
                          })
                        }
                      >
                        Payment Issued
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Box>
          </Card>

          {purchase?.transactions && !!purchase.transactions.length && <TransactionsTable purchaseId={purchase?._id} />}
        </Stack>
      </Container>

      {/*  Printable purchase */}
      <>
        <Stack
          direction={'column'}
          sx={{
            width: '1100px',
            height: '100%',
            padding: '20px',
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
          spacing={5}
          ref={componentRef}
          className="print-only"
        >
          {/* Head */}
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'} sx={{ width: '100%' }} pb={5}>
            <Stack direction={'column'} spacing={2} sx={{ height: '100%' }} flex={1}>
              <Logo />
              <Stack spacing={0.25} sx={{ maxWidth: '300px' }}>
                <Typography variant="h5" fontWeight={'bold'} gutterBottom>
                  {purchase?.to?.name}
                </Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.to?.address}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.to?.pin}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.to?.phone}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.to?.email}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{`TRN:   ${purchase?.to?.trn}`}</Typography>
              </Stack>
            </Stack>
            <Stack
              direction={'column'}
              spacing={2}
              sx={{ height: '100%' }}
              flex={1}
              alignItems={'flex-end'}
              justifyContent={'space-between'}
            >
              <Stack
                direction={'column'}
                alignItems={'flex-end'}
                sx={{
                  height: '90px',
                }}
              >
                <Stack alignItems={'flex-end'}>
                  <Typography sx={{ fontSize: '35px', lineHeight: 1 }}>Bill</Typography>
                  <Typography
                    variant="h5"
                    fontWeight={'bold'}
                    sx={{
                      lineHeight: '1',
                    }}
                  >
                    {`Bill#  ${purchase?.billNumber}`}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'column'} alignItems={'flex-end'}>
                <Typography sx={{ fontSize: '15px' }} fontWeight={'bold'}>
                  Balance Due
                </Typography>
                <Typography variant="h5" fontWeight={'bold'} gutterBottom>
                  {`AED ${parseFloat(purchase?.grandTotal - purchase?.paidAmount).toFixed(2)}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  bill from and date */}
          <Stack direction={'row'} justifyContent={'end'} alignItems={'start'} sx={{ width: '100%' }}>
            <Stack
              direction={'column'}
              spacing={2}
              sx={{ height: '100%' }}
              flex={1}
              alignItems={'start'}
              justifyContent={'end'}
            >
              <Stack direction={'column'} alignItems={'start'} justifyContent={'end'}>
                <Typography sx={{ fontSize: '15px' }}>Bill From</Typography>
                <Typography sx={{ fontSize: '15px' }} fontWeight={'bold'}>
                  {purchase?.from?.name}
                </Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.from?.address}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.from?.phone}</Typography>
                <Typography sx={{ fontSize: '15px' }}>{purchase?.from?.email}</Typography>
              </Stack>
            </Stack>
            <Stack direction={'column'} spacing={2} sx={{ height: '100%' }} flex={1} alignItems={'flex-end'}>
              <Stack direction={'column'} spacing={1}>
                <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} spacing={2}>
                  <Typography sx={{ fontSize: '15px' }}>Date:</Typography>
                  <Typography sx={{ fontSize: '15px' }}>
                    {moment(purchase?.invoiceDate).format('DD/MM/YYYY')}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          {/*  Table */}
          <TableContainer
            sx={{
              border: '1px solid #eceff3',
              borderRadius: '5px',
              overflow: 'hidden',
            }}
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: (theme) => theme.palette.primary?.main,
                }}
              >
                <TableRow>
                  <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Description
                  </TableCell>
                  <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Quantity
                  </TableCell>
                  <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Unit Price
                  </TableCell>
                  <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    Total
                  </TableCell>
                  <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    VAT
                  </TableCell>
                  <TableCell sx={{ color: (theme) => theme.palette.primary?.contrastText, fontSize: '15px' }}>
                    VAT Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchase?.items?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: '15px' }}>{item.name}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{item.quantity}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>{parseFloat(item.unitPrice).toFixed(2)}</TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      {parseFloat(Number(item.unitPrice) * Number(item.quantity)).toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      <Stack direction={'column'} spacing={1}>
                        <Typography variant="body2">{item.tax?.percentage}%</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ fontSize: '15px' }}>
                      {parseFloat(Number(item.tax?.amount) * Number(item.quantity)).toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/*  Total */}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'end'}
            sx={{ width: '100%' }}
            paddingX={5}
            paddingY={5}
          >
            <Typography
              fontWeight={'bold'}
              sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px', paddingRight: 2 }}
            >
              {purchase?.grandTotalInWords}
            </Typography>
            <Stack justifyContent="center" spacing={2} sx={{ width: '400px' }} pr={4}>
              <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  Sub Total (AED):
                </Typography>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  {parseFloat(purchase?.subTotal).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  VAT (AED):
                </Typography>
                <Typography fontWeight={'bold'} sx={{ fontSize: '15px' }}>
                  {parseFloat(purchase?.totalTax).toFixed(2)}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={1} sx={{ width: '100%' }} justifyContent={'space-between'}>
                <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}>
                  Grand Total (AED):
                </Typography>
                <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '15px' }}>
                  {parseFloat(purchase?.grandTotal).toFixed(2)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {/*  payment terms */}
          {purchase?.paymentTerms && purchase?.paymentTerms.length > 0 && purchase.paymentTerms[0]?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                Payment Terms
              </Typography>
              <Stack spacing={1}>
                {purchase?.paymentTerms?.map((term, index) => (
                  <Typography key={index} sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary }}>
                    {term}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/*  notes */}
          {purchase?.notes && purchase?.notes.length > 0 && purchase.notes[0]?.length > 0 && (
            <Stack direction={'column'} spacing={2} alignItems={'flex-start'} sx={{ width: '100%' }}>
              <Typography fontWeight={'bold'} sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
                Notes
              </Typography>
              <Stack spacing={1}>
                {purchase?.notes?.map((note, index) => (
                  <Typography key={index} sx={{ fontSize: '14px', color: (theme) => theme.palette.text.secondary }}>
                    {note}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          )}

          {/* This document is computer generated and does not require the signature or the Company's stamp in order to be considered valid */}
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            paddingTop={5}
            sx={{
              width: '100%',
              padding: '20px',
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'lighter',
                color: (theme) => theme.palette.text.secondary,
                fontStyle: 'italic',
              }}
              textAlign={'center'}
            >
              This document is computer generated and does not require the signature or the Company's stamp in order to
              be considered valid.
            </Typography>
          </Stack>
        </Stack>
      </>
    </Page>
  );
};

export default ViewPurchase;
