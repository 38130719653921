import React, { useEffect, useState } from 'react';
import { Container, Stack, Card, Typography, Avatar, Box, Grid, TextField, MenuItem } from '@mui/material';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Page from '../components/Page';
import LoadingIcon from '../components/smallComponents/LoadingIcon';

import Iconify from '../components/Iconify';
import ToolTableTab from '../components/ToolTableTab';
import PageHeader from '../components/smallComponents/PageHeader';
import ProjectToolTransaferLogs from '../components/ProjectToolTransferLogs';

function DetailItem({ icon, title, data, background, sx, onClick, profilePic, statusChange, onStatusChange }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
      paddingX={1}
      paddingY={0.5}
    >
      <Stack direction="row" alignItems="center" background={background} padding={profilePic ? 0 : 1}>
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      {statusChange === 'yes' ? (
        <>
          <TextField select label="Status" value={data} size="small" onChange={(e) => onStatusChange(e.target.value)}>
            <MenuItem value="false">Active</MenuItem>
            <MenuItem value="true">Blocked</MenuItem>
          </TextField>
        </>
      ) : (
        <Stack direction="column">
          <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
          <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

function ToolProjectLog() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const toolId = searchParams.get('toolId');
  const [isLoading, setIsLoading] = useState(true);
  const [tool, setTool] = useState({});
  const [toolUsage, setToolUsage] = useState({});
  const [project, setProject] = useState({});

  const getToolUsageData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/toolFlowLog/usageInProject/single`, {
        params: { toolId, projectId },
      });
      console.log(res);
      if (res.data.isError === false) {
        setToolUsage(res.data.data);
        setTool(res.data.data.toolData);
        setProject(res.data.data.projectData);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getToolUsageData();
  }, []);

  return (
    <Page title="View Project Tool Detail">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Container maxWidth="xl">
          <PageHeader title="View Tool" />
          <Stack direction={'column'} spacing={2}>
            <Card>
              <Box padding={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailItem
                      icon="icons8:parallel-tasks"
                      title="Project Name"
                      data={toolUsage.projectData.name}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="entypo:tools"
                      title="Name"
                      data={tool?.name}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="ion:pricetags"
                      title="Unit Price"
                      data={tool?.unitPrice}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Stock"
                      data={tool?.stock}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Total Units In Use"
                      data={tool?.unitsInUse}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Units In Use In this Project"
                      data={toolUsage?.inUse}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon="teenyicons:spreadsheet-solid"
                      title="Units Available"
                      data={tool?.unitsAvailable}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DetailItem
                      icon={tool?.isBlocked ? 'icomoon-free:blocked' : 'gg:check-o'}
                      title="Status"
                      data={tool?.isBlocked ? 'Blocked' : 'Active'}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                      // statusChange={'yes'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DetailItem
                      icon="material-symbols:description"
                      title="Description"
                      data={tool?.description}
                      background="#f0f0f0"
                      sx={{ minHeight: '65px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Card>
            <ProjectToolTransaferLogs />
          </Stack>
        </Container>
      )}
    </Page>
  );
}

export default ToolProjectLog;
