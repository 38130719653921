import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Button,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function PaymentFormPurchase({ invoices, purchase, totalAmount, error }) {
  const navigate = useNavigate();
  const [ledgers, setLedgers] = useState([]);
  const paymentSchema = Yup.object().shape({
    vendorId: Yup.string().required('Supplier is required'),
    paymentDate: Yup.string().required('Payment Date is required'),
    paymentMode: Yup.string().required('Payment Reference is required'),
    paidThrough: Yup.string().required('Paid Through is required'),
  });

  const formik = useFormik({
    initialValues: {
      vendorId: purchase.vendorId,
      amount: totalAmount,
      paymentDate: moment().format('YYYY-MM-DD'),
      paymentReference: '',
      paymentMode: '',
      remarks: '',
      invoices,
      paidThrough: '',
    },
    validationSchema: paymentSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/payment/purchase`, values);
        toast(res.data.message);
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getLedgers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/ledger/all/active`, {
        params: {
          accountType: 'Cash & Cash Equivalent',
        },
      });
      console.log(res.data.data, 'ledgers');
      if (!res.data.isError) {
        setLedgers(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    formik.setFieldValue('amount', totalAmount);
    formik.setFieldValue('invoices', invoices);
  }, [purchase, invoices, totalAmount]);

  useEffect(() => {
    getLedgers();
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Amount Payable"
              disabled
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{ width: '100%' }}
              {...getFieldProps('amount')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              label="Payment Mode"
              fullWidth
              sx={{ width: '100%' }}
              {...getFieldProps('paymentMode')}
              error={Boolean(touched.paymentMode && errors.paymentMode)}
              helperText={touched.paymentMode && errors.paymentMode}
            >
              <MenuItem value="BANK_TRANSFER">BANK TRANSFER</MenuItem>
              <MenuItem value="CASH">CASH</MenuItem>
              <MenuItem value="CHEQUE">CHEQUE</MenuItem>
              <MenuItem value="CARD">CARD</MenuItem>
              <MenuItem value="OTHER">OTHER</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              fullWidth
              id="asynchronous-demo"
              isOptionEqualToValue={(option, value) => option.name === value.name}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.name === value.name}
              options={ledgers}
              onChange={(_, value) => {
                if (value) {
                  formik.setFieldValue('paidThrough', value._id);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Paid Through"
                  InputProps={{
                    ...params.InputProps,
                  }}
                  fullWidth
                  sx={{ width: '100%' }}
                  error={Boolean(touched.depositTo && errors.depositTo)}
                  helperText={touched.depositTo && errors.depositTo}
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                      {option.name}
                    </Typography>
                  </Stack>
                </li>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              type="date"
              label="Payment Date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={{ width: '100%' }}
              {...getFieldProps('paymentDate')}
              error={Boolean(touched.paymentDate && errors.paymentDate)}
              helperText={touched.paymentDate && errors.paymentDate}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField
              label="Payment Reference"
              fullWidth
              sx={{ width: '100%' }}
              {...getFieldProps('paymentReference')}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <TextField label="Remarks" fullWidth sx={{ width: '100%' }} {...getFieldProps('Remarks')} />
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
              {!!totalAmount && <Typography>{` ${totalAmount} will be credited `}</Typography>}
              <LoadingButton variant="contained" type="submit" loading={isSubmitting} disabled={error}>
                Submit
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
