import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import { DeleteForever } from '@mui/icons-material';
import * as Yup from 'yup';
import { FilePond, File, registerPlugin } from 'react-filepond';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

/// file pond //
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import PageHeader from '../components/smallComponents/PageHeader';
import Page from '../components/Page';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    color: 'white',
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottomColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiInputLabel-root': {
    color: 'white',
  },
});

const AddToolPurchase = () => {
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [company, setCompany] = useState({});
  const [vendor, setVendor] = useState({});
  const [files, setFiles] = useState([]);
  const [tools, setTools] = useState([]);
  const [toolsLoading, setToolsLoading] = useState(false);
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);

  const convertToParseFloat = (value) => parseFloat(value).toFixed(5);

  const purchaseSchema = Yup.object().shape({
    invoiceDate: Yup.string().required('Invoice Date is required'),
    subTotal: Yup.string().required('Sub Total is required'),
    grandTotal: Yup.string().required('Grand Total is required'),
    vendorId: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      invoiceDate: moment().format('YYYY-MM-DD'),
      vendorId: '',
      subTotal: '',
      grandTotal: '',
      items: [],
      billNumber: '',
      attachments: [],
      remarks: '',
    },
    validationSchema: purchaseSchema,
    onSubmit: async () => {
      const data = new FormData();
      if (files?.length > 0) {
        files.forEach((item) => {
          data.append('attachments', item.file);
        });
      }
      data.append('invoiceDate', values.invoiceDate);
      data.append('vendorId', values.vendorId);
      data.append('subTotal', convertToParseFloat(values.subTotal));
      // eslint-disable-next-line no-restricted-globals
      data.append('grandTotal', convertToParseFloat(values.grandTotal));
      data.append('items', JSON.stringify(values.items));
      data.append('billNumber', values.billNumber);
      data.append('remarks', values.remarks);

      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/toolTransaction/purchase`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res.data.data);
        toast.success(res.data.message);
        resetForm();
        navigate('/dashboard/warehouse/stock?tab=2');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const getVendors = async () => {
    try {
      setVendorsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/active`);
      console.log(res.data.data, 'vendors');
      setVendors(res.data.data);
      setVendorsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTools = async () => {
    try {
      setToolsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/tool/active`);
      console.log(res.data.data, 'tools');
      setTools(res.data.data);
      setToolsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const totalCalculator = (quantity, price) => {
    let total = 0;
    total = Number(quantity) * Number(price);
    return Number(total.toFixed(2));
  };

  const getCompany = async () => {
    try {
      setCompanyLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/utils/companyAddress`);
      console.log(res.data.data, 'company');
      setCompany(res.data.data);
      setCompanyLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getVendor = async (_id) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/vendor/one`, { params: { _id } });
      console.log(res.data, 'vendor');
      if (!res.data.isError) {
        setVendor(res.data.data.vendor);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVendors();
    getCompany();
    getTools();
  }, []);

  useEffect(() => {
    if (values.vendorId) {
      getVendor(values.vendorId);
    }
  }, [values.vendorId]);

  useEffect(() => {
    let subTotal = 0;
    let grandTotal = 0;
    values.items.forEach((item) => {
      subTotal += Number(item.total);
    });
    grandTotal = subTotal;
    formik.setFieldValue('subTotal', subTotal);
    formik.setFieldValue('grandTotal', grandTotal);
  }, [values.items]);

  useEffect(() => {
    const attachments = [];
    files.forEach((item) => {
      attachments.push(item.file);
    });
    console.log(attachments);
    formik.setFieldValue('attachments', attachments);
  }, [files]);

  console.log(values?.source);
  return (
    <Page title="Add Purchase">
      <Container maxWidth="xl">
        <PageHeader title="Add Purchase" />
        <Card>
          <Box>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack
                  direction={'row'}
                  px={5}
                  py={3}
                  sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                >
                  {/* <Box sx={{ flex: '1' }}>Logo</Box> */}
                  <Stack justifyContent="center" spacing={2} direction={'column'}>
                    <Stack direction={'row'} spacing={2}>
                      <StyledTextField
                        label=" Date"
                        size="small"
                        type="date"
                        {...getFieldProps('invoiceDate')}
                        error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                        helperText={touched.invoiceDate && errors.invoiceDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <Autocomplete
                        id="asynchronous-demo"
                        isOptionEqualToValue={(option, value) => option.companyName === value.companyName}
                        getOptionLabel={(option) => option.companyName}
                        getOptionSelected={(option, value) => option.companyName === value.companyName}
                        options={vendors}
                        onChange={(_, value) => {
                          if (value) {
                            formik.setFieldValue('vendorId', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <StyledTextField
                            {...params}
                            label="Supplier"
                            size="small"
                            sx={{
                              minWidth: '250px',
                            }}
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.vendorId && errors.vendorId)}
                            helperText={touched.vendorId && errors.vendorId}
                          />
                        )}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <Stack direction="row" spacing={2} alignItems="center">
                              <Avatar
                                sx={{ width: 35, height: 35, cursor: 'pointer' }}
                                src={
                                  (option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) ||
                                  'https://www.w3schools.com/howto/img_avatar2.png'
                                }
                              />
                              <Stack direction={'column'}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                  {option.companyName}
                                </Typography>
                                <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                  {option.phone}
                                </Typography>
                              </Stack>
                            </Stack>
                          </li>
                        )}
                      />
                      <StyledTextField label="Bill Number" size="small" type="text" {...getFieldProps('billNumber')} />
                    </Stack>
                  </Stack>
                </Stack>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        Supplier
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{vendor.companyName}</Typography>
                        <Typography>{vendor.contactPerson}</Typography>
                        <Typography>{vendor.companyAddress}</Typography>
                        <Typography>{vendor.email}</Typography>
                        <Typography>{vendor.phone1}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <Stack px={5} pt={5}>
                      <Typography variant="h6" fontWeight={'bold'} gutterBottom>
                        To
                      </Typography>
                      <Stack paddingLeft={4}>
                        <Typography>{company.companyName}</Typography>
                        <Typography>{company.address}</Typography>
                        <Typography>{company.pin}</Typography>
                        <Typography>{company.phone}</Typography>
                        <Typography>{company.email}</Typography>
                      </Stack>
                    </Stack>
                  </Grid> */}
                </Grid>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    margin={1}
                    sx={{
                      borderRadius: '5px',
                    }}
                  >
                    <Stack p={5}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Item</TableCell>
                              {/* <TableCell>Unit Price</TableCell> */}
                              <TableCell>Quantity</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.items.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <Autocomplete
                                    fullWidth
                                    sx={{
                                      minWidth: '250px',
                                    }}
                                    id="asynchronous-demo"
                                    isOptionEqualToValue={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => `${option.name} - ${option.unitPrice}`}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    options={tools}
                                    onChange={(_, value) => {
                                      if (value) {
                                        const newItems = [...values.items];
                                        newItems[index] = {
                                          ...newItems[index],
                                          toolId: value._id,
                                          unitPrice: value.unitPrice,
                                          total: totalCalculator(item.quantity, value.unitPrice),
                                        };
                                        formik.setFieldValue('items', newItems);
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Tools"
                                        InputProps={{
                                          ...params.InputProps,
                                        }}
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <li {...props}>
                                        <Stack direction={'column'}>
                                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                                            {option.name}
                                          </Typography>
                                          <Typography variant="caption" sx={{ lineHeight: '1' }}>
                                            {option.unitPrice}
                                          </Typography>
                                        </Stack>
                                      </li>
                                    )}
                                  />
                                </TableCell>
                                <TableCell sx={{ width: '120px' }}>
                                  <TextField
                                    fullWidth
                                    type="number"
                                    value={item.quantity || ''}
                                    onChange={(e) => {
                                      const newItems = [...values.items];
                                      newItems[index] = {
                                        ...newItems[index],
                                        quantity: Number(e.target.value),
                                        total: totalCalculator(Number(e.target.value), item.unitPrice, item.tax),
                                      };
                                      formik.setFieldValue('items', newItems);
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      const newItems = [...values.items];
                                      newItems.splice(index, 1);
                                      formik.setFieldValue('items', newItems);
                                    }}
                                    variant="outlined"
                                    color="error"
                                  >
                                    <DeleteForever />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Stack sx={{ width: '100%' }} justifyContent={'flex-end'} direction={'row'} mt={1}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            const newItems = [...values.items];
                            newItems.push({
                              toolId: '',
                              unitPrice: 0,
                              quantity: 0,
                              total: 0,
                            });
                            formik.setFieldValue('items', newItems);
                          }}
                        >
                          Add Item
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              
                <Grid container justifyContent={'center'}>
                  <Grid item xs={10} mt={2}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={10}
                      credits={false}
                      // server="/api"
                      name="files"
                      labelIdle="Drag & Drop files"
                    />
                  </Grid>
                </Grid>
                <Box p={5} sx={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    Submit
                  </Button>
                  <Button type="button" variant="contained" sx={{ marginLeft: 2 }} onClick={() => resetForm()}>
                    Reset
                  </Button>
                </Box>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

export default AddToolPurchase;
