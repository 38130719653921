import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { Close } from '@mui/icons-material';
import {
  Stack,
  Button,
  Select,
  Typography,
  MenuItem,
  TextField,
  Grid,
  Autocomplete,
  Avatar,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import avatar from '../assets/avatar.png';

export default function ProjectEditForm({ project, getProject, setEditMode, editMode }) {
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const [engineers, setEngineers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [workers, setWorkers] = useState([]);
  const [siteEngineers, setSiteEngineers] = useState([]);
  const [leads, setLeads] = useState([]);

  const formik = useFormik({
    initialValues: {
      id: project?._id,
      name: project?.name,
      location: project?.location,
      managers: project?.managers?.map((m) => m._id),
      engineers: project?.engineers?.map((e) => e._id),
      workers: project?.workers?.map((w) => w._id),
      siteEngineers: project?.siteEngineers?.map((s) => s._id),
      status: project?.status,
      workOrderNumber: project?.workOrderNumber,
      lead: project?.lead?._id,
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/project`, values);
        if (!res.data.isError) {
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setEditMode(false);
        getProject();
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const getEngineers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'PROJECT_ENGINEER',
        },
      });
      console.log(res.data.data);
      setEngineers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getManagers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'PROJECT_MANAGER',
        },
      });
      console.log(res.data.data);
      setManagers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getWorkers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'WORKER',
        },
      });
      console.log(res.data.data);
      setWorkers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getLeads = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/lead/customer`, {
        params: {
          customerId: project?.customerId?._id,
        },
      });
      setLeads(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSiteEngineers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/employee/active/all`, {
        params: {
          role: 'SITE_ENGINEER',
        },
      });
      console.log(res.data.data);
      setSiteEngineers(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getManagers();
    getEngineers();
    getWorkers();
    getSiteEngineers();
    getLeads();
  }, []);

  useEffect(() => {
    if (project) {
      setFieldValue('name', project?.name);
      setFieldValue('location', project?.location);
      setFieldValue(
        'managers',
        project?.managers?.map((m) => m._id)
      );
      setFieldValue(
        'engineers',
        project?.engineers?.map((e) => e._id)
      );
      setFieldValue(
        'workers',
        project?.workers?.map((w) => w._id)
      );
      setFieldValue('status', project?.status);
    }
  }, [project]);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="end" mb={2} spacing={2}>
        <IconButton onClick={() => setEditMode(!editMode)}>
          <Close />
        </IconButton>
      </Stack>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={9}>
              <TextField
                fullWidth
                label="Project Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Select
                fullWidth
                {...getFieldProps('status')}
                error={Boolean(touched.status && errors.status)}
                helperText={touched.status && errors.status}
                disabled={!editMode}
              >
                <MenuItem value="ASSESSMENT">Assessment</MenuItem>
                <MenuItem value="APPROVED">Approved</MenuItem>
                <MenuItem value="ONGOING">Ongoing</MenuItem>
                <MenuItem value="PAUSED">Paused</MenuItem>
                <MenuItem value="CANCELLED">Cancelled</MenuItem>
                <MenuItem value="COMPLETED">Completed</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={8}>
              <TextField
                fullWidth
                label="Location"
                {...getFieldProps('location')}
                error={Boolean(touched.location && errors.location)}
                helperText={touched.location && errors.location}
                disabled={!editMode}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Work Order Number"
                {...getFieldProps('workOrderNumber')}
                error={Boolean(touched.workOrderNumber && errors.workOrderNumber)}
                helperText={touched.workOrderNumber && errors.workOrderNumber}
                disabled={!editMode}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                id="asynchronous-demo"
                onChange={(_, value) => {
                  console.log('Selected Value:', value);
                  formik.setFieldValue('lead', value ? value._id : null);
                }}
                defaultValue={project?.lead}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                getOptionSelected={(option, value) => option.fullname === value.fullname}
                options={leads}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lead"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={Boolean(touched.lead && errors.lead)}
                    helperText={touched.lead && errors.lead}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack direction={'column'}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                        {option.fullname}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                        {option.title}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                        {option.email}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                        {option.phone}
                      </Typography>
                    </Stack>
                  </li>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                fullWidth
                id="asynchronous-demo"
                value={managers.filter((e) => values.managers.includes(e._id))}
                onChange={(_, value) => {
                  formik.setFieldValue('managers', value ? value.map((v) => v._id) : []);
                }}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                getOptionSelected={(option, value) => option.fullname === value.fullname}
                options={managers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Managers"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={Boolean(touched.managers && errors.managers)}
                    helperText={touched.managers && errors.managers}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                        src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                      />
                      <Stack direction={'column'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                          {option.fullname}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                          {option.role?.name?.replaceAll('_', ' ')}
                        </Typography>
                      </Stack>
                    </Stack>
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                fullWidth
                id="asynchronous-demo"
                value={engineers.filter((e) => values.engineers.includes(e._id))}
                onChange={(_, value) => {
                  formik.setFieldValue('engineers', value ? value.map((v) => v._id) : []);
                }}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                getOptionSelected={(option, value) => option.fullname === value.fullname}
                options={engineers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Engineers"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={Boolean(touched.engineers && errors.engineers)}
                    helperText={touched.engineers && errors.engineers}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                        src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                      />
                      <Stack direction={'column'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                          {option.fullname}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                          {option.role?.name?.replaceAll('_', ' ')}
                        </Typography>
                      </Stack>
                    </Stack>
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                fullWidth
                id="asynchronous-demo"
                value={siteEngineers.filter((s) => values.siteEngineers.includes(s._id))}
                onChange={(_, value) => {
                  formik.setFieldValue('siteEngineers', value ? value.map((v) => v._id) : []);
                }}
                isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                getOptionLabel={(option) => option.fullname}
                getOptionSelected={(option, value) => option.fullname === value.fullname}
                options={siteEngineers}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Site Engineers"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={Boolean(touched.siteEngineers && errors.siteEngineers)}
                    helperText={touched.siteEngineers && errors.siteEngineers}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Avatar
                        sx={{ width: 40, height: 40, cursor: 'pointer' }}
                        src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                      />
                      <Stack direction={'column'}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                          {option.fullname}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                          {option.role?.name?.replaceAll('_', ' ')}
                        </Typography>
                      </Stack>
                    </Stack>
                  </li>
                )}
              />
            </Grid>
            {project?.status !== 'ASSESSMENT' && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  fullWidth
                  id="asynchronous-demo"
                  value={workers.filter((w) => values.workers.includes(w._id))}
                  onChange={(_, value) => {
                    formik.setFieldValue('workers', value ? value.map((v) => v._id) : []);
                  }}
                  isOptionEqualToValue={(option, value) => option.fullname === value.fullname}
                  getOptionLabel={(option) => option.fullname}
                  getOptionSelected={(option, value) => option.fullname === value.fullname}
                  options={workers}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Workers"
                      InputProps={{
                        ...params.InputProps,
                      }}
                      error={Boolean(touched.workers && errors.workers)}
                      helperText={touched.workers && errors.workers}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          sx={{ width: 40, height: 40, cursor: 'pointer' }}
                          src={(option?.profilePic && process.env.REACT_APP_API_URL + option.profilePic) || avatar}
                        />
                        <Stack direction={'column'}>
                          <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                            {option.fullname}
                          </Typography>
                          <Typography variant="caption" sx={{ lineHeight: '1.1' }}>
                            {option.role?.name?.replaceAll('_', ' ')}
                          </Typography>
                        </Stack>
                      </Stack>
                    </li>
                  )}
                />
              </Grid>
            )}
          </Grid>
          <Stack direction={'row'} justifyContent="flex-end" spacing={1} paddingTop="20px">
            <Button variant="contained" onClick={() => setEditMode(false)}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
              Update
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
