import { useEffect, useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

// components
import ProjectBillTable from './ProjectBillTable ';
import LoadingIcon from './smallComponents/LoadingIcon';

export default function ProjectBills({ quotationId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const [project, setProject] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProject = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      });
      console.log('project', res);
      setProject(res.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <LoadingIcon />
        </>
      ) : (
        <Stack my={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
              Invoices
            </Typography>
          </Stack>
          <Card>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Pending" value="1" />
                    <Tab label="Completed" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <ProjectBillTable
                    status={'pending'}
                    projectId={projectId}
                    customer={project?.customerId}
                    quotationId={quotationId}
                  />
                </TabPanel>
                <TabPanel value="2">
                  <ProjectBillTable
                    status={'completed'}
                    projectId={projectId}
                    customer={project?.customerId}
                    quotationId={quotationId}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </Stack>
      )}
    </>
  );
}
