import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  Chip,
  Avatar,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close, ContentCopy, CopyAll } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from './filters/Tool';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
import AddTool from './popups/AddTool';
import Popup from './popups/ToolStatusChange';

import PageHeader from './smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import Export from './filters/ToolEntryExport';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 14px',
}));

export default function ToolsTableInProject() {
  const [toolData, setToolData] = useState([]);
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [toolCount, setToolCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [isLoading1, setIsLoading1] = useState(false);

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTools(temp);
  };

  const getTools = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/tool/allForProject`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setToolCount(res.data.data.maxRecords);
          setToolData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getToolsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/tool/allForProject`, {
        params: {
          ...options,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setToolCount(res.data.data.maxRecords);
          setToolData(res.data.data.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getToolsWithoutLoading(temp);
  };

  useEffect(() => {
    getTools(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTools(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center', 
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} padding={2}>
              <SearchStyle
                value={name}
                onChange={onSearch}
                placeholder="Search tool name..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                //  add a clear icon when user types something
                endAdornment={
                  name.length > 0 && (
                    <Icon
                      onClick={() => {
                        setName('');
                        setOptions({ ...options, name: '' });
                        getToolsWithoutLoading({ ...options, name: '' });
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <Close />
                    </Icon>
                  )
                }
              />
            </Stack>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCellCustom>Sl No</TableCellCustom>
                    <TableCellCustom>Tool</TableCellCustom>
                    <TableCellCustom>Description</TableCellCustom>
                    <TableCellCustom>Unit Price</TableCellCustom>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {toolData?.map((item, id) => (
                    <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                      <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                      <TableCellCustom>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            src={item?.image && process.env.REACT_APP_API_URL + item.image}
                            sx={{ width: 40, height: 40 }}
                          >
                            {item?.name?.charAt(0).toUpperCase()}
                          </Avatar>
                          <Box sx={{ marginLeft: 1 }}>
                            <Typography variant="subtitle2">{item?.name}</Typography>
                          </Box>
                        </Box>
                      </TableCellCustom>

                      <TableCellCustom>
                        <Tooltip title={item.description} placement="top-start">
                          <Typography
                            variant="p"
                            sx={{
                              maxWidth: '150px',
                              overflow: 'hidden', 
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              cursor: 'pointer',
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Tooltip>
                      </TableCellCustom>
                      <TableCellCustom>{item.unitPrice}</TableCellCustom>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            onRowsPerPageChange={(e) => {
              setSize(e.target.value);
            }}
            component={'div'}
            count={toolCount}
            showFirstButton
            showLastButton
            onPageChange={handlePageChange}
            rowsPerPage={size}
            page={pages}
          />
        </>
      )}
    </>
  );
}
