import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Collapse, ListItemText, ListItemIcon, Tooltip, Typography, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
//
import Iconify from './Iconify';
import StateContext from './Context/Context';
import hasAccessOf from '../store/hasAccessOf';

// ----------------------------------------------------------------------

const BoxItemStyle = styled((props) => <Box component="div" {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 38,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.primary.contrastText,
  borderRadius: '19px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
  backgroundColor: '#43349437',
  paddingX: 2,
  cursor: 'pointer',
}));

const BoxItemStyleChildren = styled((props) => <Box component="div" {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 38,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.primary.contrastText,
  borderRadius: '19px',
  fontSize: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textDecoration: 'none',
  paddingX: 2,
  cursor: 'pointer',
}));

const BoxIconStyle = styled((props) => <Box component="div" {...props} />)({
  color: 'primary.contrastText',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50%',
  justifyContent: 'center',
  padding: '3px',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();
  const { accType, role } = useSelector((state) => state.user.userInfo);
  const { dispatch, state } = useContext(StateContext);
  const navigate = useNavigate();

  const isActiveRoot = active(item.path.split('?')[0]);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.contrastText',
    fontWeight: 'fontWeightMedium',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primary.contrastText',
    fontWeight: 'fontWeightMedium',
    // bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  if (children) {
    return (
      <>
        <Tooltip title={!state ? title : ''} placement="right" arrow>
          <BoxItemStyle
            onClick={handleOpen}
            sx={{
              ...(isActiveRoot && activeRootStyle),
              justifyContent: !state ? 'center' : 'space-between',
              paddingX: !state ? 0 : 2,
            }}
          >
            <Stack direction="row" spacing={2}>
              <BoxIconStyle
                sx={{
                  ...(isActiveRoot &&
                    !open && {
                      color: (theme) => theme.palette.primary.main,
                      bgcolor: (theme) => theme.palette.primary.contrastText,
                    }),
                  ...(!isActiveRoot && {
                    color: (theme) => theme.palette.primary.contrastText,
                    bgcolor: 'transparent',
                  }),
                }}
              >
                {icon && icon}
              </BoxIconStyle>
              {state && <Typography variant="body2">{title}</Typography>}
            </Stack>
            {info && info}
            {state && (
              <Iconify
                icon={!open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-upward-fill'}
                sx={{ width: 16, height: 16, mr: 1 }}
              />
            )}
          </BoxItemStyle>
        </Tooltip>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Stack
            spacing={1.5}
            sx={{
              pl: state ? 2 : 0,
            }}
          >
            {children
              .filter((item) => item.paths.some((path) => hasAccessOf(path)))
              .map((item) => {
                const { title, path, icon } = item;
                const isActiveSub = active(path.split('?')[0]);

                return (
                  <Tooltip key={title} title={!state ? title : ''} placement="right" arrow>
                    <BoxItemStyleChildren
                      sx={{
                        ...(isActiveSub && activeSubStyle),
                        justifyContent: !state ? 'center' : 'space-between',
                        paddingX: !state ? 0 : 2,
                      }}
                      component={RouterLink}
                      to={path}
                    >
                      <Stack direction="row" spacing={2}>
                        <BoxIconStyle
                          sx={{
                            ...(isActiveSub && {
                              color: (theme) => theme.palette.macinsIndigo.main,
                              bgcolor: (theme) => theme.palette.primary.contrastText,
                            }),
                            ...(!isActiveSub && {
                              color: (theme) => theme.palette.primary.contrastText,
                              bgcolor: 'transparent',
                            }),
                          }}
                        >
                          <Iconify
                            icon={icon}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              color: 'inherit',
                              width: 22,
                              height: 22,
                              transition: (theme) => theme.transitions.create('transform'),
                              ...(isActiveSub && {
                                color: (theme) => theme.palette.primary.main,
                              }),
                            }}
                          />
                        </BoxIconStyle>
                        {state && <Typography variant="body2">{title}</Typography>}
                      </Stack>
                    </BoxItemStyleChildren>
                  </Tooltip>
                );
              })}
          </Stack>
        </Collapse>
      </>
    );
  }

  return (
    <Tooltip title={!state ? title : ''} placement="right" arrow>
      <BoxItemStyle
        sx={{
          ...(isActiveRoot && activeRootStyle),
          justifyContent: !state ? 'center' : 'space-between',
          paddingX: !state ? 0 : 2,
        }}
        component={RouterLink}
        to={path}
      >
        <Stack direction="row" spacing={2} alignItems="center" justifyContent={state ? 'center' : 'space-between'}>
          <BoxIconStyle
            sx={{
              ...(isActiveRoot && {
                color: (theme) => theme.palette.primary.main,
                bgcolor: (theme) => theme.palette.primary.contrastText,
              }),
              ...(!isActiveRoot && {
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: 'transparent',
              }),
            }}
          >
            {icon && icon}
          </BoxIconStyle>
          {state && <Typography variant="body2">{title}</Typography>}
        </Stack>
        {info && info}
      </BoxItemStyle>
    </Tooltip>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box
      {...other}
      sx={{
        paddingBottom: '300px', /// added to fix not scrollable issue
      }}
    >
      <Stack spacing={1.5} sx={{ p: 1 }}>
        {navConfig
          .filter(
            (item) =>
              item.paths.some((path) => hasAccessOf(path)) &&
              (item.children && item.children.length
                ? item.children.some((child) => child.paths.some((path) => hasAccessOf(path)))
                : true)
          )
          .map((item) => (
            <NavItem key={item.title} item={item} active={match} />
          ))}
      </Stack>
    </Box>
  );
}
