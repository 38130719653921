import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';

// file pond import

import { FilePond, File, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { Box, Container, Divider, IconButton, Stack } from '@mui/material';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Add, Close } from '@mui/icons-material';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { onClose, selectedValue, open, getAttachments, type } = props;
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleFileUpload = async () => {
    setIsSubmitting(true);
    try {
      const data = new FormData();
      data.append('id', id);
      files.forEach((item) => {
        data.append('files', item.file);
      });
      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}/quotation/${type}/file`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          if (res.data.isError) {
            setIsSubmitting(false);
            toast.error('Something Went Wrong');
            console.log('res', res);
          } else {
            setIsSubmitting(false);
            handleClose();
            getAttachments();
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          toast.error(err.response.data.message);
          console.log(err);
        });
    } catch (error) {
      setIsSubmitting(false);
      toast.error(error.response.data.message);
      console.log(error);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth>
      <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: '8px', top: '8px' }}>
        <Close />
      </IconButton>
      <DialogTitle>File Upload</DialogTitle>
      <Divider />
      <Container style={{ padding: '20px' }}>
        <Stack padding={2} marginTop={1} spacing={2} sx={{ borderRadius: '5px' }}>
          <Box>
            {/* <Typography variant="h6" paddingBottom={1}>
              Attach Files
            </Typography> */}
            <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple
              maxFiles={50}
              credits={false}
              // server="/api"
              name="files"
              labelIdle="Drag & Drop your files"
            />
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LoadingButton
                disabled={!files.length}
                loading={isSubmitting}
                variant="contained"
                onClick={handleFileUpload}
              >
                Upload Files
              </LoadingButton>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  getAttachments: PropTypes.func.isRequired,
};

FileUploadModal.propTypes = {
  getAttachments: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default function FileUploadModal({ getAttachments, type }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      <Button variant="contained" size="small" startIcon={<Add />} onClick={handleClickOpen}>
        Add
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        getAttachments={getAttachments}
        type={type}
      />
    </div>
  );
}
