import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Collapse,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Iconify from './Iconify';

const headStyle = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '-0.08px',
};

const contentStyle = {
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};

const CommonListItem = ({ data, id }) => (
  <ListItem
    key={id}
    fullWidth
    sx={{
      borderRadius: '14.5px',
    }}
  >
    <ListItemAvatar>
      <Avatar
        alt={data.fullname.charAt(0) + data.fullname.slice(1)}
        src={data.profilePic && process.env.REACT_APP_API_URL + data.profilePic}
      />
    </ListItemAvatar>
    <ListItemText
      sx={{
        '& .MuiListItemText-primary': {
          ...contentStyle,
        },
      }}
      primary={data.fullname.charAt(0) + data.fullname.slice(1)}
      secondary={data.email}
    />
  </ListItem>
);

const CommonStack = ({ type, handleClick, open }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ backgroundColor: '#fff', height: '60px', cursor: 'pointer', borderRadius: '14.5px' }}
    padding={2}
    onClick={handleClick}
  >
    <Typography sx={headStyle}>{type}</Typography>
    <Iconify
      icon="uiw:down-circle-o"
      width="24px"
      height="24px"
      sx={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease-in-out' }}
    />
  </Stack>
);

function MemberList({ engineers, managers, workers, siteEngineers, status }) {
  // const [open1, setOpen1] = useState(true);
  // const [open2, setOpen2] = useState(false);
  // const [open5, setOpen5] = useState(false);
  const [openManagers, setOpenManagers] = useState(true);
  const [openEngineers, setOpenEngineers] = useState(false);
  const [openWorkers, setOpenWorkers] = useState(false);
  const [openSiteEngineers, setOpenSiteEngineers] = useState(false);

  const handleClickManagers = () => {
    setOpenManagers(!openManagers);
    if (!openManagers) {
      setOpenEngineers(false);
      setOpenWorkers(false);
      setOpenSiteEngineers(false);
    }
  };
  const handleClickEngineers = () => {
    setOpenEngineers(!openEngineers);
    if (!openEngineers) {
      setOpenManagers(false);
      setOpenWorkers(false);
      setOpenSiteEngineers(false);
    }
  };

  const handleClickWorkers = () => {
    setOpenWorkers(!openWorkers);
    if (!openWorkers) {
      setOpenManagers(false);
      setOpenEngineers(false);
      setOpenSiteEngineers(false);
    }
  };

  const handleClickSiteEngineers = () => {
    setOpenSiteEngineers(!openSiteEngineers);
    if (!openSiteEngineers) {
      setOpenManagers(false);
      setOpenEngineers(false);
      setOpenWorkers(false);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Stack spacing={0.5}>
            <CommonStack type="Managers" handleClick={handleClickManagers} open={openManagers} />
            <Collapse in={openManagers}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {managers?.map((mdata, id) => (
                  <CommonListItem key={id} data={mdata} id={id} />
                ))}
              </Stack>
            </Collapse>
          </Stack>

          <Stack spacing={0.5}>
            <CommonStack type="Engineers" handleClick={handleClickEngineers} open={openEngineers} />
            <Collapse in={openEngineers}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {engineers?.map((mdata, id) => (
                  <CommonListItem key={id} data={mdata} id={id} />
                ))}
              </Stack>
            </Collapse>
          </Stack>

          <Stack spacing={0.5}>
            <CommonStack type="Site Engineers" handleClick={handleClickSiteEngineers} open={openSiteEngineers} />
            <Collapse in={openSiteEngineers}>
              <Stack
                spacing={1}
                component="div"
                disablePadding
                sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                mb={1}
              >
                {siteEngineers?.map((mdata, id) => (
                  <CommonListItem key={id} data={mdata} id={id} />
                ))}
              </Stack>
            </Collapse>
          </Stack>

          {status !== 'ASSESSMENT' && (
            <Stack spacing={0.5}>
              <CommonStack type="Workers" handleClick={handleClickWorkers} open={openWorkers} />
              <Collapse in={openWorkers}>
                <Stack
                  spacing={1}
                  component="div"
                  disablePadding
                  sx={{ backgroundColor: '#fff', borderRadius: '14.5px' }}
                  mb={1}
                >
                  {workers?.map((mdata, id) => (
                    <CommonListItem key={id} data={mdata} id={id} />
                  ))}
                </Stack>
              </Collapse>
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default MemberList;
