import { useEffect, useState } from 'react';
import axios from 'axios';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { AppCurrentVisits, BarGraph } from '../sections/@dashboard/app';
import ProjectToolUsage from '../components/ProjectToolUsage';

// ----------------------------------------------------------------------

export default function WarehouseAnalyticsComponent() {
  const theme = useTheme();

  const [toolTransferBarGraphDataIn, setToolTransferBarGraphDataIn] = useState({ chartLabels: [], chartData: [] });
  const [toolTransferBarGraphDataOut, setToolTransferBarGraphDataOut] = useState({ chartLabels: [], chartData: [] });
  const [materialTransferBarGraphDataIn, setMaterialTransferBarGraphDataIn] = useState({
    chartLabels: [],
    chartData: [],
  });
  const [materialTransferBarGraphDataOut, setMaterialTransferBarGraphDataOut] = useState({
    chartLabels: [],
    chartData: [],
  });

  const [isLoadingActiveBlockedToolsData, setIsLoadingActiveBlockedToolsData] = useState(true);
  const [activeBlockedToolsData, setActiveBlockedToolsData] = useState([]);

  const [isLoadingActiveBlockedMaterialsData, setIsLoadingActiveBlockedMaterialsData] = useState(true);
  const [activeBlockedMaterialsData, setActiveBlockedMaterialsData] = useState([]);

  const [isLoadingToolsInUseAvailableDefunctData, setIsLoadingToolsInUseAvailableDefunctData] = useState(true);
  const [toolsInUseAvailableDefunctData, setToolsInUseAvailableDefunctData] = useState([]);

  const [isLoadingMaterialStockDefunctData, setIsLoadingMaterialStockDefunctData] = useState(true);
  const [materialStockDefunctData, setMaterialStockDefunctData] = useState([]);

  const getToolTransferBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/tool/count/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setToolTransferBarGraphDataIn(res?.data?.data?.graphDataWareHouseIn);
      setToolTransferBarGraphDataOut(res?.data?.data?.graphDataWareHouseOut);
    } catch (error) {
      console.log(error);
    }
  };

  const getMaterialTransferBarGraphData = async (startDate, endDate, graphType) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/material/count/bar-graph`, {
        params: {
          graphType,
          startDate,
          endDate,
        },
      });
      console.log(res.data.data);
      setMaterialTransferBarGraphDataIn(res?.data?.data?.graphDataWareHouseIn);
      setMaterialTransferBarGraphDataOut(res?.data?.data?.graphDataWareHouseOut);
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveBlockedToolsData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/tool/count/active-blocked`);
      console.log(res.data.data);
      setActiveBlockedToolsData(res.data.data);
      setIsLoadingActiveBlockedToolsData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveBlockedMaterialsData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/material/count/active-blocked`);
      console.log(res.data.data);
      setActiveBlockedMaterialsData(res.data.data);
      setIsLoadingActiveBlockedMaterialsData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getToolsInUseAvailableDefunctData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/tool/count/inUse-available-defunct`);
      console.log(res.data.data);
      setToolsInUseAvailableDefunctData(res.data.data);
      setIsLoadingToolsInUseAvailableDefunctData(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getMaterialStockDefunctData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/dashboard/material/count/stock-defunct`);
      console.log(res.data.data);
      setMaterialStockDefunctData(res.data.data);
      setIsLoadingMaterialStockDefunctData(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActiveBlockedToolsData();
    getActiveBlockedMaterialsData();
    getToolsInUseAvailableDefunctData();
    getMaterialStockDefunctData();
  }, []);

  return (
    <Grid container spacing={3} rowGap={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Warehouse Analytics</Typography>
      </Grid>

      {/* materials pie active blocked */}
      <Grid item xs={12} md={6} lg={6}>
        <AppCurrentVisits
          title="Material Status"
          hideStartDate={'hide'}
          chartData={activeBlockedMaterialsData}
          graphloader={isLoadingActiveBlockedMaterialsData}
          getNewData={getActiveBlockedMaterialsData}
          chartColors={['#8A9A5B', '#CC5500']}
        />
      </Grid>

      {/* materials pie stock defunct */}
      <Grid item xs={12} md={6} lg={6}>
        <AppCurrentVisits
          title="Materials Stock & Defunct"
          hideStartDate={'hide'}
          chartData={materialStockDefunctData}
          graphloader={isLoadingMaterialStockDefunctData}
          getNewData={getMaterialStockDefunctData}
          chartColors={['#323a64', '#875c00']}
        />
      </Grid>

      {/* Material flow Bar graph */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Material Transfer</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <BarGraph
              xaxisPlot={'category'}
              callAPI={getMaterialTransferBarGraphData}
              unit="Units"
              chartLabels={[
                ...materialTransferBarGraphDataIn.chartLabels,
                ...materialTransferBarGraphDataOut.chartLabels,
              ]}
              chartData={[
                {
                  name: 'Warehouse In',
                  type: 'area',
                  fill: 'gradient',
                  data: [...materialTransferBarGraphDataIn.chartData],
                  color: theme.palette.navy.main,
                },
                {
                  name: 'Warehouse Out',
                  type: 'area',
                  fill: 'gradient',
                  data: [...materialTransferBarGraphDataOut.chartData],
                  color: theme.palette.coral.main,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* tools pie active blocked */}
      <Grid item xs={12} md={6} lg={6}>
        <AppCurrentVisits
          title="Tools Status"
          hideStartDate={'hide'}
          chartData={activeBlockedToolsData}
          graphloader={isLoadingActiveBlockedToolsData}
          getNewData={getActiveBlockedToolsData}
          chartColors={['#8A9A5B', '#CC5500']}
        />
      </Grid>

      {/* tools pie in use available defunct */}
      <Grid item xs={12} md={6} lg={6}>
        <AppCurrentVisits
          title="Tools In Use, Available & Defunct"
          hideStartDate={'hide'}
          chartData={toolsInUseAvailableDefunctData}
          graphloader={isLoadingToolsInUseAvailableDefunctData}
          getNewData={getToolsInUseAvailableDefunctData}
          chartColors={['#8A9A5B', '#3127eccc', '#FF0000']}
        />
      </Grid>

      {/* Tool flow Bar graph */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Tool Transfer</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <BarGraph
              xaxisPlot={'category'}
              callAPI={getToolTransferBarGraphData}
              unit="Units"
              chartLabels={[...toolTransferBarGraphDataIn.chartLabels, ...toolTransferBarGraphDataOut.chartLabels]}
              chartData={[
                {
                  name: 'Warehouse In',
                  type: 'area',
                  fill: 'gradient',
                  data: [...toolTransferBarGraphDataIn.chartData],
                  color: theme.palette.navy.main,
                },
                {
                  name: 'Warehouse Out',
                  type: 'area',
                  fill: 'gradient',
                  data: [...toolTransferBarGraphDataOut.chartData],
                  color: theme.palette.coral.main,
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Project tool usage */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5">Tools In Use</Typography>
          </Grid>
          <Grid item xs={12}>
            <ProjectToolUsage />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
