import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#fff0f5',
  light: '#ec467d',
  main: '#740027',
  dark: '#490018',
  darker: '#2e000f',
  contrastText: '#fff',
};

const VIEW_BUTTON = {
  lighter: '#fff0f564',
  light: '#ec467d41',
  main: '#74002753',
  dark: '#49001857',
  darker: '#2e000f2d',
  contrastText: '#2e000f',
};

const MACINS_INDIGO = {
  lighter: '#dedaf6',
  light: '#b9b1e7',
  main: '#2b1f6e',
  dark: '#1f1651',
  darker: '#160f39',
  contrastText: '#fff',
};

const PRIMARY_DARK = {
  lighter: '#f8e6b4',
  light: '#0096dc',
  main: '#0072a7',
  dark: '#6b5001',
  darker: '#342700',
  contrastText: '#000',
};

const GREENISH = {
  lighter: '#f1ead3',
  light: '#A4BE7B',
  main: '#5F8D4E',
  dark: '#285430',
  darker: '#1b3921',
  contrastText: '#fff',
};

const PURPLE = {
  lighter: '#e1d9cf',
  light: '#cac4be',
  main: '#886f97',
  dark: '#4a3e2f',
  darker: '#2c183d',
  contrastText: '#fff',
};
const ORANGE = {
  lighter: '#eee6d7',
  light: '#E6D5B8',
  main: '#E45826',
  dark: '#875c00',
  darker: '#1B1A17',
  contrastText: '#fff',
};

const ORANGE1 = {
  lighter: '#eee6d7',
  light: '#E6D5B8',
  main: '#ab5b3f',
  dark: '#6b562a',
  darker: '#1B1A17',
  contrastText: '#fff',
};

const NAVY = {
  lighter: '#bcc7ff',
  light: '#8191e7',
  main: '#323a64',
  dark: '#2b3258',
  darker: '#1f2441',
  contrastText: '#fff',
};

const BALANCED = {
  lighter: '#EDE4E0',
  light: '#C8DBBE',
  main: '#726152',
  dark: '#383228',
  darker: '#221d16',
  contrastText: '#fff',
};

const LIGHT = {
  lighter: '#ffffff',
  light: '#f8fff4',
  main: '#f4eaea',
  dark: '#343434',
  darker: '#000000',
  contrastText: '#000',
};

const BROWN = {
  lighter: '#be9b9b',
  light: '#a48282',
  main: '#553939',
  dark: '#765353',
  darker: '#3a2424',
  contrastText: '#fff',
};

const RED = {
  lighter: '#FF5733',
  light: '#C70039',
  main: '#900C3F',
  dark: '#511845',
  darker: '#300d29',
  contrastText: '#fff',
};
const BLUE = {
  lighter: '#d3e3f6',
  light: '#7fabe8',
  main: '#004fbd',
  dark: '#002252',
  darker: '#000e22',
  contrastText: '#fff',
};
const YELLOW = {
  lighter: '#f9eccf',
  light: '#f7d384',
  main: '#F4BB44',
  dark: '#d69d22',
  darker: '#8a6616',
  contrastText: '#000',
};
const CORAL = {
  lighter: '#ffe3d9',
  light: '#f89e7d',
  main: '#ee7143',
  dark: '#c1532b',
  darker: '#622711',
  contrastText: '#000',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const SUCCESS_DARKER = {
  lighter: '#bfd1ab',
  light: '#7fb75f',
  main: '#32811a',
  dark: '#176810',
  darker: '#053c08',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const ERROR_DARKER = {
  lighter: '#dac0b1',
  light: '#c97a67',
  main: '#ad2f2b',
  dark: '#841624',
  darker: '#50061d',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const palette = {
  common: { black: '#000', white: '#fff' },
  greenish: { ...GREENISH },
  purplish: { ...PURPLE },
  orangee: { ...ORANGE },
  orangee1: { ...ORANGE1 },
  balanced: { ...BALANCED },
  browne: { ...BROWN },
  redish: { ...RED },
  yellowish: { ...YELLOW },
  coral: { ...CORAL },
  primaryDark: { ...PRIMARY_DARK },
  viewButton: { ...VIEW_BUTTON },
  bluee: { ...BLUE },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  light: { ...LIGHT },
  navy: { ...NAVY },
  success: { ...SUCCESS },
  successDarker: { ...SUCCESS_DARKER },
  warning: { ...WARNING },
  error: { ...ERROR },
  errorDarker: { ...ERROR_DARKER },
  macinsIndigo: { ...MACINS_INDIGO },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500], light: '#fff0f5' },
  background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
