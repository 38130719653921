import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const UpdateMainItemDescription = ({ item, getQto, setSelectedIndex }) => {
  const [open, setOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [newDescription, setNewDescription] = useState(item.description);

  const handleClose = () => {
    setOpen(false);
    setSelectedIndex(null);
  };

  const updateDescription = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/quotation/qto/item`, {
        itemId: item._id,
        id,
        description: newDescription,
      })
      .then((res) => {
        handleClose();
        toast.success(res.data.message);
        getQto(); 
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Update Description</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack
              spacing={2}
              my={2}
              sx={{
                width: {
                  xs: '100%',
                  sm: '350px',
                },
              }}
            >
              <TextField
                type="text"
                multiline
                value={newDescription}
                onChange={(e) => setNewDescription(e.target.value)}
                label="Description"
                fullWidth
                sx={{ width: '100%' }}
              />
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateDescription}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateMainItemDescription;
