import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Leads from './pages/Leads';
import Telecallers from './pages/TeleCallers';
import Admins from './pages/Admins';
import LeadManagers from './pages/LeadManagers';
import Settings from './pages/Settings';
import Employees from './pages/Employees';
import ViewEmployee from './pages/ViewEmployee';
import ViewAdmin from './pages/ViewAdmin';
import ViewLead from './pages/ViewLead';
import ViewProfile from './pages/ViewProfile';
import Customers from './pages/Customers';
import ViewCustomer from './pages/ViewCustomer';
import AccountsSettings from './pages/AccountsSettings';
import GeneralsSettings from './pages/GeneralSettings';
import Vendors from './pages/Vendors';
import Bills from './pages/Bills';
import Purchases from './pages/Purchases';
import Salary from './pages/Salary';
import PendingPayments from './pages/PendingPayments';
import Payments from './pages/Payments';
import AddBill from './pages/AddBill';
import AddPurchase from './pages/AddPurchase';
import ViewBill from './pages/ViewBill';
import ViewPurchase from './pages/ViewPurchase';
import ViewSalary from './pages/ViewSalary';
import ViewPendingBillPayment from './pages/ViewPendingBillPayment';
import ViewPendingPurchasePayment from './pages/ViewPendingPurchasePayment';
import ViewPendingSalaryPayment from './pages/ViewPendingSalaryPayment';
import ViewTransaction from './pages/ViewTransaction';
import Ledgers from './pages/Ledgers';
import ViewVendor from './pages/ViewVendor';
import Transactions from './pages/Transactions';
import AddTransaction from './pages/AddTransaction';
import Reports from './pages/Reports';
import Qto from './pages/Qto';
import Boq from './pages/Boq';
import ViewBoq from './pages/ViewBoq';
import ViewQuote from './pages/ViewQuote';
import Quote from './pages/Quote';
import ViewQto from './pages/ViewQto';
import ViewProject from './pages/ViewProject';
import ProjectPurchaseUpload from './pages/ProjectPurchaseUpload';
import ViewPurchaseUpload from './pages/ViewPurchaseUpload';
import Tools from './pages/Tools';
import AddMemberRole from './pages/AddMemberRole';
import ViewMemberRole from './pages/ViewMemberRole';
import QuotationTable from './pages/QuotationTable';
import ProjectWorkers from './pages/ProjectWorkers';
import AddEmployee from './pages/AddEmployee';
import ViewTool from './pages/ViewTool';
import Materials from './pages/Materials';
import ViewMaterial from './pages/ViewMaterial';
import WarehousePurchase from './pages/WarehousePurchase';
import AddToolPurchase from './pages/AddToolPurchase';
import ReduceToolStock from './pages/ReduceToolStock';
import AddMaterialPurchase from './pages/AddMaterialPurchase';
import ProjectMaterials from './pages/ProjectMaterials';
import ProjectMaterialView from './pages/ProjectMaterialView';
import ProjectTools from './pages/ProjectTools';
import ProjectToolView from './pages/ProjectToolView';
import MaterialProjectLogs from './pages/MaterialProjectLogs';
import ProjectsWithAssessmentStatus from './pages/ProjectsWithAssessmentStatus';
import ProjectsWithApprovedStatus from './pages/ProjectsWithApprovedStatus';
import CrmAnalytics from './pages/CrmAnalytics';
import ProjectAnalytics from './pages/ProjectAnalytics';
import ViewTelecaller from './pages/ViewTelecaller';
import ViewLeadManager from './pages/ViewLeadManager';
import ViewProjectManager from './pages/ViewProjectManager';
import ViewProjectEngineer from './pages/ViewProjectEngineer';
import ViewWorker from './pages/ViewWorker';
import ViewAccountant from './pages/ViewAccountant';
import CustomerQuotationTable from './pages/CustomerQuotationTable';
import CustomerAccounts from './pages/CustomerAccounts';
import ProjectsOfCustomer from './pages/ProjectsOfCustomer';
import VendorAccounts from './pages/VendorAccounts';
import ViewToolPurchase from './pages/ViewToolPurchase';
import ViewMaterialPurchase from './pages/ViewMaterialPurchase';
import ProjectsOfWorker from './pages/ProjectsOfWorker';
import WorkerWorkEntries from './pages/WorkerWorkEntries';
import ProjectsOfProjectManager from './pages/ProjectsOfProjectManager';
import ProjectsOfProjectEngineer from './pages/ProjectsOfProjectEngineer';
import CustomerLeads from './pages/CustomerLeads';
import TeleCallerLeads from './pages/TeleCallerLeads';
import LeadManagerLeads from './pages/LeadManagerLeads';
import ToolProjectLog from './pages/ToolProjectLog';
import ProjectSettings from './pages/ProjectSettings';
import ViewWareHouseManager from './pages/ViewWareHouseManager';
import AddLead from './pages/AddLead';
import Tasks from './pages/Tasks';
import ReduceMaterialStock from './pages/ReduceMaterialStock';
import QuoteNotifications from './pages/QuoteNotifications';
import ProjectAccouts from './pages/ProjectAccouts';
import CrmReports from './pages/CrmReports';
import WarehouseAnalytics from './pages/WarehouseAnalytics';
import QuotationTabs from './pages/QuotationTabs';
import AccountAnalytics from './pages/AccountAnalytics';
import LeadManagersTeleCallers from './pages/LeadManagersTeleCallers';
import GeneralAnalytics from './pages/GeneralAnalytics';
import RfqNotes from './pages/RfqNotes';
import AllTasks from './pages/AllTasks';
import ProjectMaterialsTabs from './pages/ProjectMaterialsTabs';
import ProjectToolsTabs from './pages/ProjectToolsTabs';
import ProjectAttachments from './pages/ProjectAttachments';
import PurchaseOrder from './pages/PurchaseOrders';
import AddPurchaseOrder from './pages/AddPurchaseOrder';
import ViewPurchaseOrder from './pages/ViewPurchaseOrder';
import AddToolsToProject from './pages/AddToolsToProject';
import AddMaterialsToProject from './pages/AddMaterialsToProject';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'profile', element: <ViewProfile /> },
        { path: 'tasks', element: <AllTasks /> },
        { path: 'general/settings', element: <GeneralsSettings /> },
        { path: 'general/analytics', element: <GeneralAnalytics /> },
        // { path: 'general/settings/add-memberrole', element: <AddMemberRole /> },
        { path: 'general/settings/edit-memberrole/:id', element: <ViewMemberRole /> },
        { path: 'general/employees', element: <Employees /> },
        { path: 'general/employees/view/:id', element: <ViewEmployee /> },
        { path: 'general/employees/lead-manager/:id', element: <ViewLeadManager /> },
        { path: 'general/employees/lead-manager/leads', element: <LeadManagerLeads /> },
        { path: 'general/employees/lead-manager/sales-staff', element: <LeadManagersTeleCallers /> },
        { path: 'general/employees/sales-staff/:id', element: <ViewTelecaller /> },
        { path: 'general/employees/sales-staff/leads', element: <TeleCallerLeads /> },
        { path: 'general/employees/project-manager/projects', element: <ProjectsOfProjectManager /> },
        { path: 'general/employees/project-manager/:id', element: <ViewProjectManager /> },
        { path: 'general/employees/project-engineer/:id', element: <ViewProjectEngineer /> },
        { path: 'general/employees/project-engineer/projects', element: <ProjectsOfProjectEngineer /> },
        { path: 'general/employees/accountant/:id', element: <ViewAccountant /> },
        { path: 'general/employees/worker/:id', element: <ViewWorker /> },
        { path: 'general/employees/worker/projects', element: <ProjectsOfWorker /> },
        { path: 'general/employees/worker/workEntry', element: <WorkerWorkEntries /> },
        { path: 'general/employees/warehouse-manager/:id', element: <ViewWareHouseManager /> },
        { path: 'general/employees/add', element: <AddEmployee /> },
        { path: 'general/customers', element: <Customers /> },
        { path: 'general/customers/view/:id', element: <ViewCustomer /> },
        { path: 'general/customers/accounts', element: <CustomerAccounts /> },
        { path: 'general/customers/projects', element: <ProjectsOfCustomer /> },
        { path: 'general/customers/quotations', element: <CustomerQuotationTable /> },
        { path: 'general/customers/leads', element: <CustomerLeads /> },
        { path: 'general/vendors', element: <Vendors /> },
        { path: 'general/vendors/view-vendor/:id', element: <ViewVendor /> },
        { path: 'general/vendors/accounts', element: <VendorAccounts /> },
        { path: 'general/admin', element: <Admins /> },
        { path: 'general/admin/view/:id', element: <ViewAdmin /> },
        { path: 'crm/analytics', element: <CrmAnalytics /> },
        { path: 'crm/lead', element: <Leads /> },
        { path: 'crm/lead/add', element: <AddLead /> },
        { path: 'crm/lead/view/:id', element: <ViewLead /> },
        { path: 'crm/leadmanager', element: <LeadManagers /> },
        { path: 'crm/telecaller', element: <Telecallers /> },
        { path: 'crm/reports', element: <CrmReports /> },
        { path: 'crm/settings', element: <Settings /> },
        { path: 'accounts/analytics', element: <AccountAnalytics /> },
        { path: 'accounts/settings', element: <AccountsSettings /> },
        { path: 'accounts/ledger', element: <Ledgers /> },
        { path: 'accounts/bill', element: <Bills /> },
        { path: 'accounts/bill/add-bill', element: <AddBill /> },
        { path: 'accounts/bill/view-bill', element: <ViewBill /> },
        { path: 'accounts/purchase', element: <Purchases /> },
        { path: 'accounts/purchase/add-purchase', element: <AddPurchase /> },
        { path: 'accounts/purchase/view-purchase/:id', element: <ViewPurchase /> },
        { path: 'accounts/salary', element: <Salary /> },
        { path: 'accounts/salary/view-salary/:id', element: <ViewSalary /> },
        { path: 'accounts/transaction', element: <Transactions /> },
        { path: 'accounts/transaction/add', element: <AddTransaction /> },
        { path: 'accounts/pending-payments', element: <PendingPayments /> },
        { path: 'accounts/pending-payments/bill/view-payment/:customerId', element: <ViewPendingBillPayment /> },
        { path: 'accounts/pending-payments/purchase/view-payment/:vendorId', element: <ViewPendingPurchasePayment /> },
        { path: 'accounts/pending-payments/salary/view-payment/:employeeId', element: <ViewPendingSalaryPayment /> },
        { path: 'accounts/payments', element: <Payments /> },
        { path: 'accounts/payments/view-transaction/:id', element: <ViewTransaction /> },
        { path: 'accounts/reports', element: <Reports /> },
        { path: 'quotation/qto', element: <Qto /> },
        { path: 'quotation/boq', element: <Boq /> },
        { path: 'quotation/quote', element: <Quote /> },
        { path: 'quotation/tabs', element: <QuotationTabs /> },
        { path: 'project/analytics', element: <ProjectAnalytics /> },
        { path: 'project/assessment', element: <ProjectsWithAssessmentStatus /> },
        { path: 'project/assessment/view/:id', element: <ViewProject /> },
        { path: 'project/approved', element: <ProjectsWithApprovedStatus /> },
        { path: 'project/approved/view/:id', element: <ViewProject /> },
        { path: 'project/purchase/upload', element: <ProjectPurchaseUpload /> },
        { path: 'project/purchase/view/:id', element: <ViewPurchaseUpload /> },
        { path: 'project/quotation', element: <QuotationTable /> },
        { path: 'project/attachment', element: <ProjectAttachments /> },
        { path: 'project/worker', element: <ProjectWorkers /> },
        { path: 'project/material', element: <ProjectMaterialsTabs /> },
        { path: 'project/material/view', element: <ProjectMaterialView /> },
        { path: 'project/tool', element: <ProjectToolsTabs /> },
        { path: 'project/tool/view', element: <ProjectToolView /> },
        { path: 'project/settings', element: <ProjectSettings /> },
        { path: 'project/task', element: <Tasks /> },
        { path: 'project/notifications', element: <QuoteNotifications /> },
        { path: 'project/account', element: <ProjectAccouts /> },
        { path: 'project/rfq/:id', element: <RfqNotes /> },
        { path: 'purchaseOrder', element: <PurchaseOrder /> },
        { path: 'purchaseOrder/add', element: <AddPurchaseOrder /> },
        { path: 'purchaseOrder/view/:id', element: <ViewPurchaseOrder /> },
        { path: 'warehouse/tool', element: <Tools /> },
        { path: 'warehouse/analytics', element: <WarehouseAnalytics /> },
        { path: 'warehouse/tool/view/:id', element: <ViewTool /> },
        { path: 'warehouse/tool/addToProject', element: <AddToolsToProject /> },
        { path: 'warehouse/tool/history', element: <ToolProjectLog /> },
        { path: 'warehouse/material', element: <Materials /> },
        { path: 'warehouse/material/view/:id', element: <ViewMaterial /> },
        { path: 'warehouse/material/addToProject', element: <AddMaterialsToProject /> },
        { path: 'warehouse/material/history', element: <MaterialProjectLogs /> },
        { path: 'warehouse/stock', element: <WarehousePurchase /> },
        { path: 'warehouse/stock/tool/add', element: <AddToolPurchase /> },
        { path: 'warehouse/stock/tool/:id', element: <ViewToolPurchase /> },
        { path: 'warehouse/stock/tool/remove', element: <ReduceToolStock /> },
        { path: 'warehouse/stock/material/add', element: <AddMaterialPurchase /> },
        { path: 'warehouse/stock/material/:id', element: <ViewMaterialPurchase /> },
        { path: 'warehouse/stock/material/remove', element: <ReduceMaterialStock /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Login />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
