import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Chip,
  Avatar,
} from '@mui/material';
import { Box } from '@mui/system';
import { Add, ContentCopy, CopyAll, Done } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Task';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/TaskStatusChange';
import Popup1 from '../components/popups/TaskResponseChange';
import Popup2 from '../components/popups/TaskManagerCommentChange';

import PageHeader from '../components/smallComponents/PageHeader';
import hasAccessOf from '../store/hasAccessOf';
import Export from '../components/filters/AllTaskExport';
import { StyledTableRow } from '../theme/styledComponents';
import AddTask from '../components/popups/AddTask';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

/// custom tablecell with custom padding
const TableCellCustom = styled(TableCell)(({ theme }) => ({
  padding: '14px 8px',
}));

export default function AllTasks() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const [taskData, setTaskData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [selectedIndex2, setSelectedIndex2] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [newStatus, setNewStatus] = useState('');
  const [isLoading1, setIsLoading1] = useState(false);

  const createTasksAccess = hasAccessOf('general.task.c');

  const handleResponseChange = (event, item, index) => {
    setSelectedIndex1(index);
  };

  const handleManagerCommentChange = (event, item, index) => {
    setSelectedIndex2(index);
  };

  const handleStatusChange = (event, item, index) => {
    setNewStatus(event.target.value);
    if (event.target.value === item.status) {
      return;
    }
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    setSelectedIndex2();
    console.log('refresh', refresh);
    if (refresh === true) {
      getTasks(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getTasks(temp);
  };

  const getTasks = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/all`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setTaskCount(res.data.data.maxRecords);
          setTaskData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getTasksWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/all`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setTaskCount(res.data.data.maxRecords);
          setTaskData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const exportTask = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/task/export-all`, { ...options, projectId });
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, fullname: e.target.value };
    setOptions(temp);
    setPages(0);
    getTasksWithoutLoading(temp);
  };

  const getStatusColor1 = (statusName) => {
    const statusMapping = {
      NOT_STARTED: '#ff0000',
      IN_PROGRESS: '#ffff00',
      FINAL_STAGE: '#00b0f0',
      COMPLETED: '#00b050',
    };
    return statusMapping[statusName] || '';
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    // if (filter.fullname.length) {
    //   temp.fullname = filter.fullname;
    // }
    // if (filter.isBlocked.length) {
    //   temp.isBlocked = filter.isBlocked.trim();
    // }
    // if (filter.id.length) {
    //   temp.id = filter.id;
    // }
    // if (filter.email.length) {
    //   temp.email = filter.email;
    // }
    // if (filter.phone.length) {
    //   temp.phone = filter.phone;
    // }
    // if (filter.startDate.length) {
    //   temp.startDate = filter.startDate;
    // }
    // if (filter.endDate.length) {
    //   temp.endDate = filter.endDate;
    // }

    if (filter.description) {
      temp.description = filter.description;
    }
    if (filter.status) {
      temp.status = filter.status;
    }
    if (filter.assignedBy) {
      temp.assignedBy = filter.assignedBy;
    }
    if (filter.assignedTo) {
      temp.assignedTo = filter.assignedTo;
    }
    if (filter.assignedDate) {
      temp.assignedDate = filter.assignedDate;
    }
    if (filter.dueDate) {
      temp.dueDate = filter.dueDate;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }

    setOptions(temp);
    setPages(0);
    getTasks(temp);
  };
  useEffect(() => {
    getTasks(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getTasks(temp);
  }, [size]);

  return (
    <>
      <Page title="Tasks">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
                <PageHeader title="Tasks" />

                <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" alignItems={'center'} spacing={5}>
                  <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" alignItems={'center'} spacing={1}>
                    <Export />
                    <LoadingButton
                      endIcon={<Iconify icon="ph:export-bold" />}
                      variant="outlined"
                      color="primary"
                      onClick={() => exportTask(options)}
                      loading={isLoading1}
                      size="small"
                    >
                      Export
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search task..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                    <AddTask callApi={getTasks} />
                    <Filter applyFilters={applyFilters} />
                  </Stack>
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCellCustom colSpan={2}>Sl No</TableCellCustom>
                          <TableCell>Task Description</TableCell>
                          <TableCell>Assigned By</TableCell>
                          <TableCell>Assigned To</TableCell>
                          <TableCell>Project </TableCell>
                          <TableCell>Work Order Number</TableCell>
                          <TableCell>Assigned Date</TableCell>
                          <TableCell>Due Date</TableCell>
                          <TableCell>Completed Date</TableCell>
                          <TableCell>Over due days</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Response</TableCell>
                          <TableCell>Manager Comment</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {taskData?.map((item, id) => (
                          <StyledTableRow key={id}>
                            <TableCellCustom>
                              <Box
                                sx={{
                                  width: 14,
                                  height: 34,
                                  backgroundColor: getStatusColor1(item?.status),
                                  padding: 0,
                                  margin: 0,
                                }}
                              />
                            </TableCellCustom>
                            <TableCellCustom>{pages * size + (id + 1)}</TableCellCustom>
                            <TableCell sx={{ minWidth: 200 }}>{item.description}</TableCell>
                            <TableCell sx={{ minWidth: 120 }}>{item?.assignedBy}</TableCell>
                            <TableCell sx={{ minWidth: 120 }}>{item.assignedTo}</TableCell>
                            <TableCell sx={{ minWidth: 180 }}>{item.project}</TableCell>
                            <TableCell>{item?.workOrderNumber}</TableCell>
                            <TableCell>{moment(item.assignedDate).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{moment(item.dueDate).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{item?.completedDate ? moment(item.completedDate).format('DD/MM/YYYY') : ''}</TableCell>
                            <TableCell sx={{ minWidth: 120 }}>
                              {item?.status === 'COMPLETED' ? (
                                <Chip
                                  size="small"
                                  color="successDarker"
                                  label="Done"
                                  variant="contained"
                                  icon={<Done />}
                                />
                              ) : (
                                <>{item.overDueDays > 0 ? item?.overDueDays : ''}</>
                              )}
                            </TableCell>
                            <TableCell sx={{ minWidth: 100 }}>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  sx={{
                                    '& .MuiSelect-select': {
                                      py: 0.5,
                                      px: 1,
                                      pr: '24px !important',
                                      borderRadius: '4px !important',
                                      bgcolor: 'transparent !important',
                                      border: '1px solid #bdbdbd !important',
                                      '&:focus': {
                                        bgcolor: 'transparent !important',
                                        borderRadius: '4px !important',
                                        border: '1px solid #bdbdbd !important',
                                      },
                                    },
                                  }}
                                  value={item.status}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value={'NOT_STARTED'}>
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                      <Box
                                        sx={{
                                          width: 14,
                                          height: 14,
                                          backgroundColor: getStatusColor1('NOT_STARTED'),
                                          borderRadius: '50%',
                                        }}
                                      />
                                      <Box>Not Started</Box>
                                    </Stack>
                                  </MenuItem>

                                  <MenuItem value={'IN_PROGRESS'}>
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                      <Box
                                        sx={{
                                          width: 14,
                                          height: 14,
                                          backgroundColor: getStatusColor1('IN_PROGRESS'),
                                          borderRadius: '50%',
                                        }}
                                      />
                                      <Box>In Progress</Box>
                                    </Stack>
                                  </MenuItem>
                                  <MenuItem value={'FINAL_STAGE'}>
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                      <Box
                                        sx={{
                                          width: 14,
                                          height: 14,
                                          backgroundColor: getStatusColor1('FINAL_STAGE'),
                                          borderRadius: '50%',
                                        }}
                                      />
                                      <Box>Final Stage</Box>
                                    </Stack>
                                  </MenuItem>
                                  <MenuItem value={'COMPLETED'}>
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                      <Box
                                        sx={{
                                          width: 14,
                                          height: 14,
                                          backgroundColor: getStatusColor1('COMPLETED'),
                                          borderRadius: '50%',
                                        }}
                                      />
                                      <Box>Completed</Box>
                                    </Stack>
                                  </MenuItem>
                                </Select>
                                {selectedIndex === id && (
                                  <Popup item={item} handleClose={handleClose} status={newStatus} />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell sx={{ minWidth: 150 }}>
                              {item?.response ? (
                                <Box
                                  sx={{ minWidth: 150, cursor: 'pointer' }}
                                  onClick={(e) => handleResponseChange(e, item, id)}
                                >
                                  {item.response}
                                </Box>
                              ) : (
                                <Chip
                                  size="small"
                                  icon={<Add />}
                                  label=" Response"
                                  variant="contained"
                                  color="greenish"
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) => handleResponseChange(e, item, id)}
                                />
                              )}
                              {selectedIndex1 === id && (
                                <Popup1 item={item} handleClose={handleClose} status={newStatus} />
                              )}
                            </TableCell>
                            {/* <TableCell sx={{ minWidth: 200 }}>{item.managerComment}</TableCell> */}
                            <TableCell sx={{ minWidth: 150 }}>
                              {item?.project && (
                                <>
                                  {item?.managerComment ? (
                                    <Box
                                      sx={{ minWidth: 150, cursor: 'pointer' }}
                                      onClick={(e) => handleManagerCommentChange(e, item, id)}
                                    >
                                      {item.managerComment}
                                    </Box>
                                  ) : (
                                    <Chip
                                      size="small"
                                      icon={<Add />}
                                      label="Comment"
                                      variant="contained"
                                      color="greenish"
                                      sx={{ cursor: 'pointer' }}
                                      onClick={(e) => handleManagerCommentChange(e, item, id)}
                                    />
                                  )}
                                  {selectedIndex2 === id && (
                                    <Popup2 item={item} handleClose={handleClose} status={newStatus} />
                                  )}
                                </>
                              )}
                            </TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={taskCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
