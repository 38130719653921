import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { useFormik, Form, FormikProvider } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const EditWorkEntryModal = ({ getAllWorkEntris, open, setOpen, selectedEntry, setSelectedEntry, project, options }) => {
  const matches = useMediaQuery('(max-width:600px)');

  const workEntrySchema = Yup.object().shape({
    id: Yup.string().required('Required'),
    date: Yup.date().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      id: selectedEntry?.id || '',
      date: moment(selectedEntry?.date).format('YYYY-MM-DD'),
      startTime: selectedEntry?.startTime ? moment(selectedEntry?.startTime).format('YYYY-MM-DDTHH:mm') : '',
      //  end time format is "2024-03-15T15:56:00.000Z"
      endTime: selectedEntry?.endTime ? moment(selectedEntry?.endTime).format('YYYY-MM-DDTHH:mm') : '',
    },
    validationSchema: workEntrySchema,
    onSubmit: async () => {
      try {
        if (moment(values.endTime).isBefore(moment(values.startTime))) {
          return toast.error('End time cannot be less than start time');
        }
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/workEntry`, {
          ...values,
          id: selectedEntry._id,
        });
        if (!res.data.isError) {
          setOpen(false);
          formik.resetForm();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setSelectedEntry(null);
        getAllWorkEntris(options);

        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleClose = () => {
    setOpen(false);
    resetForm();
    setSelectedEntry(null);
  };

  useEffect(() => {
    if (selectedEntry) {
      formik.setFieldValue('id', selectedEntry._id);
      formik.setFieldValue('workHours', selectedEntry.workHours);
      formik.setFieldValue('date', moment(selectedEntry.date).format('YYYY-MM-DD'));
      formik.setFieldValue('startTime', selectedEntry.startTime ? moment(selectedEntry.startTime).format('YYYY-MM-DDTHH:mm') : '');
      formik.setFieldValue('endTime', selectedEntry.endTime ? moment(selectedEntry.endTime).format('YYYY-MM-DDTHH:mm') : '');
    }
  }, [selectedEntry]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={matches}>
      <DialogTitle>Edit Work Entry</DialogTitle>
      <Box paddingBottom={'10px'} width={{ xs: '100%', sm: '600px' }}>
        <DialogContent paddingTop="20px">
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField fullWidth label="Project" value={project} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Name" value={selectedEntry?.workerData?.fullname} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="date"
                    fullWidth
                    label="Date"
                    {...getFieldProps('date')}
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Work Hours"
                    disabled
                    {...getFieldProps('workHours')}
                    error={Boolean(touched.workHours && errors.workHours)}
                    helperText={touched.workHours && errors.workHours}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="datetime-local"
                    label="Start Time"
                    type="datetime-local"
                    sx={{ width: 250 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...getFieldProps('startTime')}
                    error={Boolean(touched.startTime && errors.startTime)}
                    helperText={touched.startTime && errors.startTime}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="datetime-local"
                    label="End Time"
                    type="datetime-local"
                    sx={{ width: 250 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...getFieldProps('endTime')}
                    error={Boolean(touched.endTime && errors.endTime)}
                    helperText={touched.endTime && errors.endTime}
                  />
                </Grid>
              </Grid>
              <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px" spacing={2}>
                <Button onClick={handleClose}>Cancel</Button>
                <LoadingButton color="primary" type="submit" loading={isSubmitting} variant="contained">
                  Update
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default EditWorkEntryModal;
