import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Button,
  Box,
  Avatar,
  Grid,
} from '@mui/material';
import {
  Add,
  AddCircle,
  ArrowBack,
  ContentCopy,
  Delete,
  Edit,
  Remove,
  RemoveCircle,
  RemoveRedEye,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
// import Filter from '../components/filters/Qtos';

// components
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Page from '../components/Page';

import PageHeader from '../components/smallComponents/PageHeader';
import Export from '../components/filters/MaterialTransferExport';
// import AddMaterialToProject from '../components/popups/AddMaterialToProject';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));
 
function DetailItem({ icon, title, data, background, sx, onClick, profilePic }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
      sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={profilePic ? 0 : 1}
      >
        {profilePic && (
          <Avatar src={profilePic} alt={title} style={{ height: '100px', width: '100px' }} color="primary" />
        )}
        {!profilePic && (
          <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
        )}
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

export default function ProjectMaterials() {
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('projectId');
  const [materialData, setMaterialData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [materialDataCount, setMaterialDataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [project, setProject] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLoading1, setIsLoading1] = useState(false);

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getMaterialData(temp);
  };

  const getMaterialData = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/usageInProject`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('Quotation', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMaterialDataCount(res.data.data.maxRecords);
          setMaterialData(res.data.data.data);
          setGrandTotal(res.data.data.grandTotal);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getMaterialDataWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/materialFlowLog/usageInProject`, {
        params: {
          ...options,
          projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setMaterialDataCount(res.data.data.maxRecords);
          setMaterialData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getProject = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project`, {
        params: {
          id: projectId,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setProject(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, materialName: e.target.value };
    setOptions(temp);
    setPages(0);
    getMaterialDataWithoutLoading(temp);
  };

  const exportMaterilTransfer = async (options) => {
    console.log(options);
    setIsLoading1(true);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/materialFlowLog/usageInProject/export`, {
        ...options,
        projectId,
      });
      console.log('res', res);
      setIsLoading1(false);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
      toast.success(error.response.data.message);
    }
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };

    if (filter.qtoType) {
      temp.qtoType = filter.qtoType.trim();
    }

    if (filter.paymentStartDate) {
      temp.paymentStartDate = filter.paymentStartDate;
    }
    if (filter.paymentEndDate) {
      temp.paymentEndDate = filter.paymentEndDate;
    }
    if (filter.txnStartDate) {
      temp.txnStartDate = filter.txnStartDate;
    }
    if (filter.txnEndDate) {
      temp.txnEndDate = filter.txnEndDate;
    }
    if (filter.paymentMode) {
      temp.paymentMode = filter.paymentMode;
    }

    setOptions(temp);
    setPages(0);
    getMaterialData(temp);
  };

  useEffect(() => {
    getMaterialData(options);
    getProject();
  }, []);

  const handleMaterialAddRemoveSuccess = () => {
    setOpenPopup(false);
    getMaterialData(options);
  };

  return (
    <Page title="Project Materials">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box padding={2} sx={{ maxWidth: '100%' }}>
            <Stack direction={'row'} justifyContent={'end'}>
              <Export />
              <LoadingButton
                endIcon={<Iconify icon="ph:export-bold" />}
                variant="outlined"
                color="primary"
                onClick={() => exportMaterilTransfer(options)}
                loading={isLoading1}
                size="small"
              >
                Export
              </LoadingButton>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {/* <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search Material..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack> */}

              <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                {/* <Button startIcon={<Add />} variant="contained" onClick={() => setOpenPopup(true)}>
                    Add
                  </Button> */}
                {/* <AddMaterialToProject 
                handleSuccess={handleMaterialAddRemoveSuccess}
                open={openPopup}
                setOpen={setOpenPopup}
                material={material}
              /> */}
                {/* <Filter applyFilters={applyFilters} /> */}
              </Stack>
            </Stack>
            {/* <Stack direction="row" alignItems="end" justifyContent="space-between">
              <Typography variant="h5" gutterBottom mb={4}>
                Project Name: {project?.name}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="end" justifyContent="space-between">
              <Typography variant="h5" gutterBottom mb={4}>
                Grand Total: AED {parseFloat(grandTotal).toFixed(2)}
              </Typography>
            </Stack> */}
            <Grid container spacing={2} marginBottom={2}>
              <Grid item xs={12} lg={8}>
                <DetailItem
                  icon={'icons8:parallel-tasks'}
                  title={'Project Name'}
                  data={project?.name}
                  sx={{
                    height: '100%',
                    minHeight: '65px',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DetailItem
                  icon={'majesticons:money-line'}
                  title={'Grand Total'}
                  data={`AED ${parseFloat(grandTotal).toFixed(2)}`}
                  sx={{
                    height: '100%',
                    minHeight: '65px',
                    borderRadius: '5px',
                  }}
                />
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Unit</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell sx={{ maxWidth: '180px' }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {materialData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar
                              src={item?.image && process.env.REACT_APP_API_URL + item.image}
                              sx={{ width: 40, height: 40 }}
                            >
                              {item?.toolName?.charAt(0).toUpperCase()}
                            </Avatar>
                            <Box sx={{ marginLeft: 1 }}>
                              <Typography variant="subtitle2">{item?.materialName}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>{item.unit}</TableCell>
                        <TableCell>{item.unitPrice}</TableCell>
                        <TableCell>{item.totalQuantity}</TableCell>
                        <TableCell>
                          {parseFloat(Number(item.totalQuantity) * Number(item.unitPrice)).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Link
                              to={`/dashboard/project/material/view?materialId=${item.materialId}&&projectId=${projectId}`}
                            >
                              <Icon sx={{ color: 'gray' }}>
                                <RemoveRedEye />
                              </Icon>
                            </Link>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
                const temp = { ...options, size: e.target.value, page: 0 };
                setPages(0);
                setOptions(temp);
                getMaterialData(temp);
              }}
              component={'div'}
              count={materialDataCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Box>
        </>
      )}
    </Page>
  );
}
