import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MemberList from './MemberList';

export default function ProjectMemberInfo({ project, getProject, projectWriteAccess }) {
  const [isLoading, setIsLoading] = useState(true);

  // const getStatusColor = (statusName) => {
  //   const statusMapping = {
  //     Pending: '#fff100',
  //     Ongoing: '#fff100',
  //     'Decision Pending': '#fff100',
  //     Interested: '#16c60c',
  //     'Not Interested': '#e81224',
  //   };
  //   return statusMapping[statusName] || 'white';
  // };

  useEffect(() => {
    if (project && project?.name) setIsLoading(false);
  }, [project]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <MemberList
            managers={project?.managers}
            engineers={project?.engineers}
            workers={project?.workers}
            siteEngineers={project?.siteEngineers}
            status={project?.status}
          />
        </>
      )}
    </>
  );
}
