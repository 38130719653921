import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';

const UpdateMainItemStatus = ({ item, handleClose, status }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const updateStatus = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/quotation/qto/item`, {
        itemId: item._id,
        id,
        status,
      })
      .then((res) => {
        handleClose(true, res.data.message);
      })
      .catch((err) => {
        handleClose(false, err.response.data.message);
      });
  };

  const open = true;

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack spacing={2}>
              <Typography>
                Are you sure you want to change the status of the item to {status && status.replaceAll('_', ' ')} ?
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateStatus}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UpdateMainItemStatus;
