import { Box, Button, Card, CardContent, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import { BorderColor } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Iconify from '../components/Iconify';
import EditSalary from '../components/EditSalary';
import PaymentsTable from '../components/PaymentsTable';

function DetailItem({ icon, title, data, background, sx, onClick }) {
  return (
    <Stack
      alignItems="center"
      spacing={1}
      direction="row"
     sx={{
        height: '100%',
        background: (theme) => theme.palette.macinsIndigo.lighter,
        minHeight: '65px',
        borderRadius: '5px',
        ...sx,
      }}
      onClick={onClick}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ height: '100%', borderRadius: '5px 0px 0px 5px' }}
        background={background}
        padding={1}
      >
      <Iconify icon={icon} sx={{ height: '40px', width: '40px', color: (theme) => theme.palette.primary.main }} />
      </Stack>

      <Stack direction="column" sx={{ padding: 1 }}>
        <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</Typography>
        <Typography style={{ wordBreak: 'break-all' }}>{data}</Typography>
      </Stack>
    </Stack>
  );
}

function ViewSalary() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [salary, setSalary] = useState({});
  const [editMode, setEditMode] = useState(false);

  const getSalary = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/salary`, { params: { _id: id } });
      if (res.data.isError === false) {
        setSalary(res.data.data.record);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSalary();
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Card>
          {!editMode && (
            <>
              <Stack
                direction={'row'}
                px={2}
                py={2}
                sx={{ backgroundColor: (theme) => theme.palette.macinsIndigo.main }}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography variant="h5" fontWeight={'bold'}>
                  Salary
                </Typography>
                <IconButton onClick={() => setEditMode(true)}>
                  <BorderColor />
                </IconButton>
              </Stack>
              <Grid container spacing={2} sx={{ px: 2, py: 2 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem
                    icon="mdi:account"
                    onClick={() => navigate(`/dashboard/general/employees/view/${salary.employeeId}`)}
                    title="Employee ID"
                    data={salary.employeeId}
                    background="#f0f0f0"
                    sx={{ cursor: 'pointer' }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem
                    icon="bxs:calendar"
                    title="Salary Date"
                    data={moment(salary.salaryDate).format('DD/MM/YYYY')}
                    background="#f0f0f0"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem
                    icon="grommet-icons:status-unknown"
                    title="Payment Status"
                    data={salary.isPaymentCompleted ? 'Completed' : 'Pending'}
                    background="#f0f0f0"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="majesticons:money-line" title="Amount" data={salary.amount} background="#f0f0f0" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem icon="majesticons:money-line" title="Paid" data={salary.paidAmount} background="#f0f0f0" />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <DetailItem
                    icon="majesticons:money-line"
                    title="Due"
                    data={parseFloat(salary.amount) - parseFloat(salary.paidAmount)}
                    background="#f0f0f0"
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <DetailItem icon="gg:notes" title="Notes" data={salary.notes} background="#f0f0f0" />
                </Grid>
              </Grid>
              {!salary.isPaymentCompleted && (
                <Stack direction={'row'} p={2} justifyContent={'end'} sx={{ width: '100%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      navigate(`/dashboard/accounts/pending-payments/salary/view-payment/${salary.employeeId}?`, {
                        state: { invoice: salary },
                      })
                    }
                  >
                    Make Payment
                  </Button>
                </Stack>
              )}
            </>
          )}
          {editMode && <EditSalary salary={salary} setEditMode={setEditMode} getSalary={getSalary} />}
        </Card>
        {salary?.transactions && salary.transactions.length > 0 && <PaymentsTable transactions={salary.transactions} />}
      </Stack>
    </Container>
  );
}

export default ViewSalary;
